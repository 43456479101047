// src/router/configuracoes.js
import NovaConfiguracaoPage from "@/views/Configuracoes/NovaConfiguracaoPage.vue";

const configuracoesBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Configurações',
        to: 'ConfiguracoesEdit'
    }
]

export default [

    {
        path: '/configuracoes',
        name: 'ConfiguracoesEdit',
        component: NovaConfiguracaoPage,
        meta: {
            title: 'Detalhes do Cliente',
            breadcrumb: [
                ...configuracoesBreadCrumb,
                {
                    label: 'Novo Cargo',
                    to: 'CargoNovo'
                }
            ]
        }
    }

];
