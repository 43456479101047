<template>
  <BreadCrumb />
  <OrigemDestinoList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import OrigemDestinoList from "@/views/OrigensDestinos/OrigemDestinoList.vue";

export default defineComponent({
  name: "ProcessoListPage",
  components: {
    BreadCrumb,
    OrigemDestinoList,
  },
});
</script>