import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getGruposResponsaveis = async (pessoaId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/pessoa/grupo/${pessoaId}/responsaveis`,{});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar informações do grupo:', error);
        throw error;
    }
};

const getGrupos = async (pageNumber, pageSize, searchTerm, situacao) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/grupo/lista`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                situacao
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getGrupo = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/grupo/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar grupo', error);
        throw error;
    }
};

const updateGrupo = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/grupo/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o grupo', error);
        throw error;
    }
};

const createGrupo = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/grupo`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o grupo', error);
        throw error;
    }
};


const updateAnalistaResponsavel = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/grupo/${id}/alterar-analista`, {});
        return response.data;
    } catch (error) {
        console.error('Erro ao alaterar o analista responsavel do grupo', error);
        throw error;
    }
};

export default {
    getGruposResponsaveis,
    getGrupos,
    getGrupo,
    createGrupo,
    updateGrupo,
    updateAnalistaResponsavel
};