import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;
const get = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/configuracao/last`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar configuracao', error);
        throw error;
    }
};

const save = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/configuracao`,values);

        return response.data;
    } catch (error) {
        console.error('Erro ao enviar e-mail com o usuario', error);
        throw error;
    }
};


export default {
    get,
    save
};