// utils/dateHelper.js

import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import {numberFormat} from "@/utils/numberHelper.js";
import RepeaterInput from "@/components/form/RepeaterInput.vue";
import KeycloakService from "@/security/KeycloakService.js";
import keycloakService from "@/security/KeycloakService.js";
import MultiSelectInput from "@/components/form/MultiSelectInput.vue";

export function getFilterData (formSchema, values) {
    let filterValues = [];
    const fieldSettings = formSchema.sections.reduce((acc, section) => {
        section.fields.forEach(field => {
            acc[field.name] = field;
        });
        return acc;
    }, {});

    for (let index in fieldSettings) {
        let field = fieldSettings[index];

        let filter = { propertyName: field.name ,value: null,operation: "Equals" }

        if ( values && values[field.name] !== undefined && values[field.name] !== null && (!Array.isArray(values[field.name]) || values[field.name].length > 0 )) {
            switch (field.type) {
                case RelationInput:
                    filter.value = values[field.name].id;
                    break;
                case TextInput:
                    filter.value = values[field.name];
                    filter.operation = "Contains"
                    break;
                case MultiSelectInput:
                    filter.value = values[field.name].map(e => e.id).join(',');
                    filter.operation = "Contains"
                    break;
                default:
                    filter.value = values[field.name];
            }

            filterValues.push(filter)
        }

    }

   //return JSON.stringify(filterValues)

   return filterValues;
}

export function getStatusFilterData (values) {
    let filterValues = [];

    for (let index in values) {
        let field = values[index];

        if (field.value) {
            filterValues.push(
                {
                    propertyName: field.name,
                    value: field.value,
                    operation: "Equals"
                }
            )
        }
    }

    return filterValues;
}


function formatNumberFields (numberFields, values) {
    var formatedValues = {
        ...values
    }
    for(let index in numberFields) {
        let decimalNumbers = 2;
        let numberField = numberFields[index];

        if (numberField.hasOwnProperty('decimalNumbers')) {
            decimalNumbers = numberField.decimalNumbers;
        }

        let value = values[numberField.name]

        if(!!value) {
            formatedValues[numberField.name] = numberFormat(value,decimalNumbers, '.', '')
        }
    }

    return formatedValues
}


function formatRepeatedFields (fieldSettings, values) {
    var formatterValues = {
        ...values
    }
    let repeaterFields = fieldSettings.filter(el => el.type == RepeaterInput);

    for (let index in repeaterFields) {
        let repeaterField = repeaterFields[index];
        let numberFields = repeaterField.fields.filter(el => el.type == NumberInput);
        let fieldValues = values[repeaterField.name]

        for (let valueIndex in fieldValues) {
            let value = fieldValues[valueIndex];

            formatterValues[repeaterField.name][valueIndex] = formatNumberFields(numberFields, value)
        }
    }

    return formatterValues
}


export function getFormatedFormData(formSchema, values) {
    let formatedValues = {
        ...values
    }

    const fieldSettings = formSchema.sections.reduce((acc, section) => {
        return acc.concat(section.fields); // Concatena os campos ao acumulador
    }, []);

    let numberFields = fieldSettings.filter(el => el.type == NumberInput);

    formatedValues = formatNumberFields(numberFields, formatedValues);
    formatedValues = formatRepeatedFields(fieldSettings, formatedValues);

    return formatedValues;
}

export function getPessoa() {
    return keycloakService.GetPessoa()
}