// src/i18n.js
import { createI18n } from 'vue-i18n'
import en from '@/locales/en.json'
import ptBr from '@/locales/ptBr.json'

const messages = {
    en,
    ptBr
}

const i18n = createI18n({
    locale: 'ptBr', // Idioma padrão
    fallbackLocale: 'ptBr', // Idioma de fallback
    messages
})

export default i18n
