import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;


const getCepInfo = async (cep) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/endereco/${cep}/consulta`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar cep', error);
        throw error;
    }
};



export default {
    getCepInfo,

};