import axios from "axios";
import KeyCloakService from "../security/KeycloakService";


const API_URL = 'http://localhost:5296'; // Substitua pela URL da sua API


const HttpMethods = {
    GET: "GET",
    POST: "POST",
    DELETE: "DELETE",
};

const _axios = axios.create();

const cb = (config) => {
    config.headers.Authorization = `Bearer ${KeyCloakService.GetAccesToken()}`;
    return config;
};


const configureAxiosKeycloak = () => {
    _axios.interceptors.request.use(
        (config) => {
            if (KeyCloakService.IsLoggedIn()) {
                KeyCloakService.UpdateToken(() => cb(config));

                config.headers.Authorization = `Bearer ${KeyCloakService.GetAccesToken()}`;
            }

            return config;
        }
    );
};

const getAxiosClient = () => _axios;

const HttpService = {
    HttpMethods,
    configureAxiosKeycloak,
    getAxiosClient,
};

export default HttpService;