import Httpservice from "@/services/httpservice.js";
import Cookies from 'js-cookie';
import {Slugfy} from "@/utils/stringHelper.js";

const API_URL = import.meta.env.VITE_API_URL;

const getPage = async (endpoint,serverOptions, searchTerm, filters, sortBy ) => {
    try
    {
        const url =  `${API_URL}${endpoint}`
        const params = new URLSearchParams();

        params.append('pageNumber', serverOptions.value.page);
        params.append('pageSize', serverOptions.value.rowsPerPage);

        if(searchTerm) {
            params.append('searchTerm', searchTerm);
        }


        filters.forEach((filter, index) => {
            Object.keys(filter).forEach(key => {
                params.append(`filters[${index}].${key}`, filter[key]);
            });
        });

        sortBy.forEach((sort, index) => {
            Object.keys(sort).forEach(key => {
                params.append(`sortBy[${index}].${key}`, sort[key]);
            });
        });


        const response = await Httpservice.getAxiosClient().get(`${url}`,{
            params: params
        });

        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};
;



const getSettings = async (tela, secao) => {
    try
    {
        tela = Slugfy(tela)
        const url =  `${API_URL}/api/usuario/configuracao/${tela}/${secao}`

        const response = await Httpservice.getAxiosClient().get(`${url}`);

        return response.data;
    } catch (error) {
        return null;
    }
};
;

const saveSettings = async (tela, secao, values) => {
    try {
        tela = Slugfy(tela)

        const response = await Httpservice.getAxiosClient()
            .put(`${API_URL}/api/usuario/configuracao/${tela}/${secao}`,
                JSON.stringify(values),
                {
                    headers: {
                        'Content-Type': 'application/json',
                    }
                });

        return response.data;
    } catch (error) {
        console.error('Erro ao salvar preferencias', error);
        throw error;
    }
};

const saveLocalSettings = async (tela, secao, values) => {
    try {
        tela = Slugfy(tela)

        let key = `${tela}-${secao}`

        await Cookies.set(key, JSON.stringify(values), { expires: 3650 })
    } catch (error) {
        console.error('Erro ao salvar preferencias', error);
        throw error;
    }
};

const getLocalSettings = async (tela, secao) => {
    try {
        tela = Slugfy(tela)

        let key = `${tela}-${secao}`

        let values = await Cookies.get(key)

        if(!values) {
            return null
        }

        return JSON.parse(values)
    } catch (error) {
        console.error('Erro ao salvar preferencias', error);
        throw error;
    }
};

export default {
    getPage,
    saveSettings,
    getSettings,
    saveLocalSettings,
    getLocalSettings
};