<template>
  <BreadCrumb  />
  <DepotForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import DepotForm from "@/views/Depots/DepotForm.vue";

export default defineComponent({
  name: "NovoProcessoPage",
  components: {
    BreadCrumb,
    DepotForm,
  },
});
</script>
