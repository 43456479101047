<template>
  <div
      class="AutocompleteInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label v-if="displayLabel" :for="name" class="d-block text-black fw-semibold mb-10">{{ label }}</label>
    <Multiselect
        :name="name"
        :id="name"
        valueProp="id"
        label="nome"
        mode="tags"
        noOptionsText="Digite para buscar"
        :value="inputValue"
        placeholder="Selecione..."
        :close-on-select="true"
        track-by="id"
        :searchable="true"
        :create-tag="false"
        :filter-results="true"
        :object="true"
        :resolve-on-load="false"
        :delay="200"
        :min-chars="1"
        @input="handleInputChange"
        :options="options"
    >
      <template v-slot:tag="{ option, handleTagRemove, disabled }">
              <div
                  class="multiselect-tag is-user"
                  :class="{
                  'is-disabled': disabled
                }"
              >
                {{ display(option) }}
                <span
                    v-if="!disabled"
                    class="multiselect-tag-remove"
                    @click="handleTagRemove(option, $event)"
                >
                  <span class="multiselect-tag-remove-icon"></span>
                </span>
              </div>
            </template>

      <template v-slot:option="{ option }">
        <span v-if="option.__CREATE__">{{ display(option) }}</span>
        <span v-else>{{ display(option) }}</span>
      </template>
    </Multiselect>

    <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }"  v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>


<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';
import Multiselect from "@vueform/multiselect";
import AutocompleteService from "@/services/AutocompleteService.js";

const props = defineProps({
  params: {
    type: Object,
    required: false,
    default: () => {}
  },
  maxLength: {
    type: Number,
    required: false,
    default: 255
  },
  displayLabel: {
    type: Boolean,
    required:false,
    default: true
  },
  mask: {
    type: String,
    required: false,
    default: undefined,
  },
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onChanged: {
    type: Function,
    required: false,
    default: () => {}
  },
  display: {
    type: Function,
    required: false,
    default: (value) => {

      return `${value.nome}`;
    }
  },
  options: {
    type: Array,
    required: true,
    default: () => []
  }
});

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const handleInputChange = (e) => {
  handleChange(e); // Chama o manipulador de mudança padrão do VeeValidate
  props.onChanged(e); // Chama a função passada via props
};



const formatLabel = (option) => {
  return `${option.id} - : ${option.name}`;
}


</script>