<template>
  <div class="card mb-25 border-0 rounded-0 bg-white stats-box h-100">
    <div class="card-body pe-20 ps-20 pe-md-25 ps-md-25 pe-lg-15 ps-lg-15">
      <div class="mb-15 d-flex align-items-center justify-content-between">
        <h5 class="card-title fw-bold mb-0">Pontuação de Demanda</h5>
      </div>
      <div class="row">
        <div class="col-12 col-md-6">
          <div class="title ms-15">
                  <span
                      class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis"
                  >
                Processos
              </span>
            <h4 class="fw-black mb-8 lh-1">
              {{ formatInteger(totalProcessos, 0) }}
            </h4>
          </div>
        </div>
        <div class="col-12 col-md-6">
          <div class="title ms-15 mb-30 ">
                  <span
                      class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis"
                  >
                Score
              </span>
            <h4 class="fw-black mb-8 lh-1">
              {{ formatInteger(totalScore, 0) }}
            </h4>


          </div>
        </div>
      </div>
      <!-- Processos -->
      <div class="title ms-15">
                  <span
                      class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis"
                  >
                Processos
              </span>
      </div>
      <div class="row mb-10">
        <div class="col-12 col-md-3" v-for="(nivel, i) in processosPorNivel" v-bind:key="i">
          <div class="title ms-15">

                  <span
                      class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis"
                  >
                {{ nivel.classificacao }}
              </span>
            <h4 class="fw-black mb-8 lh-1">
              {{ formatInteger(nivel.nProcessos) }}
            </h4>
          </div>
        </div>


      </div>

      <!-- Clientes -->
      <div class="title ms-15">
                  <span
                      class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis"
                  >
                Clientes
              </span>
      </div>
      <div class="row">
        <div class="col-12 col-md-3" v-for="(nivel, i) in clientesPorNivel" v-bind:key="i">
          <div class="title ms-15">

                  <span
                      class="d-block mb-7 fs-13 text-uppercase fw-medium text-dark-emphasis"
                  >
                {{ nivel.classificacao }}
              </span>
            <h4 class="fw-black mb-8 lh-1">
              {{ formatInteger(nivel.nClientes) }}
            </h4>
          </div>
        </div>


      </div>


    </div>
  </div>

</template>

<script setup>
import {computed} from "vue";
import {formatInteger} from "@/utils/numberHelper.js";


let colors = ['#A8D5A2CC', '#F7E7A0CC', '#F8B88BCC', '#F8A6A1CC']

const props = defineProps({
      processosPorNivel: {
        type: Array,
        required: true,
        default: () => []
      },
      clientesPorNivel: {
        type: Array,
        required: true,
        default: () => []
      }
    }
);

const totalProcessos = computed(() => {
  return props.processosPorNivel.reduce((acumulador, el) => acumulador + el.nProcessos, 0)
})

const totalScore = computed(() => {

  return props.processosPorNivel.reduce((acumulador, el) => acumulador + el.Score, 0)

})

const formatLegend = (index) => {
  let color = colors[index];

  //let style ='background: rgba(248, 166, 161, 0.8) !important; color: rgba(248, 166, 161, 0.8); height: 12px; width: 12px; left: -2px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;'
  let style = 'background: ' + color + ' !important; color: ' + color + '; height: 12px; width: 12px; left: -2px; top: 0px; border-width: 0px; border-color: rgb(255, 255, 255); border-radius: 12px;'
  return style;
}

</script>

<style scoped>

.list-unstyled li {
  padding-top: 5px;
}
</style>

