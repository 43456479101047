<template>
  <div class="nav">
    <ul>
      <li>
        <router-link to="/">Voltar para a Home</router-link>
      </li>
    </ul>
  </div>
  <slot></slot>
</template>

