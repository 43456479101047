// src/router/usuarios.js
import UsuarioListPage from "@/views/Usuarios/UsuarioListPage.vue";
import NovoUsuarioPage from "@/views/Usuarios/NovoUsuarioPage.vue";
import OceanProducts from "@/views/Schedule/OceanProducts.vue";
import VesselPage from "@/views/Schedule/VesselPage.vue";

const usuariosBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Usuários',
        to: 'Usuarios'
    }
]

export default [
    {
        path: '/usuarios',
        name: 'Usuarios',
        component: UsuarioListPage,
        meta: {
            title: 'Usuários',
            breadcrumb: usuariosBreadCrumb
        },
    },
    {
        path: '/usuarios/novo',
        name: 'UsuarioNovo',
        component: NovoUsuarioPage,
        meta: {
            title: 'Novo Usuário',
            breadcrumb: [
                ...usuariosBreadCrumb,
                {
                    label: 'Novo Cliente',
                    to: 'ClienteNovo'
                }
            ]
        }
    },
    {
        path: '/usuarios/:id/edit',
        name: 'UsuarioEdit',
        component: NovoUsuarioPage,
        meta: {
            title: 'Detalhes do Cliente',
            breadcrumb: [
                ...usuariosBreadCrumb,
                {
                    label: 'Novo Usuário',
                    to: 'UsuarioNovo'
                }
            ]
        }
    },
    {
        path: '/schedule',
        name: 'Schedule',
        component: OceanProducts,
        meta: {
            title: 'Schedule',
            breadcrumb: [
                ...usuariosBreadCrumb,
                {
                    label: 'Novo Usuário',
                    to: 'UsuarioNovo'
                }
            ]
        }
    },
    {
        path: '/vessel-page',
        name: 'VessalPage',
        component: VesselPage,
        meta: {
            title: 'Schedule',
            breadcrumb: [
                ...usuariosBreadCrumb,
                {
                    label: 'Novo Usuário',
                    to: 'UsuarioNovo'
                }
            ]
        }
    }
];
