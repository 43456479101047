<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="fullPage"/>
  <data-grid
      endpoint="/api/agenda/list"
      section="data-grid"
      :filterForm="filterForm"
      :default-filters="defaultFilters"
      :columns="defaultColumnsSettings"
      :initial-sort-by="[{ propertyName: 'previsaoInicio', sortOrder: 'asc'}]"
      :force-reload="forceReload"
      @on-db-click-row="onConcluirTarefaDb"
  >
    <template v-slot:[`item-tarefaSituacao`]="{item}">
      <div class="text-center">
        <span  v-if="item.tarefaSituacao == 1" class="icon text-center rounded-1 text-white p-1 position-relative bg-warning ">Pendente</span>
        <span  v-if="item.tarefaSituacao == 2" class="icon text-center rounded-1 text-white p-1 position-relative bg-orange ">Adiado</span>
        <span  v-if="item.tarefaSituacao == 3" class="icon text-center rounded-1 text-white p-1 position-relative bg-success ">Concluído</span>
        <span  v-if="item.tarefaSituacao == 4" class="icon text-center rounded-1 text-white p-1 position-relative bg-danger ">Não Realizado</span>
        <span  v-if="item.tarefaSituacao == 99" class="icon text-center rounded-1 text-white p-1 position-relative bg-danger ">Atrasado</span>
      </div>
    </template>

    <template v-slot:[`item-previsaoInicio`]="{item}">
      <div>{{ formatDate(item.previsaoInicio)}}</div>
    </template>

    <template v-slot:[`item-inicio`]="{item}">
      <div>{{ formatDate(item.inicio)}}</div>
    </template>

    <template v-slot:[`item-previsaoConclusao`]="{item}">
      <div>{{ formatDate(item.previsaoConclusao)}}</div>
    </template>

    <template v-slot:[`item-conclusao`]="{item}">
      <div>{{ formatDate(item.conclusao)}}</div>
    </template>






    <template v-slot:[`item-acoes`]="{item}">
      <div class="dropdown">
        <button
            class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
            type="button"
            data-bs-toggle="dropdown"
            aria-expanded="false"
        >
          <i class="flaticon-dots"></i>
        </button>
        <ul class="dropdown-menu">
          <li>
            <a
                class="dropdown-item d-flex align-items-center"
                @click="onTarefaDetalhes(item.id)"
                href="javascript:void(0);"
            ><i
                class="flaticon-edit lh-1 me-8 position-relative top-1"
            ></i>
              Detalhes</a>
          </li>
          <li>
            <a
                class="dropdown-item d-flex align-items-center"
                @click="onConcluirTarefa(item)"
                href="javascript:void(0);"
            ><i
                class="flaticon-check lh-1 me-8 position-relative top-1"
            ></i>
              Concluir</a>
          </li>
          <li>
            <a
                class="dropdown-item d-flex align-items-center"
                @click="onAdiarTarefa(item.id)"
                href="javascript:void(0);"
            ><i
                class="flaticon-calendar lh-1 me-8 position-relative top-1"
            ></i>
              Adiar</a
            >
          </li>
        </ul>
      </div>
    </template>

  </data-grid>

  <EnviarFollowModal ref="followUpModal"  @save="onSave()"  @onLoading="onLoadingChange" />
  <ConferenciaDiModal ref="conferenciaDiModal"  @save="onSave()"  @onLoading="onLoadingChange" />
  <AdiarTarefaModal ref="adiarTarefaModal"  @save="onSave()" />
</template>

<script setup>
import AgendaService from "@/services/AgendaService.js";
import router from "@/router/index.js";
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";

import SelectInput from "@/components/form/SelectInput.vue";
import dataGrid from '@/components/dataGrid/dataGrid.vue'

import {formatDate} from "../../utils/dateHelper.js";
import {ref} from "vue";
import EnviarFollowModal from "@/views/Agenda/Modal/EnviarFollowModal.vue";
import ConferenciaDiModal from "@/views/Agenda/Modal/ConferenciaDiModal.vue";
import Loading from "vue-loading-overlay";
import AdiarTarefaModal from "@/views/Agenda/Modal/AdiarTarefaModal.vue";

const defaultFilters = {
  statusFilters: [
    {
      label: 'Situação',
      name: 'situacao',
      value: 1,
      options: [
        { value: null,  name: 'Todos' },
        { value: 1,     name: 'Pendente' },
        { value: 2,     name: 'Adiado' },
        { value: 3,     name: 'Concluido' },
        { value: 4,     name: 'Não realizada' },
        { value: 99,     name: 'Atrasada' },
      ]
    }
  ],
  filters: null
}

const defaultColumnsSettings = [
  { text: "CÓD.", value: "tarefaTipoCodigo", sortable: true },
  { text: "TIPO", value: "tarefaTipo", sortable: true },
  { text: "PROCESSO", value: "processo", sortable: true },
  { text: "CLIENTE", value: "cliente", sortable: true },
  { text: "SITUAÇÃO", value: "tarefaSituacao", sortable: true, width: 120 },
  { text: "PREV. INÍCIO", value: "previsaoInicio", sortable: true },
  { text: "PREV. CONCLUSÃO", value: "previsaoConclusao", sortable: true },
  { text: "INÍCIO", value: "inicio", sortable: true },
  { text: "CONCLUSÃO", value: "conclusao", sortable: true },
  { text: "RESPONSÁVEL", value: "responsavel", sortable: true },
  { text: "AÇÕES", value: "acoes", sortable: false, slot: true },
]

const filterForm = {
  sections: [
    {
      section: "Geral",
      fields: [
        {
          label: 'Cliente',
          name: 'cliente',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/cliente',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Tipo',
          name: 'tarefaTipo',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'tarefa-tipo/lista',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Responsável',
          name: 'responsavel',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
      ]
    }

  ],
};

const isLoading = ref(false)
const forceReload = ref(new Date())
const fullPage = ref(false)
const followUpModal = ref(null)
const conferenciaDiModal = ref(null)
const adiarTarefaModal = ref(null)
const onConcluirTarefa = (item) => {
  console.log('item', item)

  let tarefaTipo = item.tarefaTipoCodigo

  switch (tarefaTipo) {
    case '1.001':
      if (followUpModal.value) {
        followUpModal.value.openModal(item.id, item.clienteId)
      }
      break
    case '2.001':
      if (conferenciaDiModal.value) {
        conferenciaDiModal.value.openModal(item)
      }
      break;
  }
}
const onConcluirTarefaDb = (item) => {
  onConcluirTarefa(item)
}

const onAdiarTarefa = (tarefaId) => {
  if (adiarTarefaModal.value) {
    adiarTarefaModal.value.openModal(tarefaId)
  }
}

const onTarefaDetalhes = (tarefaId) => {
  router.push({name: 'TarefaDetalhes', params: {id: tarefaId}});
}

function onSave() {
  forceReload.value = new Date()
}

const onLoadingChange = (loading) => {
  isLoading.value = loading
}

</script>
