<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            :placeholder="$t('Buscar')"
            v-model="searchTerm"
            @input="filtroGeral()"
            @keydown.enter.prevent
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <button
           @click="onNovoAcompanhamento( processoId )"
          class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          {{ $t('Novo Acompanhamento') }}
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </button>
        <select
          class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10"
          v-model="situacao"
          @change="situacaoAlterada()"
        >
          <option :selected="situacao == null" :value="null">Todos <span v-if="situacao == null">({{ totalAcompanhamentos }})</span> </option>
          <option :selected="situacao == 1" value="1">Interno <span v-if="situacao == 1">({{ totalAcompanhamentos }})</span> </option>
          <option :selected="situacao == 0" value="0">Externo <span v-if="situacao == 0">({{ totalAcompanhamentos }})</span> </option>
        </select>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"/>
        <table class="table table-compact align-middle mb-0">
          <thead>
            <tr>
              <th width="150px"
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0"
              >
                DATA
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                RESPONSÁVEL
              </th>
              <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                TIPO
              </th>
              <th
                  width="80px"
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                VISIBILIDADE
              </th>
              <th
                  width="60%"
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                MENSAGEM
              </th>
              <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >

              </th>
            </tr>
          </thead>
          <tbody>

            <tr v-for="acompanhamento in acompanhamentos" v-bind:key="acompanhamento.id">
              <td class="shadow-none lh-1">
                {{ formatDateTime(acompanhamento.data) }}
              </td>
              <th
                  class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0"
              >
                <div class="d-flex align-items-center">
                  {{ acompanhamento.responsavel?.nome }}
                </div>
              </th>
              <td class="shadow-none lh-1 fw-medium">
                <span  v-if="acompanhamento.tipoAcompanhamento == 1" class="icon text-center p-1 position-relative">Mapa Madeira</span>
                <span  v-if="acompanhamento.tipoAcompanhamento == 2" class="icon text-center p-1 position-relative">Follow Up</span>
                <span  v-if="acompanhamento.tipoAcompanhamento == 3" class="icon text-center p-1 position-relative">Alteração responsável</span>
                <span  v-if="acompanhamento.tipoAcompanhamento == 4" class="icon text-center p-1 position-relative">Visita</span>
                <span  v-if="acompanhamento.tipoAcompanhamento == 5" class="icon text-center p-1 position-relative">Contato cliente (E-mail, Telefone, Whatsapp)</span>
                <span  v-if="acompanhamento.tipoAcompanhamento == 6" class="icon text-center p-1 position-relative">Digitação D.I.</span>
              </td>
              <td class="shadow-none lh-1 fw-medium text-center">
                <span  v-if="acompanhamento.interno" class="icon text-center rounded-1 text-white p-1 position-relative bg-success ">Interno</span>
                <span  v-if="!acompanhamento.interno" class="icon text-center rounded-1 text-white p-1 position-relative bg-warning ">Externo</span>
              </td>
              <td class="shadow-none lh-base fw-medium richTextTableItem" v-html="nl2br(acompanhamento.mensagem)"></td>
              <td class="shadow-none lh-1 fw-medium">
                <div class="dropdown">
                <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                >
                  <i class="flaticon-dots"></i>
                </button>
                <ul class="dropdown-menu">
                  <li>
                    <button
                        @click="onEditAcompanhamento(processoId, acompanhamento.id)"
                        class="dropdown-item d-flex align-items-center"
                    ><i
                        class="flaticon-view lh-1 me-8 position-relative top-1"
                    ></i>
                      Editar</button
                    >
                  </li>
                  <li>
                    <button
                      class="dropdown-item d-flex align-items-center"
                      @click="onDeleteAcompanhamento(processoId, acompanhamento.id)"
                      >
                      <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i>
                      Excluir
                    </button>
                  </li>
                </ul>
              </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination :total-paginas="totalPaginas" :pagina-atual="paginaAtual" :por-pagina="porPagina"
                  :total-items="totalAcompanhamentos"
                  :item-inicial="itemInicial"
                  :item-final="itemFinal"
                  @onPagination="OnPagination"
      />

    </div>
  </div>
  <acompanhamento-modal ref="acompanhamentoFormModal" @save="OnSave()"  />
</template>

<script setup>
import {ref, onMounted, watch, defineEmits} from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Pagination from "@/components/common/Pagination.vue";
import ProcessoService from "@/services/ProcessoService.js";
import router from "@/router/index.js";
import {formatDateTime} from "../../../utils/dateHelper.js";
import {nl2br} from "@/utils/stringHelper.js";
import AcompanhamentoModal from "@/views/Processos/Acompanhamento/AcompanhamentoModal.vue";
import novoCargoPage from "@/views/Cargos/NovoCargoPage.vue";

const acompanhamentoFormModal = ref(null)

const isLoading = ref(false);
const fullPage = ref(false);
const situacao = ref(null);
const acompanhamentos = ref([]);
const searchTerm = ref(null);
const totalAcompanhamentos = ref(0);
const porPagina = ref(20);
const paginaAtual = ref(1);
const totalPaginas = ref(0);
const itemInicial = ref(0);
const itemFinal = ref(0);
let timeout = null;
const wait = 400;

const emit = defineEmits(['onRefresh', 'onAction']);

const onNovoAcompanhamento = (processoId) => {
  if (acompanhamentoFormModal.value) {
    acompanhamentoFormModal.value.openModal(processoId)
  }
}

const onEditAcompanhamento = (processoId, acompanhamentoId) => {
  if (acompanhamentoFormModal.value) {
    acompanhamentoFormModal.value.openModal(processoId, acompanhamentoId)
  }
}

const onDeleteAcompanhamento = async (processoId, acompanhamentoId) => {
  await  ProcessoService.deleteAcompanhamento(processoId, acompanhamentoId)

  loadData()
}

const props = defineProps({
  processoId: {
    type: Number
  }
})

function OnPagination(data) {
  paginaAtual.value = data.paginaAtual
  porPagina.value = data.porPagina

  loadData()
}


function OnSave() {
  paginaAtual.value = 1

  loadData()
}


function situacaoAlterada() {
  paginaAtual.value = 1;
  loadData();
}

function filtroGeral() {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    loadData();
  }, wait);
}

async function loadData() {
  try {
    isLoading.value = true;
    let filterBy = null;
    if (searchTerm.value) {
      filterBy = searchTerm.value;
    }

    acompanhamentos.value = [];
    totalAcompanhamentos.value = 0;

    const response = await ProcessoService.getAcompanhamentos(
        props.processoId,
        paginaAtual.value,
        porPagina.value,
        filterBy,
        situacao.value,
    );

    acompanhamentos.value = response.items;
    totalAcompanhamentos.value = response.totalItems;
    porPagina.value = response.pageSize;
    itemInicial.value = response.from;
    itemFinal.value = response.to;
    totalPaginas.value = response.totalPages;

    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    console.error('Erro ao carregar acompanhamentos:', error);
  }
}

async function onAction(action, props) {
  props.action = action;

  emit('onAction', props)
}

onMounted(() => {
  //loadData();
});

// Watch para observar mudanças no processoId
watch(
    () => props.processoId,
    (newVal, oldVal) => {
      console.log(`processoId mudou de ${oldVal} para ${newVal}`);
      // Adicione a lógica que deseja executar quando processoId mudar
      loadData()
    }
);

</script>

<style>

.richTextTableItem p {
  margin: 0;
  word-wrap: break-word !important;
  text-wrap: normal !important;
  overflow-wrap: break-word;
}


.richTextTableItem {
  max-width: 200px;
}
</style>
