<template>
  <div
      :id="name"
      class="RepeaterInput"
  >
    <h5 v-if="label" class="card-subtitle fw-bold mt-15 mb-15 mb-lg-20 mb-xl-25">
      {{ label }}
    </h5>

    <div v-for="(field, idx) in repeater" :key="idx" class="repeaterRow border-bottom border-dashed pb-10 pt-10">
      <div class="row gx-1">
        <div
            v-for="({ type, name :fieldName, label, children, maxLength, title, value, col, mask, placeholder, disabled, display, endpoint, options, rows, onChanged, decimalNumbers, fields, ...attrs }, i) in fields"
            :key="`${field.key}${fieldName}`"
            :class="col"
        >
          <div class="form-group mb-15 mb-sm-20 mb-md-10">
            <component
                :is="type ?? TextInput"
                :name="`${name}[${idx}].${fieldName}`"
                :label="label"
                :display="display"
                :decimalNumbers="decimalNumbers"
                :placeholder="placeholder"
                :disabled="disabled"
                :endpoint="endpoint"
                :title="title"
                :mask="mask"
                :maxLength="maxLength"
                :onChanged="onChanged"
                :options="options"
                :rows="rows"
                :fields="fields"
                :displayLabel="true"
            />
          </div>
        </div>
      </div>
      <button v-if="field.value.conexosId == null && (!required || ( required  && repeater.length > 1))" class="removeButton btn btn-danger btn-sm" type="button" @click="removeByIndex(idx)"><i class="ph ph-trash"></i></button>

    </div>

    <div v-if="repeater.length <= 0">
        {{ emptyMessage }}
    </div>

    <div class="d-flex justify-content-end pt-10">
      <button type="button" class="btn btn-secondary btn-sm"   @click="push(emptyObject)">Adicionar</button>
    </div>
  </div>
</template>


<script setup>
import { toRef } from 'vue';
import { useField, useFieldArray } from 'vee-validate';
import TextInput from "@/components/form/TextInput.vue";

const props = defineProps({
  required: {
    type: Boolean,
    required: false,
    default: true,
  },
  emptyObject: {
    type: Object,
    required: true
  },
  fields: {
    type: Array,
    required: true
  },
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  emptyMessage: {
    type: String,
    required: false,
    default: 'Nenhum item adicionado.'
  }
});

const name = toRef(props, 'name');

const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
  setValue,
} = useField(name, undefined, {
  initialValue: props.value,
});


const { remove, move, push, fields : repeater } = useFieldArray(props.name);


const removeByIndex = (index) => {
  remove(index);
};

</script>

<style scoped>
.repeaterRow {
  position: relative;
}

.removeButton {
  position: absolute;
  right: 10px;
  bottom: 15px;
  opacity: 0;
  transition: opacity 0.8s ease;
}

.repeaterRow:hover .removeButton {
  opacity: 1;
}


</style>

<style>
  .RepeaterInput label {
    font-size: 80%;
  }

  .RepeaterInput .col-md-5-percent {
    max-width: 5%;
  }

  .RepeaterInput .col-md-6-percent {
    max-width: 6%;
  }

  .RepeaterInput .col-md-8-percent {
    max-width: 8%;
  }

  .RepeaterInput .col-md-9-percent {
    max-width: 9%;
  }

  .RepeaterInput .col-md-10-percent {
    max-width: 10%;
  }

  .RepeaterInput .col-md-15-percent {
    max-width: 15%;
  }



  /* Reduz a margem inferior das colunas */
  .col-md-2,
  .col-md-1 {
    margin-bottom: 5px; /* Ajuste conforme necessário */
  }

  /* Remove o padding dos elementos de formulário */
  .form-group {
    margin-bottom: 5px; /* Ajuste conforme necessário */
    padding: 0; /* Remove padding interno se necessário */
  }

  /* Ajusta o padding dos inputs */
  .form-control {
    padding: 0.25rem; /* Ajuste conforme necessário */
  }

  .RepeaterInput .max-80 {
    max-width: 80px;
  }

  .RepeaterInput .max-100 {
    max-width: 100px;
  }

  .RepeaterInput .max-120 {
    max-width: 120px;
  }

  .RepeaterInput .max-130 {
    max-width: 130px;
  }

  .RepeaterInput .max-150 {
    max-width: 150px;
  }
  .RepeaterInput .max-200 {
    max-width: 200px;
  }
</style>