// src/router/relatorios.js

import PrevisaoFaturamentoMensal from "@/views/Relatorios/PrevisaoFaturamento/PrevisaoFaturamentoMensal.vue";
import DistribuicaoProcessosClassificacaoResponsavel from "@/views/Relatorios/DistribuicaoProcessosClassificacaoResponsavel/DistribuicaoProcessosClassificacaoResponsavel.vue";
import PessoaContaBancariaListPage from "@/views/Relatorios/PessoaContaBancaria/PessoaContaBancariaListPage.vue";
import ControleContainerLavacao from "@/views/Relatorios/ControleContainerLavacao/ControleContainerLavacao.vue";
import ProcessosEstatisticas from "@/views/Relatorios/ProcessosEstatisticas/ProcessosEstatisticas.vue";

const relatoriosBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    }
]
export default [
    {
        path: '/relatorios/prev-faturamento-mensal',
        name: 'RelatorioPrevisaoFaturamentoMensal',
        component: PrevisaoFaturamentoMensal,
        meta: {
            title: 'Previsão Faturamento Mensal',
            breadcrumb: [
                ...relatoriosBreadCrumb,
                {
                    label: 'Novo Relatorio',
                    to: 'RelatorioNovo'
                }
            ]
        }
    },
    {
        path: '/relatorios/controle-container-lavacao',
        name: 'RelatorioControleContainerLavacao',
        component: ControleContainerLavacao,
        meta: {
            title: 'Controle Container Lavacação',
            breadcrumb: [
                ...relatoriosBreadCrumb,
                {
                    label: 'Novo Relatorio',
                    to: 'RelatorioNovo'
                }
            ]
        }
    },
    {
        path: '/relatorios/distribuicao-processos-classificacao-responsavel',
        name: 'RelatorioDistribuicaoProcessosClassificacaoResponsavel',
        component: DistribuicaoProcessosClassificacaoResponsavel,
        meta: {
            title: 'Distribuição de Processos por Classificacao e Responsável',
            breadcrumb: [
                ...relatoriosBreadCrumb,
                {
                    label: 'Novo Relatorio',
                    to: 'RelatorioNovo'
                }
            ]
        }
    },
    {
        path: '/relatorios/pessoa-conta-bancaria',
        name: 'RelatorioPessoaContaBancaria',
        component: PessoaContaBancariaListPage,
        meta: {
            title: 'Distribuição de Processos por Classificacao e Responsável',
            breadcrumb: [
                ...relatoriosBreadCrumb
            ]
        }
    },
    {
        path: '/relatorios/processos-estatisticas',
        name: 'RelatorioProcessosEstatisticas',
        component: ProcessosEstatisticas,
        meta: {
            title: 'Estatísticas',
            breadcrumb: [
                ...relatoriosBreadCrumb
            ]
        }
    }
];
