<template>
  <div v-if="formData && formData.lastSyncAt"  class="d-flex align-items-center justify-content-between letter-spacing">
    <span class="small">
    Última sincronização : {{ formatDate(formData.lastSyncAt) }} <i title="Atualizar agora" @click="atualizarAgora()" class="cursor-pointer flaticon-refresh" />
    </span>
  </div>
</template>

<script setup>
const props = defineProps({
  formData: {
    type: Object,
    default: {},
  },
  refreshEndPoint: {
    type: String,
    required: true,
  },
  params: {
    type: Function,
    required: false,
    default: () => {

    }
  },
});

const formatDate = (dateString) => {

  if(!dateString) {
    return
  }

  const date = new Date(dateString);

  const formatedDate = new Intl.DateTimeFormat('pt-BR', {
    dateStyle: 'short',
    timeStyle: 'medium',
    timeZone: 'America/Sao_Paulo',
  })

  return formatedDate.format(date);
}

// const atualizarAgora() {
//   let endpoint = props.end
// }



</script>