<template>
  <div class="mb-20 letter-spacing">
    <div class="card-head bg-white d-md-flex justify-content-start p-10 p-sm-10 ">
      <!-- Geral -->
      <div class="dropdown me-3">
        <button class="btn default-outline-btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false"> Geral
        </button>
        <ul class="dropdown-menu">

          <li v-if="IsGranted('admin') ||  IsGranted('logistica') ||  IsGranted('colaborador')" ><a class="dropdown-item" @click="onAtribuirResponsavel(processoId)">Atribuir resposável</a></li>
          <li><a class="dropdown-item" @click="onImprimirCapaProcesso(processoId)">Capa Processo</a></li>
        </ul>
      </div>

      <!-- Logistica -->
      <div class="dropdown me-3">
        <button class="btn default-outline-btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false"> Logística
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" @click="onConfirmarChegada(processoId)">Confirmação de Chegada</a></li>
          <li><a class="dropdown-item" @click="onPresencaCarga(processoId)">Presença de carga</a></li>
          <li><a class="dropdown-item" @click="onDiDigitada(processoId)">D.I. Digitada</a></li>
          <li><a class="dropdown-item" @click="onDiRegistrada(processoId)">D.I. Registrada</a></li>
          <li><a class="dropdown-item" @click="onMapa(processoId)">Mapa (Madeira)</a></li>
        </ul>
      </div>
      <!-- Histórico -->
      <button class="me-3 btn default-outline-btn btn-sm" v-if="processoId && !showHistorico" type="button" @click="showHistorico = !showHistorico; onAction('showHistorico', { processoId })"
              > Acompanhamento
      </button>

      <button class="me-3 btn default-outline-btn btn-sm" v-if="processoId && showHistorico"   type="button" @click="showHistorico = !showHistorico; onAction('showProcesso', { processoId })"
        > Processo
      </button>

      <button class="me-3 btn default-outline-btn btn-sm" type="button" @click="onEnviarFollowUP(processoId)"
      > Enviar Follow Up
      </button>

      <MapaModal ref="mapaModal" @save="onSave" />
      <AtribuirResponsavelModal ref="atribuirResponsavelModal" @save="onSave" />

      <ConfirmacaoChegadaModal  ref="confirmacaoChegadaModal" @save="onConfirmacaoChegadaSave"  @onLoading="onLoadingChange" />
      <PresencaCargaModal ref="presencaCargaModal" @save="onPresencaCargaSave"  @onLoading="onLoadingChange" />
      <EnviarFollowModal ref="enviarFollowModal" @onLoading="onLoadingChange"   />
      <DiRegistradaModal ref="diRegistradaModal"  @save="onDiRegistradaSave"  @onLoading="onLoadingChange" />
      <DiDigitadaModal ref="diDigitadaModal"  @save="onDiDigitadaSave"  @onLoading="onLoadingChange" />
    </div>
  </div>
</template>

<script setup>
import {useRoute} from "vue-router";
import MapaModal from "@/views/Processos/Mapa/MapaModal.vue";

import {defineEmits, onBeforeMount, ref, inject, onMounted, onBeforeUnmount, watch} from "vue";
import AtribuirResponsavelModal from "@/views/Processos/Responsavel/AtribuirResponsavelModal.vue";
import ConfirmacaoChegadaModal from "@/views/Processos/ConfirmacaoChegada/ConfirmacaoChegadaModal.vue";
import PresencaCargaModal from "@/views/Processos/PresencaCarga/PresencaCargaModal.vue";
import EnviarFollowModal from "@/views/Processos/FollowUp/EnviarFollowModal.vue";
import DiRegistradaModal from "@/views/Processos/DiRegistrada/DiRegistradaModal.vue";
import DiDigitadaModal from "@/views/Processos/DiDigitata/DiDigitadaModal.vue";
import AcompanhamentoService from "@/services/AcompanhamentoService.js";
import ProcessoService from "@/services/ProcessoService.js";
import KeycloakService from "@/security/KeycloakService.js";


const registerShortcut = inject('registerShortcut');
const unregisterShortcut = inject('unregisterShortcut');
const registerGroupShortcut = inject('registerGroupShortcut')

const processoId = ref(null)

const confirmacaoChegadaModal = ref(null)
const presencaCargaModal = ref(null)
const enviarFollowModal = ref(null)
const diRegistradaModal = ref(null)
const diDigitadaModal = ref(null)
const mapaModal = ref(null)
const atribuirResponsavelModal = ref(null)
const emit = defineEmits(['onRefresh', 'onAction', 'onLoading']);


//shortcutManager.value.registerShortcut('Alt + F1', () => { alert('eita')},'Ajuda', 'Geral');
//console.log('shortcutManager aqui', shortcutManager)

//shortcutManager?.registerShortcut('Alt + F2', () => { alert('eita')},'Ajuda 2', 'Geral');

// shortcutManager?.registerShortcut('ctrl+alt+s', () => {
//   console.log('Atalho Ctrl+Alt+S acionado a partir de OutroComponente!');
//   // Ação desejada
// });


onBeforeMount(async ()  => {
  const  currentRoute  = useRoute();

  if (currentRoute.params.hasOwnProperty('id')) {
      processoId.value=  parseInt(currentRoute.params.id);
  }
})


onMounted(() => {
  if (registerShortcut) {
    registerGroupShortcut('Processo','Atalhos disponíveis na tela de processos')
    registerShortcut('Ctrl + Alt + P', () => { onImprimirCapaProcessoShortcut() },'Imprimir Capa', 'Processo');
    registerShortcut('Ctrl + Alt + F', () => { onEnviarFollowUpShortcut() },'Enviar Follow Up', 'Processo');
    registerShortcut('Ctrl + Alt + A', () => { onToggleAcompanhamentoProcessoShortcut() },'Ver Acompanhamentos/Processo', 'Processo');
    registerShortcut('Ctrl + Alt + R', () => { onAtribuirResponsavelShortcut() },'Atribuir Responsável', 'Processo');
    registerShortcut('Ctrl + Alt + M', () => { onMapaShortcut() },'Mapa (Madeira)', 'Processo');
  }
});







onBeforeUnmount(() => {
  console.log('unregisterShortcut', unregisterShortcut)
  if (unregisterShortcut) {
    unregisterShortcut('Ctrl + Alt + P')
    unregisterShortcut('Ctrl + Alt + F')
    unregisterShortcut('Ctrl + Alt + A')
    unregisterShortcut('Ctrl + Alt + R')
    unregisterShortcut('Ctrl + Alt + M')
  }
})



function onSave() {
  emit('onRefresh')
}

async function onConfirmacaoChegadaSave() {
  if (enviarFollowModal.value) {
    await enviarFollowModal.value.openModal(processoId.value, AcompanhamentoService.FOLLOW_UP_CONFIRMACAO_CHEGADA)
  }

  //emit('onRefresh')
}

async function onPresencaCargaSave() {
  if (enviarFollowModal.value) {
    await enviarFollowModal.value.openModal(processoId.value, AcompanhamentoService.FOLLOW_UP_PRESENCA_CARGA)
  }

  //emit('onRefresh')
}


async function onAction(action, props) {
  props.action = action;

  emit('onAction', props)
}


const props = defineProps({
  showHistorico: {
    type: Boolean,
    required: false,
    default: false,
  },
})

const onConfirmarChegada = (processoId) => {
  if (confirmacaoChegadaModal.value) {
    confirmacaoChegadaModal.value.openModal(processoId)
  }
}

const onPresencaCarga = (processoId) => {
  console.log('onPresencaCarga click')

  if (presencaCargaModal.value) {
    presencaCargaModal.value.openModal(processoId)
  }
}

const onDiRegistrada = (processoId) => {
  if (diRegistradaModal.value) {
    diRegistradaModal.value.openModal(processoId)
  }
}

const onDiDigitada = (processoId) => {
  if (diDigitadaModal.value) {
    diDigitadaModal.value.openModal(processoId)
  }
}


const onMapa = (processoId) => {
  if (mapaModal.value) {
    mapaModal.value.openModal(processoId)
  }
}

const onAtribuirResponsavel = (processoId) => {
  if (atribuirResponsavelModal.value) {
    atribuirResponsavelModal.value.openModal(processoId)
  }
}

async function onDiRegistradaSave() {
  if (enviarFollowModal.value) {
    await enviarFollowModal.value.openModal(processoId.value, AcompanhamentoService.FOLLOW_UP_DI_REGISTRADA)
  }
  //emit('onRefresh')
}
async function onDiDigitadaSave() {
  emit('onRefresh')
}

const onEnviarFollowUP = (processoId) => {
  if (enviarFollowModal.value) {
    enviarFollowModal.value.openModal(processoId)
  }
}

const onAtribuirResponsavelShortcut = () => {
  onAtribuirResponsavel(processoId.value)
}

const onMapaShortcut = () => {
  onMapa(processoId.value)
}

const onEnviarFollowUpShortcut = () => {
  onEnviarFollowUP(processoId.value)
}

const onImprimirCapaProcessoShortcut = () => {
  onImprimirCapaProcesso(processoId.value)
}

const onToggleAcompanhamentoProcessoShortcut = () => {
console.log('processoId', processoId.value)

  if(!props.showHistorico.value) {
    onAction('showHistorico', {processoId: processoId.value})
  } else {
    onAction('showProcesso', {processoId:processoId.value})
  }
}



const onLoadingChange = (loading) => {
  emit('onLoading',loading)
}

// Capa Processo

const onImprimirCapaProcesso = async (processoId) => {
  try {

    onLoadingChange(true)
    const response = await ProcessoService.downloadCapaProcesso(processoId)

    // Criar uma URL temporária para o arquivo PDF
    const pdfBlob = new Blob([response.data], { type: 'application/pdf' });
    const pdfUrl = URL.createObjectURL(pdfBlob);

    // Criar um iframe invisível para carregar o PDF
    const iframe = document.createElement('iframe');
    iframe.style.display = 'none';
    iframe.src = pdfUrl;

    // Adiciona o iframe ao DOM
    document.body.appendChild(iframe);

    // Espera o PDF ser carregado e imprime automaticamente
    iframe.onload = function() {
      iframe.contentWindow.focus(); // Garante que o conteúdo do iframe tenha o foco
      iframe.contentWindow.print(); // Inicia a impressão
    };
  } catch (error) {
    console.error('Erro ao baixar o PDF:', error);
  } finally {
    onLoadingChange(false)
  }
}


const IsGranted = (role) => {
  return KeycloakService.IsGranted(role);
}


// watch(() => props.showHistorico, (value) => {
//   console.log('----------------------------------------')
//   console.log('showHistorico', value)
// }, {immediate: true});


</script>

<style scoped>

</style>