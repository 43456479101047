<template>
  <div class="card border-0 rounded-0 bg-white h-100">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="d-sm-flex align-items-center justify-content-between">
        <h5 class="card-title fw-bold mb-0">Processos por nível</h5>
      </div>
      <div id="taskOverviewChart" class="chart">
        <apexchart
            type="bar"
            height="280"
            :options="taskOverviewChart"
            :series="task"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue';

// Propriedades recebidas do componente pai
const props = defineProps({
  processosPorNivel: {
    type: Array,
    required: true,
    default: () => []
  },
  labelsEixoX: {
    type: Array,
    required: true,
    default: () => ["Nível 1", "Nível 2", "Nível 3", "Nível 4"] // Valores padrão
  }
});


// Mapeando os dados da série com base nos processos recebidos
const chartData = computed(() => {
  return props.processosPorNivel.map(el => el.nProcessos);
});

// Definindo os dados da série para o gráfico
const task = ref([
  {
    name: "Processos por nível",
    data: chartData,
  },
]);

// Configurando as opções do gráfico
const taskOverviewChart = ref({
  chart: {
    type: "bar",
    height: 270,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      columnWidth: "50%",
      distributed: true, // Adiciona cores diferentes para cada coluna
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#A8D5A2CC", "#F7E7A0CC", "#F8B88BCC", "#F8A6A1CC"], // Cores para cada coluna
  stroke: {
    show: false,
  },
  xaxis: {
    categories: ref([...props.labelsEixoX]), // Labels do eixo X
    labels: {
      show: true,
      style: {
        fontFamily: "Red Hat Display, sans-serif",
        colors: "#9C9AB6",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    axisTicks: {
      show: false,
    },
    axisBorder: {
      show: false,
    },
  },
  yaxis: {
    show: true,
    tickAmount: 6,
    labels: {
      show: true,
      style: {
        fontFamily: "Red Hat Display, sans-serif",
        colors: ["#9C9AB6"],
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    axisBorder: {
      show: true,
      color: "#eee",
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    style: {
      fontSize: "14px",
      fontFamily: "Red Hat Display, sans-serif",
    },
  },
  grid: {
    show: true,
    strokeDashArray: 5,
    borderColor: "#d9e9ef",
  },
});

// Observando mudanças na prop labelsEixoX
watch(() => props.labelsEixoX, (newLabels, oldLabels) => {
  console.log('Labels alteradas!');
  console.log('Labels anteriores:', oldLabels);
  console.log('Novas labels:', newLabels);

  taskOverviewChart.value.xaxis.categories = newLabels


  taskOverviewChart.value = {
    ...taskOverviewChart.value
  }

  // Aqui você pode adicionar qualquer lógica que precisa ser executada
  // quando as labels forem alteradas, por exemplo, atualizar o gráfico.
});


// Função para adicionar uma nova label ao eixo X
// const updateXLabels = () => {
//   taskOverviewChart.value.xaxis.categories =
//   taskOverviewChart.value = {
//     ...taskOverviewChart.value,
//     ...{
//       xaxis: {
//         categories: ref(['teste 001']), // Labels do eixo X
//       }
//     },
//   };
//
//
//
//
// };
</script>
