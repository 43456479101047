<template>
  <data-grid
      endpoint="/api/relatorio/controle-demurrage-lavacao-containers"
      section="data-grid"
      :allow-filter="false"
      :filterForm="filterForm"
      :default-filters="defaultFilters"
      :columns="defaultColumnsSettings"
      :initial-sort-by="[{ propertyName: 'dataLimiteDevolucao', sortOrder: 'asc' }]"
      @on-db-click-row="showDetails"
  >
    <template v-slot:[`item-situacaoDemurrage`]="{item}">
      <div class="text-center">
        <span  v-if="item.situacaoDemurrage == 1" class="icon text-center rounded-1 text-white p-1 position-relative bg-warning">Ag. Devolução</span>
        <span  v-if="item.situacaoDemurrage == 2" class="icon text-center rounded-1 text-white p-1 position-relative bg-success ">Dev. no prazo</span>
        <span  v-if="item.situacaoDemurrage == 3" class="icon text-center rounded-1 text-white p-1 position-relative bg-danger ">Demurrage</span>
        <span  v-if="item.situacaoDemurrage == 4" class="icon text-center rounded-1 text-white p-1 position-relative bg-danger ">Dev. com demurrage</span>
        <span  v-if="item.situacaoDemurrage == 5" class="icon text-center rounded-1 text-white p-1 position-relative bg-orange ">Risco</span>
      </div>
    </template>

    <template v-slot:[`item-situacaoLavacao`]="{item}">
      <div class="text-center">
        <span  v-if="item.situacaoLavacao == 1" class="icon text-center rounded-1 text-white p-1 position-relative bg-danger">Lavação</span>
        <span  v-if="item.situacaoLavacao == 2" class="icon text-center rounded-1 text-white p-1 position-relative bg-success ">Sem lavação</span>
        <span  v-if="item.situacaoLavacao == 3" class="icon text-center rounded-1 text-white p-1 position-relative bg-success ">Isento</span>
        <span  v-if="item.situacaoLavacao == 4" class="icon text-center rounded-1 text-white p-1 position-relative bg-warning ">Ag. vistoria</span>
        <span  v-if="item.situacaoLavacao == 5" class="icon text-center rounded-1 text-white p-1 position-relative bg-gray text-black">Ag. Devolução</span>

      </div>
    </template>

    <template v-slot:[`item-valorDemurrage`]="{item}">
      <div v-if="item.valorDemurrage != null">R$ {{ formatDecimal(item.valorDemurrage, 2) }}</div>
    </template>

    <template v-slot:[`item-valorLavacao`]="{item}">
      <div v-if="item.valorLavacao != null">R$ {{ formatDecimal(item.valorLavacao, 2) }}</div>
    </template>

    <template v-slot:[`item-lavacao`]="{item}">
      <div v-if="(item.lavacao && item.lavacao == 1)">Sim</div>
      <div v-else-if="(item.lavacao && item.lavacao == 0)">Não</div>
    </template>



    <template v-slot:[`item-dataChegada`]="{item}">
      <div>{{ formatDate(item.dataChegada)}}</div>
    </template>

    <template v-slot:[`item-dataLimiteDevolucao`]="{item}">
      <div>{{ formatDate(item.dataLimiteDevolucao)}}</div>
    </template>

    <template v-slot:[`item-dataDevolucao`]="{item}">
      <div>{{ formatDate(item.dataDevolucao)}}</div>
    </template>

    <template v-slot:[`item-acoes`]="{item}">
      <div class="d-flex justify-content-center align-items-center">
        <a
            class="dropdown-item cursor-pointer d-flex justify-content-center align-items-center"
            @click.prevent="showDetails(item)"
        >
          <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
        </a>
      </div>

    </template>

  </data-grid>
</template>

<script setup>
import ProcessoService from "@/services/ProcessoService.js";
import router from "@/router/index.js";
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import dataGrid from '@/components/dataGrid/dataGrid.vue'

import {formatDate} from "@/utils/dateHelper.js";
import {formatDecimal} from "@/utils/numberHelper.js";

const defaultFilters = {
  statusFilters: [
    {
      label: 'Situação',
      name: 'situacao',
      value: null,
      options: [
        { value: null,  name: 'Todos' },
//        { value: 1,     name: 'Ag. Embarque' },
//        { value: 2,     name: 'Ag. Chegada' },
        { value: 3,     name: 'Ag. Devolução' },
        { value: 4,     name: 'Devolvido' },
        { value: 5,     name: 'Demurrage' },
      ]
    }
  ],
  filters: null
}

const defaultColumnsSettings = [
  { text: "CONTAINER", value: "numeroContainer", sortable: true, slot: false },
  { text: "PROCESSO", value: "referenciaInterna", sortable: true, width: 120 },

  { text: "CLIENTE", value: "cliente", sortable: true, slot: true, width: 180 },
  // { text: "REF. CLIENTE", value: "referenciaCliente", sortable: false, width: 180 },
  // { text: "FREE TIME", value: "freeTime", sortable: false, slot: false },
  { text: "ATA ", value: "dataChegada", sortable: true , slot: true },
  { text: "LIMITE DEVOLUÇÃO ", value: "dataLimiteDevolucao", sortable: true , slot: true },
   { text: "DEVOLUÇÃO ", value: "dataDevolucao", sortable: true , slot: true },
  { text: "DEPOT", value: "depot", sortable: true, width: 130 } ,
  { text: "ARMADOR", value: "companhiaTransporte", sortable: true, width: 130 },
  { text: "SITUAÇÃO DEM.", value: "situacaoDemurrage", sortable: false, slot: true, width: 150 },
  { text: "SITUAÇÃO LAV.", value: "situacaoLavacao", sortable: false, slot: true, width: 150 },
  { text: "TIPO CONT.", value: "tipoContainer", sortable: true},
  { text: "VALOR DEM", value: "valorDemurrage", sortable: true, slot: true },
  { text: "VALOR LAV", value: "valorLavacao", sortable: true, slot: true },
  // { text: "AÇÕES", value: "acoes", sortable: false, slot: true },
]

const filterForm = {
  sections: [
    {
      section: "Geral",
      fields: [
        {
          label: 'Container',
          name: 'container',
          type: TextInput,
          col: 'col-12',
        },
        {
          label: 'Ref. Cliente',
          name: 'referencia_cliente',
          type: TextInput,
          col: 'col-12',
        },
        {
          label: 'Cliente',
          name: 'cliente',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/cliente',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Lavação',
          name: 'lavacao',
          type: SelectInput,
          col: 'col-12',
          options: [{id: null, nome: 'Todos'},{id: true, nome: 'Sim'},{id: false, nome: 'Não'}]
        },
      ]
    }
  ],
};

function showDetails(container, newWindow) {
  const routeData = router.resolve({
    name: 'ProcessoDetalhesContainer',
    params: { id: container.processoId, containerId: container.id }
  });

  if (newWindow) {
    window.open(routeData.href, '_blank');
  } else {
    router.push(routeData);
  }
}
</script>
