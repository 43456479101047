// src/router/depots.js
import DepotListPage from "@/views/Depots/DepotListPage.vue";
import NovoDepotPage from "@/views/Depots/NovoDepotPage.vue";

const depotsBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Depots',
        to: 'Depots'
    }
]

export default [
    {
        path: '/depots',
        name: 'Depots',
        component: DepotListPage,
        meta: {
            title: 'Depots',
            breadcrumb: depotsBreadCrumb
        },
    },
    {
        path: '/depots/novo',
        name: 'DepotNovo',
        component: NovoDepotPage,
        meta: {
            title: 'Novo Depot',
            breadcrumb: [
                ...depotsBreadCrumb,
                {
                    label: 'Novo Cliente',
                    to: 'ClienteNovo'
                }
            ]
        }
    },
    {
        path: '/depots/:id/edit',
        name: 'DepotEdit',
        component: NovoDepotPage,
        meta: {
            title: 'Detalhes do Cliente',
            breadcrumb: [
                ...depotsBreadCrumb,
                {
                    label: 'Novo Depot',
                    to: 'DepotNovo'
                }
            ]
        }
    }

];
