import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;



const getRecintoAlfandegados = async (pageNumber, pageSize, searchTerm, situacao) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/recinto-alfandegado/lista`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                situacao
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getRecintoAlfandegado = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/recinto-alfandegado/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar recintoAlfandegado', error);
        throw error;
    }
};

const updateRecintoAlfandegado = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/recinto-alfandegado/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o recintoAlfandegado', error);
        throw error;
    }
};

const createRecintoAlfandegado = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/recinto-alfandegado`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o recintoAlfandegado', error);
        throw error;
    }
};




export default {
    getRecintoAlfandegados,
    getRecintoAlfandegado,
    createRecintoAlfandegado,
    updateRecintoAlfandegado,
};