<template>
  <div
    class="PasswordInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label v-if="displayLabel" :for="name" class="d-block text-black fw-semibold mb-10">{{ label }}</label>
    <div class="input-group mb-15 mb-md-20">
      <span v-if="!disabled" class="input-group-text" @click="copyPassword()">
        <i class="flaticon-copy"></i>
      </span>


      <input
          class="form-control shadow-none  text-black"
          :name="name"
          :id="name"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          :disabled="disabled"
          @input="handleChange"
          @blur="handleBlur"
          :maxlength="maxLength"
      />

      <span
        class="input-group-text"
        v-if="!disabled"
        @click="toggleShowPassword()"
      >
        <i  v-if="type === 'password'" class="flaticon-eye"></i>
        <i  v-else class="flaticon-eye-1"></i>
      </span>
    </div>




    <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }"  v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>


<script setup>
import {ref, toRef} from 'vue';
import { useField } from 'vee-validate';
import { useToast} from "@/plugins/useToast.js";

const { showToast } = useToast();

const type = ref('password');


const props = defineProps({
  maxLength: {
    type: Number,
    required: false,
    default: 255
  },
  displayLabel: {
    type: Boolean,
    required:false,
    default: true
  },
  mask: {
    type: String,
    required: false,
    default: undefined,
  },
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');


const copyPassword = () => {
    navigator.clipboard.writeText(inputValue.value)
        .then(() => {


          showToast('Copiado com sucesso!', {
            title: 'Sucesso',
            type: 'success'
          });
        })
        .catch(err => {
          showToast('Não foi possível copiar!', {
            title: 'Atenção',
            type: 'warning'
          });
        });
}

const toggleShowPassword = () => {
  if (type.value === 'text') {
    type.value = 'password'
  } else {
    type.value = 'text'
  }


}

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>