// src/router/recintoAlfandegados.js
import RecintoAlfandegadoListPage from "@/views/RecintosAlfandegados/RecintoAlfandegadoListPage.vue";
import NovoRecintoAlfandegadoPage from "@/views/RecintosAlfandegados/NovoRecintoAlfandegadoPage.vue";

const recintoAlfandegadosBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Recintos Alfandegados',
        to: 'RecintoAlfandegados'
    }
]

export default [
    {
        path: '/recintos-alfandegados',
        name: 'RecintoAlfandegados',
        component: RecintoAlfandegadoListPage,
        meta: {
            title: 'Recintos Alfandegados',
            breadcrumb: recintoAlfandegadosBreadCrumb
        },
    },
    {
        path: '/recintos-alfandegados/novo',
        name: 'RecintoAlfandegadoNovo',
        component: NovoRecintoAlfandegadoPage,
        meta: {
            title: 'Novo Recinto Alfandegado',
            breadcrumb: [
                ...recintoAlfandegadosBreadCrumb,
                {
                    label: 'Novo Cliente',
                    to: 'ClienteNovo'
                }
            ]
        }
    },
    {
        path: '/recintos-alfandegados/:id/edit',
        name: 'RecintoAlfandegadoEdit',
        component: NovoRecintoAlfandegadoPage,
        meta: {
            title: 'Detalhes do Recinto Alfandedago',
            breadcrumb: [
                ...recintoAlfandegadosBreadCrumb,
                {
                    label: 'Novo RecintoAlfandegado',
                    to: 'RecintoAlfandegadoNovo'
                }
            ]
        }
    }

];
