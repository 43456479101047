<!-- ShortcutManager.vue -->
<template>
  <shortcut-manager-modal ref="shortcutManagerModalRef" />
</template>

<script setup>
import { onMounted, onBeforeUnmount, ref } from 'vue';
import ShortcutManagerModal from "@/components/common/ShortcutManagerModal.vue";
import router from "@/router/index.js";

const shortcutManagerModalRef = ref(null)

const groups = ref([])
const shortcuts = ref([])

const registerShortcut = (keyCombo, action, description, group, keyUpAction) => {
  shortcuts.value.push({ keyCombo, action, description, group, keyUpAction });
};


const unregisterShortcut = (keyCombo) => {
  let shortcut = shortcuts.value.find(p => p.keyCombo == keyCombo)


  if(!shortcut) {
    alert(`Atalho ${keyCombo} não encontrado`)
  }

  shortcuts.value = shortcuts.value.filter(p => p.keyCombo !== keyCombo)

  let shortcutsInGroup = shortcuts.value.filter(p => p.group == shortcut.group)

  if (shortcutsInGroup.length <= 0) {
    groups.value = groups.value.filter(p => p.title !== shortcut.group)
  }
};

const registerGroupShortcut = (title, description) => {
  groups.value.push({ title, description });
};

// Função para verificar se a combinação de teclas foi pressionada
const checkShortcuts = (event) => {
  shortcuts.value.forEach(({ keyCombo, action, keyUpAction }) => {
    const keys = keyCombo.split('+').map(key => key.trim().toLowerCase());
    const ctrl = keys.includes('ctrl');
    const alt = keys.includes('alt');
    const shift = keys.includes('shift');
    const key = keys.find(key => !['ctrl', 'alt', 'shift'].includes(key));

    const isKeyUp = event.type === 'keyup';

    if (key && ctrl == event.ctrlKey &&  alt == event.altKey && shift == event.shiftKey && event.key.toLowerCase() === key) {
      if (isKeyUp) {
        if (typeof keyUpAction === 'function') {
          keyUpAction()
        }
      } else {
        action();
      }
    } else if (!key && ctrl == event.ctrlKey &&  alt == event.altKey && shift == event.shiftKey) {
      if (isKeyUp) {
        if (typeof keyUpAction === 'function') {
          keyUpAction();
        }
      } else {
        action();
      }
    } else if (!key && !ctrl == event.ctrlKey &&  alt == event.altKey && shift == event.shiftKey) {
      if (isKeyUp) {
        if (typeof keyUpAction === 'function') {
          keyUpAction()
        }
      } else {
        action()
      }
    }
  });
};

onMounted(() => {
  window.addEventListener('keydown', checkShortcuts);
  window.addEventListener('keyup', checkShortcuts);

  registerGroupShortcut('Geral', 'Usados em qualquer lugar')
  registerShortcut('Alt + F1', () => { onHelp() },'Ajuda', 'Geral');
  registerShortcut('Alt + P', () => { onGoTo('Processos') },'Listagem de processos', 'Geral');
});



onBeforeUnmount(() => {
  window.removeEventListener('keydown', checkShortcuts);
  window.removeEventListener('keyup', checkShortcuts);
});



async function onHelp() {
  // console.log('onHelp',shortcutManagerModalRef.value)
  if (shortcutManagerModalRef.value) {
    await shortcutManagerModalRef.value.openModal(shortcuts.value, groups.value)
  }
}


function onGoTo(name) {

  if(router.currentRoute.value.name == name) {
    window.location.reload();
  }


  router.push({name: name});
}


defineExpose({
  registerShortcut,
  unregisterShortcut,
  registerGroupShortcut
});
</script>

