<template>
  <BreadCrumb />
  <CargoList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import CargoList from "@/views/Cargos/CargoList.vue";

export default defineComponent({
  name: "ProcessoListPage",
  components: {
    BreadCrumb,
    CargoList,
  },
});
</script>