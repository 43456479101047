<!-- AcompanhamentoFormModal.vue -->
<template>
  <div
      class="modal fade"
      id="acompanhamentoFormModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Novo Acompanhamento</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row d-flex">
            <!-- Coluna principal -->
            <div class="col-12 col-md-10">
              <DynamicForm
                  ref="modalForm"
                  :schema="formSchema"
                  :show-actions="false"
                  :initial-values="formData"
                  @onSubmit="onSubmit"
                  @onCancel="handleCancel"
              />
            </div>
            <!-- Coluna com rolagem -->
            <div class="col-12 col-md-2 scrollable-column">
              <div>
                <div v-for="grupo in processoInfos">
                  <div class="fc-event-title">{{ grupo.grupo }}</div>
                  <ul class="list-unstyled">
                    <li v-for="info in grupo.campos">
                      <div class="small"><strong>{{ info.label }}</strong></div>
                      <div class="info-container">
                        <span class="info-text">{{ info.value }}</span>
                        <span class="icon" @click="copyValue(info.value)">
                          <i class="flaticon-copy"></i>
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">

          <Multiselect
              placeholder="placeholder"
              :close-on-select="true"
              valueProp="id"
              label="nome"
              :options=macros
              @input="handleMacroChange"
          >

            <template v-slot:placeholder>
              <div class="multiselect-single-label">
        <span class="multiselect-single-label-text">
          <i class="flaticon flaticon-flash-1 small"></i> Aplicar macro

        </span>
              </div>
              <!--      <div class="text-left">-->
              <!--        <i class="flaticon flaticon-flash-1"></i>Aplicar macro-->
              <!--      </div>-->

            </template>
          </Multiselect>

          <button
              type="button"
              class="btn btn-outline-light me-3"
              @click="handleCancel"
          >
            Cancelar
          </button>
          <button
              type="button"
              class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              @click="validateAndSave"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeMount, computed, defineEmits, reactive} from 'vue';
import {Modal} from 'bootstrap';
import DynamicForm from "@/components/form/DynamicForm.vue";
import Multiselect from "@vueform/multiselect";
import * as Yup from "yup";
import {saudacao} from "@/utils/stringHelper.js";
import {useToast} from "@/plugins/useToast.js";
import {DateToAmericaSaoPaulo} from "@/utils/dateHelper.js";
import {getPessoa} from "@/utils/formHelper.js";

const {showToast} = useToast();

const modalElement = ref(null);
const processoId = ref(null);
const processo = ref(null)
const acompanhamentoId = ref(null);
const modalForm = ref(null);
let bsModalInstance = null;
let formData = reactive({});

import RichTextEditorInput from "@/components/form/RichTextEditorInput.vue";
import agendaService from "@/services/AgendaService.js";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import RelationInput from "@/components/form/RelationInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import AcompanhamentoService from "@/services/AcompanhamentoService.js";
import ProcessoService from "@/services/ProcessoService.js";
import {formatDate} from '@/utils/dateHelper.js';

const macros = ref([])

const text = ref(`<p>${saudacao()},</p>` +
    '<p>Tudo bem?</p>' +
    '<p>Segue em anexo planilha de embarques para seu acompanhamento e conhecimento.</p>');

const emit = defineEmits(['cancel', 'save', 'onLoading']);

const processoInfos = computed(() => {
  if (processo.value == null) {
    return []
  }

  let dados = [
    {
      grupo: "Dados do processo",
      campos: [
        {label: "Ref. Cliente", value: processo.value.referenciaCliente},
        {label: "N. Invoice", value: processo.value.numeroInvoice},
        {label: "Navio", value: processo.value.veiculo?.nome},
        {label: "Conh. House", value: processo.value.numeroHouse},
        {label: "Conh. Master", value: processo.value.numeroMaster},
        {label: "CE Mercante", value: processo.value.numeroCe},
        {label: "Número da D.I.", value: processo.value.declaracaoImportacaoNumero},
      ].filter(campo => campo.value !== null && campo.value !== '')
    },
  ];

  if (processo.value.containers.length > 0) {
    let containers = []

    for (let i = 0; i < processo.value.containers.length; i++) {
      let container = processo.value.containers[i]
      console.log('container', container)
      containers.push({label: "Número", value: container.numeroContainer})
    }

    dados.push({
          grupo: "Containers",
          campos: containers
        }
    )


  }

  return dados
});


const loadMacros =  async () => {
  macros.value =  await AcompanhamentoService.getMacros()
};

const copyValue = (value) => {
  navigator.clipboard.writeText(value)
      .then(() => {
        showToast(`Copiado com sucesso!`, {
          title: 'Sucesso',
          type: 'success'
        });
      })
      .catch(err => {
        showToast('Não foi possível copiar!', {
          title: 'Atenção',
          type: 'warning'
        });
      });
}

const handleMacroChange = (e) => {
  let pessoa = getPessoa()
  let mensagem = ''
  formData = {
    data: new Date(),
    mensagem: '',
    responsavel: pessoa,
    interno: false,
    tipoAcompanhamento: 2,
  };

  let now = DateToAmericaSaoPaulo(new Date());
  let a = new Date();

  switch (e) {
    case 1:
      if(now.getHours() < 12) {
        mensagem =
            `<p>Segue anexo DI registrada.</p>` +
            `<p>Declaração aguardando parametrização prevista para ocorrer no final do dia de hoje</p>` +
            `<p>Seguiremos acompanhando e informaremos mediante novidades.</p>`
      } else {
        mensagem =
            `<p>Segue anexo DI registrada.</p>` +
            `<p>Declaração aguardando parametrização prevista para ocorrer no início da tarde de amanhã.</p>` +
            `<p>Seguiremos acompanhando e informaremos mediante novidades.</p>`
      }
      break;
    case 2:
      mensagem =
      `<p>Confirmamos a chegada da carga na região de {porto_destino} no dia {data_chegada}.</p>` +
      `<p>Aguardamos a presença de carga no recinto {recinto_nome} para darmos continuidade aos procedimentos de despacho aduaneiro.</p>` +
      `<p>Seguiremos acompanhando e informaremos mediante novidades.</p>`
      break;
    case 3:
      mensagem =
          `<p>Declaração de importação desembaraçada, segue anexo a CI.</p>` +
          `<p>Processo encaminhado ao setor de logística para dar sequência nos trâmites de liberação da carga.</p>` +
          `<p>Seguiremos acompanhando e informaremos mediante novidades</p>`

        break;
    case 4:
      mensagem =
          `<p>Declaração de importação registrada e desembaraçada, segue anexo a DI e CI.</p>` +
          `<p>Seguiremos com a liberação documental no recinto {recinto_nome}.</p>` +
          `<p>Seguiremos acompanhando e informaremos mediante novidades.</p>`
        break;
    case 5:
      mensagem =
        `<p>Carga presenciada no recinto {recinto_nome}.</p>` +
        `<p>Aguardamos o depósito de numerário para seguir com o registro da DI.</p>` +
        `<p>Seguiremos acompanhando e informaremos mediante novidades.</p>`
      break;
  }

  if (processo.value.dataChegada) {
    mensagem = mensagem.replaceAll('{data_chegada}', formatDate(processo.value.dataChegada))
  } else {
    mensagem = mensagem.replaceAll('{data_chegada}', '**/**/****')
  }

  if (processo.value.recintoAlfandegado) {
    let recintoNome = processo.value.recintoAlfandegado.nomeExibicao ? processo.value.recintoAlfandegado.nomeExibicao :  processo.value.recintoAlfandegado.nome;
    mensagem = mensagem.replaceAll('{recinto_nome}', recintoNome)
  }

  if (processo.value.destino) {
    let destinoNome = processo.value.destino.nomeExibicao ? processo.value.destino.nomeExibicao :  processo.value.destino.nome;
    mensagem = mensagem.replaceAll('{porto_destino}', destinoNome)
  }

  formData.mensagem = mensagem


  updateFormValues(formData)

  console.log('e', e)
  console.log('macro executada com sucesso!')
};

const getFormSchema = () => {
  return {
    sections: [
      {
        //section: 'Informações Gerais',
        description: "Informações sobre a situação do mapa no processo",
        col: 'col-12 col-md-12',
        fields: [
          {
            label: 'Data',
            name: 'data',
            type: DateTimeInput,
            col: 'col-12 col-md-3',
            rules: Yup.date().required("Campo obrigatório"),
          },
          {
            label: 'Responsável',
            name: 'responsavel',
            type: RelationInput,
            col: 'col-12 col-md-5',
            rules: Yup.object().required("Responsável é obrigatório"),
            endpoint: 'pessoa/colaborador',
            display: (value) => {
              return value.nome ?? value.razaoSocial
            }
          },
          {
            label: 'Visibilidade',
            name: 'interno',
            type: SelectInput,
            col: 'col-12 col-md-2',
            rules: Yup.string().required("Obrigatório"),
            options: AcompanhamentoService.getTiposOperacao(),
          },
          {
            label: 'Tipo',
            name: 'tipoAcompanhamento',
            type: SelectInput,
            col: 'col-12 col-md-2',
            rules: Yup.string().required("Tipo é obrigatório"),
            options: AcompanhamentoService.getGrupos(),
          },
          {
            label: 'Descrição',
            name: 'mensagem',
            type: RichTextEditorInput,
            col: 'col-12 col-md-12',
            rules: Yup.string().required("Você deve informar uma descrição"),
          }
        ]
      }
    ],
  }
}

var formSchema = getFormSchema();

onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});

onBeforeMount(() => {
  loadMacros()
});


const onSubmit = async (values) => {
  emit('onLoading', true)
  try {
    if (acompanhamentoId.value) {
      await ProcessoService.updateAcompanhamento(processoId.value, acompanhamentoId.value, values);

    } else {
      await ProcessoService.newAcompanhamento(processoId.value, values);
    }

    closeModal();
    emit('save')
  } catch (error) {
    showToast('Não foi possível salvar o acompanhamento', {
      title: 'Erro',
      type: 'error'
    })
  } finally {
    emit('onLoading', false)
  }
}


const openModal = async (id, acompanhamento) => {
  emit('onLoading', true)
  processo.value = null

  let pessoa = await getPessoa()

  formData = {
    data: new Date(),
    mensagem: '',
    responsavel: pessoa,
    interno: false,
    tipoAcompanhamento: 2,
  };

  if (bsModalInstance) {
    processoId.value = id
    acompanhamentoId.value = acompanhamento

    processo.value = await ProcessoService.getProcess(processoId.value);

    if (acompanhamentoId.value) {
      let acompanhamento = await ProcessoService.getAcompanhamento(processoId.value, acompanhamentoId.value)
      Object.assign(formData, acompanhamento);
    }

    formSchema = getFormSchema()
    updateFormValues(formData)
    bsModalInstance.show();
  }
};

const closeModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};

const handleCancel = () => {
  closeModal();
  emit('cancel');
};

const validateAndSave = async () => {
  if (modalForm.value) {
    await modalForm.value.submitForm();
  }
};

const updateFormValues = (values) => {
  if (modalForm.value) {
    modalForm.value.updateFormValues(values)
  }
};

defineExpose({
  openModal,
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
<style scoped>
  .info-container {
    display: flex;
    justify-content: space-between; /* Distribui o texto e o ícone com espaço entre eles */
    align-items: center; /* Alinha verticalmente ao centro */
  }

  .info-text {
    text-align: left; /* Garante que o texto está alinhado à esquerda */
  }

  .icon {
    cursor: pointer; /* Mostra o cursor de mão ao passar sobre o ícone */
    text-align: right; /* Alinha o ícone à direita */
  }

  .scrollable-column {
    display: flex;
    flex-direction: column;
    height: 100%;
    overflow-y: auto;
    max-height: 350px;
    border-left: 1px solid #e0e0e0;
  }

  .modal-body {
    display: flex;
    flex-grow: 1;
  }

  .row.d-flex {
    display: flex;
    flex-grow: 1;
  }
</style>