import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getTarefas = async (pageNumber, pageSize, situacao, responsavelId, searchTerm, filters) => {
    try {
        const params = new URLSearchParams();

        params.append('pageNumber', pageNumber);
        params.append('pageSize', pageSize);
        if(situacao) {
            params.append('situacao', situacao);
        }
        if(searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        if(responsavelId) {
            params.append('responsavelId', responsavelId);
        }

        // Serializa os filtros manualmente
        filters.forEach((filter, index) => {
            Object.keys(filter).forEach(key => {
                params.append(`filters[${index}].${key}`, filter[key]);
            });
        });


        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/agenda/list`,{
            params: params
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar tafefas:', error);
        throw error;
    }
};


const getAcompanhamentos = async (processoId, pageNumber, pageSize, searchTerm, visibilidade) => {
    try {



        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${processoId}/acompanhamentos`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                visibilidade
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar Acompanhamentos:', error);
        throw error;
    }
};


const getTarefa = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/agenda/tarefa/${id}/detalhes`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar tarefa', error);
        throw error;
    }
};


const getTarefaPrepararEnvioFollowAgrupado = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/agenda/tarefa/${id}/follow-up-agrupado/preparar-envio`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar tarefa', error);
        throw error;
    }
};


const newProcess = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao slavar o processo', error);
        throw error;
    }
};


const adiarTarefa = async (tarefaId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/agenda/tarefa/${tarefaId}/adiar`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao slavar o processo', error);
        throw error;
    }
};

const concluirTarefa = async (tarefaId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/agenda/tarefa/${tarefaId}/concluir`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar a tarefa', error);
        throw error;
    }
};

const updateProcess = async (processId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/processo/${processId}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao slavar o processo', error);
        throw error;
    }
};

const updateSituacaoMapa = async (processoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${processoId}/mapa`, values);

        return response.data;
    } catch (error) {
        console.error('Erro ao salvar alteração no mapa', error);
        throw error;
    }
};


const getListTiposRemocao = () => {
    return [
        { nome: 'Não aplica', id: 1 },
        { nome: 'DTC', id: 2 },
        { nome: 'DTA', id: 3 },
    ];
}


const getTiposRemocao =  () => {
    return getListTiposRemocao()
};

const atribuirResponsavel = async (processoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${processoId}/responsavel`, values);

        return response.data;
    } catch (error) {
        console.error('Erro ao salvar alteração no mapa', error);
        throw error;
    }
};

const getListTarefaSituacao = () => {
    return [
        { nome: 'Pendente', id: 1 },
        { nome: 'Adiado', id: 2 },
        { nome: 'Concluída', id: 3},
        { nome: 'Atrasada', id: 4},
    ];
}

const geTarefaSituacao =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListTarefaSituacao()]
    }
    return getListTarefaSituacao()
};


export default {
    getTarefas,
    getTarefa,
    geTarefaSituacao,
    adiarTarefa,
    concluirTarefa,
    newProcess,
    updateProcess,
    getAcompanhamentos,
    updateSituacaoMapa,
    atribuirResponsavel,
    getTarefaPrepararEnvioFollowAgrupado,
};