<template>
  <div
    class="TextInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label v-if="displayLabel" :for="name" class="d-block text-black fw-semibold mb-10">{{ label }}</label>
    <input v-if="mask"
      class="form-control shadow-none rounded-1 text-black"
      :name="name"
      :id="name"
      :type="type"
      :value="inputValue"
      :placeholder="placeholder"
      :disabled="disabled"
      @input="handleInputChange"
      @blur="handleBlur"
      v-mask="mask"
      :maxlength="maxLength"
    />
    <input v-else
           class="form-control shadow-none rounded-1 text-black"
           :name="name"
           :id="name"
           :type="type"
           :value="inputValue"
           :placeholder="placeholder"
           :disabled="disabled"
           @input="handleChange"
           @blur="handleBlur"
           :maxlength="maxLength"
    />
    <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }"  v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>


<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  maxLength: {
    type: Number,
    required: false,
    default: 255
  },
  displayLabel: {
    type: Boolean,
    required:false,
    default: true
  },
  mask: {
    type: String,
    required: false,
    default: undefined,
  },
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onChanged: {
    type: Function,
    required: false,
    default: () => {}
  }
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const handleInputChange = (e) => {
  handleChange(e); // Chama o manipulador de mudança padrão do VeeValidate
  props.onChanged(e.target.value); // Chama a função passada via props
};

</script>