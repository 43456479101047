<template>
  <BreadCrumb  />
  <UsuarioForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import UsuarioForm from "@/views/Usuarios/UsuarioForm.vue";

export default defineComponent({
  name: "NovoProcessoPage",
  components: {
    BreadCrumb,
    UsuarioForm,
  },
});
</script>
