<template>
  <div class="dropdown notification-dropdown">
            <button
              class="dropdown-toggle p-0 position-relative bg-transparent border-0 transition lh-1"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <i class="flaticon-bell-2"></i>
              <span v-if="TotalNotificacoes > 0"
                class="dot-badge position-absolute fw-bold rounded-circle text-white bg-primary d-inline-block"
              >
                {{ TotalNotificacoes }}
              </span>
            </button>

            <div
              class="dropdown-menu rounded-0 bg-white border-0 start-auto end-0"
            >
              <div
                class="title d-flex align-items-center justify-content-between"
              >
                <span class="text-black fw-bold"
                  >Notificações <span class="text-muted">({{ TotalNotificacoes }})</span></span
                >
                <div
                  class="text-decoration-none link-btn transition text-primary fw-medium cursor-pointer"
                  @click="markAllRead"
                >
                  Marcar todas como lidas
                </div>
              </div>
              <ul class="ps-0 mb-0 list-unstyled dropdown-body" v-if="notifications.length > 0">
                <li class="text-muted position-relative" v-for="(notification, index) in notifications" :key="index" >
                  <div>
                    <div
                      class="icon rounded-circle position-absolute text-center"
                    >
                      <i class="ph-bold" :class="TypeToIcon(notification)"></i>
                    </div>
                    <span class="d-block text-black-emphasis" v-html="notification.message"></span>
                    {{ DataParaTempoAtras(notification.createdAt) }}
                    <a v-if="notification.goTo"
                       @click="markRead(notification)"
                      class="d-block position-absolute start-0 top-0 end-0 bottom-0 text-decoration-none cursor-pointer"
                    ></a>
                    <span
                      class="unread d-inline-block rounded-circle bg-primary position-absolute w-10 h-10"
                      v-if="!notification.isRead"
                    >
                    </span>
                  </div>
                </li>


              </ul>

              <ul class="ps-0 mb-0 list-unstyled dropdown-body" v-else>
                <li class="text-muted position-relative" >
                  <div
                      class="icon rounded-circle position-absolute text-center"
                  >
                    <i class="ph-bold ph-trophy text-outline-success"></i>
                  </div>
                  <span class="d-block text-black-emphasis">Nenhuma notificação não lida</span>
                </li>


              </ul>

              <div class="text-center dropdown-footer">
                <router-link
                  to="/notificacoes"
                  class="link-btn text-decoration-none text-primary position-relative d-inline-block transition fw-medium fw-medium"
                >
                  Ver todas as notificações
                </router-link>
              </div>
            </div>
</div>
</template>

<script setup>
import NotificationService from "@/services/NotificationService.js";
import { HubConnectionBuilder } from '@microsoft/signalr';
import {ref, onMounted, onBeforeUnmount} from 'vue';
import KeycloakService from '@/security/KeycloakService.js'
import {DataParaTempoAtras} from "@/utils/dateHelper.js";
import { useToast} from "@/plugins/useToast.js";
import router from "@/router/index.js";

const API_URL = import.meta.env.VITE_API_URL;

const notifications = ref([]);
const {showToast} = useToast();
let alertTimeout;
let audio;

let connection = ref(null)

const getToken = () => {
  return KeycloakService.GetAccesToken();
};

// Inicialize o áudio somente após interação do usuário
const initializeAudio = () => {
  if (!audio) {
    audio = new Audio("/audio/alert.wav");
  }
};

const prepareAudio = () => {
    initializeAudio();
    window.removeEventListener("mousemove", prepareAudio);
    window.removeEventListener("click", prepareAudio);
    window.removeEventListener("keydown", prepareAudio);
};

const startConnection = async () => {
  const MAX_ATTEMPTS = 3; // Número máximo de tentativas
  let attempts = 0; // Contador de tentativas

  try {
    // Criando a conexão com o SignalR
    connection.value = new HubConnectionBuilder()
        .withUrl(`${API_URL}/api/notificationHub`, {
          withCredentials: true,
          headers: {
            Authorization: `Bearer ${getToken()}`, // Adiciona o token no cabeçalho
          }
        })
        .withAutomaticReconnect()
        .build();

    while (attempts < MAX_ATTEMPTS) {
      attempts++;
      try {
        await connection.value.start();
        console.log(`Conectado ao SignalR na tentativa ${attempts}`);
        break; // Sai do loop se conectar com sucesso
      } catch (error) {
        console.log(`Tentativa ${attempts} falhou:`, error);

        if (attempts >= MAX_ATTEMPTS) {
          throw new Error("Não foi possível conectar ao SignalR após 3 tentativas");
        }

        await new Promise((resolve) => setTimeout(resolve, 2000)); // Exemplo: aguarda 2 segundos
      }
    }


    // Registrando o método para receber notificações
    connection.value.on('ReceiveNotification', (novaNotificacao) => {
      TotalNotificacoes.value++
      notifications.value.unshift(novaNotificacao);

      playAlertSound()
    });


    connection.value.onclose(async (error) => {
      console.error("Conexão perdida, tentando reconectar...", error);
      await startConnection();
    });
  } catch (error) {
    console.error('Erro ao conectar no SignalR', error);
  }
};

// Fechando a conexão ao desmontar o componente
onBeforeUnmount(() => {
  if (connection.value) {
    connection.value.stop();
    console.log('Conexão com o SignalR foi interrompida');
  }
});

// Iniciando a conexão quando o componente for montado
onMounted(async () => {
  // Adiciona eventos para capturar a interação inicial
  window.addEventListener("click", prepareAudio);
  window.addEventListener("mousemove", prepareAudio);
  window.addEventListener("keydown", prepareAudio);

  let notificoesObtidas = false;

  try {
    let notificacoes = await NotificationService.getUltimasNotificacoes()
    TotalNotificacoes.value = notificacoes.totalItems
    notifications.value = [...notificacoes.items]
    notificoesObtidas = true;
  } catch (err) {
    console.log('----------------------------------------------------------------')
    console.log('erro', err)
    console.log('----------------------------------------------------------------')

    showToast(`Não foi obter as notificações. <br/> Atualize a página.`, {
      title: 'Erro',
      type: 'error'
    });
  }

  if (notificoesObtidas) {
    setTimeout(() => {
      startConnection();
    }, 1000)
  }

});

const TotalNotificacoes  = ref(0)

const TypeToIcon = (notification) => {
  const iconMap = {
    di_digitada: 'ph-list-checks',
    di_digitada_aprovada: 'ph-check-circle text-success',
    di_digitada_pendencia: 'ph-warning-octagon text-danger',
  };
  return iconMap[notification.type] || 'ph-question';
}


const GoTo2Rote = (goTo) => {
  if(!goTo) {
    return undefined
  }

  let route = JSON.parse(goTo)

  router.push(route)
}

const playAlertSound = () => {
  clearTimeout(alertTimeout);

  alertTimeout = setTimeout(() => {
    //const audio = new Audio("@/assets/audio/alert.wav");
    audio.play().catch((error) => {
      console.log('error', error)
      console.error("Erro ao tocar o som:", error);
    });
  }, 1000);
}


const markAllRead = async () => {
  try {
    await NotificationService.postMarkAllRead();

    notifications.value = []
    TotalNotificacoes.value = 0;

    showToast('Todas as notificações foram marcadas como lidas.', {
      title: 'Sucesso',
      type: 'success'
    });
  } catch (error) {
    showToast(`Não foi possível marcar todas como lidas<br/>${error.response.data}`, {
      title: 'Erro',
      type: 'error'
    });
  }

}

const markRead = async (notification) => {

  if(!notification) {
    return
  }

  await NotificationService.postMarkRead(notification.id);
  notifications.value = notifications.value.filter((p) => p.id !== notification.id);
  TotalNotificacoes.value--
  GoTo2Rote(notification.goTo)

}

</script>


<style>
  .notification-dropdown p {
    margin-bottom: 0;
  }

</style>