<template>
  <div class="card mb-25 border-0 rounded-0 bg-white h-100">
    <div class="card-body p-15 p-sm-20 p-sm-25 p-lg-30 letter-spacing">
      <div class="mb-15 d-flex align-items-center justify-content-between">
        <h5 class="card-title fw-bold mb-0">Processos por situação</h5>
      </div>
      <div id="projectDistributionChart" class="chart">
        <apexchart
            type="donut"
            height="280"
            :options="projectDistributionChart"
            :series="chartData"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, ref} from 'vue';

const chartData = computed(() => {
  return props.processosPorSituacao.map(el => {
    return el.nItens;
  })
})


// Definindo os dados da série para o gráfico de distribuição


// Configurando as opções do gráfico de distribuição
const projectDistributionChart = ref({
  chart: {
    type: "donut",
    height: 382,
  },
  plotOptions: {
    pie: {
      donut: {
        size: "75%",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 4,
  },
  colors: [
    '#D0D0D0', // - Cinza claro elegante
    '#A8D5A2CC', // - Verde claro pastel
    '#F8B88BCC', // - Laranja suave
    '#F7E7A0CC', // - Amarelo claro pastel
    '#F8A6A1CC', // - Coral claro (substituído por outra cor neutra abaixo)
    '#A3C8F2', // - Azul claro pastel
    '#EFE1A7', // - Amarelo ouro suave
    '#94D3C2', // - Verde água pastel

  ], // Cores para cada coluna
  legend: {
    position: "bottom",
    horizontalAlign: "center",
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Red Hat Display, sans-serif",
    labels: {
      colors: "#9C9AB6",
    },
    markers: {
      offsetX: -2,
    },
    itemMargin: {
      horizontal: 8,
      vertical: 5,
    },
  },
  labels: ["Ag. Embarque", "Embarcados", "Desembarcados", "Finalizados"],
  tooltip: {
    style: {
      fontSize: "14px",
      fontFamily: "Red Hat Display, sans-serif",
    },
  },
});

const props = defineProps({
      processosPorSituacao: {
        type: Array,
        required: true,
        default: () => []
      }
    }
);
</script>
