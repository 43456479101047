<template>
  <div
    class="TextInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label :for="name" class="d-block text-black fw-semibold mb-10">{{ label }}</label>
    <select class="form-control shadow-none rounded-1 text-black"
            :name="name"
            :id="name"
            :disabled="disabled"
            @input="handleInputChange"
            @blur="handleBlur"
    >
      <option  :selected="option.id == inputValue"  :value="option.id" v-for="option in options" v-html="option.nome" ></option>
    </select>


    <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }"  v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>


<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  options: {
    type: Array,
    required: true
  },
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onChanged: {
    type: Function,
    required: false,
    default: () => {}
  }
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

const handleInputChange = (e) => {
  handleChange(e); // Chama o manipulador de mudança padrão do VeeValidate
  props.onChanged(e.target.value); // Chama a função passada via props
};

/*
function handleSelectChange(event) {
  let value = event.target.value
  if (value){
    value = parseInt(value);

  }
  // Atualiza o valor do input com o valor do select
  inputValue.value = value;

  // Chama a função onChanged passada via props
  props.onChanged(inputValue.value);
}
*/
</script>

<style scoped>
  select.form-control {
    padding: 9px 11px;
  }
</style>