import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getPrevFaturamentoMensal = async (pageNumber, pageSize, year, month, filial, situacaoDi) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/relatorio/previsao-faturamento-mensal`,{
            params: {
                pageNumber,
                pageSize,
                year,
                month,
                filial,
                situacaoDi
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};


const getDistribuicaoProcessosAnalista = async (pageNumber, pageSize, responsavelId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/relatorio/distribuicao-processos-por-analista`,{
            params: {
                pageNumber,
                pageSize,
                responsavelId
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getPessoaContaBancaria = async (pageNumber, pageSize, responsavelId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/relatorio/pessoa-conta-bancaria`,{
            params: {
                pageNumber,
                pageSize,
                responsavelId
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};


const getControleDemurrageLavacao = async (pageNumber, pageSize) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/relatorio/controle-demurrage-lavacao`,{
            params: {
                pageNumber,
                pageSize
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getProcessoEstatistica = async (pageNumber, pageSize) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/relatorio/processos-estatisticas`,{
            params: {
                pageNumber,
                pageSize
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};



export default {
    getPrevFaturamentoMensal,
    getDistribuicaoProcessosAnalista,
    getPessoaContaBancaria,
    getControleDemurrageLavacao,
    getProcessoEstatistica
};