import Keycloak from "keycloak-js";

const keycloakInstance = new Keycloak({
    url: import.meta.env.VITE_KEYCLOAK_URL,
    realm: 'Triveni',
    clientId: 'prana',
    "ssl-required": "none",
    resource: "prana",
    "public-client": true,
    "verify-token-audience": true,
    "use-resource-role-mappings": true,
    "confidential-port": 0
});
/*
"realm": "Triveni",
    "auth-server-url": "http://localhost:8080/",
    "ssl-required": "external",
    "resource": "prana",
    "public-client": true,
    "confidential-port": 0
*/
/**
 * Initializes Keycloak instance and calls the provided callback function if successfully authenticated.
 *
 * @param {Function} onAuthenticatedCallback
 */
const Login = (onAuthenticatedCallback) => {
    keycloakInstance
        .init({
            onLoad: "login-required" ,
            redirectUri: window.location.href
        })
        .then(function (authenticated) {
            authenticated ? onAuthenticatedCallback() : alert("non authenticated");
        })
        .catch((e) => {
            console.dir(e);
            console.log(`keycloak init exception: ${e}`);
        });
};

const UserName = () => keycloakInstance?.tokenParsed?.preferred_username;

const Name = () => keycloakInstance?.tokenParsed?.name


const Cargo = () => keycloakInstance?.tokenParsed?.cargo

const CodigoPessoa = () => keycloakInstance?.tokenParsed?.codigo_pessoa

const Pessoa = () => {
    return {
        conexosId: keycloakInstance?.tokenParsed?.codigo_pessoa,
        nome: keycloakInstance?.tokenParsed?.name
    }
}

const Token = () => keycloakInstance?.token;

const LogOut = () => keycloakInstance.logout();

const UserRoles = () => {
    if (keycloakInstance.resourceAccess === undefined) return undefined;
    if (keycloakInstance.resourceAccess["prana"] === undefined) return undefined;

    return keycloakInstance.resourceAccess["prana"].roles;
};

const updateToken = (successCallback) =>
    keycloakInstance.updateToken(5).then(successCallback).catch(doLogin);

const doLogin = keycloakInstance.login;

const isLoggedIn = () => !!keycloakInstance.token;

const isGranted = (role) => {
    if (keycloakInstance.resourceAccess === undefined) return undefined;

    if (keycloakInstance.resourceAccess["prana"] === undefined) return undefined;

    return keycloakInstance.resourceAccess["prana"].roles.includes(role);
}


const KeycloakService = {
    CallLogin: Login,
    GetUserName: UserName,
    GetName: Name,
    GetCargo: Cargo,
    GetCodigoPessoa: CodigoPessoa,
    GetAccesToken: Token,
    CallLogOut: LogOut,
    GetUserRoles: UserRoles,
    UpdateToken: updateToken,
    IsLoggedIn: isLoggedIn,
    IsGranted: isGranted,
    GetPessoa: Pessoa,
};

export default KeycloakService;
