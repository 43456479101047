import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getCargosResponsaveis = async (pessoaId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/pessoa/cargo/${pessoaId}/responsaveis`,{});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar informações do cargo:', error);
        throw error;
    }
};

const getCargos = async (pageNumber, pageSize, searchTerm, situacao) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/cargo/lista`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                situacao
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getCargo = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/cargo/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar cargo', error);
        throw error;
    }
};

const updateCargo = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/cargo/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o cargo', error);
        throw error;
    }
};

const createCargo = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/cargo`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o cargo', error);
        throw error;
    }
};


const updateAnalistaResponsavel = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/cargo/${id}/alterar-analista`, {});
        return response.data;
    } catch (error) {
        console.error('Erro ao alaterar o analista responsavel do cargo', error);
        throw error;
    }
};

export default {
    getCargosResponsaveis,
    getCargos,
    getCargo,
    createCargo,
    updateCargo,
    updateAnalistaResponsavel
};