import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

// Exemplo de uma constante
const FOLLOW_UP_CONFIRMACAO_CHEGADA = 1;
const FOLLOW_UP_PRESENCA_CARGA = 2;
const FOLLOW_UP_DI_REGISTRADA = 3;

const getAcompanhamentosResponsaveis = async (pessoaId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/pessoa/acompanhamento/${pessoaId}/responsaveis`,{});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar informações do acompanhamento:', error);
        throw error;
    }
};

const getAcompanhamentos = async (pageNumber, pageSize, searchTerm, situacao) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/acompanhamento/lista`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                situacao
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getAcompanhamento = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/acompanhamento/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar acompanhamento', error);
        throw error;
    }
};

const updateAcompanhamento = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/acompanhamento/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o acompanhamento', error);
        throw error;
    }
};

const createAcompanhamento = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/acompanhamento`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o acompanhamento', error);
        throw error;
    }
};


const updateAnalistaResponsavel = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/acompanhamento/${id}/alterar-analista`, {});
        return response.data;
    } catch (error) {
        console.error('Erro ao alaterar o analista responsavel do acompanhamento', error);
        throw error;
    }
};


const getListTiposOperacao = () => {
    return [
        { nome: 'Interno', id: true },
        { nome: 'Externo', id: false },

    ];
}

const getTiposOperacao =() => {
    return (getListTiposOperacao());
}

const getListGrupos = () => {
    return [
        { nome: 'Follow Up', id: 2 },
        { nome: 'Mapa Madeira', id: 1 },
        { nome: 'Alteração responsável', id: 3 },
        { nome: 'Visita Comercial', id: 4 },
        { nome: 'Contato cliente (E-mail, Telefone, Whatsapp)', id: 5 },
    ];
}

const getGrupos =() => {
    return (getListGrupos());
}


const getListMacros = () => {
    return [
        { nome: 'Confirmação de chegada', id: 2 },
        { nome: 'D.I. Registrada', id: 1 },
        { nome: 'D.I. Desembaraçada', id: 3 },
        { nome: 'D.I. Registrada e D.I. Desembaraçada', id: 4 },
        { nome: 'Presença + Numerário', id: 5 },
    ];
}

const getMacros =() => {
    return (getListMacros());
}


export default {
    getAcompanhamentosResponsaveis,
    getAcompanhamentos,
    getAcompanhamento,
    createAcompanhamento,
    updateAcompanhamento,
    updateAnalistaResponsavel,
    getTiposOperacao,
    getGrupos,
    getMacros,
    FOLLOW_UP_CONFIRMACAO_CHEGADA,
    FOLLOW_UP_PRESENCA_CARGA,
    FOLLOW_UP_DI_REGISTRADA,

};