<!-- AtribuirResponsavelModal.vue -->
<template>
  <div
      class="modal fade"
      id="atribuirResponsavelModal"
      data-bs-backdrop="static"
      data-bs-keyboard="true"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Atribuir responsável</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicForm ref="atribuirResponsavelForm" :schema="formSchema" :show-actions="false" :initial-values="formData" @onSubmit="onSubmit" @onCancel="onCancel"  />
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-outline-light me-3"
              @click="onCancel"
          >
            Cancelar
          </button>
          <button
              type="button"
              class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              @click="validateAndSave"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {computed, defineEmits, onBeforeMount, onMounted, reactive, ref} from 'vue';
import DynamicForm from "@/components/form/DynamicForm.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import {ProcessoSituacaoService} from "@/services/ProcessoSituacaoService.js";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import * as Yup from 'yup';
import TextAreaInput from "@/components/form/TextAreaInput.vue";
import ProcessoService from "@/services/ProcessoService.js";
import {useRoute} from "vue-router";
import RelationInput from "@/components/form/RelationInput.vue";
import {Modal} from "bootstrap";

let bsModalInstance = null;
const modalElement = ref(null);
const closeButton = ref(null);
const atribuirResponsavelForm = ref(null);
const processoId = ref(null)
const formData = reactive({

});

const showDataVistoria = computed(() => {
  let statusList = [3]

  return statusList.includes(formData.atribuirResponsavelVegetal)
});

const showDataAgendamentoVistoria = computed(() => {
  let statusList = [2]

  return statusList.includes(formData.atribuirResponsavelVegetal)
});

const emit = defineEmits(['cancel', 'save']);

const formSchema = {
  sections: [
    {
      //section: 'Informações Gerais',
      description: "Atribuir financeiro responsável",
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Responsável',
          name: 'responsavel',
          type: RelationInput,
          col: 'col-12 col-md-4',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Observação',
          name: 'observacao',
          type: TextAreaInput,
          col: 'col-12 col-md-12',
          rules: Yup.string(),
        },
      ]
    }
  ],
};


onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});

onBeforeMount(async ()  => {
  const  currentRoute  = useRoute();

  if (currentRoute.params.hasOwnProperty('id')) {
    processoId.value= currentRoute.params.id;
  }
})

const onSubmit = async (values) => {
  console.log('values', values)
  let result = await ProcessoService.atribuirResponsavel(processoId.value, values);
  console.log('result', result)

  emit('save')
}


const openModal = async (id) => {
  if (bsModalInstance) {
    processoId.value = id
    bsModalInstance.show();
  }
};


// Função para validar e salvar
const validateAndSave = async () => {
  if (atribuirResponsavelForm.value) {
     await atribuirResponsavelForm.value.submitForm();
  }
};

// Função para lidar com o cancelamento
function handleCancel() {
  emit('cancel');
}

function onCancel() {
  if (closeButton.value) {
    closeButton.value.click();
  }
}


defineExpose({
  openModal,
});
</script>
