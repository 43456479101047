<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>
  <BreadCrumb :title="getTitle"/>
  <Actions @onAction="onAction" :show-historico="pageStatus.showHistorico" @onRefresh="refreshPage" @onLoading="onLoadingChange" />
  <ProcessoForm v-if="!pageStatus.showHistorico" @process-loaded="onProcessLoaded" />
  <HistoricoList v-if="pageStatus.showHistorico" :processo-id="pageStatus.processoId" @onAction="onAction"  />
</template>

<script setup>
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import ProcessoForm from "@/views/Processos/ProcessoForm.vue";
import Actions from "@/views/Processos/Actions.vue";
import ProcessoService from "@/services/ProcessoService.js";
import Loading from "vue-loading-overlay";
import {computed, ref, onBeforeMount, nextTick, onMounted} from "vue";
import HistoricoList from "@/views/Processos/Historico/HistoricoList.vue";
import {useRoute} from "vue-router";

const isLoading = ref(false);
const processo = ref(null)
const  currentRoute  = useRoute();

onMounted(async ()  => {

  if ( currentRoute.name == 'ProcessoDetalhesAcompanhamentos') {
    console.log('in')
    let processoId = currentRoute.params.id
    nextTick(() => {
      pageStatus.value.showHistorico = false
      nextTick(() => {
        pageStatus.value.showHistorico = true
        showHistorico(processoId)
      })

    });
  }
})



const pageStatus = ref({
  showHistorico : false,
  processoId: null
})

const onAction = (props) => {
  switch (props.action) {
    case 'showHistorico':
        showHistorico(props.processoId)
          break;
    case 'showProcesso':
      showProcesso()
      break;

  }

}

const getTitle = computed(() => {
  if(processo.value) {
    return `${processo.value.referenciaInterna}`
  }


});


const refreshPage = () => {
  console.log('refresh')
  window.location.reload();
}


const onProcessLoaded = (value) =>{

  processo.value = value
}

const showHistorico = async (processoId) =>  {
  isLoading.value = true

  pageStatus.value.processoId = null
  pageStatus.value.showHistorico = true

  // Force change
  setTimeout(() => {
    pageStatus.value.processoId = processoId
  }, 100)

  isLoading.value = false
}

const showProcesso = async () =>  {
  // let historicos = await ProcessoService.getAcompanhamentos(processoId, 1, 10, null);

  pageStatus.value.showHistorico = false
}


const onLoadingChange = (loading) => {
  isLoading.value = loading
}

</script>
