<template>
  <data-grid
      endpoint="/api/tipo-container"
      section="data-grid"
      :filterForm="filterForm"
      :default-filters="defaultFilters"
      :columns="defaultColumnsSettings"
      new-label="Novo"
      new-action="/tipos-containers/novo"
      :initial-sort-by="[]"
      @on-db-click-row="showDetails"
      :allow-toggle-only-my="false"
  >
    <template v-slot:[`item-ativo`]="{item}">
      <div>{{ item.ativo ? 'Sim' : 'Não' }}</div>
    </template>

    <template v-slot:[`item-acoes`]="{item}">
      <div class="d-flex justify-content-center align-items-center">
        <a
            class="dropdown-item cursor-pointer d-flex justify-content-center align-items-center"
            @click.prevent="showDetails(item)"
        >
          <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
        </a>
      </div>

    </template>

  </data-grid>
</template>

<script setup>
import router from "@/router/index.js";
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import dataGrid from '@/components/dataGrid/dataGrid.vue'

const defaultFilters = {
  statusFilters: [ ],
  // statusFilters: [
  //   {
  //     label: 'Situação',
  //     name: 'situacao',
  //     value: 1,
  //     options: [
  //       { value: null,  name: 'Todos' },
  //       { value: 3,     name: 'Em cadastro' },
  //       { value: 1,     name: 'Ativo' },
  //       { value: 3,     name: 'Inativo' },
  //       { value: 4,     name: 'Bloqueado' },
  //       { value: 5,     name: 'Não vender' },
  //     ]
  //   }
  // ],
  filters: null
}

const defaultColumnsSettings = [
  { text: "NOME", value: "nome", sortable: false  },
  { text: "SIGLA", value: "sigla", sortable: false  },
  { text: "ATIVO", value: "ativo", sortable: false },
  { text: "AÇÕES", value: "acoes",  width: 50, sortable: false, slot: true },
]
const filterForm = {
  sections: [
    {
      section: "Geral",
      fields: [
        {
          label: 'Nome',
          name: 'nome',
          type: TextInput,
          col: 'col-12',
        },
        {
          label: 'Sigla',
          name: 'sigla',
          type: TextInput,
          col: 'col-12',
        },
      ]
    }
  ],
};

function showDetails(item, newWindow) {
  const routeData = router.resolve({
    name: 'TipoContainerEdit',
    params: { id: item.id }
  });

  if (newWindow) {
    window.open(routeData.href, '_blank');
  } else {
    router.push(routeData);
  }

}



</script>
