// src/router/agenda.js
import AgendaPage from "@/views/Agenda/AgendaPage.vue";

import NovaTarefaPage from "@/views/Agenda/NovaTarefaPage.vue";

const agendaBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Agenda',
        to: 'Agenda'
    }
]

export default [
    {
        path: '/agenda',
        name: 'Agenda',
        component: AgendaPage,
        meta: {
            title: 'Agenda',
            breadcrumb: agendaBreadCrumb
        },
    },
    {
        path: '/agenda/tarefa/nova',
        name: 'TarefaNova',
        component: NovaTarefaPage,
        meta: {
            title: 'Novo Processo',
            breadcrumb: [
                ...agendaBreadCrumb,
                {
                    label: 'Novo Processo',
                    to: 'ProcessoNovo'
                }
            ]
        }
    },
    {
        path: '/agenda/tarefa/:id/detalhe',
        name: 'TarefaDetalhes',
        component: NovaTarefaPage,
        meta: {
            title: 'Detalhes da Tarefa',
            breadcrumb: [
                ...agendaBreadCrumb,
                {
                    label: 'Nova Tarefa',
                    to: 'TarefaNova'
                }
            ]
        }
    }
];
