<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div class="mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between">
        <!-- Título alinhado à esquerda -->
        <h6 class="card-title fw-bold mb-0 me-auto">Processos</h6>
        <!-- Container para filtro e pesquisa alinhados à direita -->
        <div class="d-flex align-items-center">
          <!-- DI Registrada -->
          <div class="card-select mt-10 mt-md-0 mb-10 mb-md-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5 me-3">
            <span class="fw-medium text-muted me-8">Situação DI</span>
            <select class="form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium w-130"
                    @change="onSituacaoDIChange"
            >
              <option  :selected="props.situacaoDi === null"  class="fw-medium" value="null" >Todas</option>
              <option  :selected="props.situacaoDi === true"  class="fw-medium" :value="true" >Registrada</option>
              <option  :selected="props.situacaoDi === false"  class="fw-medium" :value="false" >Não Registrada</option>

            </select>
          </div>
          <!-- Filtro -->
          <div class="card-select mt-10 mt-md-0 mb-10 mb-md-0 d-inline-block d-sm-flex align-items-center ps-10 pe-10 pt-5 pb-5 me-3">
            <span class="fw-medium text-muted me-8">Ref</span>
            <select class="form-select shadow-none text-black border-0 ps-0 pt-0 pb-0 pe-20 fs-14 fw-medium w-130"
                    @change="onReferenciaChange"
            >
              <option  :selected="props.year === referencia.year && props.month === referencia.month"  class="fw-medium" v-for="referencia in obterDatasReferencias()" v-bind:key="referencia" :value="referencia.month+'-'+ referencia.year" >{{ referencia.display }}</option>

            </select>
          </div>

          <!-- Pesquisa -->
<!--          <form>-->
<!--            <div class="input-group">-->
<!--              <input-->
<!--                  type="text"-->
<!--                  class="form-control shadow-none fw-medium ps-12 pt-8 pb-8 pe-12 letter-spacing"-->
<!--                  placeholder="Search"-->
<!--              />-->
<!--              <button-->
<!--                  class="default-btn position-relative transition border-0 text-white ps-12 pe-12 rounded-1"-->
<!--                  type="button"-->
<!--              >-->
<!--                <i class="flaticon-search-interface-symbol position-relative"></i>-->
<!--              </button>-->
<!--            </div>-->
<!--          </form>-->
        </div>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
              PROCESSO
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              CONEXOS ID
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              FILIAL
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              DI REGISTRADA
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end"
            >
              VALOR CIF (R$)
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 pe-0"
            >
              DATA CHEGADA
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 pe-0"
            >
              DATA REGISTRO D.I
            </th>
          </tr>
          </thead>
          <tbody>
          <!-- Loop através do `processos` para renderizar os dados -->
          <tr v-for="(processo, i) in processos" :key="i">
            <th class="shadow-none lh-1 fw-bold ps-0">

              <router-link
                  class="d-block text-decoration-none text-black"
                  :to="{name: 'ProcessoDetalhes', params: {id: processo.id}}"
              >
                {{ processo.referenciaInterna }}
              </router-link>
            </th>
            <td class="shadow-none lh-1 fw-medium">
              {{ processo.conexosId }}
            </td>
            <td class="shadow-none lh-1 fw-medium text-black-emphasis">
              <div class="d-flex align-items-center">

                {{ processo.filial }}
              </div>
            </td>
            <td class="shadow-none lh-1 fw-medium text-body-tertiary">

              <span class="new-badge" :class="['badge', {'text-outline-success':processo.diRegistrada, 'text-outline-primary':!processo.diRegistrada}]">{{ processo.diRegistrada ? 'Sim' : 'Não' }}</span>
            </td>
            <td class="shadow-none lh-1 fw-medium text-body-tertiary text-end">
              {{ formatDecimal(processo.valorCifMoedaNacional) }}
            </td>
            <td class="shadow-none lh-1 fw-medium text-body-tertiary">
              {{ formatDate(processo.dataChegada) }}
            </td>
            <td class="shadow-none lh-1 fw-medium text-body-tertiary">
              {{ formatDate(processo.dataRegistroDI) }}
            </td>
            <td class="shadow-none lh-1 fw-medium pe-0">

            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {formatDate} from "../../../utils/dateHelper.js";
import {formatDecimal} from "../../../utils/numberHelper.js";
import {monthNumberToShortName} from "@/utils/stringHelper.js";
import {ref} from "vue";

const emit = defineEmits(['changed']);

const onReferenciaChange = (event) => {
  const newValue = event.target.value;

  let values = newValue.split('-')
  let year = parseInt(values[1])
  let month = parseInt(values[0])

  emit('changed', {
    situacaoDI: props.situacaoDi,
    month: month,
    year: year,
  });
};

const onSituacaoDIChange = (event) => {
  const newValue = event.target.value;
  let value = null;
  if (newValue !== 'null') {
    value = newValue === 'true' ? true : false
  }

  emit('changed', {
    situacaoDI: value,
    month: props.month,
    year: props.year,
  });
}

function obterDatasReferencias() {
  const currentDate = new Date();
  currentDate.setMonth(currentDate.getMonth() + 1);
  const endYear = currentDate.getFullYear();
  const endMonth = currentDate.getMonth(); // 0-11, onde 0 é Janeiro e 11 é Dezembro

  const dates = [];
  for (let year = endYear; year >= endYear - 5; year--) {
    if(year < 2020) { break }
    for (let month = (year === endYear ? endMonth : 11); month >= 0; month--) {

      if(year === 2020 && month <= 10) {
        console.log('break')
        break

      }

      dates.push({
        year: year,
        month: month + 1,
        display: `${monthNumberToShortName(month + 1)}/${year}`
        }
      );
    }
  }

  return dates;
}

const props = defineProps({
  processos: {
    type: Array,
    required: true,
    default: () => []
  },
  year: {
    type: Number,
    required: true
  },
  month: {
    type: Number,
    required: true
  },
  situacaoDi: {
    type: Boolean,
    required: false,
    default: null
  }
});
</script>
