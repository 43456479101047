<!-- EnviarFollowModal.vue -->
<template>
  <div
      class="modal fade"
      id="enviarFollowModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Enviar Follow</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <div style="display: none">
              ProcessoId: {{processoId}}
          </div>
          <DynamicForm ref="modalForm" :schema="formSchema" :show-actions="false" :initial-values="formData" @onSubmit="onSubmit" @onCancel="handleCancel" />
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-outline-light me-3"
              @click="handleCancel"
          >
            Cancelar
          </button>
          <button
              type="button"
              class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              @click="validateAndSave"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, defineEmits, reactive, computed} from 'vue';
import { Modal } from 'bootstrap';
import DynamicForm from "@/components/form/DynamicForm.vue";
import * as Yup from "yup";
import TextAreaInput from "@/components/form/TextAreaInput.vue";
import AgendaService from "@/services/AgendaService.js";
import ProcessoService from "@/services/ProcessoService.js";
import TextInput from "@/components/form/TextInput.vue";
import {saudacao} from "@/utils/stringHelper.js";
import {useToast} from "@/plugins/useToast.js";

const {showToast} = useToast();

const modalElement = ref(null);
const processoId = ref(null);
const clienteId = ref(null);
const followUpTipo = ref(null);
const modalForm = ref(null);
let bsModalInstance = null;
let formData = reactive({

});

//import RichTextEditorInput from "@/components/form/RichTextEditorInput.vue";
import AutoCompleteInput from "@/components/form/AutoCompleteInput.vue";
import FileInput from "@/components/form/FileInput.vue";
import RichTextEditorInput from "@/components/form/RichTextEditorInput.vue";

const text = ref(`<p>${saudacao()},</p>` +
    '<p>Tudo bem?</p>' +
    '<p>Segue em anexo planilha de embarques para seu acompanhamento e conhecimento.</p>');

const emit = defineEmits(['cancel', 'save', 'onLoading']);

const getFormSchema = () => {
  return {
    sections: [
      {
        //section: 'Informações Gerais',
        description: "Informações sobre a situação do mapa no processo",
        col: 'col-12 col-md-12',
        fields: [
          {
            label: 'Destinatário(s)',
            name: 'destinatarios',
            type: AutoCompleteInput,
            endpoint:`/api/agenda/processo/${processoId.value}/follow-up-agrupado/contatos`,
            params: {
              clienteId: clienteId.value
            },
            display: (value) => {
              if (value.novo) {
                return value.id
              }

              return `${value.name} (${value.id})`
            },
            col: 'col-12 col-md-12',
            rules: Yup.array()
                .min(1, 'Você deve informar pelo menos um destinatário')
                .required("Você deve informar pelo menos um destinatário").of(
                Yup.object().shape({
                  id: Yup.string()
                      .required("Obrigatório")
                      .email("Deve conter apenas e-mails válidos")
                })
            )
            .strict(),
          },
          {
            label: 'CC(s)',
            name: 'cc',
            type: AutoCompleteInput,
            endpoint:`/api/agenda/processo/${processoId.value}/follow-up-agrupado/contatos`,
            params: {
              clienteId: clienteId.value
            },
            display: (value) => {
              if (value.novo) {
                return value.id
              }

              return `${value.name} (${value.id})`
            },
            col: 'col-12 col-md-6',
            rules: Yup.array().nullable("Você deve informar pelo menos um destinatário").of(
                Yup.object().shape({
                  id: Yup.string()
                      .required("Obrigatório").email("Deve conter apenas e-mails válidos")
                      .test('unique-email', function (value) {
                        const { path, createError } = this;
                        let destinatarios = modalForm.value?.getFieldValue('destinatarios');
                        let cc = modalForm.value?.getFieldValue('cc');
                        let repeated = [];

                        for (let i in destinatarios) {
                          const destinatario = destinatarios[i];
                          const founded = cc.findIndex(el => el.id === destinatario.id);

                          if (founded !== -1) {
                            repeated.push(destinatario.id);
                          }
                        }

                        if (repeated.length == 1) {
                          return createError({
                            path,
                            message: `O e-mail já está informado como destinatário: ${repeated.join(', ')}`
                          });
                        } else if (repeated.length > 1) {
                          return createError({
                            path,
                            message: `Os e-mails já estão informados como destinatários: ${repeated.join(', ')}`
                          });
                        }

                        return true;
                      })
                })
            )
                .strict(),
          },
          {
            label: 'CCO(s)',
            name: 'cco',
            type: AutoCompleteInput,
            endpoint:`/api/agenda/processo/${processoId.value}/follow-up-agrupado/contatos`,
            params: {
              clienteId: clienteId.value
            },
            display: (value) => {
              if (value.novo) {
                return value.id
              }

              return `${value.name} (${value.id})`
            },
            col: 'col-12 col-md-6',
            rules: Yup.array().nullable().of(
                Yup.object().shape({
                  id: Yup.string()
                      .required("Obrigatório").email("Deve conter apenas e-mails válidos")
                      .test('unique-email', function (value) {
                        const { path, createError } = this;
                        let destinatarios = modalForm.value?.getFieldValue('destinatarios');
                        let ccs = modalForm.value?.getFieldValue('cc');
                        let ccos = modalForm.value?.getFieldValue('cco');

                        let destinatarioRepeated = [];
                        let ccRepeated = [];

                        for (let i in destinatarios) {
                          const destinatario = destinatarios[i];
                          const founded = ccos.findIndex(el => el.id === destinatario.id);

                          if (founded !== -1) {
                            destinatarioRepeated.push(destinatario.id);
                          }
                        }

                        for (let i in ccs) {
                          const cc = ccs[i];
                          const founded = ccos.findIndex(el => el.id === cc.id);

                          if (founded !== -1) {
                            ccRepeated.push(cc.id);
                          }
                        }

                        if (destinatarioRepeated.length == 1) {
                          return createError({
                            path,
                            message: `O e-mail já está informado como destinatário: ${destinatarioRepeated.join(', ')}`
                          });
                        } else if (destinatarioRepeated.length > 1) {
                          return createError({
                            path,
                            message: `Os e-mails já estão informados como destinatários: ${destinatarioRepeated.join(', ')}`
                          });
                        }

                        if (ccRepeated.length == 1) {
                          return createError({
                            path,
                            message: `O e-mail já está informado em cópia: ${ccRepeated.join(', ')}`
                          });
                        } else if (ccRepeated.length > 1) {
                          return createError({
                            path,
                            message: `Os e-mails já estão informados em cópia: ${ccRepeated.join(', ')}`
                          });
                        }

                        return true;
                      })
                  ,
                })
            )
            .strict(),
          },
          {
            label: 'Assunto',
            name: 'assunto',
            type: TextInput,
            col: 'col-12 col-md-12',
            rules: Yup.string().required("Você deve informar o assunto do e-mail")
          },
          {
            label: 'Conteúdo',
            name: 'conteudo',
            type: RichTextEditorInput,
            col: 'col-12 col-md-12',
            rules: Yup.string().required("Você deve adicionar uma mensagem"),
            rows: 10
          },
          {
            label: 'Anexos',
            name: 'anexos',
            type: FileInput,
            multiple: true,
            col: 'col-12 col-md-12',
          },
        ]
      }
    ],
  }
}

var formSchema = getFormSchema();

// Inicializar o modal quando o componente for montado
onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});

const onSubmit = async (values) => {
  emit('onLoading', true)
  HideModal();
  try {
    let result = await ProcessoService.enviarFollowUp(processoId.value, followUpTipo.value, values);

    closeModal();
    emit('save')

    showToast('Follow Enviado', {
      title: 'Sucesso',
      type: 'success'
    });

  } catch (err) {
    console.log('err', )
    showToast(`Não foi possível enviar o follow<br/>${err.response.data}`, {
      title: 'Erro',
      type: 'error'
    });
    ShowModal()
  } finally {
     emit('onLoading', false)
  }


}


// Função para abrir o modal
const openModal = (ProcessoId, tipo ) => {
  emit('onLoading',true)

  formData = {

  };

  if (bsModalInstance) {
    processoId.value = ProcessoId
    followUpTipo.value = tipo
    formSchema = getFormSchema()

    ProcessoService.getPrepararEnvioFollow(processoId.value, followUpTipo.value)
        .then((data) => {
          Object.assign(formData, data);
          console.log(formData)
          updateFormValues(formData)
          bsModalInstance.show();
        })
        .catch((ex) => {
          if(ex.status == 401) {
            showToast('Você não tem permissão de acesso para executar essa ação.', {
              title: 'Acesso negado',
              type: 'warning'
            });
          } else if(ex.status == 404) {
            showToast(ex.response.data, {
              title: 'Atenção',
              type: 'warning'
            });
          } else {
            showToast(ex.message, {
              title: 'Erro',
              type: 'danger'
            });
          }
        })
        .finally(() => {
          emit('onLoading',false)
    })
  }
};

// Função para fechar o modal
const closeModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};

const HideModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};


const ShowModal = () => {
  if (bsModalInstance) {
    bsModalInstance.show();
  }
};


// Função para lidar com o cancelamento
const handleCancel = () => {
  closeModal();
  emit('cancel');
};

const validateAndSave = async () => {
  if (modalForm.value) {
    await modalForm.value.submitForm();
  }
};


const updateFormValues = (values) => {
  if (modalForm.value) {
    modalForm.value.updateFormValues(values)
  }
};



defineExpose({
  openModal,
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>
