<template>
  <data-grid
      endpoint="/api/cliente/list"
      section="data-grid"
      :filterForm="filterForm"
      :default-filters="defaultFilters"
      :columns="defaultColumnsSettings"
      :initial-sort-by="[{ propertyName: 'razaoSocial', sortOrder: 'asc'}]"
      @on-db-click-row="showDetails"
  >
    <template v-slot:[`item-analistaResponsavel`]="{item}">
      <div>{{ item.analistaResponsavel?.pessoa?.nome ? item.analistaResponsavel?.pessoa?.nome : item.analistaResponsavel?.pessoa?.razaoSocial}}</div>
    </template>

    <template v-slot:[`item-comercialResponsavel`]="{item}">
      <div>{{ item.comercialResponsavel?.pessoa?.nome ? item.comercialResponsavel?.pessoa?.nome : item.comercialResponsavel?.pessoa?.razaoSocial}}</div>
    </template>

    <template v-slot:[`item-acoes`]="{item}">
      <div class="d-flex justify-content-center align-items-center">
        <a
            class="dropdown-item cursor-pointer d-flex justify-content-center align-items-center"
            @click.prevent="showDetails(item)"
        >
          <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
        </a>
      </div>

    </template>

  </data-grid>
</template>

<script setup>
import router from "@/router/index.js";
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import dataGrid from '@/components/dataGrid/dataGrid.vue'

const defaultFilters = {
  statusFilters: [ ],
  // statusFilters: [
  //   {
  //     label: 'Situação',
  //     name: 'situacao',
  //     value: 1,
  //     options: [
  //       { value: null,  name: 'Todos' },
  //       { value: 3,     name: 'Em cadastro' },
  //       { value: 1,     name: 'Ativo' },
  //       { value: 3,     name: 'Inativo' },
  //       { value: 4,     name: 'Bloqueado' },
  //       { value: 5,     name: 'Não vender' },
  //     ]
  //   }
  // ],
  filters: null
}

const defaultColumnsSettings = [
  { text: "CONEXOS ID", value: "conexosId", sortable: false, width: 130},
  { text: "RAZÃO SOCIAL", value: "razaoSocial", sortable: true  },
  { text: "NOME FANTASIA", value: "nome", sortable: true },
  { text: "ANALISTA RESPONSÁVEL", value: "analistaResponsavel", sortable: false, slot: true },
  { text: "COMERCIAL RESPONSÁVEL", value: "comercialResponsavel", sortable: false, slot: true },
  { text: "AÇÕES", value: "acoes", sortable: false, slot: true },
]
const filterForm = {
  sections: [
    {
      section: "Geral",
      fields: [
        {
          label: 'Razão Social',
          name: 'razaoSocial',
          type: TextInput,
          col: 'col-12',
        },
      ]
    },
    {
      section: "Responsáveis",
      fields: [
        {
          label: 'Analista Responsável',
          name: 'analista_responsavel',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Comercial Responsável',
          name: 'comercial_responsavel',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
      ]
    }

  ],
};

function showDetails(item, newWindow) {
  const routeData = router.resolve({
    name: 'ClienteDetalhes',
    params: { id: item.id }
  });

  if (newWindow) {
    window.open(routeData.href, '_blank');
  } else {
    router.push(routeData);
  }

}



</script>
