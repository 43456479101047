import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;


const getTipoContainers = async (pageNumber, pageSize, searchTerm, situacao) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/tipo-tipoContainer`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                situacao
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getTipoContainer = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/tipo-container/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar tipoContainer', error);
        throw error;
    }
};

const updateTipoContainer = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/tipo-container/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o tipoContainer', error);
        throw error;
    }
};

const createTipoContainer = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/tipo-container`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o tipoContainer', error);
        throw error;
    }
};




export default {
    getTipoContainers,
    getTipoContainer,
    createTipoContainer,
    updateTipoContainer,
};