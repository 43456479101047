import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getOptions = async (endpoint,searchTerm, filterParams) => {
    try {
        const params = new URLSearchParams();

        params.append('pageNumber', 1);
        params.append('pageSize', 20);

        if(searchTerm) {
            params.append('searchTerm', searchTerm);
        }

        if (filterParams && typeof filterParams === 'object') {
            for (const [key, value] of Object.entries(filterParams)) {
                params.append(key, value);
            }
        }

        const response = await Httpservice.getAxiosClient().get(`${API_URL}${endpoint}`,{
            params: params
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao obter opcoes:', error);
        throw error;
    }
};









export default {
    getOptions,

};