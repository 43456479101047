<template>
  <BreadCrumb />
  <TipoContainerList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import TipoContainerList from "@/views/TiposContainers/TipoContainerList.vue";

export default defineComponent({
  name: "ProcessoListPage",
  components: {
    BreadCrumb,
    TipoContainerList,
  },
});
</script>