import { createRouter, createWebHistory } from "vue-router";
import HomeView from '../views/HomeView.vue'
import LoginPage from "@/components/LoginPage.vue";
import LogoutPage from "@/views/LogoutPage.vue";
import AboutView from "@/views/AboutView.vue";

import processosRoutes from "./processos.js"
import clientesRoutes from "./clientes.js"
import usuariosRoutes from "./usuarios.js"
import cargosRoutes from "./cargos.js"
import gruposRoutes from "./grupos.js"
import notificacoesRoutes from "./notificacoes.js"
import depotsRoutes from "./depots.js"
import containersRoutes from "@/router/containers.js";
import tiposContainersRoutes from "@/router/tiposContainers.js";
import configuracoesRoutes from "./configuracoes.js"
import recintosAlfandegados from "@/router/recintosAlfandegados.js";
import origensDestinos from "@/router/origensDestinos.js";
import relatoriosRoutes from "./relatorios.js"
import agendaRoutes from "./agenda.js"
import ProcessoList from "@/views/Processos/ProcessoList.vue";

function lazyLoad(view) {
  var tela =  () => import(`@/views/${view}.vue`);

  return tela()

}

const routes = [
  {
    path: "/",
    name: "Home",
    redirect: { name: "Processos" } //
  },
  ...processosRoutes,
  ...clientesRoutes,
  ...relatoriosRoutes,
  ...usuariosRoutes,
  ...cargosRoutes,
  ...gruposRoutes,
  ...depotsRoutes,
  ...agendaRoutes,
  ... notificacoesRoutes,
  ...recintosAlfandegados,
  ...origensDestinos,
  ...containersRoutes,
  ...tiposContainersRoutes,
  ... configuracoesRoutes,
  {
    path: "/about",
    name: "About",
    component: AboutView
  },
  {
    path: "/login",
    name: "Login",
    component: LoginPage,
    meta: { layout: "empty" },
  },
  {
    path: "/logout",
    name: "Logout",
    component: LogoutPage,
    meta: { layout: "empty" },
  },
  {
    path: "/acesso-negado",
    component: lazyLoad("ForbiddenPage"),
    meta: { layout: "empty" },
  },
  {
    path: "/:pathMatch(.*)",
    component: lazyLoad("ErrorPage"),
    meta: { layout: "empty" },
  },
];

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
});

export default router;

// import { createRouter, createWebHistory } from 'vue-router'
// import HomeView from '../views/HomeView.vue'
//
// const router = createRouter({
//   history: createWebHistory(import.meta.env.BASE_URL),
//   routes: [
//     {
//       path: '/',
//       name: 'home',
//       component: HomeView
//     },
//     {
//       path: '/about',
//       name: 'about',
//       // route level code-splitting
//       // this generates a separate chunk (About.[hash].js) for this route
//       // which is lazy-loaded when the route is visited.
//       component: () => import('../views/AboutView.vue')
//     }
//   ]
// })
//
// export default router
