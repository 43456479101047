<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>
  <DynamicForm ref="dynamicForm" :schema="formSchema" cancel-route="Processos" :initial-values="formData" @onSubmit="onSubmit" />

  <!--  <Toast />-->

  <div class="action-point-2  bg-white" :class="{'opened': !stateStoreInstance.open}">
  </div>

</template>



<script setup>
import {reactive, ref, onBeforeMount} from 'vue';
import DynamicForm from "@/components/form/DynamicForm.vue"
import stateStore from "@/utils/store";
import Loading from 'vue-loading-overlay';

import * as Yup from 'yup';
import TextInput from "@/components/form/TextInput.vue";
import router from "@/router/index.js";

import {useRoute} from "vue-router";

import { useToast} from "@/plugins/useToast.js";
import PasswordInput from "@/components/form/PasswordInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import ConfiguracaoService from "@/services/ConfiguracaoService.js";



const { showToast } = useToast();

const stateStoreInstance = stateStore;

const  currentRoute  = useRoute();
const dynamicForm = ref(null);

// Initial Data - Valor inicial do formulário
const formData = reactive({
});

const isLoading = ref(false);

onBeforeMount(async ()  => {
  isLoading.value = true;

  try {
    let config = await ConfiguracaoService.get();
    Object.assign(formData, config);
    updateFormValues(formData)
  } catch (error) {
    if (error.status == 403) {
      router.push('/acesso-negado')
    }
  } finally {
    isLoading.value = false;
  }

})

const onSubmit = async (values) =>  {
  isLoading.value = true

  try {
    let result = await ConfiguracaoService.save(values)

    showToast('Configuração salva.', {
      title: 'Sucesso',
      type: 'success'
    });

  } catch (err) {
    console.log('err', err)
    showToast(err.message, {
      title: 'Erro',
      type: 'error'
    });
  } finally {
    isLoading.value = false
  }
}


const updateFormValues = (values) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFormValues(values)
  }
};


const formSchema = {
  sections: [
    {
      section: 'Envio SMTP',
      col: 'col-12 col-md-6',
      fields: [
        {
          label: 'Host',
          name: 'emailHost',
          type: TextInput,
          col: 'col-12 col-md-8',
          rules: Yup.string().required("Host é obrigatório")
        },
        {
          label: 'Porta',
          name: 'emailPort',
          type: TextInput,
          col: 'col-12 col-md-2',
          rules: Yup.string().required('Porta é obrigatória')
        },
        {
          label: 'StartTls',
          name: 'startTls',
          type: SelectInput,
          col: 'col-12 col-md-2',
          options: [ {id: true, nome: 'Sim',}, {id: false, nome: 'Não',}]
        },
        {
          label: 'Usuário',
          name: 'emailUsuario',
          type: TextInput,
          col: 'col-12 col-md-6',
          rules: Yup.string().required("Usuário é obrigatório")
        },
        {
          label: 'Senha',
          name: 'emailSenha',
          type: PasswordInput,
          col: 'col-12 col-md-6',
          rules: Yup.string().required("Obrigatório").min(6,'Deve conter pelo menos 6 caracteres'),
          formatValue: (value) => {
            return value.nome
          },
        },
        // {
        //   label: 'Foto',
        //   name: 'imagem',
        //   type: FileInput,
        //   col: 'col-12 col-md-4',
        // }
      ]
    }
  ],
};

const onLoadingChange = (loading) => {
  isLoading.value = loading
}

const onConfiguracoesEmailChanged = (values) => {
  updateFormValue('emailUsuario', values.emailUsuario)
}

const updateFormValue = (name, value) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFieldValue(name, value);
  }
};

</script>

<style scoped>
 .action-point-2 {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 9999;
   padding: 10px;
   transition: width 0.8s ease, transform 0.8s ease; /* Animação suave */
   transform: translateX(0px); /* Move o elemento para a esquerda */
 }

 .action-point-2.opened {
   left: 280px;
   transform: translateX(0px); /* Move o elemento para a esquerda */

 }
</style>