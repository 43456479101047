<template>
  <BreadCrumb />
  <UsuarioList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import UsuarioList from "@/views/Usuarios/UsuarioList.vue";

export default defineComponent({
  name: "ProcessoListPage",
  components: {
    BreadCrumb,
    UsuarioList,
  },
});
</script>