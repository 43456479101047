<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>
  <DynamicForm ref="dynamicForm" :schema="formSchema" cancel-route="Clientes" :initial-values="formData"
               @onSubmit="onSubmit"/>

  <!--  <Toast />-->
  <div class="action-point-2  bg-white" :class="{'opened': !stateStoreInstance.open}">
  </div>

</template>


<script setup>
// RN 001 - Retornar a obrigatoriedade após data definida.
import {reactive, ref, onBeforeMount, inject, computed} from 'vue';
import DynamicForm from "@/components/form/DynamicForm.vue"
import stateStore from "@/utils/store";
import Loading from 'vue-loading-overlay';

import * as Yup from 'yup';
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import router from "@/router/index.js";
import ClienteService from "@/services/ClienteService.js";
import ProcessoService from "@/services/ProcessoService.js";

import {TipoOperacaoService} from "@/services/TipoOperacaoService.js";
import SelectInput from "@/components/form/SelectInput.vue";
import {CambioService} from "@/services/CambioService.js";
import {useRoute} from "vue-router";
import {TipoCargaService} from "@/services/TipoCargaService.js";
import {ProcessoSituacaoService} from "@/services/ProcessoSituacaoService.js";
import TextAreaInput from "@/components/form/TextAreaInput.vue";
import RepeaterInput from "@/components/form/RepeaterInput.vue";
import {useToast} from "@/plugins/useToast.js";
import CheckInput from "@/components/form/CheckInput.vue";
import DateYearMonthInput from "@/components/form/DateYearMonthInput.vue";
import EnderecoService from "@/services/EnderecoService.js";
import NumberInput from "@/components/form/NumberInput.vue";
import Separator from "@/components/form/Separator.vue";
import SeparatorCheckInput from "@/components/form/SeparatorCheckInput.vue";
import HiddenInput from "@/components/form/HiddenInput.vue";
import {getFormatedFormData} from "@/utils/formHelper.js";
import ButtonInput from "@/components/form/ButtonInput.vue";

const {showToast} = useToast();

const stateStoreInstance = stateStore;

let pessoaEmptyObject = {
  nome: null,
  apelido: null,
  email: null,
  anoNaoInformado: false,
  nascimento: null,
  cargo: null,
  telefoneComercial: null,
  telefoneComercialRamal: null,
  celular: null,
  configuracoes: null
}

let enderecoEmptyObject = {
  tipo: null,
  cep: null,
  logradouro: null,
  numero: 0,
  complemento: null,
  bairro: null,
  estado: null
}


let regraCobrancaEmptyObject = {
  tipo: null,
  icmsDestacado: null,
  icmsNegociado: null,
  finalidade: null,
  camexEx: null,
  conv5291: null,
}

let contaEmptyObject = {
  instituicao: null,
  contaCorrente: null,
  agencia: null
}

const currentRoute = useRoute();
const dynamicForm = ref(null);

// Initial Data - Valor inicial do formulário
const formData = reactive({
  contasBancarias: [
    // contaEmptyObject
  ],
  contatos: [
    // pessoaEmptyObject
  ],
  regrasCobrancas: [
    regraCobrancaEmptyObject
  ],
  servicoTrading: true
});

const isLoading = ref(false);

onBeforeMount(async () => {
  if (currentRoute.params.hasOwnProperty('id')) {
    isLoading.value = true;
    let cliente = await ClienteService.getCliente(currentRoute.params.id);

    Object.assign(formData, cliente);

    updateFormValues(getFormatedFormData(formSchema, formData))
    isLoading.value = false;
  } else {

  }
})


const onSubmit = async (values) => {
  isLoading.value = true

  if (currentRoute.params.hasOwnProperty('id')) {
    try {
      let result = await ClienteService.updateCliente(currentRoute.params.id, values)

      showToast('Cliente salvo.', {
        title: 'Sucesso',
        type: 'success'
      });

      router.push({name: 'Clientes'});
    } catch (ex) {
      isLoading.value = false;
    }
  } else {
    alert("Não é autorizado criar um novo cliente")
  }

  isLoading.value = false;
}


const updateFormValues = (values) => {

  if (dynamicForm.value) {
    dynamicForm.value.updateFormValues(values)
  }



};

const updateFieldValue = (fieldName, value) => {

  if (dynamicForm.value) {
    dynamicForm.value.updateFieldValue(fieldName, value)
  }
};


const showSeguroInfo = computed(() => {
  if (dynamicForm.value) {
    let possuiSeguro = dynamicForm.value.getFieldValue('possuiSeguroCredito')

    return (possuiSeguro === true)
  }
});








const showVinculoSalarioGeral = (checkField1Name, checkField2Name) => {
  return computed(() => {
    if (dynamicForm.value) {
      let servico = dynamicForm.value.getFieldValue(checkField1Name)

      if (servico === true || servico === 'true') {

        let servicoVinculoSalarioMinimo = dynamicForm.value.getFieldValue(checkField2Name)

        return (servicoVinculoSalarioMinimo === true || servicoVinculoSalarioMinimo === 'true')
      }
      return false
    }
  })
};


const showOutrosGeral = (checkField1Name, checkField2Name) => {
  return computed(() => {
    if (dynamicForm.value) {
      let servico = dynamicForm.value.getFieldValue(checkField1Name)

      if (servico === true || servico === 'true') {

        let outros = dynamicForm.value.getFieldValue(checkField2Name)

        return (outros === 999 || outros === '999')
      }
      return false
    }
  })
};



const showInfoGeral = (checkFieldName) => {
  return computed(() => {
    if (dynamicForm.value) {
      let servico = dynamicForm.value.getFieldValue(checkFieldName)

      return (servico === true || servico === 'true')
    }
  })
};

const showSemVinculoSalarioGeral = (checkField1Name, checkField2Name) => {
  return computed(() => {
    if (dynamicForm.value) {
      let servico = dynamicForm.value.getFieldValue(checkField1Name)

      if (servico === true || servico === 'true') {

        let servicoVinculoSalarioMinimo = dynamicForm.value.getFieldValue(checkField2Name)

        return (servicoVinculoSalarioMinimo === false || servicoVinculoSalarioMinimo === 'false')
      }
      return false
    }
  })
};




const showFinanceiamentoInfo = computed(() => {
  if (dynamicForm.value) {
    let possuiFinanciamento = dynamicForm.value.getFieldValue('possuiFinanciamento')

    return (possuiFinanciamento === true)
  }
});




const formSchema = {
  sections: [
    {
      section: 'Empresa',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Ref. Conexos',
          name: 'conexosId',
          type: TextInput,
          col: 'col-12 col-md-2',
          disabled: true,
        },
        {
          label: 'Razão social',
          name: 'razaoSocial',
          type: TextInput,
          col: 'col-12 col-md-5',
          disabled: true,
        },
        {
          label: 'Nome Fantasia',
          name: 'nome',
          type: TextInput,
          col: 'col-12 col-md-5',
        },
        {
          label: 'CNPJ',
          name: 'cnpj',
          mask: '##.###.###/####-##',
          type: TextInput,
          col: 'col-12 col-md-2',
          disabled: true,
        },
        {
          label: 'I.E.',
          name: 'inscricaoEstadual',
          type: TextInput,
          col: 'col-12 col-md-2',
        },
        {
          label: 'Regime tributário',
          name: 'regimeTributario',
          type: SelectInput,
          col: 'col-12 col-md-2',
          rules: Yup.string().nullable(),//RN 001 .required("Campo obrigatório"),
          options: ClienteService.getRegimesTributario(true),

        },
        {
          label: 'Telefone',
          name: 'telefone',
          mask: '+## (##) #####-####',
          type: TextInput,
          col: 'col-12 col-md-2 col-md-15-percent',
        },
        {
          label: 'Fax',
          name: 'fax',
          mask: '+## (##) #####-####',
          type: TextInput,
          col: 'col-12 col-md-2 col-md-15-percent',
        },
        {
          label: 'Classificação',
          name: 'classificacaoCliente',
          type: RelationInput,
          endpoint: 'classificacao-cliente',
          col: 'col-12 col-md-4',
          display: (value) => {
            return value.nome
          }
        },
      ]
    },


    // Endereços
    {
      //section: 'Endereços',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Endereços',
          name: 'enderecos',
          type: RepeaterInput,
          required: true,
          rules: Yup.array()
              .of(
                  Yup.object().shape({
                    tipo: Yup.number().required("Obrigatório"),
                    cep: Yup.string()
                        .required("Obrigatório"),
                    logradouro: Yup.string()
                        .required("Obrigatório"),
                    numero: Yup.string()
                        .required("Obrigatório"),
                    bairro: Yup.string()
                        .required("Obrigatório"),
                    estado: Yup.object()
                        .required("Obrigatório"),


                  })
              )
              .strict(),
          fields: [
            {
              label: 'Tipo',
              name: 'tipo',
              type: SelectInput,
              col: 'col-12 col-md-2',
              options: ClienteService.getTiposEndereco(true),
            },
            {
              label: 'CEP',
              name: 'cep',
              type: TextInput,
              col: 'col-12 col-md-1',
              mask: '#####-###',
              onChanged: (value) => {
                if (value && value.length == 9) {
                  let cep = value.replace('-', '')

                  isLoading.value = true
                  EnderecoService.getCepInfo(cep).then((rs) => {
                    updateFieldValue('logradouro', rs.logradouro)
                    updateFieldValue('complemento', rs.complemento)
                    updateFieldValue('bairro', rs.bairro)
                    updateFieldValue('estado', rs.uf)

                  }).finally(() => {
                    isLoading.value = false
                  })
                }

              }
            },
            {
              label: 'Logradouro',
              name: 'logradouro',
              type: TextInput,
              col: 'col-12 col-md-4',
            },
            {
              label: 'Número',
              name: 'numero',
              type: TextInput,
              col: 'col-12 col-md-1',
            },
            {
              label: 'Complemento',
              name: 'complemento',
              type: TextInput,
              col: 'col-12 col-md-4',
            },
            {
              label: 'Bairro',
              name: 'bairro',
              type: TextInput,
              col: 'col-12 col-md-4',
            },
            {
              label: 'Estado',
              name: 'estado',
              type: RelationInput,
              endpoint: 'unidade-federativa',
              col: 'col-12 col-md-4',
              display: (value) => {
                return value.nome
              }
            },
          ],
          emptyObject: enderecoEmptyObject,
          col: 'col-12 col-md-12',
        },
      ]
    },



    {
      section: 'Informações Adicionais',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Debito de Tributos Federais',
          name: 'debitoTributosFederais',
          type: SelectInput,
          col: 'col-12 col-md-6',
          rules: Yup.string().nullable(),//RN 001 .required("Campo obrigatório"),
          options: ClienteService.getDebitoTributosFederais(true),
        },
        {
          label: 'Finalidade da mercadoria',
          name: 'finalidadeMercadoria',
          type: SelectInput,
          col: 'col-12 col-md-6',
          rules: Yup.string().nullable(),//RN 001 .required("Campo obrigatório"),
          options: ClienteService.getFinalidadesMercadorias(true),
        },
        {
          label: 'Pagamento de frete int. e taxas',
          name: 'pagamentoFreteInternacionalTaxas',
          type: SelectInput,
          col: 'col-12 col-md-6',
          rules: Yup.string().nullable(),//RN 001 .required("Campo obrigatório"),
          options: ClienteService.getPagamentoFreteInternacionalTaxas(true),
        },

      ]
    },
    // Dados Bancários
    {
      //section: 'Dados Bancários',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Dados bancários',
          name: 'contasBancarias',
          type: RepeaterInput,
          required: true,
          rules: Yup.array()
              .of(
                  Yup.object().shape({
                    instituicao: Yup.string()
                        .required("Campo obrigatório"),
                    contaCorrente: Yup.string()
                        .required("Campo obrigatório"),
                    agencia: Yup.string()
                        .required("Campo obrigatório"),

                  })
              )
              .strict(),
          fields: [
            {
              name: 'id',
              type: HiddenInput,
            },
            {
              label: 'Instituição',
              name: 'instituicao',
              type: TextInput,
              col: 'col-12 col-md-6',
            },
            {
              label: 'Conta Corrente',
              name: 'contaCorrente',
              type: TextInput,
              col: 'col-12 col-md-2',
            },
            {
              label: 'AG',
              name: 'agencia',
              type: TextInput,
              col: 'col-12 col-md-4',
            },


          ],
          emptyObject: contaEmptyObject,
          col: 'col-12 col-md-12',
          //rules: Yup.string().required(),
        },
      ]
    },

    {
      section: 'Seguro de crédido e financiamento',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Possui seguro de crédito',
          name: 'possuiSeguroCredito',
          type: SelectInput,
          col: 'col-12 col-md-2',
          options: [{id: null, nome: 'Selecione'}, {id: true, nome: 'Sim'}, {id: false, nome: 'Não'}],
          onChanged: (value) => {
            if (value !== 'true' || value !== true) {
              updateFieldValue('valorSeguroCredito', null)
              updateFieldValue('seguradora', null)
            }
          },
          rules: Yup.string().nullable(),//RN 001 .required("Campo obrigatório"),
        },
        {
          label: 'Valor',
          name: 'valorSeguroCredito',
          type: NumberInput,
          col: 'col-12 col-md-3',
          visible: showSeguroInfo,
          rules: Yup.string().when('possuiSeguroCredito', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatória'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Seguradora',
          name: 'seguradora',
          type: TextInput,
          visible: showSeguroInfo,
          col: 'col-12 col-md-7',
          rules: Yup.string().when('possuiSeguroCredito', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatória'),
            otherwise: () => Yup.string().nullable()
          })
        },

        {
          type: Separator,
          col: 'col-12',
        },
        {
          label: 'Possui financiamento',
          name: 'possuiFinanciamento',
          type: SelectInput,
          col: 'col-12 col-md-2',
          rules: Yup.string().nullable(),//RN 001 .required.required("Campo obrigatório"),
          options: [{id: null, nome: 'Selecione'}, {id: true, nome: 'Sim'}, {id: false, nome: 'Não'}],
          onChanged: (value) => {
            if (value !== 'true' || value !== true) {
              updateFieldValue('valorFinanciamento', null)
              updateFieldValue('bancoFinanciamento', null)
            }
          },
        },
        {
          label: 'Valor',
          name: 'valorFinanciamento',
          type: NumberInput,
          col: 'col-12 col-md-3',
          visible: showFinanceiamentoInfo,
          rules: Yup.string().when('possuiFinanciamento', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatória'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Banco',
          name: 'bancoFinanciamento',
          type: TextInput,
          col: 'col-12 col-md-7',
          visible: showFinanceiamentoInfo,
          rules: Yup.string().when('possuiFinanciamento', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatória'),
            otherwise: () => Yup.string().nullable()
          })
        },

      ]
    },

    // Serviços e negociação
    {
      section: 'Serviços e negociação',
      col: 'col-12 col-md-12',
      fields: [
        //   Trading
        {
          type: SeparatorCheckInput,
          label: 'Trading',
          name: 'servicoTrading',
          col: 'col-12',
        },
        {
          label: 'Regras de cobrança',
          col: 'col-12 col-md-12',
          name: 'regrasCobrancas',
          type: RepeaterInput,
          required: false,
          visible: showInfoGeral('servicoTrading'),
          rules: Yup
              .array()
              .of(
                  Yup.object().shape({
                    tipo: Yup.number()
                        .when('servicoTrading', {
                          is: (value) => {
                            var servico = dynamicForm.value.getFieldValue('servicoTrading')
                            return  servico !== null && servico !== undefined  && (servico === true || servico === 'true')
                          },
                          then: () => Yup.number().required('Obrigatório'),
                        otherwise: () => Yup.number().nullable()
                      }),
                    icmsDestacado: Yup.string()
                        .when('servicoTrading', {
                          is: (value) => {
                            var servico = dynamicForm.value.getFieldValue('servicoTrading')
                            return  servico !== null && servico !== undefined  && (servico === true || servico === 'true')
                          },
                          then: () => Yup.string().required('Obrigatório'),
                          otherwise: () => Yup.string().nullable()
                        }),
                    icmsNegociado: Yup.string()
                        .when('servicoTrading', {
                          is: (value) => {
                            var servico = dynamicForm.value.getFieldValue('servicoTrading')
                            return  servico !== null && servico !== undefined  && (servico === true || servico === 'true')
                          },
                          then: () => Yup.string().required('Obrigatório'),
                          otherwise: () => Yup.string().nullable()
                        }),
                    finalidade: Yup.number()
                        .when('servicoTrading', {
                          is: (value) => {
                            var servico = dynamicForm.value.getFieldValue('servicoTrading')
                            return  servico !== null && servico !== undefined  && (servico === true || servico === 'true')
                          },
                          then: () => Yup.number().required('Obrigatório'),
                          otherwise: () => Yup.number().nullable()
                        }),
                    camexEx: Yup.number()
                        .when('servicoTrading', {
                          is: (value) => {
                            var servico = dynamicForm.value.getFieldValue('servicoTrading')
                            return  servico !== null && servico !== undefined  && (servico === true || servico === 'true')
                          },
                          then: () => Yup.bool().required('Obrigatório'),
                          otherwise: () => Yup.bool().nullable()
                        }),
                    conv5291: Yup.number()
                        .when('servicoTrading', {
                          is: (value) => {
                            var servico = dynamicForm.value.getFieldValue('servicoTrading')
                            return  servico !== null && servico !== undefined  && (servico === true || servico === 'true')
                          },
                          then: () => Yup.bool().required('Obrigatório'),
                          otherwise: () => Yup.bool().nullable()
                        }),
                  })
              )
              .strict(),
          fields: [
            {
              label: 'Tipo',
              name: 'tipo',
              type: SelectInput,
              col: 'col-12 col-md-2',
              options: ClienteService.getServicoTipo(true),
            },
            {
              label: 'ICMS Destacado',
              name: 'icmsDestacado',
              type: NumberInput,
              col: 'col-12 col-md-1',
              maxLength: 5,
            },
            {
              label: 'ICMS Negociado',
              name: 'icmsNegociado',
              type: NumberInput,
              maxLength: 5,
              col: 'col-12 col-md-1'
            },
            {
              label: 'Finalidade',
              name: 'finalidade',
              type: SelectInput,
              col: 'col-12 col-md-1',
              rules: Yup.string().required("Campo obrigatório"),
              options: ClienteService.getFinalidades(true),
            },
            {
              label: 'CAMEX/EX',
              name: 'camexEx',
              type: SelectInput,
              col: 'col-12 col-md-1',
              options: [{nome: '', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
            },
            {
              label: 'CONV 52/91',
              name: 'conv5291',
              type: SelectInput,
              col: 'col-12 col-md-1',
              options: [{nome: '', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
            },
            {
              label: 'Observação',
              name: 'observacao',
              type: TextInput,
              col: 'col-12 col-md-5',
              options: [{nome: '', id: null}, {nome: 'Sim', id: 1}, {nome: 'Não', id: 0}],
            },
          ],
          emptyObject: regraCobrancaEmptyObject,
          //rules: Yup.string().required(),
        },

        // Despesa Administrativa
        {
          type: SeparatorCheckInput,
          label: 'Despesa Administrativa',
          name: 'servicoDespesaAdministrativa',
          col: 'col-12',
        },
        {
          label: 'Tipo',
          name: 'servicoDespesaAdministrativaTipo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoDespesaAdministrativa'),
          rules: Yup.string().when('servicoDespesaAdministrativa', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: ClienteService.getDespesaAdministrativaTipo(true),
        },
        {
          label: 'Vínculo salário mínimo',
          name: 'servicoDespesaAdministrativaVinculoSalarioMinimo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoDespesaAdministrativa'),
          rules: Yup.string().when('servicoDespesaAdministrativa', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: [{nome: 'Selecione', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
        },
        {
          label: 'Salário(s) Mínimo(s)',
          name: 'valorDespesaAdministrativaSalarios',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showVinculoSalarioGeral('servicoDespesaAdministrativa', 'servicoDespesaAdministrativaVinculoSalarioMinimo'),
          options: ClienteService.getFaixasSalariosMinimos(true),
          rules: Yup.string().when('servicoDespesaAdministrativaVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoDespesaAdministrativa')

              return  servicoSda !== null && servicoSda !== undefined  && (value === true || value === 'true')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Valor (R$)',
          name: 'valorDespesaAdministrativa',
          type: NumberInput,
          col: 'col-12 col-md-2',
          visible: (showSemVinculoSalarioGeral('servicoDespesaAdministrativa','servicoDespesaAdministrativaVinculoSalarioMinimo')),
          rules: Yup.string().when('servicoDespesaAdministrativaVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoDespesaAdministrativa')

              return  servicoSda !== null && servicoSda !== undefined  && (value === false || value === 'false')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Observação',
          name: 'servicoDespesaAdministrativaObservacao',
          type: TextInput,
          visible: showInfoGeral('servicoDespesaAdministrativa'),
          col: 'col-12 col-md-4'
        },  
          
          
        // Despacho Aduaneiro
        {
          type: SeparatorCheckInput,
          label: 'Despachante Aduaneiro',
          name: 'servicoDespachoAduaneiro',
          col: 'col-12',
        },
        {
          label: 'Vínculo salário mínimo',
          name: 'servicoDespachoAduaneiroVinculoSalarioMinimo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoDespachoAduaneiro'),
          rules: Yup.string().when('servicoDespachoAduaneiro', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: [{nome: 'Selecione', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
        },
        {
          label: 'Salário(s) Mínimo(s)',
          name: 'valorDespachoAduaneiroSalarios',
          type: SelectInput,
          col: 'col-12 col-md-3',
          visible: showVinculoSalarioGeral('servicoDespachoAduaneiro', 'servicoDespachoAduaneiroVinculoSalarioMinimo'),
          options: ClienteService.getFaixasSalariosMinimos(true),
          rules: Yup.string().when('servicoDespachoAduaneiroVinculoSalarioMinimo', {
            is: (value) => {
              var servicoDespachoAduaneiro = dynamicForm.value.getFieldValue('servicoDespachoAduaneiro')

              return  servicoDespachoAduaneiro !== null && servicoDespachoAduaneiro !== undefined  && (value === true || value === 'true')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Valor (R$)',
          name: 'valorDespachoAduaneiro',
          type: NumberInput,
          col: 'col-12 col-md-2',
          visible: (showSemVinculoSalarioGeral('servicoDespachoAduaneiro','servicoDespachoAduaneiroVinculoSalarioMinimo')),
          rules: Yup.string().when('servicoDespachoAduaneiroVinculoSalarioMinimo', {
            is: (value) => {
              var servicoDespachoAduaneiro = dynamicForm.value.getFieldValue('servicoDespachoAduaneiro')

              return  servicoDespachoAduaneiro !== null && servicoDespachoAduaneiro !== undefined  && (value === false || value === 'false')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Observação',
          name: 'servicoDespachoAduaneiroObservacao',
          type: TextInput,
          visible: showInfoGeral('servicoDespachoAduaneiro'),
          col: 'col-12 col-md-5'
        },


        // S.D.A
        {
          type: SeparatorCheckInput,
          label: 'S.D.A',
          name: 'servicoSda',
          col: 'col-12',
        },
        {
          label: 'Vínculo salário mínimo',
          name: 'servicoSdaVinculoSalarioMinimo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoSda'),
          rules: Yup.string().when('servicoSda', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: [{nome: 'Selecione', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
        },
        {
          label: 'Salário(s) Mínimo(s)',
          name: 'valorSdaSalarios',
          type: SelectInput,
          col: 'col-12 col-md-3',
          visible: showVinculoSalarioGeral('servicoSda', 'servicoSdaVinculoSalarioMinimo'),
          options: ClienteService.getFaixasSalariosMinimos(true),
          rules: Yup.string().when('servicoSdaVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoSda')

              return  servicoSda !== null && servicoSda !== undefined  && (value === true || value === 'true')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string()
          })
        },
        {
          label: 'Valor (R$)',
          name: 'valorSda',
          type: NumberInput,
          col: 'col-12 col-md-2',
          visible: (showSemVinculoSalarioGeral('servicoSda','servicoSdaVinculoSalarioMinimo')),
          rules: Yup.string().when('servicoSdaVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoSda')

              return  servicoSda !== null && servicoSda !== undefined  && (value === false || value === 'false')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Observação',
          name: 'servicoSdaObservacao',
          type: TextInput,
          visible: showInfoGeral('servicoSda'),
          col: 'col-12 col-md-5'
        },

        // Câmbio
        {
          type: SeparatorCheckInput,
          label: 'Câmbio',
          name: 'servicoCambio',
          col: 'col-12',
        },
        {
          label: 'Valor (%)',
          name: 'valorCambio',
          type: NumberInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoCambio'),
          rules: Yup.string().when('servicoCambio', {
            is: (value) => {
              var servico = dynamicForm.value.getFieldValue('servicoCambio')

              return  servico !== null && servico !== undefined  && (value === true || value === 'true')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Observação',
          name: 'servicoCambioObservacao',
          type: TextInput,
          visible: showInfoGeral('servicoCambio'),
          col: 'col-12 col-md-5'
        },

        //   Admissão temporária
        {
          type: SeparatorCheckInput,
          label: 'Admissão temporária',
          name: 'servicoAdmissaoTemporaria',
          col: 'col-12',
        },

        //   ExTarifario
        {
          type: SeparatorCheckInput,
          label: 'Ex tarifário',
          name: 'servicoExTarifario',
          col: 'col-12',
        },

        //   Habilitação de Radar
        {
          type: SeparatorCheckInput,
          label: 'Habilitação de Radar',
          name: 'servicoHabilitacaoRadar',
          col: 'col-12',
        },
        {
          label: 'Modalidade',
          name: 'servicoHabilitacaoRadarModalidade',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoHabilitacaoRadar'),
          rules: Yup.string().when('servicoHabilitacaoRadar', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: ClienteService.getModalidadesRadar(true),
        },
        {
          label: 'Vínculo salário mínimo',
          name: 'servicoHabilitacaoRadarVinculoSalarioMinimo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoHabilitacaoRadar'),
          rules: Yup.string().when('servicoHabilitacaoRadar', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: [{nome: 'Selecione', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
        },
        {
          label: 'Salário(s) Mínimo(s)',
          name: 'valorHabilitacaoRadarSalarios',
          type: SelectInput,
          col: 'col-12 col-md-3',
          visible: showVinculoSalarioGeral('servicoHabilitacaoRadar', 'servicoHabilitacaoRadarVinculoSalarioMinimo'),
          options: ClienteService.getFaixasSalariosMinimos(true),
          rules: Yup.string().when('servicoHabilitacaoRadarVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoHabilitacaoRadar')

              return  servicoSda !== null && servicoSda !== undefined  && (value === true || value === 'true')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Valor (R$)',
          name: 'valorHabilitacaoRadar',
          type: NumberInput,
          col: 'col-12 col-md-2',
          visible: (showSemVinculoSalarioGeral('servicoHabilitacaoRadar','servicoHabilitacaoRadarVinculoSalarioMinimo')),
          rules: Yup.string().when('servicoHabilitacaoRadarVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoHabilitacaoRadar')

              return  servicoSda !== null && servicoSda !== undefined  && (value === false || value === 'false')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Observação',
          name: 'servicoHabilitacaoRadarObservacao',
          type: TextInput,
          visible: showInfoGeral('servicoHabilitacaoRadar'),
          col: 'col-12 col-md-5'
        },


        //   Revisao de Radar
        {
          type: SeparatorCheckInput,
          label: 'Revisão de Radar',
          name: 'servicoRevisaoRadar',
          col: 'col-12',
        },
        {
          label: 'Modalidade',
          name: 'servicoRevisaoRadarModalidade',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoRevisaoRadar'),
          rules: Yup.string().when('servicoRevisaoRadar', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: ClienteService.getModalidadesRadar(true),
        },
        {
          label: 'Vínculo salário mínimo',
          name: 'servicoRevisaoRadarVinculoSalarioMinimo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoRevisaoRadar'),
          rules: Yup.string().when('servicoRevisaoRadar', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: [{nome: 'Selecione', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
        },
        {
          label: 'Salário(s) Mínimo(s)',
          name: 'valorRevisaoRadarSalarios',
          type: SelectInput,
          col: 'col-12 col-md-3',
          visible: showVinculoSalarioGeral('servicoRevisaoRadar', 'servicoRevisaoRadarVinculoSalarioMinimo'),
          options: ClienteService.getFaixasSalariosMinimos(true),
          rules: Yup.string().when('servicoRevisaoRadarVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoRevisaoRadar')

              return  servicoSda !== null && servicoSda !== undefined  && (value === true || value === 'true')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Valor (R$)',
          name: 'valorRevisaoRadar',
          type: NumberInput,
          col: 'col-12 col-md-2',
          visible: (showSemVinculoSalarioGeral('servicoRevisaoRadar','servicoRevisaoRadarVinculoSalarioMinimo')),
          rules: Yup.string().when('servicoRevisaoRadarVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoRevisaoRadar')

              return  servicoSda !== null && servicoSda !== undefined  && (value === false || value === 'false')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Observação',
          name: 'servicoRevisaoRadarObservacao',
          type: TextInput,
          visible: showInfoGeral('servicoRevisaoRadar'),
          col: 'col-12 col-md-5'
        },

        // Procurement
        {
          type: SeparatorCheckInput,
          label: 'Procurement',
          name: 'servicoProcurement',
          col: 'col-12',
        },
        {
          label: 'Vínculo salário mínimo',
          name: 'servicoProcurementVinculoSalarioMinimo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoProcurement'),
          rules: Yup.string().when('servicoProcurement', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: [{nome: 'Selecione', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
        },
        {
          label: 'Salário(s) Mínimo(s)',
          name: 'valorProcurementSalarios',
          type: SelectInput,
          col: 'col-12 col-md-3',
          visible: showVinculoSalarioGeral('servicoProcurement', 'servicoProcurementVinculoSalarioMinimo'),
          options: ClienteService.getFaixasSalariosMinimos(true),
          rules: Yup.string().when('servicoProcurementVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoProcurement')

              return  servicoSda !== null && servicoSda !== undefined  && (value === true || value === 'true')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Valor (R$)',
          name: 'valorProcurement',
          type: NumberInput,
          col: 'col-12 col-md-2',
          visible: (showSemVinculoSalarioGeral('servicoProcurement','servicoProcurementVinculoSalarioMinimo')),
          rules: Yup.string().when('servicoProcurementVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoProcurement')

              return  servicoSda !== null && servicoSda !== undefined  && (value === false || value === 'false')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Observação',
          name: 'servicoProcurementObservacao',
          type: TextInput,
          visible: showInfoGeral('servicoProcurement'),
          col: 'col-12 col-md-5'
        },


        // Assesoria de compra
        {
          type: SeparatorCheckInput,
          label: 'Assessoria de Compra',
          name: 'servicoAssessoriaCompra',
          col: 'col-12',
        },
        {
          label: 'Mercadoria',
          name: 'servicoAssessoriaCompraCommodity',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoAssessoriaCompra'),
          rules: Yup.string().when('servicoAssessoriaCompra', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: ClienteService.getAssessoriaCompraCommodity(true),
        },
        {
          label: 'Mercadoria',
          name: 'servicoAssessoriaCompraCommodityOutros',
          type: TextInput,
          col: 'col-12 col-md-2',
          visible: showOutrosGeral('servicoAssessoriaCompra', 'servicoAssessoriaCompraCommodity'),
          rules: Yup.string().when('servicoAssessoriaCompraCommodity', {
            is: (value) => value === 999 || value === '999',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
        },
        {
          label: 'Vínculo salário mínimo',
          name: 'servicoAssessoriaCompraVinculoSalarioMinimo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showInfoGeral('servicoAssessoriaCompra'),
          rules: Yup.string().when('servicoAssessoriaCompra', {
            is: (value) => value === true || value === 'true',
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          }),
          options: [{nome: 'Selecione', id: null}, {nome: 'Sim', id: true}, {nome: 'Não', id: false}],
        },
        {
          label: 'Salário(s) Mínimo(s)',
          name: 'valorAssessoriaCompraSalarios',
          type: SelectInput,
          col: 'col-12 col-md-2',
          visible: showVinculoSalarioGeral('servicoAssessoriaCompra', 'servicoAssessoriaCompraVinculoSalarioMinimo'),
          options: ClienteService.getFaixasSalariosMinimos(true),
          rules: Yup.string().when('servicoAssessoriaCompraVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoAssessoriaCompra')

              return  servicoSda !== null && servicoSda !== undefined  && (value === true || value === 'true')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Valor (R$)',
          name: 'valorAssessoriaCompra',
          type: NumberInput,
          col: 'col-12 col-md-2',
          visible: (showSemVinculoSalarioGeral('servicoAssessoriaCompra','servicoAssessoriaCompraVinculoSalarioMinimo')),
          rules: Yup.string().when('servicoAssessoriaCompraVinculoSalarioMinimo', {
            is: (value) => {
              var servicoSda = dynamicForm.value.getFieldValue('servicoAssessoriaCompra')

              return  servicoSda !== null && servicoSda !== undefined  && (value === false || value === 'false')
            },
            then: () => Yup.string().required('Campo obrigatório'),
            otherwise: () => Yup.string().nullable()
          })
        },
        {
          label: 'Observação',
          name: 'servicoAssessoriaCompraObservacao',
          type: TextInput,
          visible: showInfoGeral('servicoAssessoriaCompra'),
          col: 'col-12 col-md-4'
        },

      ]
    },

    // Responsáveis
    {
      // section: 'Responsáveis',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Controle de Responsáveis',
          name: 'responsaveis',
          type: RepeaterInput,
          required: false,
          rules: Yup
              .array()
              .of(
                  Yup.object().shape({
                    cargo: Yup.object().required("Cargo é obrigatório"),
                    pessoa: Yup.object().required("Responsável é obrigatório"),
                  })
              )
              .strict(),
          fields: [
            {
              label: 'Cargo',
              name: 'cargo',
              type: RelationInput,
              col: 'col-12 col-md-4',
              endpoint: 'cargo',
              display: (value) => {
                return value.nome
              },
              rules: Yup.object().required(),
            },
            {
              label: 'Responsável',
              name: 'pessoa',
              type: RelationInput,
              col: 'col-12 col-md-7',
              endpoint: 'pessoa/colaborador',
              display: (value) => {
                return value.nome ?? value.razaoSocial
              },
            },
          ],
          emptyObject: pessoaEmptyObject,
          col: 'col-12 col-md-12',
          //rules: Yup.string().required(),
        },
      ]
    },
    // Contatos
    {
      //section: 'Contatos',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Contatos',
          name: 'contatos',
          type: RepeaterInput,
          required: true,
          rules: Yup.array()
              .of(
                  Yup.object().shape({
                    nome: Yup.string()
                        .required("Nome é obrigatório"),
                    email: Yup.string()
                        .when('configuracoes', {
                          is: (value) => {
                            return (value && value.includes('1'))
                          },
                          then: () => {
                            return Yup.string()
                                .required('E-mail é obrigatório')
                                .nullable()
                                .email("Não é um e-mail válido")
                          },
                          otherwise: () => {
                            return Yup.string().nullable()
                                .email("Não é um e-mail válido")
                          }
                        }),
                    celular: Yup.string()
                        .when('configuracoes', {
                          is: (value) => {
                            return (value && value.includes('2'))
                          },
                          then: () => {
                            return Yup.string()
                                .nullable()
                                .required('Celular é obrigatório')
                          },
                          otherwise: () => {
                            return Yup.string() .nullable()
                          }
                        })
                  })
              )
              .strict(),
          fields: [
            {
              label: 'Nome',
              name: 'nome',
              type: TextInput,
              col: 'col-12 col-md-2',
            },
            {
              label: 'Apelido',
              name: 'apelido',
              type: TextInput,
              col: 'col-12 col-md-1',
            },
            {
              label: 'E-mail',
              name: 'email',
              type: TextInput,
              col: 'col-12 col-md-2',
            },
            // {
            //   label: 'Não informar ano',
            //   name: 'anoNaoInformado',
            //   type: SelectInput,
            //   col: 'col-12 col-md-1',
            //   rules: Yup.string().required(),
            //   options: [{nome: 'Sim', id: true}, {nome: 'Não', id: false}],
            // },
            {
              label: 'Data Nascimento',
              name: 'dataNascimento',
              type: DateInput,
              col: 'col-12 col-md-2',
            },
            {
              label: 'Cargo',
              name: 'cargo',
              type: RelationInput,
              col: 'col-12 col-md-3',
              endpoint: 'cargo',
              display: (value) => {
                return value.nome
              },
            },
            {
              label: 'Telefone Comercial',
              name: 'telefoneComercial',
              mask: '+## (##) #####-####',
              type: TextInput,
              col: 'col-12 col-md-2 col-md-15-percent',
            },
            {
              label: 'Ramal',
              name: 'telefoneComercialRamal',
              type: TextInput,
              col: 'col-12 col-md-2 col-md-15-percent',
            },
            {
              label: 'Celular',
              name: 'celular',
              mask: '+## (##) #####-####',
              type: TextInput,
              col: 'col-12 col-md-2 col-md-15-percent',
            },
            {
              label: 'Envio pelo whatsapp',
              name: 'habilitadoEnvioWhatsapp',
              type: SelectInput,
              col: 'col-12 col-md-2',
              options: [{id: null, nome: 'Selecione'}, {id: true, nome: 'Sim'}, {id: false, nome: 'Não'}],
            },
            {
              label: 'Envio pelo e-mail',
              name: 'habilitadoEnvioEmail',
              type: SelectInput,
              col: 'col-12 col-md-2',
              options: [{id: null, nome: 'Selecione'}, {id: true, nome: 'Sim'}, {id: false, nome: 'Não'}],
            },
          ],
          emptyObject: pessoaEmptyObject,
          col: 'col-12 col-md-12',
          //rules: Yup.string().required(),
        },
      ]
    },

    {
      section: 'Observações',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: '',
          name: 'observacao',
          type: TextAreaInput,
          col: 'col-12 col-md-12',
          // rules: Yup.string().required(),
        },
      ]
    }
  ],
};

</script>

<style scoped>
.action-point-2 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 10px;
  transition: width 0.8s ease, transform 0.8s ease; /* Animação suave */
  transform: translateX(0px); /* Move o elemento para a esquerda */
}

.action-point-2.opened {
  left: 280px;
  transform: translateX(0px); /* Move o elemento para a esquerda */

}



</style>

<style>
.separatorItem {

  background-color: #ccc;
}
</style>