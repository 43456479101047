// src/router/grupos.js
import GrupoListPage from "@/views/Grupos/GrupoListPage.vue";
import NovoGrupoPage from "@/views/Grupos/NovoGrupoPage.vue";

const gruposBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Grupos',
        to: 'Grupos'
    }
]

export default [
    {
        path: '/grupos',
        name: 'Grupos',
        component: GrupoListPage,
        meta: {
            title: 'Grupos',
            breadcrumb: gruposBreadCrumb
        },
    },
    {
        path: '/grupos/novo',
        name: 'GrupoNovo',
        component: NovoGrupoPage,
        meta: {
            title: 'Novo Grupo',
            breadcrumb: [
                ...gruposBreadCrumb,
                {
                    label: 'Novo Cliente',
                    to: 'ClienteNovo'
                }
            ]
        }
    },
    {
        path: '/grupos/:id/edit',
        name: 'GrupoEdit',
        component: NovoGrupoPage,
        meta: {
            title: 'Detalhes do Cliente',
            breadcrumb: [
                ...gruposBreadCrumb,
                {
                    label: 'Novo Grupo',
                    to: 'GrupoNovo'
                }
            ]
        }
    }

];
