<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>



  <span v-if="isAgendaIMO" class="alert-icon bg-warning rounded-circle p-2"  title="Atenção: Agenda IMO">
    <i class="flaticon flaticon-warning"></i>
  </span>
  <DynamicForm ref="dynamicForm" :schema="formSchema" cancel-route="Agenda" :initial-values="formData" @onSubmit="onSubmit" />
</template>



<script setup>
import {reactive, ref, onBeforeMount, computed } from 'vue';
import DynamicForm from "@/components/form/DynamicForm.vue"
import Loading from 'vue-loading-overlay';

import * as Yup from 'yup';
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import DateInput from "@/components/form/DateInput.vue";

import ClienteService from "@/services/ClienteService.js";
import AgendaService from "@/services/AgendaService.js";

import {TipoOperacaoService} from "@/services/TipoOperacaoService.js";
import SelectInput from "@/components/form/SelectInput.vue";
import {CambioService} from "@/services/CambioService.js";
import {useRoute} from "vue-router";
import {TipoCargaService} from "@/services/TipoCargaService.js";

import TextAreaInput from "@/components/form/TextAreaInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import RepeaterInput from "@/components/form/RepeaterInput.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import {DateTime, Interval} from "luxon";
import {useToast} from "@/plugins/useToast.js";
import {getFormatedFormData} from "@/utils/formHelper.js";
const { showToast } = useToast();


const dynamicForm = ref(null);

// Initial Data - Valor inicial do formulário
const formData = reactive({
  cargaImo: 0,
  necessitaLi: 1,
  tipoCarga: null,
  cambio: null,
  canalParametrizacao: null,
  situacaoMapaVegetal: 99,
  freeTime: null,
  dataChegada: null,
});

const isLoading = ref(false);
const agendaId = ref(false);

onBeforeMount(async ()  => {
  const  currentRoute  = useRoute();

  if (currentRoute.params.hasOwnProperty('id')) {
      isLoading.value = true;
      agendaId.value = currentRoute.params.id

      let agenda = await AgendaService.getTarefa(agendaId.value);
      // Object.assign(formData, agenda);

      updateFormValues(getFormatedFormData(formSchema, agenda))
      // updateFormValues(agenda)
      isLoading.value = false;
  }
})

const onSubmit = async (values) => {
  isLoading.value = true
  try {
    if (agendaId.value != null) {

      await AgendaService.updateProcess(agendaId.value, values)
    } else {
      await AgendaService.newProcess(values)
    }
  } catch (ex) {
    showToast('Não foi possível alterar o agenda. Tente novamente mais tarde.', {
      title: 'Erro',
      type: 'error'
    });
  }
  isLoading.value = false
}

const calculateDemurrage = (repeaterItem) => {
  let dataDevolucao = repeaterItem.dataDevolucao;

  let dataConsiderada = dataDevolucao ? DateTime.fromISO(dataDevolucao).toUTC() :  DateTime.utc();
    dataConsiderada.startOf('day');

    let freeTime = dynamicForm.value?.getFieldValue('freeTime');
    let dataChegada = dynamicForm.value?.getFieldValue('dataChegada');

    let diasDemurrage = null;

    if (!!freeTime && !!dataChegada) {
      freeTime = parseInt(freeTime)
      dataChegada = DateTime.fromISO(dataChegada).toUTC();

      if (freeTime > 0) {
        freeTime = freeTime - 1;
      }

      let dataLimite = dataChegada.plus({ days: (freeTime)}).toUTC();

      let interval = Interval.fromDateTimes(dataLimite, dataConsiderada);
      diasDemurrage = Math.floor(interval.length('days'))
    }

    return diasDemurrage;
}


const updateFormValue = (name, value) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFieldValue(name, value);
  }
};

const updateFormRepeaterValues = (repeaterName, name, calculateFunction) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateRepeaterCalculatedFieldValue(repeaterName, name, calculateFunction);
  }
};



const updateFormValues = (values) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFormValues(values)
  }
};


const isAgendaIMO = computed( () => {
  return dynamicForm.value?.getFieldValue('cargaImo') == 1
});

const showNumeroDossie = computed(() => {
  return formData.canalParametrizacao !== 1;
});

const showControleLi = computed(() => {
  let necessitaLi = dynamicForm.value?.getFieldValue('necessitaLi')

  console.log('necessitaLi:', necessitaLi, typeof necessitaLi);
  return necessitaLi == 1;
});



const showDataVistoria = computed(() => {
  let statusList = [3]

  return statusList.includes(formData.situacaoMapaVegetal)
});

const showDataAgendamentoVistoria = computed(() => {
  let statusList = [2, 3]

  return statusList.includes(formData.situacaoMapaVegetal)
});



const getClienteResponsaveis = async (cliente) => {
  let responsaveis = await ClienteService.getClientesResponsaveis((cliente.id));

  let analista = responsaveis.find(e => e.idCargo == 1);

  if (analista) {
    updateFormValue('analista', {id: analista.idResponsavel, nome: analista.responsavel})
  }

  let comercial = responsaveis.find(e => e.idCargo == 2);
  if (comercial) {
    updateFormValue('comercial', {id: comercial.idResponsavel, nome: comercial.responsavel})
  }
}

const formSchema = {
  sections: [
    {
      section: 'Informações Gerais',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Cliente',
          name: 'cliente',
          type: RelationInput,
          disabled: true,
          col: 'col-12 col-md-3',
          rules: Yup.object().required("Cliente é obrigatório"),
          endpoint: 'pessoa/cliente',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          },
          onChanged: (value) => {
            getClienteResponsaveis(value);
            //updateFormValue('analista', {id:4545, nome: 'sucesso'})
          }
        },
        {
          label: 'Tipo',
          name: 'tarefaTipo',
          type: RelationInput,
          disabled: true,
          col: 'col-12 col-md-3',
          rules: Yup.object().required("Tarefa tipo é obrigatória"),
          endpoint: 'pessoa/cliente',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          },
          onChanged: (value) => {
            getClienteResponsaveis(value);
            //updateFormValue('analista', {id:4545, nome: 'sucesso'})
          }
        },
        {
          label: 'Situação',
          name: 'tarefaSituacao',
          type: SelectInput,
          col: 'col-12 col-md-2',
          rules: Yup.number().required(),
          disabled: true,
          options: AgendaService.geTarefaSituacao(),
        },
        {
          label: 'Responsável',
          name: 'responsavel',
          type: RelationInput,
          col: 'col-12 col-md-4',
          disabled: true,
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },

        {
          label: 'Previsão Início',
          name: 'previsaoInicio',
          type: DateTimeInput,
          col: 'col-12 col-md-2',
          disabled: true,
          // rules: Yup.string().required(),
        },
        {
          label: 'Previsão Conclusão',
          name: 'previsaoConclusao',
          type: DateTimeInput,
          col: 'col-12 col-md-2',
          disabled: true,
          // rules: Yup.string().required(),
        },
        {
          label: 'Início',
          name: 'inicio',
          type: DateTimeInput,
          col: 'col-12 col-md-2',
          disabled: true,
          // rules: Yup.string().required(),
        },
        {
          label: 'Conclusão',
          name: 'conclusao',
          type: DateTimeInput,
          col: 'col-12 col-md-2',
          disabled: true,
          // rules: Yup.string().required(),
        },

      ]
    }
  ],
};

</script>


<style scoped>

.alert-icon {
  display: inline-block;
  position: fixed;
  z-index: 99;
  right: 5px;
  top: calc(30% - 15px)
}

.alert-icon i {
  font-size: 1.2em;
}

</style>
