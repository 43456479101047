import './assets/main.css'

import { createApp } from 'vue'
import { createPinia } from 'pinia'
import KeyCloakService from "./security/KeycloakService";
import HttpService from "@/services/httpservice";
import VueMask from '@devindex/vue-mask';
import VueApexCharts from "vue3-apexcharts";
import DefaultLayout from "./components/layouts/DefaultLayout.vue";
import EmptyLayout from "./components/layouts/EmptyLayout.vue";
import NavbarLayout from "./components/layouts/NavbarLayout.vue";
import Vue3EasyDataTable from 'vue3-easy-data-table';
// import 'vue3-easy-data-table/dist/style.css';
import Draggable from "vue3-draggable";

import i18n from "@/plugins/i18n.js";
import App from './App.vue'

import router from './router'

import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"

import "./assets/custom.scss";


const app = createApp(App)

app.use(createPinia())
app.use(router)
app.use(VueApexCharts)

//app.mount('#app')
const renderApp = () => {
    const app = createApp(App)
    app.component("default-layout", DefaultLayout)
    app.component("empty-layout", EmptyLayout)
    app.component("navbar-layout", NavbarLayout)
    app.component('apexchart', VueApexCharts)
    app.component('EasyDataTable', Vue3EasyDataTable)
    app.component('draggable', Draggable)
    app.use(router)
    app.use(i18n) // Use o i18n
    app.use(VueMask)
    app.mount('#app')
};

KeyCloakService.CallLogin(renderApp);
HttpService.configureAxiosKeycloak();

