<template>
  <data-grid
      endpoint="/api/processo/list"
      section="data-grid"
      :filterForm="filterForm"
      :default-filters="defaultFilters"
      :columns="defaultColumnsSettings"
      :allow-toggle-only-my="allowToggleOnlyMy()"
      :initial-sort-by="[{ propertyName: 'previsaoEmbarque', sortOrder: 'asc'}]"
      @on-db-click-row="showDetails"
      :get-row-class="getRowClass"
  >

    <template v-slot:[`item-observacao`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content"  v-if="item.observacao">
            <span v-if="item.dataProximaAcao">{{ formatDate(item.dataProximaAcao) }} - </span><span v-html="item.observacao"></span>
          </div>
          <div class="tooltip-content" v-else>
            Nenhuma observação disponível
          </div>
        </template>
        <i class="fs-16 flaticon flaticon-form"></i>
        <div style="display: none"><span v-if="item.dataProximaAcao">{{formatDate(item.dataProximaAcao)}} - </span>{{ item.observacao }}</div>
      </tooltip>
    </template>

    <template v-slot:[`item-situacao`]="{item}">
      <div class="text-center">
        <span  v-if="item.situacao == 1" class="icon text-center rounded-1 text-white p-1 position-relative bg-warning ">Aberto</span>
        <span  v-if="item.situacao == 2" class="icon text-center rounded-1 text-white p-1 position-relative bg-success ">Concluído</span>
        <span  v-if="item.situacao == 3" class="icon text-center rounded-1 text-white p-1 position-relative bg-gray text-black ">Arquivado</span>
        <span  v-if="item.situacao == 9" class="icon text-center rounded-1 text-white p-1 position-relative bg-danger ">Cancelado</span>
      </div>
    </template>

    <template v-slot:[`item-situacaoCanal`]="{item}">
      <div class="text-center">
        <span  v-if="item.situacaoCanal == 10" class="icon text-center rounded-1 text-white p-1 position-relative bg-success ">Verde</span>
        <span  v-if="item.situacaoCanal == 20" class="icon text-center rounded-1 text-white p-1 position-relative bg-warning ">Amarelo</span>
        <span  v-if="item.situacaoCanal == 30" class="icon text-center rounded-1 text-white p-1 position-relative bg-gray text-black ">Cinza</span>
        <span  v-if="item.situacaoCanal == 40" class="icon text-center rounded-1 text-white p-1 position-relative bg-danger ">Vermelho</span>
      </div>
    </template>

    <template v-slot:[`item-dataChegada`]="{item}">
      <div>{{ formatDate(item.dataChegada)}}</div>
    </template>

    <template v-slot:[`item-diasDataChegada`]="{item}">
      <div class="text-center">
        <span class="text-white bg-success p-1 rounded-1"  v-if="item.diasDataChegada > 7">{{ item.diasDataChegada  }}</span>
        <span class="text-white bg-warning p-1 rounded-1"  v-else-if="item.diasDataChegada != null && item.diasDataChegada >= 0">{{ item.diasDataChegada  }}</span>
        <span class="text-white bg-danger p-1 rounded-1"  v-else-if="item.diasDataChegada < 0">{{ item.diasDataChegada * -1 }}</span>
        <span  v-else>{{ item.diasDataChegada }}</span>
      </div>
    </template>

    <template v-slot:[`item-previsaoChegada`]="{item}">
      <div>{{ formatDate(item.previsaoChegada)}}</div>
    </template>

    <template v-slot:[`item-previsaoEmbarque`]="{item}">
      <div>{{ formatDate(item.previsaoEmbarque)}}</div>
    </template>

    <template v-slot:[`item-dataEmbarque`]="{item}">
      <div>{{ formatDate(item.dataEmbarque)}}</div>
    </template>

    <template v-slot:[`item-situacaoBL`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Localização do conhecimento original</strong> igual a recebido</span>
            </div>

            <!--            <div class="tooltip-label" >-->
            <!--              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>-->
            <!--              <span class="label">TEste 1</span>-->
            <!--            </div>-->

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Localização do conhecimento original</strong> diferente de Recebido</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Chegada</strong>  e <strong>Localização do conhecimento original</strong> diferente de Recebido</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoNumerario == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoNumerario == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoNumerario == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoNumerario == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-situacaoNumerario`]="{item}">

      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Situação de numerário</strong> igual a Recebido ou Sem ação necessária</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Situação de numerário</strong> igual a Emitido ou Aguardando recebimento</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Situação de numerário</strong> igual a Pendente emissão</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoNumerario == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoNumerario == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoNumerario == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoNumerario == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>




    </template>

    <template v-slot:[`item-situacaoMapa`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Mapa (Madeira)</strong> igual a Liberado</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Mapa (Madeira)</strong> igual a Aguardando análise ou Não informado</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Mapa (Madeira)</strong> igual a Conferencia Física</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoMapa == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoMapa == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoMapa == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoMapa == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-situacaoSeguro`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Averbação de Seguro</strong> igual a Averbado, Sem Seguro ou CIF</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Averbação de Seguro</strong> diferente de Averbado</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Averbação de Seguro</strong> diferente de Averbado após 5 dias da chegada</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoSeguro == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoSeguro == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoSeguro == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoSeguro == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-situacaoPagamentoFrete`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Pagamento Frete</strong> igual a Pago</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Pagamento Frete</strong> igual a Solicitar ou Aguardando Valores</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Pagamento Frete</strong> não foi realizado até 1 dia antes da data da previsão de chegada. Ou Embarque confirmado e data de previsão de chegada não informada</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoPagamentoFrete == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoPagamentoFrete == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoPagamentoFrete == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoPagamentoFrete == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-situacaoExoneracaoICMS`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Exoneração ICMS</strong> igual a Exonerado ou Pago</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Exoneração ICMS</strong> igual a Em análise ou Solicitar antes da data de desembaraço</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Exoneração ICMS</strong> diferente de Exonerado ou Pago após desembaraço</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoExoneracaoICMS == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoExoneracaoICMS == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoExoneracaoICMS == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoExoneracaoICMS == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-situacaoLiberacaoComex`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Liberação Comex</strong> igual a Liberado</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Liberação Comex</strong> igual a Pendente pagamento</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Liberação Comex</strong> igual a Atrasado ou não informado</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoLiberacaoComex == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoLiberacaoComex == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoLiberacaoComex == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoLiberacaoComex == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-situacaoCarregamento`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Status Carregamento</strong> igual a Agendado</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Status Carregamento</strong> igual a Aguardando Agendamento</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Status Carregamento</strong> igual a Carregamento atrasado, Reagendamentos (Outros Motivos) ou Não informmado.</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoLiberacaoComex == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoLiberacaoComex == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoLiberacaoComex == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoLiberacaoComex == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-situacaoNotaFiscal`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Emissão NF</strong> igual a Emitida</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Emissão NF</strong> igual a Solicitar ou Aguardando Emissão</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Data de Carregamento</strong> preenchida e <strong>Emissão NF</strong> diferente de Emitida </span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoNotaFiscal == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoNotaFiscal == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoNotaFiscal == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoNotaFiscal == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-situacaoLiberacaoDocumentalFrete`]="{item}">
      <tooltip>
        <template v-slot:tooltip>
          <div class="tooltip-content">
            <div class="tooltip-label" >
              <span  class="rounded-circle icon text-center position-relative bg-success w-10 h-10 inline-block"></span>
              <span class="label"><strong>Liberação documental</strong> igual a Liberado</span>
            </div>
            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
              <span class="label"><strong>Liberação documental</strong> igual a Apresentado ou Em Análise</span>
            </div>

            <div class="tooltip-label" >
              <span style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
              <span class="label"><strong>Liberação documental</strong> não foi realizada até 1 dia antes da data da previsão de chegada. Ou Embarque confirmado e data de previsão de chegada não informada</span>
            </div>
          </div>
        </template>

        <span  v-if="item.situacaoLiberacaoDocumentalFrete == 10" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-success "></span>
        <span  v-else-if="item.situacaoLiberacaoDocumentalFrete == 20" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-gray "></span>
        <span  v-else-if="item.situacaoLiberacaoDocumentalFrete == 30" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-warning "></span>
        <span  v-else-if="item.situacaoLiberacaoDocumentalFrete == 40" style=" display:inline-block; width: 20px; height: 20px;" class="icon text-center rounded-circle position-relative bg-danger "></span>
      </tooltip>
    </template>

    <template v-slot:[`item-acoes`]="{item}">
      <div class="d-flex justify-content-center align-items-center">
        <a
            class="dropdown-item cursor-pointer d-flex justify-content-center align-items-center"
            @click.prevent="showDetails(item)"
        >
          <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
        </a>
      </div>

    </template>
  </data-grid>
</template>

<script setup>
import ProcessoService from "@/services/ProcessoService.js";
import router from "@/router/index.js";
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";

import SelectInput from "@/components/form/SelectInput.vue";
import dataGrid from '@/components/dataGrid/dataGrid.vue'

import {formatDate, diferencaEmDias} from "../../utils/dateHelper.js";
import KeycloakService from "@/security/KeycloakService.js";
import Tooltip from "@/components/common/Tooltip.vue";
import MultiSelectInput from "@/components/form/MultiSelectInput.vue";

const defaultFilters = {
  statusFilters: [
    {
      label: 'Situação',
      name: 'situacao',
      value: 1,
      options: [
        { value: null,  name: 'Todos' },
        { value: 1,     name: 'Aberto' },
        { value: 2,     name: 'Concluído' },
        { value: 3,     name: 'Arquivado' },
        { value: 9,     name: 'Cancelado' },
      ]
    }
  ],
  filters: null
}

const defaultColumnsSettings = [
  { text: "PROCESSO", value: "referenciaInterna", sortable: true, width: 90 },
  { text: "CONEXOS ID", value: "conexosId", sortable: false, width: 85},
  { text: "CLIENTE", value: "clienteNome", sortable: true, width: 100 },
  { text: "RESPONSÁVEL", value: "responsavelNome", sortable: true, width: 100 },
  { text: "PROCEDIMENTO", value: "procedimento", sortable: true, width: 90 },
  { text: "NÚM. CONHEC.", value: "numeroConhecimento", sortable: true, width: 120 },
  { text: "NÚM. CE", value: "numeroCe", sortable: true, width: 110 },
  { text: "REF. CLIENTE", value: "referenciaCliente", sortable: false, width: 120},
  { text: "TIPO OPERAÇÃO", value: "tipoOperacao", sortable: false,  width: 120},
  { text: "CÂMBIO", value: "cambio", sortable: false },
  { text: "MODAL", value: "modal", sortable: true,  width: 105 },
  { text: "ETD", value: "previsaoEmbarque", sortable: true },
  { text: "ATD", value: "dataEmbarque", sortable: true  },
  { text: "ETA", value: "previsaoChegada", sortable: true  },
  { text: "ATA ", value: "dataChegada", sortable: true  },
  { text: "DIAS CHEG. ", value: "diasDataChegada", sortable: true, width: 110 },
  { text: "EXPORTADOR", value: "exportadorNome", sortable: false, width: 100 },
  { text: "PRODUTOS", value: "produto", sortable: false, width: 120 },
  { text: "NAVIO/AERONAVE", value: "navioNome", sortable: false,  width: 120 },
  { text: "AGENTE DE CARGA", value: "agenteCargaNome", sortable: false, width: 120 },
  { text: "ARMADOR", value: "companhiaTransporteNome", sortable: false, width: 120 },
  { text: "QTD. CONT.", value: "qtdConteiners", sortable: false, width: 80 },
  { text: "SITUAÇÃO", value: "situacao", sortable: true },
  { text: "CANAL", value: "situacaoCanal", sortable: false },
  { text: "BL", value: "situacaoBL", sortable: false, width: 50 },
  { text: "NUM", value: "situacaoNumerario", sortable: false, width: 50 },
  // { text: "LI", value: "situacaoBL", sortable: false, width: 50 },
  { text: "MAPA", value: "situacaoMapa", sortable: false, width: 50 },
  { text: "S.SEG", value: "situacaoSeguro", sortable: false, width: 50 },
  { text: "FR.INT", value: "situacaoPagamentoFrete", sortable: false, width: 50 },
  { text: "L.DOC.FR", value: "situacaoLiberacaoDocumentalFrete", sortable: false, width: 50 },
  { text: "EX.ICMS", value: "situacaoExoneracaoICMS", sortable: false, width: 50 },
  { text: "COMEX", value: "situacaoLiberacaoComex", sortable: false, width: 50 },
  { text: "CARREG.", value: "situacaoCarregamento", sortable: false, width: 50 },
  { text: "EMI.NF", value: "situacaoNotaFiscal", sortable: false, width: 50 },
  { text: "OBS.", value: "observacao", sortable: false, width: 50 },
  { text: "AÇÕES", value: "acoes", sortable: false },
]
const filterForm = {
  sections: [
    {
      section: "Geral",
      fields: [
        {
          label: 'Ref. Cliente',
          name: 'referencia_cliente',
          type: TextInput,
          col: 'col-12',
        },
        {
          label: 'Número CE',
          name: 'numeroCe',
          type: TextInput,
          col: 'col-12',
        },
        {
          label: 'Número Conhecimento',
          name: 'numeroConhecimento',
          type: TextInput,
          col: 'col-12',
        },
        {
          label: 'Cliente',
          name: 'cliente',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/cliente',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Procedimento',
          name: 'procedimento',
          type: MultiSelectInput,
          col: 'col-12',
          options: ProcessoService.getProcedimentos()
        },
        {
          label: 'Modal',
          name: 'viaTransporte',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'via-transporte',
          display: (value) => {
            return value.nome
          }
        },
      ]
    },
    {
      section: "Controle",
      fields: [
        {
          label: 'Seguro',
          name: 'seguroPendente',
          type: SelectInput,
          col: 'col-12',
          options: [{ nome: 'Todos os processos', id: null},{ nome: 'Pendente', id: true},{ nome: 'Concluído', id: false}],
        },

      ]
    },
    {
      section: "Responsáveis",
      fields: [
        {
          label: 'Responsável',
          name: 'responsavel',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Analista Responsável',
          name: 'analista_responsavel',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Comercial Responsável',
          name: 'comercial_responsavel',
          type: RelationInput,
          col: 'col-12',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
      ]
    }

  ],
};

function showDetails(processo, newWindow) {
  const routeData = router.resolve({
    name: 'ProcessoDetalhes',
    params: { id: processo.id }
  });

  if (newWindow) {
    window.open(routeData.href, '_blank');
  } else {
    router.push(routeData);
  }
}


const getRowClass = (row) => {

  if (row.dataProximaAcao) {
    if(row.dataProximaAcao) {
      const dataProximaAcao = new Date(row.dataProximaAcao);
      const nowUTC = new Date(new Date().toISOString());

      let dias = diferencaEmDias(nowUTC, dataProximaAcao)

      if (dias == 0) {
        return 'warning-line'
      } else if (dias < 0) {
        return 'danger-line'
      }
    }
  }

  return ''

}

const allowToggleOnlyMy = () => {
  if(IsGranted('colaborador')) {
    return true;
  }

  return false;
}


const IsGranted = (role) => {
  return KeycloakService.IsGranted(role);
}

</script>

<style>
  .tooltip-label {
    display: flex; /* Ativa o flexbox */
    align-items: center; /* Alinha os itens verticalmente ao centro */
    gap: 8px; /* Espaçamento entre o ícone e o texto (opcional) */
    margin-bottom: 6px;
  }

  .tooltip-label .icon {
    display: inline-block;
    flex-shrink: 0;
    width: 20px !important;
    height: 20px !important;
  }

  .tooltip-label .label {
    flex-grow: 1;
    font-size: 11px; /* Ajuste o tamanho da fonte se necessário */
  }

  .tooltip-content {
    padding: 5px;
  }
</style>