import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getUsuariosResponsaveis = async (pessoaId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/pessoa/usuario/${pessoaId}/responsaveis`,{});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar informações do usuario:', error);
        throw error;
    }
};

const getUsuarios = async (pageNumber, pageSize, searchTerm) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/usuario/lista`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getUsuario = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/usuario/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar usuario', error);
        throw error;
    }
};

const updateUsuario = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/usuario/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o usuario', error);
        throw error;
    }
};

const createUsuario = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/usuario`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o usuario', error);
        throw error;
    }
};


const updateAnalistaResponsavel = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/usuario/${id}/alterar-analista`, {});
        return response.data;
    } catch (error) {
        console.error('Erro ao alaterar o analista responsavel do usuario', error);
        throw error;
    }
};

const alterarConfiguracoesEmail = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/usuario/${id}/alterar-senha-email`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o usuario', error);
        throw error;
    }
};

const alterarSenha = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/usuario/${id}/alterar-senha`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o usuario', error);
        throw error;
    }
};


const testMailSend = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/usuario/${id}/testar-envio-email`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao enviar e-mail com o usuario', error);
        throw error;
    }
};


export default {
    getUsuariosResponsaveis,
    getUsuarios,
    getUsuario,
    createUsuario,
    updateUsuario,
    updateAnalistaResponsavel,
    alterarConfiguracoesEmail,
    alterarSenha,
    testMailSend
};