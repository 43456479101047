// src/router/cargos.js
import CargoListPage from "@/views/Cargos/CargoListPage.vue";
import NovoCargoPage from "@/views/Cargos/NovoCargoPage.vue";

const cargosBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Cargos',
        to: 'Cargos'
    }
]

export default [
    {
        path: '/cargos',
        name: 'Cargos',
        component: CargoListPage,
        meta: {
            title: 'Cargos',
            breadcrumb: cargosBreadCrumb
        },
    },
    {
        path: '/cargos/novo',
        name: 'CargoNovo',
        component: NovoCargoPage,
        meta: {
            title: 'Novo Cargo',
            breadcrumb: [
                ...cargosBreadCrumb,
                {
                    label: 'Novo Cliente',
                    to: 'ClienteNovo'
                }
            ]
        }
    },
    {
        path: '/cargos/:id/edit',
        name: 'CargoEdit',
        component: NovoCargoPage,
        meta: {
            title: 'Detalhes do Cliente',
            breadcrumb: [
                ...cargosBreadCrumb,
                {
                    label: 'Novo Cargo',
                    to: 'CargoNovo'
                }
            ]
        }
    }

];
