<!-- ColumnsSettingsModal.vue -->
<template>
  <div
      class="modal fade"
      id="columnsSettingsModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Colunas</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row draggagle-scroll">
            <div class="col-12">
              <draggable :key="draggableKey" v-model="localColumns">
                <template v-slot:item="{item}">
                  <!-- example -->
                  <div class="btn btn-outline-light w-100 d-flex justify-content-between align-items-center">
                      <span>
                        <label>
                          <input
                              type="checkbox"
                              v-model="item.visible"
                          />
                          {{ item.text }}
                        </label>

                      </span>
                    <span>
                      <i class="flaticon-up-arrow" @click.prevent="onUpItem(item)"></i>
                      <i class="flaticon-down-arrow" @click.prevent="onDownItem(item)"></i>
                        </span>
                  </div>
                  <!-- or your own template -->
                </template>
              </draggable>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-outline-light me-3"
              @click="handleCancel"
          >
            Cancelar
          </button>
          <button
              type="button"
              class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              @click="onApply"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, defineEmits, reactive} from 'vue';
import { Modal } from 'bootstrap';
import AgendaService from "@/services/AgendaService.js";
import DataGridService from "@/services/DataGridService.js";
import {useRouter} from "vue-router";


const modalElement = ref(null);
const draggableKey = ref(0);
const tarefaId = ref(null);


let bsModalInstance = null;
const formData = reactive({

});

const emit = defineEmits(['cancel', 'onApply']);
const localColumns = ref([])



// Inicializar o modal quando o componente for montado
onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});


const copyValues = (values) => {
  console.log('copyValues values', values)
  return JSON.parse(JSON.stringify(
      values.map(p => {
        if (p.visible === undefined) {
          p.visible = true;
        }
        return p;
      })
  ))
}


const onUpItem = (item) => {
  const currentIndex = localColumns.value.findIndex(p => p.value === item.value);

  if (currentIndex > 0) {
    localColumns.value.splice(currentIndex, 1);
    localColumns.value.splice(currentIndex - 1, 0, item);
    localColumns.value = [...localColumns.value]
    draggableKey.value += 1;
  }
};

const onDownItem = (item) => {
  const currentIndex = localColumns.value.findIndex(p => p.value === item.value);

  if (currentIndex < localColumns.value.length - 1) {
    localColumns.value.splice(currentIndex, 1);
    localColumns.value.splice(currentIndex + 1, 0, item);
    localColumns.value = [...localColumns.value]
    draggableKey.value += 1;
  }
};


const onApply = async () => {
  let currentSettings = localColumns.value;
  emit("onApply",  currentSettings);
  closeModal()
};


// Função para abrir o modal
const openModal = (columns) => {
  if (bsModalInstance) {
    draggableKey.value += 1;
    localColumns.value = copyValues(columns)
    bsModalInstance.show();
  }
};

// Função para fechar o modal
const closeModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};

// Função para lidar com o cancelamento
const handleCancel = () => {
  closeModal();
  emit('cancel');
};




defineExpose({
  openModal,
});
</script>


<style scoped>
.draggagle-scroll {
  height: 72vh;
  overflow: hidden;
  overflow-y: auto;
}
</style>