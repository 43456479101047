<template>
  <BreadCrumb  />
  <TipoContainerForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import TipoContainerForm from "@/views/TiposContainers/TipoContainerForm.vue";

export default defineComponent({
  name: "NovoTipoContainerPage",
  components: {
    BreadCrumb,
    TipoContainerForm,
  },
});
</script>
