<template>
  <div class="mb-20 letter-spacing">
    <div class="card-head bg-white d-md-flex justify-content-start p-10 p-sm-10 ">
      <!-- Logistica -->
      <div class="dropdown me-3">
        <button class="btn default-outline-btn btn-sm dropdown-toggle" type="button" data-bs-toggle="dropdown"
                aria-expanded="false"> Logistica
        </button>
        <ul class="dropdown-menu">
          <li><a class="dropdown-item" data-bs-toggle="modal" data-bs-target="#situacaoMapaModal">Mapa (Madeira)</a></li>
        </ul>
      </div>
      <!-- Histórico -->
      <button class="btn default-outline-btn btn-sm" v-if="agendaId && !showHistorico" type="button" @click="showHistorico = !showHistorico; onAction('showHistorico', { agendaId })"
              > Acompanhamento
      </button>

      <button class="btn default-outline-btn btn-sm" v-if="agendaId && showHistorico"   type="button" @click="showHistorico = !showHistorico; onAction('showAgenda', { agendaId })"
        > Agenda
      </button>
    </div>
  </div>
</template>

<script setup>
import {useRoute} from "vue-router";

import AgendaService from "@/services/AgendaService.js";
import {defineEmits, onBeforeMount, ref} from "vue";


const agendaId = ref(null)
const showHistorico = ref(false)

const emit = defineEmits(['onRefresh', 'onAction']);

onBeforeMount(async ()  => {
  const  currentRoute  = useRoute();

  if (currentRoute.params.hasOwnProperty('id')) {
      agendaId.value=  parseInt(currentRoute.params.id);
  }
})




function onSave() {
  emit('onRefresh')
}




async function onAction(action, props) {
  props.action = action;

  emit('onAction', props)
}


</script>

<style scoped>

</style>