export const TipoCargaService = {
    getData() {
        return [
            { nome: 'Selecione', id: null, modalidade: null },
            { nome: 'FCL', id: 10, modalidade: 10 },
            { nome: 'LCL', id: 11, modalidade: 10  },
            { nome: 'Carga Solta', id: 20, modalidade: 9  },

        ];
    },
    getTiposCarga() {
        return (this.getData());
    }
};
