<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>
  <DynamicForm ref="dynamicForm" :schema="formSchema" cancel-route="RecintoAlfandegados" :initial-values="formData" @onSubmit="onSubmit" />

  <!--  <Toast />-->

  <div class="action-point-2  bg-white" :class="{'opened': !stateStoreInstance.open}">
  </div>

</template>



<script setup>
import {reactive, ref, onBeforeMount, inject, computed} from 'vue';
import DynamicForm from "@/components/form/DynamicForm.vue"
import stateStore from "@/utils/store";
import Loading from 'vue-loading-overlay';

import * as Yup from 'yup';
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import router from "@/router/index.js";
import RecintoAlfandegadoService from "@/services/RecintoAlfandegadoService.js";

import {useRoute} from "vue-router";

import { useToast} from "@/plugins/useToast.js";
import PasswordInput from "@/components/form/PasswordInput.vue";
import FileInput from "@/components/form/FileInput.vue";
import CheckInput from "@/components/form/CheckInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";


const { showToast } = useToast();

const stateStoreInstance = stateStore;

let pessoaEmptyObject = {
  pessoa: null,
  recintoAlfandegado: null
}

const  currentRoute  = useRoute();
const dynamicForm = ref(null);

// Initial Data - Valor inicial do formulário
const formData = reactive({
});

const isLoading = ref(false);

onBeforeMount(async ()  => {
  if (currentRoute.params.hasOwnProperty('id')) {
      isLoading.value = true;



      let recintoAlfandegado = await RecintoAlfandegadoService.getRecintoAlfandegado(currentRoute.params.id);

      console.log('recintoAlfandegado', recintoAlfandegado)
      Object.assign(formData, recintoAlfandegado);
      updateFormValues(formData)
      isLoading.value = false;
  }
})

const onSubmit = async (values) =>  {
  isLoading.value = true

  if (currentRoute.params.hasOwnProperty('id')) {
    let result = await RecintoAlfandegadoService.updateRecintoAlfandegado(currentRoute.params.id, values)

    showToast('RecintoAlfandegado salvo.', {
      title: 'Sucesso',
      type: 'success'
    });

    router.push({name: 'RecintoAlfandegados'});
  } else {
    RecintoAlfandegadoService.createRecintoAlfandegado(values).then(r => {
      showToast('RecintoAlfandegado salvo.', {
        title: 'Sucesso',
        type: 'success'
      })

      router.push({name: 'RecintoAlfandegados'});

    }).catch(ex => {


        showToast('Não foi possível salvar o recintoAlfandegado. Tente novamente mais tarde.', {
          title: 'Erro',
          type: 'error'
        })

    }).finally(() => {

      isLoading.value = false;
    })
  }

}


const updateFormValues = (values) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFormValues(values)
  }
};





const formSchema = {
  sections: [
    {
      // section: 'Informações Gerais',
      col: 'col-12 col-md-12',
      fields: [

        {
          label: 'Nome',
          name: 'nome',
          type: TextInput,
          col: 'col-12 col-md-5',
          rules: Yup.string().required("Nome é obrigatório")
        },
        {
          label: 'Nome Exibição',
          name: 'nomeExibicao',
          type: TextInput,
          col: 'col-12 col-md-3'
        },
        {
          label: 'Cód. SRF',
          name: 'codigoSRF',
          type: TextInput,
          col: 'col-12 col-md-2',
          rules: Yup.string().required("Código é obrigatório")
        },
        {
          label: 'Ativo',
          name: 'ativo',
          type: SelectInput,
          col: 'col-12 col-md-1',
          rules: Yup.string().required(),
          options: [{ nome: 'Selecione..', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          onChanged: (value) => {
            console.log('value', value)
          }
        },

        // {
        //   label: 'Foto',
        //   name: 'imagem',
        //   type: FileInput,
        //   col: 'col-12 col-md-4',
        // }
      ]
    }
  ],
};
</script>

<style scoped>
 .action-point-2 {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 9999;
   padding: 10px;
   transition: width 0.8s ease, transform 0.8s ease; /* Animação suave */
   transform: translateX(0px); /* Move o elemento para a esquerda */
 }

 .action-point-2.opened {
   left: 280px;
   transform: translateX(0px); /* Move o elemento para a esquerda */

 }
</style>