import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getDepotsResponsaveis = async (pessoaId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/pessoa/depot/${pessoaId}/responsaveis`,{});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar informações do depot:', error);
        throw error;
    }
};

const getDepots = async (pageNumber, pageSize, searchTerm, situacao) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/depot/lista`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                situacao
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getDepot = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/depot/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar depot', error);
        throw error;
    }
};

const updateDepot = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/depot/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o depot', error);
        throw error;
    }
};

const createDepot = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/depot`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o depot', error);
        throw error;
    }
};


const updateAnalistaResponsavel = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/depot/${id}/alterar-analista`, {});
        return response.data;
    } catch (error) {
        console.error('Erro ao alaterar o analista responsavel do depot', error);
        throw error;
    }
};

export default {
    getDepotsResponsaveis,
    getDepots,
    getDepot,
    createDepot,
    updateDepot,
    updateAnalistaResponsavel
};