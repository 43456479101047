<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>

  <div className="row">
    <div className="col-12">
      <div className="row">
        <div className="col-12">
          <div class="card mb-25 border-0 rounded-0 bg-white expected-earnings-box">
            <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
              <div className="row">
                <span class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis">
                  FILTROS
                </span>
                <div class="col-12 col-sm-4">
                  <relation-input label="Analista" name="analista" endpoint="pessoa/analista" :display="(value) =>  value.nome ?? value.razaoSocial" @onChanged="onResponsavelChange"  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="row d-flex align-items-stretch">
    <div  class="col-12 col-md-4 mb-25">
      <processos-por-nivel :processos-por-nivel="processosPorNivel"  :labelsEixoX="labelsEixoX"   />
    </div>

    <div  class="col-12 col-md-4 mb-25">
      <clientes-por-nivel :clientes-por-nivel="clientesPorNivel"  />
    </div>

    <div  class="col-12 col-md-4 mb-25">
      <SituacaoAtual :processos-por-nivel="processosPorNivel" :clientes-por-nivel="clientesPorNivel" />
    </div>

    <div  class="col-xxl-12">
      <clientes :items="clientes" :totalItems="clientes.length" />
      <!-- Passe o objeto `processos` completo como prop -->
<!--      <Clientes :processos="processos.items" :totalItems="processos.totalItems"    @changed="filterChanged"/>-->
    </div>

<!--    <div  class="col-xxl-12">-->
<!--      &lt;!&ndash; Passe o objeto `processos` completo como prop &ndash;&gt;-->
<!--      <Processos :processos="processos.items" :totalItems="processos.totalItems"    @changed="filterChanged"/>-->
<!--    </div>-->
  </div>
</template>

<script setup>
import {ref, onBeforeMount, computed} from 'vue';

import Loading from 'vue-loading-overlay';
import Processos from "@/views/Relatorios/DistribuicaoProcessosClassificacaoResponsavel/Processos.vue";
import ExpectedEarnings from "@/components/temp/Dashboard/Ecommerce/ExpectedEarnings.vue";
import AverageDailySales from "@/components/temp/Dashboard/Ecommerce/AverageDailySales.vue";
import RelatorioService from "@/services/RelatorioService.js";
import ProcessosPorNivel from "@/views/Relatorios/DistribuicaoProcessosClassificacaoResponsavel/ProcessosPorNivel.vue";
import PrevFaturamentoCard from "@/views/Relatorios/PrevisaoFaturamento/PrevFaturamentoCard.vue";
import PrevFaturamentoMediaMensalCard from "@/views/Relatorios/PrevisaoFaturamento/PrevFaturamentoMediaMensalCard.vue";
import {monthNumberToShortName} from "@/utils/stringHelper.js";
import {formatDecimal} from "@/utils/numberHelper.js";
import RelationInput from "@/components/form/RelationInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import ClientesPorNivel from "@/views/Relatorios/DistribuicaoProcessosClassificacaoResponsavel/ClientesPorNivel.vue";
import StatsBoxes from "@/components/temp/Dashboard/Ecommerce/StatsBoxes/index.vue";
import SituacaoAtual from "@/views/Relatorios/DistribuicaoProcessosClassificacaoResponsavel/SituacaoAtualBkp.vue";
import Clientes from "@/views/Relatorios/DistribuicaoProcessosClassificacaoResponsavel/Clientes.vue";

const isLoading = ref(false);
let today = new Date();




const labelsEixoX = computed(() => {
  return undefined
});


let responsavel = ref(null);


let result = ref({});

let processos = ref([]); // Reactive ref for processos
let clientes = ref([]);
let processosPorNivel = ref([]); // Reactive ref for processos
let clientesPorNivel = ref([]); // Reactive ref for processos

const onResponsavelChange =  async (value) => {

  responsavel.value = value

  await getData();
};

async function getData () {
  isLoading.value = true;
  result.value = await RelatorioService.getDistribuicaoProcessosAnalista(1, 200,  responsavel.value?.id);

  // Atualize `processos` diretamente
  processos.value = result.value.processos;

  processosPorNivel.value = result.value.processosPorNivel;
  clientesPorNivel.value = result.value.clientesPorNivel;
  clientes.value = result.value.clientes;
  isLoading.value = false;
}

async function filterChanged(filtros) {
  console.log('filters', filtros)
  await getData()
}

onBeforeMount(async () => {
  result.value = {
    "processos": {
      "items": [],
      "totalItems": 0,
      "pageNumber": 1,
      "pageSize": 20,
      "searchTerm": null,
      "totalPages": 0,
      "from": 0,
      "to": 0
    }
  };

   await getData();
});
</script>
