<template>
  <BreadCrumb  />
  <ClienteForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import ClienteForm from "@/views/Clientes/ClienteForm.vue";

export default defineComponent({
  name: "NovoProcessoPage",
  components: {
    BreadCrumb,
    ClienteForm,
  },
});
</script>
