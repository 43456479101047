<template>
  <data-grid
      endpoint="/api/relatorio/pessoa-conta-bancaria"
      section="data-grid"
      :filterForm="filterForm"
      :default-filters="defaultFilters"
      :allow-toggle-only-my="false"
      :columns="defaultColumnsSettings"
      :initial-sort-by="[{ propertyName: 'razaoSocial', sortOrder: 'asc'}]"
  >
    <template v-slot:[`item-informacoes`]="{item}">
      <div v-html="item.informacoes"></div>
    </template>
  </data-grid>
</template>

<script setup>
import router from "@/router/index.js";
import TextInput from "@/components/form/TextInput.vue";

import dataGrid from '@/components/dataGrid/dataGrid.vue'

import NumberInput from "@/components/form/NumberInput.vue";

const defaultFilters = {
  statusFilters: [
    {
      label: 'Situação',
      name: 'situacao',
      value: 1,
      options: [
        { value: null,  name: 'Todos' },
        { value: 1,     name: 'Cadastrado' },
        { value: 2,     name: 'Não cadastrado' },
      ]
    }
  ],
  filters: null
}

const defaultColumnsSettings = [
  { text: "ID", value: "id", sortable: true, width: 120 },
  { text: "CONEXOS ID", value: "conexosId", sortable: true, width: 130},
  { text: "RAZÃO SOCIAL", value: "razaoSocial", sortable: true, width: 180 },
  { text: "NOME", value: "nome", sortable: true, width: 180 },
  { text: "INFORMAÇÕES", value: "informacoes", sortable: false, slot: true},
]
const filterForm = {
  sections: [
    {
      section: "Geral",
      fields: [
        {
          label: 'Razão Social',
          name: 'razaoSocial',
          type: TextInput,
          col: 'col-12'
        },
        {
          label: 'Nome',
          name: 'nome',
          type: TextInput,
          col: 'col-12'
        },
        {
          label: 'Conexos Id',
          name: 'conexosId',
          type: NumberInput,
          decimalNumbers: 0,
          col: 'col-12'
        },
        {
          label: 'Informações',
          name: 'informacoes',
          type: TextInput,
          col: 'col-12'
        },
      ]
    }
  ],
};
</script>
