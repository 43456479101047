<template>
  <div
      :class="[
      'sidebar-area position-fixed start-0 top-0 bg-black h-100vh transition',
      { active: stateStoreInstance.open },
    ]"
      id="sidebar-area"
  >
    <div class="logo position-absolute start-0 end-0 top-0 bg-black">
      <router-link
          to="/"
          class="d-flex align-items-center text-white text-decoration-none"
      >
        <img src="../../../assets/logotipo.png" alt="logo-icon" />
        <span class="fw-bold ms-10">Triveni</span>
      </router-link>
      <div class="border-bottom"></div>
      <button
          class="sidebar-burger-menu position-absolute lh-1 bg-transparent p-0 border-0"
          @click="stateStoreInstance.onChange"
      >
        <i class="ph-duotone ph-caret-double-right"></i>
      </button>
    </div>
    <div class="sidebar-menu">
      <ul
          class="sidebar-navbar-nav ps-0 mb-0 list-unstyled accordion"
          id="sidebarNavAccordion"
      >
<!--        <li-->
<!--            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"-->
<!--        >-->
<!--          <a-->
<!--              href="#"-->
<!--              class="accordion-button rounded-0 shadow-none bg-transparent d-block"-->
<!--              data-bs-toggle="collapse"-->
<!--              data-bs-target="#sidebarCollapseOne"-->
<!--              aria-expanded="true"-->
<!--              aria-controls="sidebarCollapseOne"-->
<!--          >-->
<!--            <i class="flaticon-more-1"></i>-->
<!--            <span class="title">Dashboard</span>-->
<!--          </a>-->
<!--          <div-->
<!--              id="sidebarCollapseOne"-->
<!--              class="accordion-collapse collapse show"-->
<!--              data-bs-parent="#sidebarNavAccordion"-->
<!--          >-->
<!--            <div class="accordion-body">-->
<!--              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link to="/" class="sidebar-sub-menu-link">-->
<!--                    Ecommerce-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link-->
<!--                      to="/project-management"-->
<!--                      class="sidebar-sub-menu-link"-->
<!--                  >-->
<!--                    Project Management-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link to="/support-desk" class="sidebar-sub-menu-link">-->
<!--                    Support Desk-->
<!--                    <span class="new-badge fw-medium d-inline-block">Hot</span>-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link to="/lms-courses" class="sidebar-sub-menu-link">-->
<!--                    LMS Courses-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link to="/crm-system" class="sidebar-sub-menu-link">-->
<!--                    CRM System-->
<!--                    <span class="new-badge fw-medium d-inline-block">Hot</span>-->
<!--                  </router-link>-->
<!--                </li>-->
<!--              </ul>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
<!--        <li class="sidebar-nav-item">-->
<!--          <router-link to="/file-manager" class="sidebar-nav-link d-block">-->
<!--            <i class="flaticon-folder"></i>-->
<!--            <span class="title">File Manager</span>-->
<!--          </router-link>-->
<!--        </li>-->
<!--        <li class="sidebar-nav-item">-->
<!--          <router-link to="/changelog" class="sidebar-nav-link d-block">-->
<!--            <i class="flaticon-award-1"></i>-->
<!--            <span class="title">Changelog</span>-->
<!--          </router-link>-->
<!--        </li>-->
        <!-- APPS -->
        <li class="sub-title sidebar-nav-item">
          <span class="d-block text-uppercase fw-medium">Apps</span>
        </li>
        <!-- Agenda -->
        <li
            v-if="IsGranted('colaborador') || IsGranted('admin')"
            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
              href="#"
              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseAgendas"
              aria-expanded="false"
              aria-controls="sidebarCollapseAgendas"
          >
            <i class="flaticon-list"></i>
            <span class="title">Agenda</span>
          </a>
          <div
              id="sidebarCollapseAgendas"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/agenda"
                      class="sidebar-sub-menu-link"
                  >
                    Tarefas
                  </router-link>
                </li>
                <!--                <li class="sidebar-sub-menu-item">-->
                <!--                  <router-link-->
                <!--                      to="/projects-grid"-->
                <!--                      class="sidebar-sub-menu-link"-->
                <!--                  >-->
                <!--                    Projects Grid-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <!--                <li class="sidebar-sub-menu-item">-->
                <!--                  <router-link-->
                <!--                      to="/create-new-project"-->
                <!--                      class="sidebar-sub-menu-link"-->
                <!--                  >-->
                <!--                    Create New Project-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <!--                <li class="sidebar-sub-menu-item">-->
                <!--                  <router-link-->
                <!--                      to="/project-details"-->
                <!--                      class="sidebar-sub-menu-link"-->
                <!--                  >-->
                <!--                    Project Details-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <!--                <li class="sidebar-sub-menu-item">-->
                <!--                  <router-link to="/to-do-list" class="sidebar-sub-menu-link">-->
                <!--                    To-do List-->
                <!--                  </router-link>-->
                <!--                </li>-->
                <!--                <li class="sidebar-sub-menu-item">-->
                <!--                  <router-link-->
                <!--                      to="/projects-teams"-->
                <!--                      class="sidebar-sub-menu-link"-->
                <!--                  >-->
                <!--                    Teams-->
                <!--                  </router-link>-->
                <!--                </li>-->
              </ul>
            </div>
          </div>
        </li>
        <!-- Processos -->
        <li
            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
        >
          <a
              href="#"
              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseProcessos"
              aria-expanded="false"
              aria-controls="sidebarCollapseProcessos"
          >
            <i class="flaticon-layer-2"></i>
            <span class="title">Processos</span>
          </a>
          <div
              id="sidebarCollapseProcessos"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/processos"
                      class="sidebar-sub-menu-link"
                  >
                    Processos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/containers"
                      class="sidebar-sub-menu-link"
                  >
                    Containers
                  </router-link>
                </li>


<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link-->
<!--                      to="/projects-grid"-->
<!--                      class="sidebar-sub-menu-link"-->
<!--                  >-->
<!--                    Projects Grid-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link-->
<!--                      to="/create-new-project"-->
<!--                      class="sidebar-sub-menu-link"-->
<!--                  >-->
<!--                    Create New Project-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link-->
<!--                      to="/project-details"-->
<!--                      class="sidebar-sub-menu-link"-->
<!--                  >-->
<!--                    Project Details-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link to="/to-do-list" class="sidebar-sub-menu-link">-->
<!--                    To-do List-->
<!--                  </router-link>-->
<!--                </li>-->
<!--                <li class="sidebar-sub-menu-item">-->
<!--                  <router-link-->
<!--                      to="/projects-teams"-->
<!--                      class="sidebar-sub-menu-link"-->
<!--                  >-->
<!--                    Teams-->
<!--                  </router-link>-->
<!--                </li>-->
              </ul>
            </div>
          </div>
        </li>
        <!-- Clientes -->
        <li
            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
            v-if="IsGranted('colaborador')"
        >
          <a
              href="#"
              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseCadastros"
              aria-expanded="false"
              aria-controls="sidebarCollapseCadastros"
          >
            <i class="flaticon-sugar-cubes"></i>
            <span class="title">Cadastros</span>
          </a>
          <div
              id="sidebarCollapseCadastros"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item" v-if="IsGranted('colaborador')" >
                  <router-link
                      to="/clientes"
                      class="sidebar-sub-menu-link"
                  >
                    Clientes
                  </router-link>
                </li>

                <li class="sidebar-sub-menu-item" v-if="IsGranted('colaborador')" >
                  <router-link
                      to="/cargos"
                      class="sidebar-sub-menu-link"
                  >
                    Cargos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-if="IsGranted('colaborador')" >
                  <router-link
                      to="/tipos-containers"
                      class="sidebar-sub-menu-link"
                  >
                    Containers
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-if="IsGranted('colaborador')" >
                  <router-link
                      to="/depots"
                      class="sidebar-sub-menu-link"
                  >
                    Depots
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-if="IsGranted('colaborador')" >
                  <router-link
                      to="/grupos"
                      class="sidebar-sub-menu-link"
                  >
                    Grupos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-if="IsGranted('colaborador')" >
                  <router-link
                      to="/origens-destinos"
                      class="sidebar-sub-menu-link"
                  >
                    Origens/Destinos
                  </router-link>
                </li>
                <li class="sidebar-sub-menu-item" v-if="IsGranted('colaborador')" >
                  <router-link
                      to="/recintos-alfandegados"
                      class="sidebar-sub-menu-link"
                  >
                    Recintos Alfandegados
                  </router-link>
                </li>

              </ul>
            </div>
          </div>
        </li>
        <!-- Relatórios -->
        <li
            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
            v-if="(IsGranted('report_access_all') || IsGranted('admin') || IsGranted('financeiro') || IsGranted('carregamento') || IsGranted('agente_carga'))"
        >
          <a
              href="#"
              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseRelatorios"
              aria-expanded="false"
              aria-controls="sidebarCollapseRelatorios"
          >
            <i class="flaticon-pie-chart"></i>
            <span class="title">Relatórios</span>
          </a>
          <div
              id="sidebarCollapseRelatorios"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item" v-if="IsGranted('report_access_all') || IsGranted('admin')" >
                  <router-link
                      to="/relatorios/prev-faturamento-mensal"
                      class="sidebar-sub-menu-link"
                  >
                    Prev. Faturamento mensal
                  </router-link>
                </li>

                <li class="sidebar-sub-menu-item" v-if="IsGranted('report_access_all') || IsGranted('admin')" >
                  <router-link
                      to="/relatorios/distribuicao-processos-classificacao-responsavel"
                      class="sidebar-sub-menu-link"
                  >
                    Distribuição Processos por Responsável
                  </router-link>
                </li>

                <li class="sidebar-sub-menu-item" v-if="IsGranted('report_access_all') || IsGranted('admin') || IsGranted('financeiro')" >
                  <router-link
                      to="/relatorios/pessoa-conta-bancaria"
                      class="sidebar-sub-menu-link"
                  >
                    Pessoas X Conta Bancária
                  </router-link>
                </li>

                <li class="sidebar-sub-menu-item" v-if="IsGranted('report_access_all') || IsGranted('admin') || IsGranted('carregamento')" >
                  <router-link
                      to="/relatorios/controle-container-lavacao"
                      class="sidebar-sub-menu-link"
                  >
                    Controle Demurrage e Lavação
                  </router-link>
                </li>

<!--                <li class="sidebar-sub-menu-item" v-if="IsGranted('report_access_all') || IsGranted('admin') || IsGranted('colaborador') || IsGranted('agente_carga')  " >-->
<!--                  <router-link-->
<!--                      to="/relatorios/processos-estatisticas"-->
<!--                      class="sidebar-sub-menu-link"-->
<!--                  >-->
<!--                    Processos Estatísticas-->
<!--                  </router-link>-->
<!--                </li>-->

              </ul>
            </div>
          </div>
        </li>
        <!-- Usuários -->
        <li
            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
            v-if="IsGranted('admin')"
        >
          <a
              href="#"
              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseUsuarios"
              aria-expanded="false"
              aria-controls="sidebarCollapseUsuarios"
          >
            <i class="flaticon-shield-1"></i>
            <span class="title">Acesso</span>
          </a>
          <div
              id="sidebarCollapseUsuarios"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/usuarios"
                      class="sidebar-sub-menu-link"
                  >
                    Usuários
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <!-- Configurações  -->
        <li
            class="sidebar-nav-item accordion-item bg-transparent border-0 rounded-0"
            v-if="IsGranted('admin')"
        >
          <a
              href="#"
              class="accordion-button collapsed rounded-0 shadow-none bg-transparent d-block"
              data-bs-toggle="collapse"
              data-bs-target="#sidebarCollapseConfiguracoes"
              aria-expanded="false"
              aria-controls="sidebarCollapseConfiguracoes"
          >
            <i class="flaticon-setting"></i>
            <span class="title">Configurações</span>
          </a>
          <div
              id="sidebarCollapseConfiguracoes"
              class="accordion-collapse collapse"
              data-bs-parent="#sidebarNavAccordion"
          >
            <div class="accordion-body">
              <ul class="sidebar-sub-menu ps-0 mb-0 list-unstyled">
                <li class="sidebar-sub-menu-item">
                  <router-link
                      to="/configuracoes"
                      class="sidebar-sub-menu-link"
                  >
                    Configurações
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup >
import stateStore from "@/utils/store";
import KeycloakService from "@/security/KeycloakService.js";

const stateStoreInstance = stateStore;
const IsGranted = (role) => {
  return KeycloakService.IsGranted(role);
}

</script>
