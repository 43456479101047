<template>
  <BreadCrumb />
  <GrupoList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import GrupoList from "@/views/Grupos/GrupoList.vue";

export default defineComponent({
  name: "ProcessoListPage",
  components: {
    BreadCrumb,
    GrupoList,
  },
});
</script>