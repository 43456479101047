<template>
  <div
    class="DateTimeInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label :for="name" class="d-block text-black fw-semibold mb-10">{{ label }}</label>
    <VueDatePicker
        :name="name"
        :id="name"
        v-model="inputValue"
        :enable-time-picker="true"
        :format="'dd/MM/yyyy HH:mm'"
        :format-locale="ptBR"
        :text-input="true"
        model-type="iso"
        @update:model-value="selectDate"
        @keydown.enter.prevent
        :disabled="disabled"
    />
    <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }"  v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>


<script setup>
import { toRef, ref } from 'vue';
import { useField } from 'vee-validate';


import VueDatePicker from '@vuepic/vue-datepicker';
import { ptBR } from 'date-fns/locale';
import '@vuepic/vue-datepicker/dist/main.css'


const date = ref();

const selectDate = (value) => {
  if(!value) {
    setValue(value)
  } else {
    let dateValue = new Date(value);
    setValue(dateValue.toISOString())
  }

  props.onChanged(value)
}



const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onChanged: {
    type: Function,
    required: false,
    default: () => {}
  }
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
  setValue,
} = useField(name, undefined, {

});
</script>

<style >
  .DateTimeInput input  {
    padding-top: 4px;
    padding-bottom: 4px;
    font-size: 14px;
  }
</style>