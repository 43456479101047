<!-- diDigitadaFormModal.vue -->
<template>
  <div
      class="modal fade "
      id="diDigitadaFormModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">DI Digitada</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicForm ref="modalForm" :schema="formSchema" :show-actions="false" :initial-values="formData" @onSubmit="onSubmit" @onCancel="handleCancel" />
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-outline-light me-3"
              @click="handleCancel"
          >
            Cancelar
          </button>
          <button
              type="button"
              class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              @click="validateAndSave"
          >
            Enviar para conferência
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, defineEmits, reactive} from 'vue';
import { Modal } from 'bootstrap';
import DynamicForm from "@/components/form/DynamicForm.vue";
import * as Yup from "yup";
import {useToast} from "@/plugins/useToast.js";
import Separator from "@/components/form/Separator.vue";


const {showToast} = useToast();

const modalElement = ref(null);
const processoId = ref(null);
const modalForm = ref(null);
let bsModalInstance = null;
let formData = reactive({

});
import SelectInput from "@/components/form/SelectInput.vue";

import ProcessoService from "@/services/ProcessoService.js";
import DateInput from "@/components/form/DateInput.vue";
import RichTextEditorInput from "@/components/form/RichTextEditorInput.vue";

const emit = defineEmits(['cancel', 'save', 'onLoading']);

const getFormSchema = () => {
  return {
    sections: [
      {
        //section: 'Informações Gerais',
        description: "Instruções de despacho",
        col: 'col-12 col-md-12',
        fields: [
          {
            label: 'Data de chegada',
            name: 'dataChegada',
            type: DateInput,
            col: 'col-12 col-md-2',
            rules: Yup.string().required("Campo obrigatório"),
          },
          {
            type: Separator
          },
          {
            label: 'Presença de carga',
            name: 'presencaCarga',
            type: SelectInput,
            col: 'col-12 col-md-6',
            rules: Yup.string().required("Campo obrigatório"),
            options: [{ nome: '', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          },
          {
            label: 'Numerário do cliente',
            name: 'numerarioCliente',
            type: SelectInput,
            col: 'col-12 col-md-6',
            rules: Yup.string().required("Campo obrigatório"),
            options: [{ nome: '', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          },
          {
            label: 'Possui acordo',
            name: 'possuiAcordo',
            type: SelectInput,
            col: 'col-12 col-md-6',
            rules: Yup.string().required("campo obrigatório"),
            options: [{ nome: '', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          },
          {
            label: 'Possui anti-dumping',
            name: 'possuiAntidumpping',
            type: SelectInput,
            col: 'col-12 col-md-6',
            rules: Yup.string().required("campo obrigatório"),
            options: [{ nome: '', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          },
          {
            label: 'Possui ex-tarifário',
            name: 'possuiExTarifario',
            type: SelectInput,
            col: 'col-12 col-md-6',
            rules: Yup.string().required("campo obrigatório"),
            options: [{ nome: '', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          },
          {
            label: 'Possui redução PIS/COFINS',
            name: 'possuiReducaoPisCofins',
            type: SelectInput,
            col: 'col-12 col-md-6',
            rules: Yup.string().required("campo obrigatório"),
            options: [{ nome: '', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          },
          {
            label: 'Possui produto proibido',
            name: 'possuiProdutoProibido',
            type: SelectInput,
            col: 'col-12 col-md-6',
            rules: Yup.string().required("campo obrigatório"),
            options: [{ nome: '', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          },
          {
            label: 'Observações',
            name: 'observacao',
            type: RichTextEditorInput,
            col: 'col-12 ',
            rules: Yup.string().nullable()
          },
        ]
      }
    ],
  }
}

var formSchema = getFormSchema();

// Inicializar o modal quando o componente for montado
onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});



const onSubmit = async (values) => {
  emit('onLoading', true)
  try {
    await ProcessoService.saveDiDigitada(processoId.value, values);

    closeModal();
    emit('save')
  } catch (error)  {
    showToast('Não foi possível salvar a confirmação de chegada', {
      title: 'Erro',
      type: 'error'
    })
  } finally {
    emit('onLoading', false)
  }
}


// Função para abrir o modal
const openModal = async (id, diDigitada) => {
  emit('onLoading',true)

  console.log('openModal')

  formData = {
    dataChegada: null,
    presencaCarga: null,
    numerarioCliente: null,
    possuiAcordo: null,
    possuiAntidumpping: null,
    possuiExTarifario: null,
    possuiReducaoPisCofins: null,
    possuiProdutoProibido: null,
  };

  if (bsModalInstance) {
    processoId.value = id

    try {
      let dadosDiDigitada = await ProcessoService.getDadosDiDigitada(processoId.value)

      Object.assign(formData, dadosDiDigitada);

      console.log('formData', formData)

      formSchema = getFormSchema()
      updateFormValues(formData)
      bsModalInstance.show();

      emit('onLoading', false)

    } catch (err) {

    }
  }
};

// Função para fechar o modal
const closeModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};

// Função para lidar com o cancelamento
const handleCancel = () => {
  closeModal();
  emit('cancel');
};

const validateAndSave = async () => {
  if (modalForm.value) {
    await modalForm.value.submitForm();
  }
};


const updateFormValues = (values) => {
  if (modalForm.value) {
    modalForm.value.updateFormValues(values)
  }
};



defineExpose({
  openModal,
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>



<style scoped>
</style>