<template>
  <div
      class="SeparatorCheckboxInput rounded-1"
      :class="{ 'has-error': !!errorMessage, success: meta.valid }"
      @click="toggleCheckbox"
  >

    <div class="d-flex align-items-center">
      <span class="me-2">
        <input
            :name="name"
            :id="name"
            type="checkbox"
            :value="true"
            :checked="isChecked()"
            :disabled="disabled"
            @input="handleInputChange"
            @blur="handleBlur"
        />
      </span>
      <span>{{ label }}</span>
    </div>
  </div>

</template>

<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

// Props definition
const props = defineProps({
  maxLength: {
    type: Number,
    required: false,
    default: 255
  },
  displayLabel: {
    type: Boolean,
    required: false,
    default: true
  },
  mask: {
    type: String,
    required: false,
    default: undefined,
  },
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onChanged: {
    type: Function,
    required: false,
    default: () => {}
  }
});

// Use `toRef` to create reactive references to `name` prop
const name = toRef(props, 'name');

// VeeValidate configuration
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});

// Handle input change event
const handleInputChange = (e) => {
  const {  checked } = e.target;
  handleChange(checked); // Call the default VeeValidate change handler
  props.onChanged(checked); // Call the function passed via props

};

// Method to get the current value
const obterValorAtual = () => {
  return inputValue.value; // Return the current value from VeeValidate
};


const isChecked = () => {
  return obterValorAtual()
}

const toggleCheckbox = () => {
  const currentChecked = inputValue.value;
  handleChange(!currentChecked); // Inverte o estado do checkbox
  props.onChanged(!currentChecked); // Chama a função passada via props com o novo estado
};

</script>

<style >

.SeparatorCheckboxInput {
  padding: 5px;
  background-color: #f8f8f8;
  padding-bottom: 0.8em;
  padding-top: 0.8em;
  border-top: 1px dashed #d2cfe4;
}

.dark .SeparatorCheckboxInput {
  background-color: #2b2a3f;
  border-top: 1px dashed #d2cfe4;
}

</style>
