<template>
  <BreadCrumb  />
  <NotificacaoForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import NotificacaoForm from "@/views/Notificacoes/NotificacaoForm.vue";

export default defineComponent({
  name: "NovoNotificacaoPage",
  components: {
    BreadCrumb,
    NotificacaoForm,
  },
});
</script>
