<template>
  <div class="card mb-25 border-0 rounded-0 bg-white daily-sales-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <span
          class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis"
      >
        FATURAMENTO CIF MÉDIO MENSAL
      </span>
      <h4 class="fw-black mb-12 lh-1">R$  {{ formatDecimal(mediaMensalMesAtual) }}</h4>
      <span class="fw-bold text-success text-badge d-inline-block" :class="{'text-success': percetualVariacao() > 0, 'text-danger': percetualVariacao() <= 0, 'invisible': situacaoDi != null && !situacaoDi }" >
        {{ formatDecimal(percetualVariacao()) }}%
        <i v-if="percetualVariacao() > 0"  class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"> </i>
        <i v-else  class="flaticon-down-arrow fs-11 lh-1 position-relative top-1"> </i>
      </span>
      <div class="chart">
        <apexchart
            type="bar"
            height="150"
            width="220"
            id="averageDailySalesChart"
            :options="averageDailySalesChart"
            :series="valoresMedios"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script setup >
import {formatDecimal} from "@/utils/numberHelper.js";
import {computed, ref} from "vue";
import {subtrairMeses} from "@/utils/dateHelper.js";
import {monthNumberToShortName} from "@/utils/stringHelper.js";

const mediaMensalMesAtual = computed(() => {
  let valor =  props.mediaMensal.find(el => el.ano === props.year  && el.mes === props.month)

  if(!valor) {
    return 0
  }

  return valor.mediaCifBrl
});




const mediaMensalMesAnterior = computed(() => {
  var dataReferencia = new Date(props.year, props.month - 1,1)




  dataReferencia = subtrairMeses(dataReferencia, 1)

  let valor =  props.mediaMensal.find(el => el.ano === dataReferencia.getFullYear()  && el.mes === dataReferencia.getMonth()+1)

  if(!valor) {
    return 0
  }

  return valor.mediaCifBrl
});


function percetualVariacao () {
  let value = ((mediaMensalMesAtual.value * 100.0 / mediaMensalMesAnterior.value) - 100.0)
  return  value
}

// Dados do gráfico
const sales = [
  {
    name: "Sales",
    data: [34528587.054, 30, 65, 90, 40, 100, 35, 1,1,1,1,1],
  },
];





const valoresMedios = computed(() => {
  return [{
    name: "Média Faturamento CIF",
    data: props.mediaMensal.map((el) => { return el.mediaCifBrl}).reverse(),
    label: props.mediaMensal.map((el) => { return monthNumberToShortName(el.mes) + '/' + el.ano}).reverse(),
  }]
});





// Configurações do gráfico
const averageDailySalesChart = computed(() => ({
  chart: {
    type: "bar",
    height: 150,
    width: 220,
    toolbar: {
      show: false,
    },
  },
  plotOptions: {
    bar: {
      borderRadius: 4,
      horizontal: false,
      columnWidth: "35%",
      borderRadiusApplication: "rounded",
    },
  },
  dataLabels: {
    enabled: false,
  },
  colors: ["#6FD3F7"],
  stroke: {
    show: false,
  },
  xaxis: {
    categories: valoresMedios.value[0].label, // Usa a label do valoresMedios
    labels: {
      show: false,
      style: {
        fontFamily: "Red Hat Display, sans-serif",
        colors: "#9C9AB6",
        fontSize: "14px",
        fontWeight: 500,
      },
    },
    axisTicks: {
      show: false,
    },
  },
  yaxis: {
    show: false,
    tickAmount: 8,
    labels: {
      show: true,
      style: {
        fontFamily: "Red Hat Display, sans-serif",
        colors: ["#9C9AB6"],
        fontSize: "14px",
        fontWeight: 500,
      },
    },
  },
  fill: {
    opacity: 1,
  },
  tooltip: {
    style: {
      fontSize: "14px",
      fontFamily: "Red Hat Display, sans-serif",
    },
    y: {
      formatter: (val) => `R$ ${formatDecimal(val)}`,
    },
  },
  grid: {
    show: false,
    strokeDashArray: 5,
    borderColor: "#f0f0f0",
  },
}));

const props = defineProps({
  mediaMensal: {
    type: Array,
    required: true,
    default: []
  },
  year: {
    type: Number,
    required: true
  },
  month: {
    type: Number,
    required: true
  },
  situacaoDi: {
    type: Boolean,
    required: false,
    default: null
  }
})

</script>
