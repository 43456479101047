<template>
  <div class="RichTextInput">
    <label v-if="displayLabel" :for="name" class="d-block text-black fw-semibold mb-10">{{ label }}</label>
    <div ref="editorContainer" class="quill-editor"></div>
    <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }" v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, toRef } from 'vue';
import Quill from 'quill';
import 'quill/dist/quill.snow.css';
import { useField } from 'vee-validate';

// Props
const props = defineProps({
  maxLength: {
    type: Number,
    required: false,
    default: 255
  },
  displayLabel: {
    type: Boolean,
    required: false,
    default: true
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: String,
    default: '',
  },
  onChanged: {
    type: Function,
    default: () => {},
  },
});

// Emitir eventos
const emit = defineEmits(['update:modelValue']);

// Configurando o Vee-Validate
const name = toRef(props, 'name');
const {
  value: inputValue, // Valor que será sincronizado
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.modelValue, // Inicialize com o valor passado via prop
});

// Referência ao container do editor
const editorContainer = ref(null);
let quill = null;

// Função para lidar com mudanças no editor
const handleTextChange = () => {
  let newValue = quill.root.innerHTML;

  // Sanitiza o conteúdo
  newValue = sanitizeHTML(newValue);

  // Atualizando o VeeValidate
  handleChange(newValue);

  // Emitir o novo valor para o pai
  emit('update:modelValue', newValue);

  // Chamar o callback onChanged passado via props
  props.onChanged(newValue);
};

// Inicializando o editor Quill
onMounted(() => {
  quill = new Quill(editorContainer.value, {
    theme: 'snow',
    modules: {
      toolbar: [
        ['bold', 'italic', 'underline', 'strike'],
        //[{ 'list': 'ordered' }, { 'list': 'bullet' }],
        //['link'],
      ],
    },
  });

  // Definir valor inicial baseado no inputValue
  quill.root.innerHTML = inputValue.value;

  // Escutando mudanças no conteúdo do editor
  quill.on('text-change', handleTextChange);
});

// Sincronizando mudanças externas no inputValue com o editor Quill
watch(inputValue, (newValue) => {
  if (quill && quill.root.innerHTML !== newValue) {
    const sanitizedValue = sanitizeHTML(newValue)
    quill.root.innerHTML = sanitizedValue
  }
});

const sanitizeHTML = (html) => {
  const tempDiv = document.createElement('div');
  tempDiv.innerHTML = html;

  tempDiv.querySelectorAll('*').forEach((el) => {
    el.removeAttribute('style');
  });

  return tempDiv.innerHTML;
};

</script>

<style>
.quill-editor {
  height: 300px; /* Defina a altura do editor */
}
</style>
