export const TipoOperacaoService = {
    getData() {
        return [
            { nome: 'Selecione', id: null },
            { nome: 'Conta própria', id: 1 },
            { nome: 'Conta e Ordem', id: 2 },
            { nome: 'Encomenda', id: 3 },
        ];
    },
    getTiposOperacao() {
        return (this.getData());
    }
};
