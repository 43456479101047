// src/composables/useToast.js
import { Toast } from 'bootstrap';

export function useToast() {
    function showToast(message, options = {}) {


        let header = options.title ?? "Notificação";
        options.delay = options.delay ?? 5000
        options.autohide = options.autohide ?? true;
        options.type = options.type ?? ''

        let icon = ''

        if (options.type == 'success') {
            icon  = '<i class="flaticon-check-mark-1 text-success me-2 text-lg"></i>'
        } else if (options.type == 'error') {
            icon  = '<i class="flaticon-close-1 text-danger me-2 text-lg"></i>'
        } else if (options.type == 'warning') {
            icon  = '<i class="flaticon-warning text-warning me-2 text-lg"></i>'
        }

        // Crie um elemento div que irá conter o Toast
        const toastEl = document.createElement('div');
        toastEl.className = 'toast position-fixed bottom-0 end-0 m-3 z-99';
        toastEl.style = 'z-index:9999';
        toastEl.role = 'alert';
        toastEl.ariaLive = 'assertive';
        toastEl.ariaAtomic = 'true';

        // Configurar o conteúdo do Toast
        toastEl.innerHTML = `
        <div class="toast-header">
            <strong class="me-auto">${header}</strong>
            <button type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
        </div>
        <div class="toast-body d-flex align-items-center">            
            ${icon}
            <span>${message}</span>
        </div>
        `;

        // Adicionar o Toast ao DOM
        document.body.appendChild(toastEl);

        // Instanciar e exibir o Toast usando o Bootstrap
        const toast = new Toast(toastEl, options);
        toast.show();

        // Remover o Toast do DOM após ocultar
        toastEl.addEventListener('hidden.bs.toast', () => {
            toastEl.remove();
        });
    }

    return {
        showToast,
    };
}
