<template>
  <data-grid
      endpoint="/api/depot/lista"
      section="data-grid"
      new-label="Novo Depot"
      new-action="/depots/novo"
      :filterForm="filterForm"
      :default-filters="defaultFilters"
      :columns="defaultColumnsSettings"
      :initial-sort-by="[{ propertyName: 'razaoSocial', sortOrder: 'asc'}]"
      @on-db-click-row="showDetails"
  >
    <template v-slot:[`item-analistaResponsavel`]="{item}">
      <div>{{ item.analistaResponsavel?.pessoa?.nome ? item.analistaResponsavel?.pessoa?.nome : item.analistaResponsavel?.pessoa?.razaoSocial}}</div>
    </template>

    <template v-slot:[`item-comercialResponsavel`]="{item}">
      <div>{{ item.comercialResponsavel?.pessoa?.nome ? item.comercialResponsavel?.pessoa?.nome : item.comercialResponsavel?.pessoa?.razaoSocial}}</div>
    </template>

    <template v-slot:[`item-acoes`]="{item}">
      <div class="d-flex justify-content-center align-items-center">
        <a
            class="dropdown-item cursor-pointer d-flex justify-content-center align-items-center"
            @click.prevent="showDetails(item)"
        >
          <i class="flaticon-edit lh-1 me-8 position-relative top-1"></i>
        </a>
      </div>

    </template>

  </data-grid>
</template>

<script setup>
import router from "@/router/index.js";
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import dataGrid from '@/components/dataGrid/dataGrid.vue'

const defaultFilters = {
  statusFilters: [ ],
  filters: null
}

const defaultColumnsSettings = [
  { text: "CONEXOS ID", value: "conexosId", sortable: false, width: 130},
  { text: "RAZÃO SOCIAL", value: "razaoSocial", sortable: true  },
  { text: "NOME FANTASIA", value: "nome", sortable: true },
  { text: "CNPJ", value: "documentoFederal", sortable: true },
  { text: "AÇÕES", value: "acoes", sortable: false, slot: true },
]
const filterForm = {
  sections: [
    {
      section: "Geral",
      fields: [
        {
          label: 'Razão Social',
          name: 'razaoSocial',
          type: TextInput,
          col: 'col-12',
        },
        {
          label: 'Nome',
          name: 'nome',
          type: TextInput,
          col: 'col-12',
        }
      ]
    },


  ],
};

function showDetails(item, newWindow) {
  const routeData = router.resolve({
    name: 'DepotEdit',
    params: { id: item.id }
  });

  if (newWindow) {
    window.open(routeData.href, '_blank');
  } else {
    router.push(routeData);
  }

}



</script>
