<!-- EnviarFollowModal.vue -->
<template>
  <div
      class="modal fade"
      id="enviarFollowModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Conferência D.I.</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <div style="display: none;">TarefaId: {{ tarefa.id }}</div>

          <div class="row" v-for="(digitacaoDi) in processo.digitacaoDis" v-bind:key="digitacaoDi.id">
            <div class="col-12">
              <div class="card mb-0 border-0 rounded-0 bg-white">
                <div class="card-body letter-spacing">
                  <div class="card-title">Instrução de despacho:</div>
                  <div class="row">
                    <div class="col-12 col-md-6"><strong>Processo:</strong> {{ tarefa.processo }}</div>
                    <div class="col-12 col-md-6"><strong>Data Chegada:</strong> {{  formatDate(digitacaoDi.dataChegada) }}</div>
                    <div class="col-12 col-md-6"><strong>Presença de carga:</strong> {{ booleanToHuman(digitacaoDi.presencaCarga) }}</div>
                    <div class="col-12 col-md-6"><strong>Numerário do cliente:</strong> {{ booleanToHuman(digitacaoDi.numerarioCliente) }}</div>
                    <div class="col-12 col-md-6"><strong>Possui acordo:</strong> {{ booleanToHuman(digitacaoDi.possuiAcordo) }}</div>
                    <div class="col-12 col-md-6"><strong>Possui anti-dumping:</strong> {{ booleanToHuman(digitacaoDi.possuiAntidumpping) }}</div>
                    <div class="col-12 col-md-6"><strong>Possui ex-tarifário:</strong> {{ booleanToHuman(digitacaoDi.possuiExTarifario) }}</div>
                    <div class="col-12 col-md-6"><strong>Possui redução PIS / COFINS:</strong> {{ booleanToHuman(digitacaoDi.possuiReducaoPisCofins) }}</div>
                    <div class="col-12 col-md-6"><strong>Possui produto proibido:</strong> {{ booleanToHuman(digitacaoDi.possuiProdutoProibido) }}</div>
                    <div class="col-12"><strong>Observação:</strong><br/>
                      <div v-html="digitacaoDi.observacao"></div>
                    </div>
                    <div class="col-12 mt-10">
                      <div class="text-sm text-size-sm">
                      Solicitado por {{  digitacaoDi.solicitante.nome }} - {{ formatDateTime(digitacaoDi.createdAt) }}
                      </div>


                    </div>
                  </div>
                </div>
              </div>
            </div>


          </div>

          <DynamicForm ref="modalForm" :schema="formSchema" :show-actions="false" :initial-values="formData" @onSubmit="onSubmit" @onCancel="handleCancel" />
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-outline-light me-3"
              @click="handleCancel"
          >
            Cancelar
          </button>
          <button
              type="button"
              class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              @click="validateAndSave"
          >
            Enviar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, onBeforeMount, defineEmits, reactive, computed} from 'vue';
import { Modal } from 'bootstrap';
import DynamicForm from "@/components/form/DynamicForm.vue";
import * as Yup from "yup";
import HiddenInput from "@/components/form/HiddenInput.vue";
import {booleanToHuman, saudacao} from "@/utils/stringHelper.js";
import {useToast} from "@/plugins/useToast.js";
import ProcessoService from "@/services/ProcessoService.js";
const {showToast} = useToast();

const modalElement = ref(null);
const tarefa = ref({ id: null});
const processo = ref({
  digitacaoDis: []
})

const modalForm = ref(null);
let bsModalInstance = null;
let formData = reactive({

});

//import RichTextEditorInput from "@/components/form/RichTextEditorInput.vue";
import AutoCompleteInput from "@/components/form/AutoCompleteInput.vue";
import agendaService from "@/services/AgendaService.js";
import FileInput from "@/components/form/FileInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";
import {ProcessoSituacaoService} from "@/services/ProcessoSituacaoService.js";
import RichTextEditorInput from "@/components/form/RichTextEditorInput.vue";
import {formatDate, formatDateTime} from "@/utils/dateHelper.js";


const text = ref(`<p>${saudacao()},</p>` +
    '<p>Tudo bem?</p>' +
    '<p>Segue em anexo planilha de embarques para seu acompanhamento e conhecimento.</p>');

const emit = defineEmits(['cancel', 'save', 'onLoading']);

const getFormSchema = () => {
  return {
    sections: [
      {
        //section: 'Informações Gerais',
        description: "Informações sobre a conferência da D.I",
        col: 'col-12 col-md-12',
        fields: [
          {
            name: 'tarefaId',
            type: HiddenInput,

          },
          {
            name: 'processoDigitacaoId',
            type: HiddenInput,
          },
          {
            label: 'Situação',
            name: 'situacao',
            type: SelectInput,
            col: 'col-12 col-md-3',
            //rules: Yup.string().required(),
            options: ProcessoSituacaoService.getSituacaoDiDigitada(),
          },
          {
            label: 'Observação',
            name: 'observacao',
            type: RichTextEditorInput,
            col: 'col-12 col-md-12',
            rules: Yup.string(),
            rows: 10
          },

        ]
      }
    ],
  }
}

var formSchema = getFormSchema();

// Inicializar o modal quando o componente for montado
onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});

const onSubmit = async (values) => {
  emit('onLoading', true)
  HideModal();
  try {
    let result = await ProcessoService.getProcessoDigitacaoDiConferencia(tarefa.value.processoId, values);

    closeModal();
    emit('save')

    showToast('Conferência salva', {
      title: 'Sucesso',
      type: 'success'
    });

  } catch (err) {
    console.log('err', )
    showToast(`Não foi possível salvar a conferência<br/>${err.response.data}`, {
      title: 'Erro',
      type: 'error'
    });
    ShowModal()
  } finally {
     emit('onLoading', false)
  }


}


// Função para abrir o modal
const openModal = async (data) => {

  emit('onLoading',true)
  tarefa.value = data
  formData = {
    tarefaId: null,
    processoDigitacaoId: null,
    observacao : null,
    situacao: null
  };

  if (bsModalInstance) {
    formSchema = getFormSchema()

    processo.value = await ProcessoService.getProcessoDigitacaoDi(tarefa.value.processoId)

    updateFieldValue('tarefaId', tarefa.value.id)
    updateFieldValue('processoDigitacaoId', processo.value.digitacaoDis[0].id)

    bsModalInstance.show();
    emit('onLoading',false)
  }
};

// Função para fechar o modal
const closeModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};

const HideModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};


const ShowModal = () => {
  if (bsModalInstance) {
    bsModalInstance.show();
  }
};


// Função para lidar com o cancelamento
const handleCancel = () => {
  closeModal();
  emit('cancel');
};

const validateAndSave = async () => {
  if (modalForm.value) {
    await modalForm.value.submitForm();
  }
};


const updateFormValues = (values) => {
  if (modalForm.value) {
    modalForm.value.updateFormValues(values)
  }
};


const updateFieldValue = (name, value) => {
  modalForm.value.updateFieldValue(name, value);
};

defineExpose({
  openModal,
});

</script>

<style src="@vueform/multiselect/themes/default.css"></style>
