<template>
  <div
    class="TextInput"
    :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label v-if="displayLabel" :for="name" class="d-block text-black fw-semibold mb-10">{{ label }}</label>
    <textarea
        class="form-control shadow-none rounded-1 text-black"
        :rows="rows"
        :name="name"
        :id="name"
        :type="type"
        :value="inputValue"
        :placeholder="placeholder"
        :disabled="disabled"
        @input="handleChange"
        @blur="handleBlur"
    ></textarea>
    <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }"  v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>


<script setup>
import { toRef } from 'vue';
import { useField } from 'vee-validate';

const props = defineProps({
  displayLabel: {
    type: Boolean,
    required:false,
    default: true
  },
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: String,
    default: undefined,
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  rows: {
    type: Number,
    required: false,
    default: 5
  }
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>