// src/router/clientes.js
import ClienteListPage from "@/views/Clientes/ClienteListPage.vue";
import NovoClientePage from "@/views/Clientes/NovoClientePage.vue";

const clientesBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Clientes',
        to: 'Clientes'
    }
]

export default [
    {
        path: '/clientes',
        name: 'Clientes',
        component: ClienteListPage,
        meta: {
            title: 'Clientes',
            breadcrumb: clientesBreadCrumb
        },
    },
    {
        path: '/clientes/novo',
        name: 'ClienteNovo',
        component: NovoClientePage,
        meta: {
            title: 'Novo Cliente',
            breadcrumb: [
                ...clientesBreadCrumb,
                {
                    label: 'Novo Cliente',
                    to: 'ClienteNovo'
                }
            ]
        }
    },
    {
        path: '/clientes/:id/detalhe',
        name: 'ClienteDetalhes',
        component: NovoClientePage,
        meta: {
            title: 'Detalhes do Cliente',
            breadcrumb: [
                ...clientesBreadCrumb,
                {
                    label: 'Novo Cliente',
                    to: 'ClienteNovo'
                }
            ]
        }
    }
];
