<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>

  <span v-if="isProcessoIMO" class="alert-icon bg-warning rounded-circle p-2"  title="Atenção: Processo IMO">
    <i class="flaticon flaticon-warning"></i>
  </span>
  <DynamicForm ref="dynamicForm" :schema="formSchema" cancel-route="Processos" :initial-values="formData" @onSubmit="onSubmit" />
</template>



<script setup>
import {reactive, ref, onBeforeMount, computed, defineEmits, onMounted, onBeforeUnmount, nextTick} from 'vue';
import DynamicForm from "@/components/form/DynamicForm.vue"
import Loading from 'vue-loading-overlay';

import * as Yup from 'yup';
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import DateInput from "@/components/form/DateInput.vue";

import ClienteService from "@/services/ClienteService.js";
import ProcessoService from "@/services/ProcessoService.js";

import {TipoOperacaoService} from "@/services/TipoOperacaoService.js";
import SelectInput from "@/components/form/SelectInput.vue";
import {CambioService} from "@/services/CambioService.js";
import {useRoute} from "vue-router";
import {TipoCargaService} from "@/services/TipoCargaService.js";
import {ProcessoSituacaoService} from "@/services/ProcessoSituacaoService.js";
import TextAreaInput from "@/components/form/TextAreaInput.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import RepeaterInput from "@/components/form/RepeaterInput.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import {DateTime, Interval} from "luxon";
import {useToast} from "@/plugins/useToast.js";
import {getFormatedFormData} from "@/utils/formHelper.js";
import router from "@/router/index.js";
import Multiselect from "@vueform/multiselect";
const { showToast } = useToast();


const shortcutManager = ref(null);

const dynamicForm = ref(null);

// Initial Data - Valor inicial do formulário
const formData = reactive({
  cargaImo: 0,
  necessitaLi: 1,
  tipoCarga: null,
  cambio: null,
  canalParametrizacao: null,
  situacaoMapaVegetal: 99,
  freeTime: null,
  dataChegada: null,
  veiculo: null,
  viaTransporte: null,

});


const emit = defineEmits(['processLoaded']);

const isLoading = ref(false);
const processoId = ref(false);

onBeforeMount(async ()  => {
  const  currentRoute  = useRoute();

  if (currentRoute.params.hasOwnProperty('id')) {
      isLoading.value = true;
      processoId.value = currentRoute.params.id
      try {
        let processo = await ProcessoService.getProcess(processoId.value);

        // Object.assign(formData, processo);

        updateFormValues(getFormatedFormData(formSchema, processo))

        if (currentRoute.params.hasOwnProperty('containerId')) {
          nextTick(() => {
            const element = document.querySelector("#containers");
            if (element) {
              element.scrollIntoView({ behavior: "smooth", block: "start" });

              setTimeout(() => {
                const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                  top: elementPosition - 90,
                  behavior: "smooth"
                });
              }, 50);

            }
          });
        }

        emit('processLoaded', processo)

      } catch (error) {

        if(error.status == 401) {
          showToast('Você não tem permissão de acesso para executar essa ação.', {
            title: 'Acesso negado',
            type: 'warning'
          });

          router.push({name:"Processos"})
        } else {
          showToast('Desculpe ocorreu um erro inesperado. Tente novamente mais tarde.', {
            title: 'Erro',
            type: 'error'
          });
        }

      } finally {
        isLoading.value = false;
      }
  }
})

const onSubmit = async (values) => {
  isLoading.value = true
  try {
    if (processoId.value != null) {
      await ProcessoService.updateProcess(processoId.value, values)
    } else {
      await ProcessoService.newProcess(values)
    }

    window.location.reload();

    //router.push({name: 'Processos'});
  } catch (ex) {
    showToast('Não foi possível alterar o processo. Tente novamente mais tarde.', {
      title: 'Erro',
      type: 'error'
    });
  }
  isLoading.value = false
}

const calculateDemurrage = (repeaterItem) => {
  let dataDevolucao = repeaterItem.dataDevolucao;

  let dataConsiderada = dataDevolucao ? DateTime.fromISO(dataDevolucao).toUTC() :  DateTime.utc();
    dataConsiderada.startOf('day');

    let freeTime = dynamicForm.value?.getFieldValue('freeTime');
    let dataChegada = dynamicForm.value?.getFieldValue('dataChegada');

    let diasDemurrage = null;

    if (!!freeTime && !!dataChegada) {
      freeTime = parseInt(freeTime)
      dataChegada = DateTime.fromISO(dataChegada).toUTC();

      if (freeTime > 0) {
        freeTime = freeTime - 1;
      }

      let dataLimite = dataChegada.plus({ days: (freeTime)}).toUTC();

      let interval = Interval.fromDateTimes(dataLimite, dataConsiderada);
      diasDemurrage = Math.floor(interval.length('days'))
    }

    return diasDemurrage;
}


const updateFormValue = (name, value) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFieldValue(name, value);
  }
};

const updateFormRepeaterValues = (repeaterName, name, calculateFunction) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateRepeaterCalculatedFieldValue(repeaterName, name, calculateFunction);
  }
};



const updateFormValues = (values) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFormValues(values)
  }
};


const isProcessoIMO = computed( () => {
  return dynamicForm.value?.getFieldValue('cargaImo') == 1
});

const showNumeroDossie = computed(() => {

  return formData.canalParametrizacao !== 1;
});

const showControleLi = computed(() => {
  let necessitaLi = dynamicForm.value?.getFieldValue('necessitaLi')

  return necessitaLi == 1;
});



const showDataVistoria = computed(() => {
  let statusList = [3]

  return statusList.includes(formData.situacaoMapaVegetal)
});

const showDataAgendamentoVistoria = computed(() => {
  let statusList = [2, 3]

  return statusList.includes(formData.situacaoMapaVegetal)
});

const getClienteResponsaveis = async (cliente) => {
  let responsaveis = await ClienteService.getClientesResponsaveis((cliente.id));

  let analista = responsaveis.find(e => e.idCargo == 1);

  if (analista) {
    updateFormValue('analista', {id: analista.idResponsavel, nome: analista.responsavel})
  }

  let comercial = responsaveis.find(e => e.idCargo == 2);
  if (comercial) {
    updateFormValue('comercial', {id: comercial.idResponsavel, nome: comercial.responsavel})
  }
}

const formSchema = {
  sections: [
    {
      section: 'Informações Gerais',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Filial',
          name: 'filial',
          type: RelationInput,
          disabled: true,
          col: 'col-12 col-md-2',
          rules: Yup.object().required("Filial é obrigatória"),
          endpoint: 'filial',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Processo',
          name: 'referenciaInterna',
          type: TextInput,
          col: 'col-12 col-md-2',
          disabled: true,
        },
        {
          label: 'Ref. Conexos',
          name: 'conexosId',
          type: TextInput,
          col: 'col-12 col-md-1',
          disabled: true,
        },
        {
          label: 'Ref. Cliente',
          name: 'referenciaCliente',
          type: TextInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string(),
        },

        {
          label: 'Cliente',
          name: 'cliente',
          type: RelationInput,
          disabled: true,
          col: 'col-12 col-md-5',
          rules: Yup.object().required("Cliente é obrigatório"),
          endpoint: 'pessoa/cliente',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          },
          onChanged: (value) => {
            getClienteResponsaveis(value);
            //updateFormValue('analista', {id:4545, nome: 'sucesso'})
          }
        },
        {
          label: 'N. Invoice',
          name: 'numeroInvoice',
          type: TextInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string(),
        },
        {
          label: 'Produto',
          name: 'produto',
          type: TextInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string(),
        },

        {
          label: 'Tipo Operação',
          name: 'tipoOperacao',
          type: SelectInput,
          col: 'col-12 col-md-2',
          disabled: true,
          rules: Yup.string().required("Tipo Operação é obrigatório"),
          options: TipoOperacaoService.getTiposOperacao(),
        },
        {
          label: 'Finalidade',
          name: 'finalidade',
          type: RelationInput,
          col: 'col-12 col-md-2',
          endpoint: 'finalidade',
          disabled: true,
          rules: Yup.object().required(),
          display: (value) => {
            return `${value.nome}`
          }
        },
        {
          label: 'Incoterm',
          name: 'incoterm',
          type: RelationInput,
          col: 'col-12 col-md-3',
          endpoint: 'incoterm',
          disabled: true,
          display: (value) => {
            return `${value.sigla} (${value.nome})`
          }
        },
        {
          label: 'Cambio',
          name: 'cambio',
          type: RelationInput,
          col: 'col-12 col-md-1',
          endpoint: 'cambio',
          preload: true,
          display: (value) => {
            return `${value.nome}`
          }
        },
        {
          label: 'Exportador',
          name: 'exportador',
          type: RelationInput,
          col: 'col-12 col-md-4',
          endpoint: 'pessoa/exportador',
          disabled: false,
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        /*
        {
          label: 'Responsável',
          name: 'responsavel',
          type: RelationInput,
          col: 'col-12 col-md-2',
          disabled: true,
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        */
        {
          label: 'Analista Responsável',
          name: 'analistaResponsavel',
          type: RelationInput,
          rules: Yup.object().required('Analista Responsável é obrigatório'),
          col: 'col-12 col-md-2',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Procedimento',
          name: 'procedimento',
          type: SelectInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string().required(),
          disabled: false,
          options: ProcessoService.getProcedimentos(true)
        },
        {
          label: 'Comercial Responsável',
          name: 'comercialResponsavel',
          type: RelationInput,
          rules: Yup.object().required('Comercial Responsável é obrigatório'),
          col: 'col-12 col-md-2',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
      ]
    },
    {
      section: 'Informações de carga e transporte',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Modal',
          name: 'viaTransporte',
          type: RelationInput,
          col: 'col-12 col-md-4',
          //rules: Yup.object().required(),
          endpoint: 'via-transporte',
          disabled: false,
          display: (value) => {
            return value.nome
          },
          onChanged: (value) => {
            formData.viaTransporte = value
          }
        },
        {
          label: 'Navio',
          name: 'veiculo',
          type: RelationInput,
          col: 'col-12 col-md-8',
          endpoint: 'veiculo',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Tipo de carga',
          name: 'tipoCarga',
          type: SelectInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string().required(),
          disabled: false,
          options: TipoCargaService.getTiposCarga(),
        },
        {
          label: 'Cargo IMO',
          name: 'cargaImo',
          type: SelectInput,
          col: 'col-12 col-md-2',
          rules: Yup.string().required("Campo obrigatório"),
          options: [{ nome: 'Selecione...', id: null},{ nome: 'Sim', id: 1},{ nome: 'Não', id: 0}],
        },
        {
          label: 'Prev. embarque',
          name: 'previsaoEmbarque',
          type: DateInput,
          col: 'col-12 col-md-2',
          // rules: Yup.string().required(),
        },
        {
          label: 'Embarque',
          name: 'dataEmbarque',
          type: DateInput,
          col: 'col-12 col-md-2',
          disabled: false,
          // rules: Yup.string().required(),
        },
        {
          label: 'Prev. chegada',
          name: 'previsaoChegada',
          type: DateInput,
          col: 'col-12 col-md-2',
          // rules: Yup.string().required(),
        },
        {
          label: 'Chegada',
          name: 'dataChegada',
          type: DateInput,
          col: 'col-12 col-md-2',
          disabled: false,
          onChanged: (value) => {
            updateFormRepeaterValues('containers', 'diasDemurrage', calculateDemurrage)
          }
          // rules: Yup.string().required(),
        },
        // {
        //   label: 'Prev. desemb.',
        //   name: 'previsaoDesembarque',
        //   type: DateInput,
        //   col: 'col-12 col-md-2',
        //   // rules: Yup.string().required(),
        // },
        // {
        //   label: 'Desembarque',
        //   name: 'dataDesembarque',
        //   type: DateInput,
        //   col: 'col-12 col-md-2',
        //   // rules: Yup.string().required(),
        // },
        {
          label: 'Agente de cargas',
          name: 'agenteCarga',
          type: RelationInput,
          col: 'col-12 col-md-6',
          endpoint: 'pessoa/agente-carga',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Armador',
          name: 'companhiaTransporte',
          type: RelationInput,
          col: 'col-12 col-md-6',
          endpoint: 'pessoa/companhia-transporte',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Nº containers',
          name: 'nContainers',
          type: NumberInput,
          decimalNumbers: 0,
          col: 'col-12 col-md-2',
          //rules: Yup.string().required(),
        },
        {
          label: 'Despachante',
          name: 'despachante',
          type: RelationInput,
          col: 'col-12 col-md-6',
          endpoint: 'pessoa/despachante',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Conh. House',
          name: 'numeroHouse',
          type: TextInput,
          col: 'col-12 col-md-1',
          //rules: Yup.string(),
        },
        {
          label: 'Conh. Master',
          name: 'numeroMaster',
          type: TextInput,
          col: 'col-12 col-md-1',
          //rules: Yup.string(),
        },
        {
          label: 'CE Mercante',
          name: 'numeroCe',
          type: TextInput,
          col: 'col-12 col-md-2',
          // rules: Yup.string(),
        },
        {
          label: 'Origem',
          name: 'origem',
          type: RelationInput,
          col: 'col-12 col-md-3',
          endpoint: 'origem-destino',
          rules: Yup.object().required('Campo obrigatório'),
          display: (value) => {
            let tipo = ''
            if(value.porto && !value.aeroporto) {
              tipo = ' (Porto)'
            } else if(!value.porto && value.aeroporto) {
              tipo = ' (Aeroporto)'
            }

            let displayName = value.nomeExibicao ?? value.nome
            let sigla = '';
            if (value.sigla) {
              sigla = ' (' + value.sigla + ')'
            }

            return displayName + sigla  + tipo
          }
        },
        {
          label: 'Destino',
          name: 'destino',
          type: RelationInput,
          col: 'col-12 col-md-3',
          endpoint: 'origem-destino',
          rules: Yup.object().required('Campo obrigatório'),
          display: (value) => {
            let tipo = ''
            if(value.porto && !value.aeroporto) {
              tipo = ' (Porto)'
            } else if(!value.porto && value.aeroporto) {
              tipo = ' (Aeroporto)'
            }

            let displayName = value.nomeExibicao ?? value.nome
            let sigla = '';
            if (value.sigla) {
              sigla = ' (' + value.sigla + ')'
            }

            return displayName + sigla  + tipo
          }
        },
        {
          label: 'Recinto Final',
          name: 'recintoAlfandegado',
          type: RelationInput,
          col: 'col-12 col-md-4',
          rules: Yup.object().required('Campo obrigatório'),
          endpoint: 'recinto-alfandegado',
                display: (value) => {
            let displayName = value.nomeExibicao ?? value.nome
            let codigoSRF = '';
            if (value.codigoSRF) {
              codigoSRF = ' (' + value.codigoSRF + ')'
            }

            return displayName + codigoSRF
          }
        },
        {
          label: 'Free Time',
          name: 'freeTime',
          type: NumberInput,
          decimalNumbers: 0,
          col: 'col-12 col-md-1',
          onChanged: (value) => {
            updateFormRepeaterValues('containers', 'diasDemurrage', calculateDemurrage)
          }
        },
        {
          label: 'Remoção',
          name: 'remocao',
          type: SelectInput,
          col: 'col-12 col-md-1',
          options: ProcessoService.getTiposRemocao(),

        },
      ]
    },
    {
      section: 'Despacho Aduaneiro e informações de valores',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Situação de numerário',
          name: 'situacaoNumerario',
          type: SelectInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoNumerario(),
        },
        {
          label: 'Valor numerário',
          name: 'valorNumerario',
          type: NumberInput,
          col: 'col-12 col-md-3',
          disabled: true,
          //rules: Yup.string().required(),
        },
        {
          label: 'Valor Estimado CIF',
          name: 'valorEstimadoCifMoedaNacional',
          type: NumberInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
        },
        {
          label: 'Valor CIF',
          name: 'valorCifMoedaNacional',
          type: NumberInput,
          disabled: true,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),

        },
        {
          label: 'Valor NF',
          name: 'valorNf',
          type: NumberInput,
          col: 'col-12 col-md-3',
          disabled: true,
          //rules: Yup.string().required(),
        },
        {
          label: 'Necessidade de LI',
          name: 'necessitaLi',
          type: SelectInput,
          col: 'col-12 col-md-2',
          rules: Yup.string().required(),
          options: [{ nome: 'Sim', id: 1},{ nome: 'Não', id: 0}],
        },
        {
          label: 'Controle de LI',
          name: 'licencas',
          type: RepeaterInput,
          visible: showControleLi,
          required: false,
          fields: [
            {
              label: 'Orgão Anuente',
              name: 'orgaoAnuente',
              type: RelationInput,
              col: 'col-12 col-md-3',
              endpoint: 'orgao-anuente',
              display: (value) => {
                return value.nome
              }
            },
            {
              label: 'Número de LI',
              name: 'numero',
              type: TextInput,
              mask: '##/#######-#',
              maxLength: 12,
              col: 'col-12 col-md-2',
            },
            {
              label: 'Data emissão (LI)',
              name: 'dataEmissao',
              type: DateInput,
              col: 'col-12 col-md-2'
            },
            {
              label: 'Status',
              name: 'licencaSituacao',
              type: SelectInput,
              col: 'col-12 col-md-2',
              //rules: Yup.string().required(),
              options: ProcessoSituacaoService.getSituacaoLi(),
            },
            {
              label: 'Data deferimento',
              name: 'dataDeferimento',
              type: DateInput,
              col: 'col-12 col-md-2'
            },
            // {
            //   label: 'Observação',
            //   name: 'observacao',
            //   type: TextAreaInput,
            //   col: 'col-12 col-md-12',
            // },
          ],
          emptyObject: {
            conexosId: null,
            conexosIdNumeroContainer: null,
            numeroContainer: null,
            tipoContainer: null,
            depot: null,
            dataPrevistaDevolucao: null,
            dataDevolucao: null,
            lavacao: null,
            valorDemurrage: null,
            valorLavacao: null,
            observacao: null
          },
          col: 'col-12 col-md-12',
          //rules: Yup.string().required(),

        },
      ]
    },
    {
      section: 'Declaração de Importação',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Número da declaração',
          name: 'declaracaoImportacaoNumero',
          type: TextInput,
          mask: '00/0000000-0',
          col: 'col-12 col-md-2',
          disabled: false,
        },
        {
          label: 'Data de registro',
          name: 'declaracaoImportacaoDataRegistro',
          type: DateInput,
          col: 'col-12 col-md-2',
          disabled: false,
        },
        {
          label: 'Data de desembaraço',
          name: 'declaracaoImportacaoDataDesembaraco',
          type: DateInput,
          col: 'col-12 col-md-2',
          disabled: false,
        },
        {
          label: 'Canal/Parametrização',
          name: 'canalParametrizacao',
          type: SelectInput,
          col: 'col-12 col-md-2',
          options: ProcessoSituacaoService.getCanalParametrizacao(),
          disabled: false
        },
        {
          label: 'Número protocolo',
          name: 'declaracaoImportacaoProtocolo',
          type: TextInput,
          col: 'col-12 col-md-2',
          disabled: false,
        },
        {
          label: 'Número de dossiê',
          name: 'declaracaoImportacaoNumeroDossie',
          type: TextInput,
          col: 'col-12 col-md-2',
          disabled: false,
          visible: showNumeroDossie,
        },
      ]
    },
    {
      section: 'Logistica - Liberação',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Mapa (Madeira)',
          name: 'situacaoMapaVegetal',
          type: SelectInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string().required(),
          disabled: true,
          options: ProcessoSituacaoService.getSituacaoMapaVegetal(),
        },
        {
          label: 'Data de agendamento',
          name: 'mapaDataAgendamento',
          type: DateTimeInput,
          col: 'col-12 col-md-2',
          disabled: true,
          visible: showDataAgendamentoVistoria,
          // rules: Yup.string().required(),
        },
        {
          label: 'Data de vistoria',
          name: 'mapaDataVistoria',
          type: DateTimeInput,
          col: 'col-12 col-md-2',
          disabled: true,
          visible: showDataVistoria,
          // rules: Yup.string().required(),
        },
        {
          label: 'Averbação de Seguro',
          name: 'situacaoSeguro',
          type: SelectInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoSeguro(),
        },
        {
          label: 'Pagamento Frete',
          name: 'situacaoPagamentoFrete',
          type: SelectInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoPagamentoFrete(),
        },
        {
          label: 'Liberação documental',
          name: 'situacaoLiberacaoDocumental',
          type: SelectInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoLiberacaoDocumental(),
        },
        {
          label: 'Exoneração ICMS',
          name: 'situacaoExoneracaoIcms',
          type: SelectInput,
          col: 'col-12 col-md-2',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoExoneracaoICMS(),
        },
      ]
    },
    {
      section: 'Logistica - Carregamento',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Liberação Comex',
          name: 'situacaoLiberacaoComex',
          type: SelectInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoLiberacaoComex(),
        },
        {
          label: 'Faturamento Armazenagem',
          name: 'situacaoPagamentoArmazenagem',
          type: SelectInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoPagamentoArmazenagem(),
        },
        {
          label: 'Status Carregamento',
          name: 'situacaoCarregamento',
          type: SelectInput,
          col: 'col-12 col-md-4',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoCarregamento(),
        },
        {
          label: 'Data de agendamento',
          name: 'carregamentoDataAgendamento',
          type: DateInput,
          col: 'col-12 col-md-2',
          // rules: Yup.string().required(),
        },
        {
          label: 'Data de Carregamento',
          name: 'carregamentoDataCarregamento',
          type: DateInput,
          col: 'col-12 col-md-2',
          // rules: Yup.string().required(),
        },
        {
          label: 'Transportadora',
          name: 'transportadora',
          type: RelationInput,
          col: 'col-12 col-md-4',
          endpoint: 'pessoa/transportadora',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          }
        },
        {
          label: 'Valor do frete',
          name: 'valorFrete',
          type: NumberInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
        },
        {
          label: 'Emissão NF',
          name: 'situacaoEmissaoNf',
          type: SelectInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoEmissaoNF(),
        },
        {
          label: 'Controle de Container',
          name: 'containers',
          required: false,
          type: RepeaterInput,
          emptyMessage: 'Nenhum container informado.',
          fields: [
            {
              label: 'Container',
              name: 'numeroContainer',
              type: TextInput,
              col: 'col-12 col-md-2 max-150',
              // rules: Yup.string().required(),
            },
            {
              label: 'Tipo',
              name: 'tipoContainer',
              type: RelationInput,
              col: 'col-12 col-md-2 max-100',
              endpoint: 'tipo-container',
              display: (value) => {
                return value.sigla ?? value.nome
              }
            },
            {
              label: 'Depot',
              name: 'depot',
              type: RelationInput,
              col: 'col-12 col-md-2',
              endpoint: 'depot',
              display: (value) => {
                return value.nome ?? value.razaoSocial
              }
            },
            {
              label: 'Data devolução',
              name: 'dataDevolucao',
              type: DateInput,
              col: 'col-12 col-md-2 max-130',
              onChanged: (value) => {
                updateFormRepeaterValues('containers', 'diasDemurrage', calculateDemurrage)
              }
            },
            {
              label: 'Dias Dem.',
              name: 'diasDemurrage',
              type: NumberInput,
              decimalNumbers: 0,
              col: 'col-12 col-md-1 col-md-5-percent',
              title: 'Dias Demurrage',
              disabled: true,
            },
            {
              label: 'Demurrage',
              name: 'demurrage',
              type: SelectInput,
              col: 'col-12 col-md-1',
              rules: Yup.string().required(),
              options: [
                  { nome: '', id: null},
                  { nome: 'Devolvido no Prazo', id: 1},
                  { nome: 'Dispute em análise', id: 2},
                  { nome: 'Dispute aprovado', id: 3},
                  { nome: 'Dispute recusado', id: 4},
                  { nome: 'Demurrage devido', id: 5}
              ],
            },
            {
              label: 'Valor Dem.',
              name: 'valorDemurrage',
              type: NumberInput,
              decimalNumbers: 2,
              col: 'col-12 col-md-2 max-100'
            },
            {
              label: 'Dt. Pg. Demurrage',
              name: 'dataPagamentoDemurrage',
              type: DateInput,
              col: 'col-12 col-md-2 max-130',
            },
            {
              label: 'Lavação',
              name: 'lavacao',
              type: SelectInput,
              col: 'col-12 col-md-2 max-80',
              rules: Yup.string().required(),
              options: [{ nome: '', id: null},{ nome: 'Sim', id: 1},{ nome: 'Não', id: 2},{ nome: 'Isento', id: 3}],
            },
            {
              label: 'Valor Lavação',
              name: 'valorLavacao',
              type: NumberInput,
              decimalNumbers: 2,
              col: 'col-12 col-md-2 max-100'
            },
            {
              label: 'Dt. Pg. Lavação',
              name: 'dataPagamentoLavacao',
              type: DateInput,
              col: 'col-12 col-md-2 max-130',
            },
            // {
            //   label: 'Observação',
            //   name: 'observacao',
            //   type: TextAreaInput,
            //   col: 'col-12 col-md-12',
            // },
          ],
          emptyObject: {
            conexosId: null,
            conexosIdNumeroContainer: null,
            numeroContainer: null,
            tipoContainer: null,
            depot: null,
            dataPrevistaDevolucao: null,
            dataDevolucao: null,
            lavacao: null,
            demurrage: null,
            valorDemurrage: null,
            dataPagamentoDemurrage: null,
            valorLavacao: null,
            dataPagamentoLavacao: null,
            observacao: null
          },
          col: 'col-12 col-md-12',
        },
      ]
    },
    //   Documentos
    {
      section: 'Controle de documentos',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Situação de Invoice(s)',
          name: 'situacaoInvoice',
          type: SelectInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoInvoice(),
        },
        {
          label: 'Situação Packing List(s)',
          name: 'situacaoPackingList',
          type: SelectInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoInvoice(),
        },
        {
          label: 'Localização do conhecimento original',
          name: 'situacaoRecebimentoBl',
          type: SelectInput,
          col: 'col-12 col-md-3',
          //rules: Yup.string().required(),
          options: ProcessoSituacaoService.getSituacaoRecebimentoBlOriginal(),
        },
        {
          label: 'Número rastreio BL',
          name: 'numeroRastreioBlOriginal',
          type: TextInput,
          col: 'col-12 col-md-2',
          // rules: Yup.string(),
        },
        {
          label: 'Observação localização BL Original',
          name: 'observacaoSituacaoRecebimentoBl',
          type: TextInput,
          col: 'col-12 col-md-6',
          // rules: Yup.string(),
        },
      ]
    },
    {
      section: 'Observações gerais',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Data Próxima Ação',
          name: 'dataProximaAcao',
          type: DateInput,
          col: 'col-12 col-md-2',
          // rules: Yup.string().required(),
        },
        {
          label: 'Observações gerais',
          name: 'observacao',
          type: TextAreaInput,
          col: 'col-12 col-md-12',
          // rules: Yup.string().required(),
        },
      ]
    }
  ],
};

</script>


<style scoped>

.alert-icon {
  display: inline-block;
  position: fixed;
  z-index: 99;
  right: 5px;
  top: calc(30% - 15px)
}

.alert-icon i {
  font-size: 1.2em;
}

</style>
