import Httpservice from "@/services/httpservice.js";
import {max} from "@popperjs/core/lib/utils/math.js";

const API_URL = import.meta.env.VITE_API_URL;

const getOptions = async (endpoint,searchTerm, filterParams) => {
    try {
        const params = new URLSearchParams();

        params.append('pageNumber', 1);
        params.append('pageSize', 20);

        if(searchTerm) {
            params.append('searchTerm', searchTerm);
        }

        if (filterParams && typeof filterParams === 'object') {
            for (const [key, value] of Object.entries(filterParams)) {
                params.append(key, value);
            }
        }

        const response = await Httpservice.getAxiosClient().get(`${API_URL}${endpoint}`,{
            params: params
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao obter opcoes:', error);
        throw error;
    }
};

const newFiles = async (files, maxWidth) => {
    try {
        const formData = new FormData();
        files.forEach((file) => {
            formData.append('files', file); // 'files' deve corresponder ao nome esperado pelo servidor
        });


        if(maxWidth) {
            formData.append('maxWidth', maxWidth)
        }


        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/arquivo/upload`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data' // Importante para o envio de arquivos
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao fazer upload do arquivo', error);
        throw error;
    }
};

const downloadFile = async (originalFileName, fileName, fileCheck) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/arquivo/download/${fileName}/${fileCheck}`, {
            responseType: 'blob'
        });

        // Cria um link temporário para baixar o arquivo
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;

        // Extrai o nome original do arquivo do cabeçalho Content-Disposition
        const contentDisposition = response.headers['content-disposition'];

        if (contentDisposition) {
            const fileNameMatch = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/);
            if (fileNameMatch && fileNameMatch[1]) {
                originalFileName = fileNameMatch[1].replace(/['"]/g, '');
            }
        }

        // Define o nome do arquivo como o original enviado pelo servidor
        link.setAttribute('download', originalFileName);
        document.body.appendChild(link);
        link.click();

        // Remove o link após o download
        document.body.removeChild(link);
    } catch (error) {
        console.error("Erro ao baixar o arquivo:", error);
    }
}







export default {
    getOptions,
    newFiles,
    downloadFile

};