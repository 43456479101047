<template>
  <div
      class="pagination-area d-md-flex mt-15 mt-md-25 mb-0 justify-content-between align-items-center"
  >
    <p class="mb-0 text-paragraph">
      Mostrando <span class="fw-bold">{{ itemInicial }} </span>  <span v-if="itemInicial != itemFinal || itemInicial != 0"><span class="fw-bold"> até {{ itemFinal }} </span> </span> de
      <span class="fw-bold">{{ totalItems }}</span> resultados
    </p>



    <nav class="mt-15 mt-md-0">
      <p class="mb-0 text-paragraph me-15">
        <span>Mostrar:</span>
        <span>
        <select class="select-input" @input="onPorPaginaChanged">
          <option v-for="option in perPageOptions" :value="option.id"  :selected="option.id == porPagina"  :key="option.id" >{{ option.nome }}</option>
        </select>
      </span>
      </p>
      <ul class="pagination mb-0">
        <li v-if="paginaAtual - 1 > 0" class="page-item" @click.prevent="previousPage()">
          <a class="page-link" href="#" aria-label="Previous">
            <i class="flaticon-chevron-1"></i>
          </a>
        </li>

        <li
            v-for="(nPage) in pagesOptions"
            v-bind:key="nPage"
            class="page-item" @click.prevent="setPage(nPage)">
          <a class="page-link" :class="{active: paginaAtual == nPage}" href="#">{{ nPage }}</a>
        </li>

        <li v-if="paginaAtual + 1 <= totalPaginas" class="page-item" @click.prevent="nextPage()">
          <a class="page-link" href="#" aria-label="Next">
            <i class="flaticon-chevron"></i>
          </a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script setup>
import {computed} from "vue";
import {number} from "yup";
import SelectInput from "@/components/form/SelectInput.vue";
const emit = defineEmits(['onPagination']);
const props = defineProps({
  totalItems: {
    type: Number,
    required: true,
  },
  paginaAtual: {
    type: Number,
    required: true,
  },
  totalPaginas: {
    type: Number,
    required: true,
  },
  porPagina: {
    type: Number,
    required: false,
    default: 20,
  },
  itemInicial: {
    type: Number,
    required: false,
    default: 20,
  },
  itemFinal: {
    type: Number,
    required: false,
    default: 20,
  },
  showPages: {
    type: Number,
    required: false,
    default : 5,
  }
});


const perPageOptions = [
  {id: 10, nome  :10},
  {id: 20, nome:20},
  {id: 50, nome:50},
  {id: 100, nome:100}
];


const setPage = (page) => {
  emit('onPagination', {
    paginaAtual: page,
    porPagina: props.porPagina,
  })
}

const nextPage = () => {
  if (props.paginaAtual + 1  <= props.totalPaginas) {
    emit('onPagination', {
      paginaAtual: props.paginaAtual + 1,
      porPagina: props.porPagina,
    })
  }
}

const previousPage = () => {
  if (props.paginaAtual - 1 > 0) {
    emit('onPagination', {
      paginaAtual: props.paginaAtual - 1,
      porPagina: props.porPagina,
    })
  }

}

const pagesOptions = computed(() => {
  if (props.totalPaginas < props.showPages) {
    return props.totalPaginas;
  }

  const pages = [];

  // Calcula o primeiro e último índice da janela de páginas visíveis
  let startPage = Math.max(1, props.paginaAtual - Math.floor(props.showPages / 2));
  let endPage = startPage + props.showPages - 1;

  // Ajusta se o final exceder o total de páginas
  if (endPage > props.totalPaginas) {
    endPage = props.totalPaginas;
    startPage = Math.max(1, props.totalPaginas - props.showPages + 1);
  }

  // Preenche o array de páginas
  for (let i = startPage; i <= endPage; i++) {
    pages.push(i);
  }

  return pages;


});

const onPorPaginaChanged = (e) => {
  emit('onPagination', {
    paginaAtual: 1,
    porPagina: parseInt(e.target.value),
  })
}

</script>

<style scoped>
   select.select-input {
    border: 1px solid #F2F1F9;
    border-radius: 4px;
     padding-top: 7px;
     padding-bottom: 7px;
     margin-left: 4px;
  }

  .pagination-area nav, .pagination-area p:last-child {
    margin-left: auto; /* Empurra o seletor e a paginação para a direita */
    display: flex;
    align-items: center;
  }

</style>