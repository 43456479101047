<template>
  <div class="vessel-call-details">
    <h3>Chamadas de Porto</h3>
    <div v-for="(call, index) in vesselCalls" :key="index" class="call-item">
      <h4>{{ call.facility.locationName }} ({{ call.facility.UNLocationCode }})</h4>
      <p><strong>Cidade:</strong> {{ call.facility.cityName }}</p>
      <p><strong>País:</strong> {{ call.facility.countryName }}</p>
      <p><strong>Terminal Code:</strong> {{ call.facility.carrierTerminalCode }}</p>

      <h5>Serviço de Transporte</h5>
      <p><strong>Voyage Inbound:</strong> {{ call.transport.inboundService.carrierVoyageNumber }} - {{ call.transport.inboundService.carrierServiceName }}</p>
      <p><strong>Voyage Outbound:</strong> {{ call.transport.outboundService.carrierVoyageNumber }} - {{ call.transport.outboundService.carrierServiceName }}</p>

      <h5>Programação de Eventos</h5>
      <ul>
        <li v-for="(schedule, sIndex) in call.callSchedules" :key="sIndex">
          <strong>{{ transportEventTypeCodeDescription(schedule.transportEventTypeCode) }}:</strong> {{ formatDateTime(schedule.classifierDateTime) }} ({{ eventClassifierCodeDescription(schedule.eventClassifierCode) }})
        </li>
      </ul>
    </div>
  </div>
</template>

<script setup>

import {formatDateTime} from "@/utils/dateHelper.js";

const props = defineProps({
  vesselCalls: {
    type: Array,
    required: true,
    default: []
  }
});


const eventClassifierCodeDescription = (value) => {
  let description = '';
  switch (value) {
    case 'EST':
        description = 'Estimado'
      break;
    case 'ACT':
      description = 'Confirmado'
      break;

  }

  return description;
}

const transportEventTypeCodeDescription = (value) => {
  let description = '';
  switch (value) {
    case 'ARRI':
      description= 'Chegada'
      break;
    case 'DEPA':
      description= 'Partida'
      break;

  }

  return description;
}

</script>

<style scoped>
.vessel-call-details {
  margin-top: 20px;
}
.call-item {
  padding: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 20px;
  background: #fff;
}
</style>
