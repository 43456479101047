<template>
  <BreadCrumb  />
  <ConfiguracaoForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import ConfiguracaoForm from "@/views/Configuracoes/ConfiguracaoForm.vue";

export default defineComponent({
  name: "NovoProcessoPage",
  components: {
    BreadCrumb,
    ConfiguracaoForm,
  },
});
</script>
