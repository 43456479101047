<!-- presencaCargaFormModal.vue -->
<template>
  <div
      class="modal fade "
      id="presencaCargaFormModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Presença da Carga</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicForm ref="modalForm" :schema="formSchema" :show-actions="false" :initial-values="formData" @onSubmit="onSubmit" @onCancel="handleCancel" />
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-outline-light me-3"
              @click="handleCancel"
          >
            Cancelar
          </button>
          <button
              type="button"
              class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              @click="validateAndSave"
          >
            Confirmar Presença de Carga
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted,  defineEmits, reactive} from 'vue';
import { Modal } from 'bootstrap';
import DynamicForm from "@/components/form/DynamicForm.vue";
import * as Yup from "yup";
import {saudacao} from "@/utils/stringHelper.js";
import {useToast} from "@/plugins/useToast.js";

import {getPessoa} from "@/utils/formHelper.js";

const {showToast} = useToast();

const modalElement = ref(null);
const processoId = ref(null);
const presencaCargaId = ref(null);
const modalForm = ref(null);
let bsModalInstance = null;
let formData = reactive({

});

import RichTextEditorInput from "@/components/form/RichTextEditorInput.vue";
import DateTimeInput from "@/components/form/DateTimeInput.vue";
import RelationInput from "@/components/form/RelationInput.vue";
import SelectInput from "@/components/form/SelectInput.vue";

import ProcessoService from "@/services/ProcessoService.js";

const text = ref(`<p>${saudacao()},</p>` +
    '<p>Tudo bem?</p>' +
    '<p>Segue em anexo planilha de embarques para seu presencaCarga e conhecimento.</p>');

const emit = defineEmits(['cancel', 'save', 'onLoading']);

const getFormSchema = () => {
  return {
    sections: [
      {
        //section: 'Informações Gerais',
        description: "Informações sobre da presença de carga",
        col: 'col-12 col-md-12',
        fields: [
          {
            label: 'Data Presença de Carga',
            name: 'dataPresencaCarga',
            type: DateTimeInput,
            col: 'col-12 col-md-3',
             rules: Yup.date().required("Campo obrigatório"),
          },
          {
            label: 'Local Chegada',
            name: 'destino',
            type: RelationInput,
            col: 'col-12 col-md-3',
            rules: Yup.object().required("Responsável é obrigatório"),
            endpoint: 'origem-destino',
            display: (value) => {
              let tipo = ''
              if(value.porto && !value.aeroporto) {
                tipo = ' (Porto)'
              } else if(!value.porto && value.aeroporto) {
                tipo = ' (Aeroporto)'
              }

              let displayName = value.nomeExibicao ?? value.nome
              let sigla = '';
              if (value.sigla) {
                sigla = ' (' + value.sigla + ')'
              }

              return displayName + sigla  + tipo
            }
          },
          {
            label: 'Recinto Final',
            name: 'recintoFinal',
            type: RelationInput,
            col: 'col-12 col-md-6',
            rules: Yup.object().required("Campo obrigatório"),
            endpoint: 'recinto-alfandegado',
            display: (value) => {
              let displayName = value.nomeExibicao ?? value.nome
              let codigoSRF = '';
              if (value.codigoSRF) {
                codigoSRF = ' (' + value.codigoSRF + ')'
              }

              return displayName + codigoSRF
            }
          }
        ]
      }
    ],
  }
}

var formSchema = getFormSchema();

// Inicializar o modal quando o componente for montado
onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});



const onSubmit = async (values) => {
  emit('onLoading', true)
  try {
    await ProcessoService.savePresencaCarga(processoId.value, values);

    closeModal();
    emit('save')
  } catch (error)  {
    showToast('Não foi possível salvar a presença de carga', {
      title: 'Erro',
      type: 'error'
    })
  } finally {
    emit('onLoading', false)
  }
}


// Função para abrir o modal
const openModal = async (id, presencaCarga) => {
  emit('onLoading',true)

  console.log('openModal presenca de carga')

  formData = {
    dataPresencaCarga: new Date(),
  };

  if (bsModalInstance) {
    processoId.value = id

    try {
      let dadosPresencaCarga = await ProcessoService.getDadosPresencaCarga(processoId.value)

      Object.assign(formData, dadosPresencaCarga);

      formSchema = getFormSchema()
      updateFormValues(formData)
      bsModalInstance.show();

      emit('onLoading', false)
    } catch (err) {
      console.log('erroooo', err)
    }
  }
};

// Função para fechar o modal
const closeModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};

// Função para lidar com o cancelamento
const handleCancel = () => {
  closeModal();
  emit('cancel');
};

const validateAndSave = async () => {
  if (modalForm.value) {
    await modalForm.value.submitForm();
  }
};


const updateFormValues = (values) => {
  if (modalForm.value) {
    modalForm.value.updateFormValues(values)
  }
};



defineExpose({
  openModal,
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>



<style scoped>
</style>