<template>
  <div class="mb-15 mb-md-20 mb-lg-25">
    <!-- Label do campo de upload -->
    <label :for="name" class="form-label fw-medium">{{ label }}</label>

    <!-- Input de arquivo -->
    <input
        class="form-control fs-15 text-black shadow-none"
        type="file"
        :id="name"
        :name="name"
        :multiple="multiple"
        :accept="acceptedExtensions"
        :disabled="disabled"
        @change="handleFileChange"
    />
    <div v-if="inputValue && inputValue.length > 0">
      <!--      -->

      <div  v-for="(file, index) in inputValue" :key="index"
            :class="{'hover': allowDownload(file) }"
          class="pt-15 pt-md-20 pb-15 pb-md-20 ps-15 ps-sm-20 ps-md-25 ps-lg-30 pe-15 pe-sm-20 pe-md-25 pe-lg-30 d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center" @click="downloadFile(file)">
          <img
              :src="'/images/icon/xlsx.png'"
              width="45px"
              class="d-block ms-auto me-auto mb-15 small" :alt="file.fileOriginalName"/>
          <div class="ms-12"><span
              class="d-block fs-md-15 fs-lg-16 text-black fw-medium mb-1">{{ file.fileOriginalName}}</span><span
              class="d-block text-muted">{{ FormatBytes(file.fileSize)}}</span></div>
        </div>
        <div >
          <div @click.prevent="removeFile(index)" class="btn btn-outline-danger btn-sm  d-flex align-items-center" >
            <i class="flaticon-delete lh-1 me-8 position-relative top-1"></i></div>
        </div>
      </div>
  </div>


  <!-- Mensagem de erro ou sucesso -->
  <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }"
     v-show="errorMessage || meta.valid">
    {{ errorMessage || successMessage }}
  </p>
  </div>
</template>

<script setup>
import {ref, toRef, watch} from 'vue';
import {useField} from 'vee-validate';
import FileService from "@/services/FileService.js";
import {FormatBytes} from "@/utils/stringHelper.js";


// Definir propriedades (props)
const props = defineProps({
  acceptedExtensions: {
    type: String,
    required: false,
    default: '.jpg,.png,.jpeg',
  },
  maxWidth: {
    type: Number,
    required: false,
    default: null,
  },
  multiple: {
    type: Boolean,
    default: false // Valor padrão para permitir apenas um arquivo
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  initialFiles: {
    type: Array,
    default: () => [],
  },
  allowDownloadTemporary: {
    type: Boolean,
    required: false,
    default: true
  }
});

// Referência ao nome do campo
const name = toRef(props, 'name');

// Estado para armazenar arquivos carregados
const uploadedFiles = ref([]);

// Definir o estado inicial de uploadedFiles com base na propriedade initialFiles
watch(() => props.initialFiles, (newFiles) => {
  uploadedFiles.value = newFiles;
}, {immediate: true});

// Função chamada ao selecionar arquivo
const handleFileChange = async (event) => {
  const files = event.target.files;
  if (files.length > 0) {
    try {
      const result = await FileService.newFiles(Array.from(files), props.maxWidth);

      if(props.multiple) {
        if (inputValue.value == undefined) {
          uploadedFiles.value = [...result];
        } else {
          uploadedFiles.value = [...inputValue.value, ...result];
        }
      } else {
        uploadedFiles.value = [...result];
      }
      event.target.value = '';
      handleChange(uploadedFiles.value);
    } catch (error) {
      console.error('Erro ao enviar os arquivos:', error);
    }
  }
};

// Função para remover arquivo da lista
const removeFile = (index) => {
  uploadedFiles.value.splice(index, 1); // Remove o arquivo selecionado
  handleChange(uploadedFiles.value)
};

const allowDownload = (file) => {
  if (props.allowDownloadTemporary == false && file.temporary == true) {
    return false;
  }

  return true;
}

const downloadFile = async (file) => {
  if (!allowDownload(file)) {
    return;
  }

  var fileOriginalName = file.fileOriginalName
  var fileName = file.fileName
  var fileCheck = file.fileCheck

  try {
    const result = await FileService.downloadFile(fileOriginalName, fileName, fileCheck); // Converte o FileList em um array

  } catch (error) {
    console.error('Erro ao enviar os arquivos:', error);
  }
}

// useField para controle de validação
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<style scoped>
ul {
  list-style-type: none;
  padding: 0;
}

li {
  margin: 5px 0;
}

.hover {
  cursor: pointer;
}
</style>
