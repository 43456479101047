<template>
  <div class="filter-overlay"v-if="filterOpened"  >
    <div class="filter-pannel bg-white p-15 p-sm-20 p-md-25 w-100"> <!-- Nova div que ocupa 100% da largura -->
      <div class="filter-content">
        <DynamicForm ref="filterForm" :schema="filterSchema" :initial-values="filters" @onSubmit="onSubmit" :show-actions="false" />
      </div>

      <div class="filter-actions p-15 p-sm-20 p-md-25 w-100">
        <button class="btn btn-sm btn-outline-light me-3" @click="onCancel()" >Cancelar</button>
        <button
            type="button"
            class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
            @click="validateAndSave()"
        >
          Aplicar filtros
        </button>
      </div>
    </div>

  </div>
</template>

<script setup>
import DynamicForm from "@/components/form/DynamicForm.vue";
import {shallowRef} from "vue";

const filterForm = shallowRef(null);
const emit = defineEmits(['onClearFilters', 'onSubmit', 'onCancel']);
const props = defineProps({
  filterOpened: {
    type: Boolean,
    required: true,
    default: false
  },
  filters: {
    type: Object,
    required: false,
    default : null,
  },
  filterSchema: {
    type: Object,
    required: true,
    default: {}
  }
});


const clearFilters = () => {
  emit('onClearFilters')
}

const validateAndSave = async () => {
  if (filterForm.value) {
    await filterForm.value.submitForm();
  }
};

const onSubmit = async (values) => {
  emit('onSubmit',values)
}




const onCancel = async () => {
  emit('onCancel')
}

</script>



<style scoped>

.filter-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5); /* Cor de fundo preta com 50% de opacidade */
  z-index: 9998; /* Colocado atrás do filtro mas na frente do conteúdo principal */
}

.filter-pannel {
  position: fixed;
  top:0;
  bottom: 0;
  right: 0;
  max-width: 500px;
  width: 100%;

  display: flex;
  flex-direction: column; /* ou row, dependendo do layout desejado */
  height: 100vh; /* ou qualquer altura desejada */

}

.filter-content {
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
}
.filter-actions {
  height: 60px; /* Altura fixa */
}

</style>