<template>
  <div  class="bg-white p-15 p-sm-20 p-md-25 w-100">
    <div>
      <div  v-for="(filter, i) in filterItems" :key="i" class="d-inline-block bg-gray rounded-1 fs-12 fw-medium p-8 me-6">
        <strong>{{ filter.label }}:&nbsp;</strong>

        <span v-if="filter.type?.__name === 'MultiSelectInput'">
          <span v-for="value in filter.value">
            <span>
              {{ value.name ?? value.nome }}
            </span>
            <button @click="onRemoveMultipleItem(filter, value)" type="button" class="bg-transparent p-0 border-0 lh-1 transition">
            <i class="p-1 small flaticon-close"></i>
            </button>
          </span>
        </span>
        <span v-else>
          <span v-if="filter.type?.__name == SelectInput">
            {{ getFieldValueLabel(filter.field, filter.value) }}
          </span>
          <span v-else-if="filter.type?.__name === 'MultiSelectInput'">
            Teste
          </span>
          <span v-else>{{ filter.value }} </span>
          <button @click="onRemoveItem(filter)" type="button" class="bg-transparent p-0 border-0 lh-1 transition">
          <i class="p-1 small flaticon-close"></i>
          </button>
        </span>
      </div>
      <button class="d-inline-block btn btn-sm btn-outline-light me-3" @click="clearFilters()" >Limpar filtros</button>
    </div>

  </div>
</template>

<script setup>
import {computed} from "vue";
import RelationInput from "@/components/form/RelationInput.vue";
import SelectInput from "../form/SelectInput.vue";
import MultiSelectInput from "@/components/form/MultiSelectInput.vue";

const emit = defineEmits(['onClearFilters','onRemoveItem', 'onRemoveMultipleItem']);
const props = defineProps({
  filters: {
    type: Object,
    required: false,
    default : null,
  },
  formSchema: {
    type: Object,
    required: true,
    default: () => {}
  }
});

const clearFilters = () => {
  emit('onClearFilters')
}

const onRemoveItem = (item) => {
  emit("onRemoveItem", item)
}

const onRemoveMultipleItem = (item, value) => {
  emit("onRemoveMultipleItem", item,value)
}


const getFieldValueLabel = (fieldName, value) => {
  let settings = getFieldSettings(fieldName);
  let option = settings.options.find(el => el.id == value)

  return option.nome
}

const getFieldSettings = (fieldName) => {
  for (const section of props.formSchema.sections) {
    for (const field of section.fields) {
      if (field.name === fieldName) {
        return field;
      }
    }
  }

  return null;
}

const filterItems = computed(() => {
  const fieldSettings = props.formSchema.sections.reduce((acc, section) => {
    section.fields.forEach(field => {
      acc[field.name] = field;
    });
    return acc;
  }, {});
  let formatedFilters = [];

  for (let i in fieldSettings ) {
    let field = fieldSettings[i];

    if(props.filters && props.filters[field.name]) {
      let item = {
        label: field.label,
        field: field.name,
        type: field.type
      };

      if (field.type === RelationInput) {
        item.value = field.display(props.filters[field.name])
      } else {
        item.value = props.filters[field.name]
      }

      formatedFilters.push(item)
    }

  }

  return formatedFilters
})

</script>