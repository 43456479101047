// src/router/processos.js
import ProcessoListPage from "@/views/Processos/ProcessoListPage.vue";
import NovoProcessoPage from "@/views/Processos/NovoProcessoPage.vue";

const processosBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Processos',
        to: 'Processos'
    }
]

export default [
    {
        path: '/processos',
        name: 'Processos',
        component: ProcessoListPage,
        meta: {
            title: 'Processos',
            breadcrumb: processosBreadCrumb
        },
    },
    {
        path: '/processos/novo',
        name: 'ProcessoNovo',
        component: NovoProcessoPage,
        meta: {
            title: 'Novo Processo',
            breadcrumb: [
                ...processosBreadCrumb,
                {
                    label: 'Novo Processo',
                    to: 'ProcessoNovo'
                }
            ]
        }
    },
    {
        path: '/processos/:id/detalhe',
        name: 'ProcessoDetalhes',
        component: NovoProcessoPage,
        meta: {
            title: 'Detalhes do Processo',
            breadcrumb: [
                ...processosBreadCrumb,
                {
                    label: 'Detalhes',
                    to: 'ProcessoNovo'
                }
            ]
        }
    },
    {
        path: '/processos/:id/detalhe/container/:containerId',
        name: 'ProcessoDetalhesContainer',
        component: NovoProcessoPage,
        meta: {
            title: 'Detalhes do Processo',
            breadcrumb: [
                ...processosBreadCrumb,
                {
                    label: 'Detalhes',
                    to: 'ProcessoNovo'
                }
            ]
        }
    },
    {
        path: '/processos/:id/detalhe/acompanhamentos',
        name: 'ProcessoDetalhesAcompanhamentos',
        component: NovoProcessoPage,
        meta: {
            title: 'Detalhes do Processo',
            breadcrumb: [
                ...processosBreadCrumb,
                {
                    label: 'Detalhes',
                    to: 'ProcessoNovo'
                }
            ]
        }
    }
];
