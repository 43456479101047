<template>
  <Form
      @submit="onSubmit"
      :validation-schema="generateSchema"
      @invalid-submit="onInvalidSubmit"
      :initial-values="initialValues"
      :context="initialValues"
      v-slot="{ setFieldValue, setValues, values }"
      ref="dynamicForm"
  >
    <div class="row"  >
      <div class="col-12" :class="section.col" v-for="(section, i) in schema.sections" :key="i">
        <div class="card mb-25 border-0 rounded-0 bg-white">
          <div class="card-body  letter-spacing" :class="{'p-15 p-sm-20 p-md-25 p-lg-30': showActions }"  :id="section.name" >
            <h5 class="card-title fw-bold mb-15 mb-lg-20 mb-xl-25" v-if="section.section"   >
              {{ section.section }}
            </h5>
            <div class="row gx-1">
              <div
                  v-for="{ type, name, label, multiple, maxWidth, emptyMessage, allowDownloadTemporary, inputType, col, title, params, placeholder, disabled, maxLength, preload, required, visible, emptyObject, readOnly, mask, formatValue, display, endpoint, options, rows, onChanged, decimalNumbers, fields, ...attrs } in section.fields"
                  :key="name"
                  :class="[col, { 'd-none': hideField(visible) }]"
              >
                <div class="form-group mb-15 mb-sm-20 mb-md-10">
                  <component
                      :is="type ?? TextInput"
                      :name="name"
                      :label="label"
                      :display="display"
                      :maxWidth="maxWidth"
                      :decimalNumbers="decimalNumbers"
                      :placeholder="placeholder"
                      :disabled="disabled"
                      :endpoint="endpoint"
                      :onChanged="onChanged"
                      :formatValue="formatValue"
                      :options="options"
                      :params="params"
                      :mask="mask"
                      :multiple="multiple"
                      :title="title"
                      :rows="rows"
                      :preload="preload"
                      :maxLength="maxLength"
                      :fields="fields"
                      :emptyObject="emptyObject"
                      :readOnly="readOnly"
                      :required="required"
                      :emptyMessage="emptyMessage"
                      :allowDownloadTemporary="allowDownloadTemporary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="col-12" :class="{'display-none' : !showActions }" >
        <div class="card mb-25 border-0 rounded-0 bg-white">
          <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing text-right d-flex justify-content-between align-items-center">
            <LastSyncInfo :form-data="initialValues" refresh-end-point="teste" />
            <div class="d-flex">
              <router-link ref="cancelButton" :to="{ name: cancelRoute }" class="btn btn-outline-light me-3">Cancelar</router-link>
              <button
                  ref="submitButton"
                  class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
                  type="submit">Salvar
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Form>
</template>

<script setup>
import { Form, useForm } from 'vee-validate';
import * as Yup from 'yup';
import {ref, computed, watch, nextTick} from 'vue';
import LastSyncInfo from "@/components/common/LastSyncInfo.vue";
import NumberInput from "@/components/form/NumberInput.vue";
import RepeaterInput from "@/components/form/RepeaterInput.vue";
import {getFormatedFormData} from "@/utils/formHelper.js";

const props = defineProps({
  initialValues: {
    type: Object,
    required: false
  },
  schema: {
    type: Object,
    required: true,
  },
  cancelRoute: {
    type: String,
    required: false,
    default: null
  },
  showActions: {
    type: Boolean,
    required: false,
    default: true
  }
});

const emit = defineEmits(['onSubmit', 'onInvalidSubmit', 'onCancel']);

const hideField = (visible) => {
  if (visible === undefined) {
    return false;
  }

  return visible.value === false;
};

const formMethods = useForm();

const cancelButton = ref(null);
const submitButton = ref(null);
const dynamicForm = ref(null);

const numberValues = ref([])
const repeaterValues = ref([])

const generateSchema = computed(() => {
  const fieldSettings = props.schema.sections.reduce((acc, section) => {
    section.fields.forEach(field => {

      if (field.type === NumberInput) {
        numberValues.value.push(field)
      }

      if (field.type === RepeaterInput) {
        repeaterValues.value.push(field)


        field.fields.forEach(field => {
          if(field.type === NumberInput) {
            numberValues.value.push(field)
          }
        })
      }

      acc[field.name] = field.rules;
    });
    return acc;
  }, {});

  return Yup.object().shape(fieldSettings);
});

const onSubmit = (values) => {
  JSON.stringify(values,null,4)

  for (var index in numberValues.value) {
    let field = numberValues.value[index];
    let value = values[field.name];
    // Se não tiver valor vai para o proximo
    if(!!!value) {
      values[field.name] = null
      continue;
    }

    let valueRaw = value

    if (typeof value != 'number') {
      valueRaw = value.replaceAll('.', '').replaceAll(',', '.')
    }

    values[field.name] = valueRaw
  }



  for (let index in repeaterValues.value) {
    let repeaterField = repeaterValues.value[index];

    if (!values.hasOwnProperty(repeaterField.name)) {
      continue;
    }

    let repeateValues = values[repeaterField.name];

    //Se não tiver valor vai para o proximo
    if(!repeateValues || repeateValues.length <= 0) {
      continue;
    }


    for (let valueIndex in repeateValues ){
      let value = repeateValues[valueIndex]

      for (let fieldIndex in repeaterField.fields) {
        let field = repeaterField.fields[fieldIndex]

        if (numberValues.value.findIndex(el => el.name == field.name) !== -1) {
          let valueRaw = value[field.name]

          if (!!valueRaw && typeof valueRaw != 'number') {
            valueRaw = valueRaw.replaceAll('.', '').replaceAll(',', '.')
          }

          values[repeaterField.name][valueIndex][field.name] = valueRaw
        }
      }
    }
  }

  emit('onSubmit', values);
};

const onInvalidSubmit = (errors) => {
  nextTick(() => {
    const firstError = document.querySelector(".help-message.text-danger");
    if (firstError) {
      firstError.scrollIntoView({ behavior: "smooth", block: "center" });
    }
  });

  emit('onInvalidSubmit', errors);
};



const submitForm = async () => {
  if (submitButton.value) {
    submitButton.value.click();
  }
};

const updateFieldValue = (name, value) => {
  dynamicForm.value.setFieldValue(name, value);
};

const updateRepeaterCalculatedFieldValue = (repeaterName, name, calculateFunction) => {
  let fields = dynamicForm.value?.values[repeaterName]

  for (let index in fields) {
    let field = fields[index]

    let calculatedValue = calculateFunction(field)

    dynamicForm.value.setFieldValue(`${repeaterName}[${index}].${name}`, calculatedValue);
  }
};



const updateFormValues = (values) => {
  dynamicForm.value.setValues(values);
};

const getFieldValue = (name) => {
  return dynamicForm.value?.values[name];
};

const onCancel = () => {
  if (cancelButton.value) {
    cancelButton.value.click();
  }
  emit('onCancel');
};

defineExpose({
  updateFieldValue,
  updateFormValues,
  updateRepeaterCalculatedFieldValue,
  getFieldValue,
  submitForm,
  onCancel
});

watch(() => props.schema, (newVal, oldVal) => {
  console.log(`Prop changed from ${oldVal} to ${newVal}`);
});
</script>


<style scoped>

.display-none {
  display: none;
}

</style>
