<!-- HelpModal.vue -->
<template>
  <div
      class="modal fade"
      id="helpModal"
      data-bs-backdrop="static"
      data-bs-keyboard="true"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Atalhos</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-12 col-md-4">
              <div v-for="(group, i) in groups" v-bind:key="i">
                <h5 class="header-group">{{group.title}}</h5>
                <div class="sub-title" >{{group.description}}</div>

                <div class="shortcuts">
                  <div class="shortcut"  v-for="(shortcut, sI) in shortcuts.filter( p => p.group == group.title)" v-bind:key="sI">
                  <span class="key">
                    {{ shortcut.keyCombo }}
                  </span>
                    <span>{{ shortcut.description }}</span>
                  </div>
                </div>

                <div class="separator"></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, defineEmits} from 'vue';
import { Modal } from 'bootstrap';

const modalElement = ref(null);
const shortcuts = ref([]);
const groups = ref([]);

let bsModalInstance = null;

const emit = defineEmits(['cancel', 'save', 'onLoading']);

onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});

const openModal = (options, groupOptions ) => {
  if (bsModalInstance) {
    ShowModal()
    shortcuts.value = options
    groups.value = groupOptions
  }
};

const closeModal = () => {
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};



const ShowModal = () => {
  if (bsModalInstance) {
    bsModalInstance.show();
  }
};


// Função para lidar com o cancelamento
const handleCancel = () => {
  closeModal();
  emit('cancel');
};






defineExpose({
  openModal,
});
</script>

<style src="@vueform/multiselect/themes/default.css"></style>


<style scoped>

.header-group {

}

.key {
  font-family: Consolas;
  font-size: 0.85em;
  padding: 4px;
  background-color: #efefef;
  margin-right: 5px;
}

.separator {
  padding: 15px;
}
.shortcut {
  margin-bottom: 6px;
}

.sub-title {
  margin-bottom: 8px;
  font-size: 0.9em;
}
</style>
