import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getProcesses = async (pageNumber, pageSize, situacao, responsavelId, searchTerm, filters) => {
    try {
        const params = new URLSearchParams();

        params.append('pageNumber', pageNumber);
        params.append('pageSize', pageSize);
        if(situacao) {
            params.append('situacao', situacao);
        }
        if(searchTerm) {
            params.append('searchTerm', searchTerm);
        }
        if(responsavelId) {
            params.append('responsavelId', responsavelId);
        }

        // Serializa os filtros manualmente
        filters.forEach((filter, index) => {
            Object.keys(filter).forEach(key => {
                params.append(`filters[${index}].${key}`, filter[key]);
            });
        });


        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/list`,{
            params: params
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};


const getAcompanhamentos = async (processoId, pageNumber, pageSize, searchTerm, visibilidade) => {
    try {



        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${processoId}/acompanhamentos`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                visibilidade
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar Acompanhamentos:', error);
        throw error;
    }
};

const getAcompanhamento = async (processoId, acompanhamentoId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${processoId}/acompanhamento/${acompanhamentoId}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao obter Acompanhamentos:', error);
        throw error;
    }
};

const deleteAcompanhamento = async (processoId, acompanhamentoId) => {
    try {
        const response = await Httpservice.getAxiosClient().delete(`${API_URL}/api/processo/${processoId}/acompanhamento/${acompanhamentoId}`);
        return response.data;
    } catch (error) {
        console.error('Erro ao excluir Acompanhamentos:', error);
        throw error;
    }
};


const getProcess = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};

const getProcessoDigitacaoDi = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${id}/di-digitadas`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};

const getProcessoDigitacaoDiConferencia = async (id, dados) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${id}/di-registrada/conferencia`,dados);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};



const newProcess = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao slavar o processo', error);
        throw error;
    }
};


const newAcompanhamento = async (processoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${processoId}/acompanhamento`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar acompanhamento', error);
        throw error;
    }
};

const updateAcompanhamento = async (processoId, acompanhamentoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/processo/${processoId}/acompanhamento/${acompanhamentoId}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar acompanhamento', error);
        throw error;
    }
};

const updateProcess = async (processId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/processo/${processId}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao slavar o processo', error);
        throw error;
    }
};

const updateSituacaoMapa = async (processoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${processoId}/mapa`, values);

        return response.data;
    } catch (error) {
        console.error('Erro ao salvar alteração no mapa', error);
        throw error;
    }
};


const getListTiposRemocao = () => {
    return [
        { nome: 'Não aplica', id: 1 },
        { nome: 'DTC', id: 2 },
        { nome: 'DTA', id: 3 },
    ];
}


const getTiposRemocao =  () => {
    return getListTiposRemocao()
};

const getListProcedimentos = () => {
    return [
        { nome: 'Operacional', id: 1 },
        { nome: 'Logística', id: 2 },
        { nome: 'Carregamento', id: 3 },
        { nome: 'Financeiro', id: 4 },
    ];
}

const getProcedimentos =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListProcedimentos()]
    }
    return getListProcedimentos()
};

const atribuirResponsavel = async (processoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${processoId}/responsavel`, values);

        return response.data;
    } catch (error) {
        console.error('Erro ao salvar alteração no mapa', error);
        throw error;
    }
};


const getDadosConfirmacaoChegada = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${id}/dados-confirmacao-chegada`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};

const getDadosPresencaCarga = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${id}/dados-presenca-carga`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};


const savePresencaCarga = async (processoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${processoId}/presenca-carga`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar presença de carga', error);
        throw error;
    }
};

const saveConfirmacaoChegada = async (processoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${processoId}/confirmacao-chegada`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar Confirmação de chegada', error);
        throw error;
    }
};

const getPrepararEnvioFollow = async (id, tipo) => {
    if (!tipo) {
        tipo = 'reenviar'
    }

    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${id}/follow-up/${tipo}/preparar-envio`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao preparar reenvio do follow up', error);
        throw error;
    }
};

const enviarFollowUp = async (id, tipo, values) => {
    try {
        if(!tipo) {
            const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${id}/follow-up/reenviar`, values);
            return response.data;
        } else {
            const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${id}/follow-up/${tipo}/enviar`, values);
            return response.data;
        }
    } catch (error) {
        console.error('Erro ao enviar follow up', error);
        throw error;
    }
};


const getDadosDiRegistrada = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${id}/dados-di-registrada`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};

const getDadosDiDigitada = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${id}/dados-di-digitada`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};

const saveDiDigitada = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${id}/di-digitada`, values);
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};

const saveDiRegistrada = async (processoId, values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/processo/${processoId}/di-registrada`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar registro de di', error);
        throw error;
    }
};

const downloadCapaProcesso = async (processoId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/processo/${processoId}/capa-processo`,{
            responseType: 'blob' // Garantir que a resposta seja tratada como blob
        });

        return response;
    } catch (error) {
        console.error('Erro ao baixar capa do processo', error);
        throw error;
    }
};

export default {
    getProcesses,
    getProcess,
    getProcedimentos,
    getTiposRemocao,
    newProcess,
    updateProcess,
    getAcompanhamentos,
    newAcompanhamento,
    updateAcompanhamento,
    updateSituacaoMapa,
    atribuirResponsavel,
    getAcompanhamento,
    deleteAcompanhamento,
    getDadosConfirmacaoChegada,
    saveConfirmacaoChegada,
    getPrepararEnvioFollow,
    enviarFollowUp,
    // Presença de Carga
    getDadosPresencaCarga,
    savePresencaCarga,
    // DI Registrada
    getDadosDiRegistrada,
    getDadosDiDigitada,
    saveDiDigitada,
    saveDiRegistrada,
    downloadCapaProcesso,
    getProcessoDigitacaoDi,
    getProcessoDigitacaoDiConferencia,
};