// src/router/containers.js
import ContainerListPage from "@/views/Containers/ContainerListPage.vue";

const containersBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Containers',
        to: 'Containers'
    }
]

export default [
    {
        path: '/containers',
        name: 'Containers',
        component: ContainerListPage,
        meta: {
            title: 'Containers',
            breadcrumb: containersBreadCrumb
        },
    },
];
