// utils/numberHelper.js

/**
 * Formata um número decimal de acordo com as opções fornecidas.
 *
 * @param {number} number - O número a ser formatado.
 * @param {string} locale - O idioma e região para formatação (ex: 'pt-BR', 'en-US').
 * @param {Object} options - Opções de formatação para Intl.NumberFormat.
 * @returns {string} - O número formatado como string.
 */
export function formatDecimal(number, locale = 'pt-BR', options = {}) {
    if (isNaN(number)) return '';

    const defaultOptions = {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    };

    const formatOptions = { ...defaultOptions, ...options };

    try {
        return new Intl.NumberFormat(locale, formatOptions).format(number);
    } catch (error) {
        console.error('Erro ao formatar o número:', error);
        return number.toString(); // Retorna o número como string em caso de erro
    }
}

export function formatInteger(number, locale = 'pt-BR', options = {}) {
    if (isNaN(number)) return '';

    const defaultOptions = {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };

    const formatOptions = { ...defaultOptions, ...options };

    try {
        return new Intl.NumberFormat(locale, formatOptions).format(number);
    } catch (error) {
        console.error('Erro ao formatar o número:', error);
        return number.toString(); // Retorna o número como string em caso de erro
    }
}


export function numberFormat(number, decimals, decPoint, thousandsSep) {


    number = (number + '').replace(/[^0-9+\-Ee.]/g, '')
    const n = !isFinite(+number) ? 0 : +number
    const prec = !isFinite(+decimals) ? 0 : Math.abs(decimals)
    const sep = typeof thousandsSep === 'undefined' ? ',' : thousandsSep
    const dec = typeof decPoint === 'undefined' ? '.' : decPoint
    let s = ''

    const toFixedFix = function (n, prec) {
        if (('' + n).indexOf('e') === -1) {
            return +(Math.round(n + 'e+' + prec) + 'e-' + prec)
        } else {
            const arr = ('' + n).split('e')
            let sig = ''
            if (+arr[1] + prec > 0) {
                sig = '+'
            }
            return (+(Math.round(+arr[0] + 'e' + sig + (+arr[1] + prec)) + 'e-' + prec)).toFixed(prec)
        }
    }

    // @todo: for IE parseFloat(0.55).toFixed(0) = 0;
    s = (prec ? toFixedFix(n, prec).toString() : '' + Math.round(n)).split('.')
    if (s[0].length > 3) {
        s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep)
    }
    if ((s[1] || '').length < prec) {
        s[1] = s[1] || ''
        s[1] += new Array(prec - s[1].length + 1).join('0')
    }

    return s.join(dec)
}