<template>
  <ShortcutManager ref="shortcutManagerRef"></ShortcutManager>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script setup>
import {computed, onMounted, ref, provide } from 'vue';
import { useRouter } from 'vue-router';
import ShortcutManager from '@/components/common/ShortcutManager.vue';

// Criar uma referência para o ShortcutManager
const shortcutManagerRef  = ref(null);


const { currentRoute } = useRouter();

const defaultLayout = 'default';
const layout = computed(() => `${currentRoute.value.meta.layout || defaultLayout}-layout`);

onMounted(() => {
  //console.log('mounted', shortcutManagerRef.value)
  //shortcutManagerRef.value.registerGroupShortcut('Geral', 'Usados em qualquer lugar')

  // Aqui você pode registrar atalhos diretamente
  //shortcutManagerRef.value.registerShortcut('Alt + F1', () => { alert('eita')},'Ajuda', 'Geral');
});


// Fornecer a função registerShortcut para outros componentes
provide('registerShortcut', (keyCombo, action, description, group, keyUpAction) => {
  if (shortcutManagerRef.value) {
    shortcutManagerRef.value.registerShortcut(keyCombo, action, description, group, keyUpAction);
  }
});


provide('registerGroupShortcut', (title, description) => {
  if (shortcutManagerRef.value) {
    shortcutManagerRef.value.registerGroupShortcut(title, description);
  }
});


provide('unregisterShortcut', (keyCombo) => {
  if (shortcutManagerRef.value) {
    shortcutManagerRef.value.unregisterShortcut(keyCombo);
  }
});

</script>


<style scoped>
body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
