<template>
  <BreadCrumb  />
  <CargoForm />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import CargoForm from "@/views/Cargos/CargoForm.vue";

export default defineComponent({
  name: "NovoProcessoPage",
  components: {
    BreadCrumb,
    CargoForm,
  },
});
</script>
