<template>
  <data-grid
      endpoint="/api/notificacao/lista"
      section="data-grid"
      :filterForm="filterForm"
      :default-filters="defaultFilters"
      :columns="defaultColumnsSettings"
      :force-reload="forceReload"
      :allow-toggle-only-my="false"
      :initial-sort-by="[{ propertyName: 'createdAt', sortOrder: 'desc'}]"
      @on-db-click-row="showDetails"
  >
    <template v-slot:[`item-isRead`]="{item}">
      <div>{{ item.isRead ? 'Sim' : 'Não' }}</div>
    </template>

    <template v-slot:[`item-message`]="{item}">
      <div v-html="item.message"></div>
    </template>

    <template v-slot:[`item-createdAt`]="{item}">
      <div>{{ formatDateTime(item.createdAt) }}</div>
    </template>

    <template v-slot:[`item-acoes`]="{item}">
        <div class="dropdown">
          <button
              class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
          >
            <i class="flaticon-dots"></i>
          </button>
          <ul class="dropdown-menu">
            <li v-if="item.isRead != true">
              <a
                  class="dropdown-item d-flex align-items-center"
                  @click="onMarcarLido(item)"
              ><i
                  class="ph ph-envelope-simple-open lh-1 me-8 position-relative top-1"
              ></i>
                Marcar como lido</a>
            </li>
            <li v-if="item.isRead == true">
              <a
                  class="dropdown-item d-flex align-items-center"
                  @click="onMarcarNaoLido(item)"

              ><i
                  class="ph ph-envelope-simple lh-1 me-8 position-relative top-1"
              ></i>
                Marcar como não lido</a>
            </li>
            <li>
              <a
                  class="dropdown-item d-flex align-items-center"
                  @click="GoTo2Rote(item.goTo)"
              ><i
                  class="ph ph-caret-circle-right lh-1 me-8 position-relative top-1"
              ></i>
                Ir Para</a>
            </li>
          </ul>
        </div>
    </template>

  </data-grid>
</template>

<script setup>
import router from "@/router/index.js"
import { ref} from "vue"
import TextInput from "@/components/form/TextInput.vue"
import dataGrid from '@/components/dataGrid/dataGrid.vue'
import {formatDateTime} from "../../utils/dateHelper.js"
import NotificationService from "@/services/NotificationService.js"
import SelectInput from "@/components/form/SelectInput.vue";

const defaultFilters = {
  statusFilters: [ ],
  // statusFilters: [
  //   {
  //     label: 'Situação',
  //     name: 'situacao',
  //     value: 1,
  //     options: [
  //       { value: null,  name: 'Todos' },
  //       { value: 3,     name: 'Em cadastro' },
  //       { value: 1,     name: 'Ativo' },
  //       { value: 3,     name: 'Inativo' },
  //       { value: 4,     name: 'Bloqueado' },
  //       { value: 5,     name: 'Não vender' },
  //     ]
  //   }
  // ],
  filters: null
}

const defaultColumnsSettings = [
  { text: "TÍTULO", value: "title", sortable: true  },
  { text: "MENSAGEM", value: "message", sortable: true },
  { text: "LIDO", value: "isRead", sortable: true },
  { text: "DATA CRIAÇÃO", value: "createdAt", sortable: true },
  { text: "AÇÕES", value: "acoes",  width: 50, sortable: false, slot: true },
]
const filterForm = {
  sections: [
    {
      section: "Geral",
      fields: [
        {
          label: 'Título',
          name: 'title',
          type: TextInput,
          col: 'col-12',
        },
        {
          label: 'Lido',
          name: 'isRead',
          type: SelectInput,
          options: [{ nome: 'Selecione..', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          col: 'col-12',
        },
      ]
    }
  ],
};
const forceReload = ref( new Date())

function showDetails(item, newWindow) {
  const routeData = router.resolve({
    name: 'NotificacaoEdit',
    params: { id: item.id }
  });

  if (newWindow) {
    window.open(routeData.href, '_blank');
  } else {
    router.push(routeData);
  }

}

const GoTo2Rote = (goTo) => {
  if(!goTo) {
    return undefined
  }

  let route = JSON.parse(goTo)

  router.push(route)
}

const forceReloadNow =() => {
  forceReload.value = new Date()
}

const onMarcarLido = async (notification) => {
  await NotificationService.postMarkRead(notification.id)

  forceReloadNow()
}

const onMarcarNaoLido = async (notification) => {
  await NotificationService.postMarkUnread(notification.id)
  forceReloadNow()
}

</script>
