<template>
  <MainHeader />
  <MainSidebar />
  <div class="main-content d-flex flex-column transition overflow-hidden">
    <router-view />
    <MainFooter />
  </div>

  <div id="toastContainer"></div>
</template>



<script setup>
import { watchEffect, onMounted } from "vue";
import stateStore from "@/utils/store";

import MainHeader from "@/components/layouts/Default/MainHeader.vue";
import MainSidebar from "@/components/layouts/Default/MainSidebar.vue";
import MainFooter from "@/components/layouts/Default/MainFooter.vue";


const rotas = [
  { id: 1, name: "Home 2", redirect: "/" },
  { id: 2, name: "Sobre", redirect: "/about" },
  { id: 3, name: "Login", redirect: "/login" },
];

// Import components
 const stateStoreInstance = stateStore;

// Lifecycle hook for mounted
onMounted(() => {
  document.body.classList.add("bg-body-secondary");
});

// Watch for changes in the stateStoreInstance
watchEffect(() => {
  if (stateStoreInstance.open) {
    document.body.classList.remove("sidebar-show");
    document.body.classList.add("sidebar-hide");
  } else {
    document.body.classList.remove("sidebar-hide");
    document.body.classList.add("sidebar-show");
  }
});
</script>

