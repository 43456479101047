<template>
  <div class="mb-20 d-sm-flex align-items-center justify-content-between letter-spacing">
    <h4 class="mb-5 mb-sm-0 fw-bold">{{ title ?? route.meta.title }}</h4>
    <nav aria-label="breadcrumb">
      <ol class="breadcrumb mb-0 list-unstyled ps-0">
        <li class="breadcrumb-item" :class="{'active': item.to == route.name}" v-for="item in route.meta.breadcrumb" v-bind:key="item.to" >
          <span v-if="item.to == route.name">{{ $t(item.label) }}</span>
          <router-link :to="{ name: item.to }" class="text-decoration-none text-black" v-else>
            {{ $t(item.label) }}
          </router-link>
        </li>
      </ol>
    </nav>
  </div>
</template>

<script setup>
import {useRoute} from "vue-router";

const route = useRoute()


const props = defineProps({
  title: {
    type: String,
    required: false,
    default: null
  }
})
</script>