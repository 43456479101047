<template>
  <BreadCrumb />
  <NotificacaoList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import NotificacaoList from "@/views/Notificacoes/NotificacaoList.vue";

export default defineComponent({
  name: "ProcessoListPage",
  components: {
    BreadCrumb,
    NotificacaoList,
  },
});
</script>