<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div class="row d-flex align-items-stretch">
    <div  class="col-12 col-md-4 mb-25">
      <containers-por-situacao :containers-por-situacao="containersPorSituacao"  />
    </div>

    <div  class="col-12 col-md-4 mb-25">
      <situacao-lavacao :situacao-lavacao="situacaoLavacao"  />
    </div>

    <div  class="col-12 col-md-4 mb-25">
      <SituacaoAtual :containers-por-situacao="containersPorSituacao" :situacao-lavacao="situacaoLavacao" />
    </div>



    <div  class="col-12">
      <containers :containers="containers.items" :totalItems="containers.totalItems"    @changed="filterChanged"/>
    </div>
  </div>
</template>

<script setup>
import {ref, onBeforeMount, computed} from 'vue';

import Loading from 'vue-loading-overlay';
import RelatorioService from "@/services/RelatorioService.js";
import ContainersPorSituacao from "@/views/Relatorios/ControleContainerLavacao/ContainersPorSituacao.vue";
import SituacaoLavacao from "@/views/Relatorios/ControleContainerLavacao/SituacaoLavacao.vue";

import Containers from "@/views/Relatorios/ControleContainerLavacao/Containers.vue";

import SituacaoAtual from "@/views/Relatorios/ControleContainerLavacao/SituacaoAtual.vue";


const isLoading = ref(false);
let today = new Date();


let responsavel = ref(null);


let result = ref({});

let containers = ref([]); // Reactive ref for containers
let clientes = ref([]);
let containersPorSituacao = ref([]); // Reactive ref for containers
let situacaoLavacao = ref([]); // Reactive ref for containers

const onResponsavelChange =  async (value) => {

  responsavel.value = value

  await getData();
};

async function getData () {
  isLoading.value = true;
  result.value = await RelatorioService.getControleDemurrageLavacao(1, 200,  responsavel.value?.id);

  // Atualize `containers` diretamente
  containers.value = result.value.containers;

  situacaoLavacao.value = result.value.situacaoLavacao;
  containersPorSituacao.value = result.value.containersPorSituacao;
  clientes.value = result.value.clientes;
  isLoading.value = false;
}

async function filterChanged(filtros) {
  console.log('filters', filtros)
  await getData()
}

onBeforeMount(async () => {
  result.value = {
    "containers": {
      "items": [],
      "totalItems": 0,
      "pageNumber": 1,
      "pageSize": 20,
      "searchTerm": null,
      "totalPages": 0,
      "from": 0,
      "to": 0
    }
  };

   await getData();
});
</script>
