<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>
  <div class="row d-flex align-items-stretch">
    <div  class="col-12 col-md-4 mb-25">
      <ProcessosPorSituacao :processos-por-situacao="processosPorSituacao"  />
    </div>

    <div  class="col-12 col-md-4 mb-25">
      <principais-agentes :situacao-lavacao="principaisAgentes"  />
    </div>

<!--    <div  class="col-12 col-md-4 mb-25">-->
<!--      <SituacaoAtual :processos-por-situacao="processosPorSituacao" :situacao-lavacao="principaisAgentes" />-->
<!--    </div>-->



<!--    <div  class="col-12">-->
<!--      <processos :processos="processos.items" :totalItems="processos.totalItems"    @changed="filterChanged"/>-->
<!--    </div>-->
  </div>
</template>

<script setup>
import {ref, onBeforeMount, computed} from 'vue';

import Loading from 'vue-loading-overlay';
import RelatorioService from "@/services/RelatorioService.js";
import ProcessosPorSituacao from "@/views/Relatorios/ProcessosEstatisticas/ProcessosPorSituacao.vue";
import PrincipaisAgentes from "@/views/Relatorios/ProcessosEstatisticas/PrincipaisAgentes.vue";
import Processos from "@/views/Relatorios/ProcessosEstatisticas/Processos.vue";

import SituacaoAtual from "@/views/Relatorios/ProcessosEstatisticas/SituacaoAtual.vue";


const isLoading = ref(false);
let today = new Date();


let responsavel = ref(null);


let result = ref({});

let processos = ref([]); // Reactive ref for processos
let clientes = ref([]);
let processosPorSituacao = ref([]); // Reactive ref for processos
let principaisAgentes = ref([]); // Reactive ref for processos

const onResponsavelChange =  async (value) => {

  responsavel.value = value

  await getData();
};

async function getData () {
  isLoading.value = true;
  result.value = await RelatorioService.getProcessoEstatistica(1, 200,  responsavel.value?.id);

  principaisAgentes.value = result.value.principaisAgentes;
  processosPorSituacao.value = result.value.processosPorSituacao;
  processos.value = result.value.processos;
  isLoading.value = false;
}

async function filterChanged(filtros) {
  console.log('filters', filtros)
  await getData()
}

onBeforeMount(async () => {
  result.value = {
    "processos": {
      "items": [],
      "totalItems": 0,
      "pageNumber": 1,
      "pageSize": 20,
      "searchTerm": null,
      "totalPages": 0,
      "from": 0,
      "to": 0
    }
  };

   await getData();
});
</script>
