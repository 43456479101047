import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;



const getOrigensDestinos = async (pageNumber, pageSize, searchTerm, situacao) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/origem-destino/lista`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm,
                situacao
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getOrigemDestino = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/origem-destino/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar origemDestino', error);
        throw error;
    }
};

const updateOrigemDestino = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/origem-destino/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o origemDestino', error);
        throw error;
    }
};

const createOrigemDestino = async (values) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/origem-destino`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o origemDestino', error);
        throw error;
    }
};




export default {
    getOrigensDestinos,
    getOrigemDestino,
    createOrigemDestino,
    updateOrigemDestino,
};