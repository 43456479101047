import { reactive } from "vue";

const stateStore = reactive({
  open: true,
  onChange() {
    stateStore.open = !stateStore.open;
  }
});

export default stateStore;
