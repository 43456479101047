// utils/stringHelper.js
import {formatDecimal} from "@/utils/numberHelper.js";


export function monthNumberToShortName(monthNumber) {
    const months = [
        'Jan', 'Fev', 'Mar', 'Abr', 'Mai', 'Jun',
        'Jul', 'Ago', 'Set', 'Out', 'Nov', 'Dez'
    ];
    return months[monthNumber - 1] || '';
}

export function monthNumberToFullName(monthNumber) {
    const months = [
        'Janeiro', 'Fevereiro', 'Março', 'Abril', 'Maio', 'Junho',
        'Julho', 'Agosto', 'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    return months[monthNumber - 1] || '';
}

export function nl2br(value) {
    if (!value) return '';
    return value.replace(/\n/g, '<br>');
}

export function capitalizeFirstLetter(string) {
    if (!string) return '';
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
}

export function truncateString(string, maxLength) {
    if (!string) return '';
    if (string.length <= maxLength) return string;
    return string.slice(0, maxLength) + '...';
}

export function booleanToHuman (value) {
    if (value === undefined || value === null) return '';

    if(value) {
        return  'Sim'
    }

    return 'Não'
}


export function saudacao() {
    var now = new Date();

    if(now.getHours() < 12) {
        return 'Bom dia'
    }

    if(now.getHours() < 18) {
        return 'Boa tarde'
    }

    if(now.getHours()) {
        return 'Boa noite'
    }

}

export function FormatBytes(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));

    const value = parseFloat((bytes / Math.pow(k, i)).toFixed(decimals))

    return  formatDecimal(value) + ' ' + sizes[i];
}

export function Slugfy(value) {
    if (value === undefined || value === null) return '';


    return value
        .toString() // Converte para string
        .normalize("NFD") // Normaliza para decompor acentos
        .replace(/[\u0300-\u036f]/g, "") // Remove acentos
        .replace(/[^a-zA-Z0-9\s-]/g, '') // Remove caracteres especiais
        .trim() // Remove espaços em branco nas extremidades
        .replace(/\s+/g, '-') // Substitui espaços por hífens
        .toLowerCase(); // Converte para minúsculas
}