<template>
  <div ref="tooltipButton" @mouseleave="hide()" @mouseenter="show()" class="text-center">
    <!-- Conteúdo principal do botão ou elemento alvo do tooltip -->
    <slot></slot>

    <div id="tooltipData" ref="tooltipData">
      <slot name="tooltip"></slot>
    </div>
  </div>

</template>

<script setup>
import { onMounted, ref, onBeforeUnmount, watch, watchEffect } from 'vue';
import Tooltip from 'bootstrap/js/src/tooltip';

const tooltipButton = ref(null);
const tooltipData = ref(null);
let tooltipInstance;

const props = defineProps({
  placement: {
    type: String,
    required: false,
    default: 'left',
    validator: (value) => ['top', 'left', 'right', 'bottom'].includes(value),
  },
});

// Função para atualizar o conteúdo do tooltip
const updateTooltipContent = () => {
  if (tooltipInstance && tooltipData.value) {
    tooltipInstance.setContent({
      '.tooltip-inner': tooltipData.value.innerHTML,
    });
  }
};

onMounted(() => {
  tooltipInstance = new Tooltip(tooltipButton.value, {
    placement: props.placement,
    html: true, // Permite HTML no tooltip
    //trigger: 'hover',
    trigger: 'manual',
    title: tooltipData.value?.innerHTML || "", // Inicializa com o conteúdo do slot tooltip
  });



  // Limpeza do observer antes de desmontar
  onBeforeUnmount(() => {
    if (tooltipInstance) {
      tooltipInstance.dispose();
    }
  });
});


const show = () => {
  if (tooltipInstance) {
    updateTooltipContent()
    tooltipInstance.show()
  }
}

const hide = () => {
  if (tooltipInstance) {
      tooltipInstance.hide()
  }
}
</script>

<style>
#tooltipData {
  display: none;
}

.tooltip {
  background-color: #fff !important;
  color: #666 !important;
  text-align: left;
  opacity: 1 !important;
  border-radius: 5px;
}

.dark .tooltip {
  background-color: #34334a !important;
  color: var(--bs-tooltip-color) !important;
}

.tooltip-inner {
  text-align: left !important;
  background-color: #fff !important;
  color: #666 !important;
  box-shadow: var(--bs-box-shadow) !important;
}

.dark .tooltip-inner {
  color: var(--bs-tooltip-color) !important;
}
</style>
