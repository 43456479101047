// utils/dateHelper.js

export function formatDate(dateString, locale = 'pt-BR', options = {}) {
    if (!dateString) return '';

    const defaultOptions = {
        dateStyle: 'short',
        timeZone: 'America/Sao_Paulo',
    };
    const formatOptions = { ...defaultOptions, ...options };

    try {
        return new Intl.DateTimeFormat(locale, formatOptions).format(new Date(dateString));
    } catch (error) {
        console.error('Erro ao formatar a data:', error);
        return dateString; // Retorna a string original em caso de erro
    }
}
export function formatDateTime(dateString, locale = 'pt-BR', options = {}) {
    if (!dateString) return '';

    const defaultOptions = {
        dateStyle: 'short',
        timeStyle: 'short',
        timeZone: 'America/Sao_Paulo',
    };
    const formatOptions = { ...defaultOptions, ...options };

    try {
        return new Intl.DateTimeFormat(locale, formatOptions).format(new Date(dateString));
    } catch (error) {
        console.error('Erro ao formatar a data:', error);
        return dateString; // Retorna a string original em caso de erro
    }
}

export function subtrairMeses(data, meses) {
    // Ajusta o ano e o mês se necessário
    const ano = data.getFullYear();
    const mes = data.getMonth() - meses;

    data.setMonth(mes);

    // Se o mês for negativo, ajuste o ano e o mês
    if (mes < 0) {
        data.setFullYear(ano - Math.ceil(Math.abs(mes) / 12));
    }

    return data;
}

export function diferencaEmDias(dataInicial, dataFinal) {
    // Converter as strings ISO ou objetos Date para objetos Date
    const inicio = new Date(dataInicial);
    const fim = new Date(dataFinal);

    // Garantir que ambas as datas estão em UTC (zerar horas, minutos, etc.)
    inicio.setUTCHours(0, 0, 0, 0);
    fim.setUTCHours(0, 0, 0, 0);

    // Calcular a diferença em milissegundos
    const diferencaMillis = fim - inicio;

    // Converter a diferença de milissegundos para dias
    const diferencaDias = diferencaMillis / (1000 * 60 * 60 * 24);

    return diferencaDias;
}


export function DataParaTempoAtras(data) {
    if (!(data instanceof Date)) {
        data = new Date(data);
    }

    const agora = new Date();
    const diferencaEmMilissegundos = agora - data;

    if (diferencaEmMilissegundos < 0) {
        return "Agora mesmo";
    }

    const minutos = Math.floor(diferencaEmMilissegundos / (1000 * 60));
    const horas = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60));
    const dias = Math.floor(diferencaEmMilissegundos / (1000 * 60 * 60 * 24));

    if (minutos < 1) {
        return "Agora mesmo";
    } else if (minutos < 60) {
        return `Há ${minutos} minuto${minutos > 1 ? "s" : ""} atrás`;
    } else if (horas < 24) {
        return `Há ${horas} hora${horas > 1 ? "s" : ""} atrás`;
    } else {
        return `Há ${dias} dia${dias > 1 ? "s" : ""} atrás`;
    }
}

export function DateToAmericaSaoPaulo(dateString, locale = 'pt-BR', options = {}) {
    if (!dateString) return '';

    const defaultOptions = {
        dateStyle: 'short',
        timeStyle: 'short',
        timeZone: 'America/Sao_Paulo', // Força o horário de Brasília
    };
    const formatOptions = { ...defaultOptions, ...options };

    try {
        // Converte a data para o objeto Date
        const date = new Date(dateString);

        // Verifica se a data já está em horário de Brasília
        const isBrasiliaTime = date.toLocaleString(locale, { timeZone: 'America/Sao_Paulo' }) === date.toLocaleString(locale);
        if (isBrasiliaTime) {
            return new Intl.DateTimeFormat(locale, formatOptions).format(date);
        }

        // Converte para o horário de Brasília usando a localidade fornecida
        const brDate = new Date(
            date.toLocaleString(locale, { timeZone: 'America/Sao_Paulo' })
        );

        return new Intl.DateTimeFormat(locale, formatOptions).format(brDate);
    } catch (error) {
        console.error('Erro ao converter a data:', error);
        return dateString; // Retorna a string original em caso de erro
    }
}