<template>
  <div class="ocean-products">
    <h1>Ocean Products</h1>
    <div v-for="product in oceanProducts" :key="product.carrierProductId" class="product-card">
      <h2>Product ID: {{ product.carrierProductId }}</h2>
      <p>Valid From: {{ product.productValidFromDate }}</p>
      <p>Valid To: {{ product.productValidToDate }}</p>

      <h3>Transport Schedules</h3>
      <div v-for="schedule in product.transportSchedules" :key="schedule.departureDateTime" class="schedule-card">
        <p><strong>Departure:</strong> {{ formatDateTime(schedule.departureDateTime) }}</p>
        <p><strong>Arrival:</strong> {{ formatDateTime(schedule.arrivalDateTime) }}</p>

        <h4>Facilities</h4>
        <ul>
          <li><strong>Origin:</strong> {{ schedule.facilities.collectionOrigin.locationName }}, {{ schedule.facilities.collectionOrigin.cityName }} ({{ schedule.facilities.collectionOrigin.countryCode }})</li>
          <li><strong>Destination:</strong> {{ schedule.facilities.deliveryDestination.locationName }}, {{ schedule.facilities.deliveryDestination.cityName }} ({{ schedule.facilities.deliveryDestination.countryCode }})</li>
        </ul>

        <h4>Transport Legs</h4>
        <table class="table table-hover">
          <thead>
          <tr>
            <th>Departure</th>
            <th>Arrival</th>
            <th>Start Location</th>
            <th>End Location</th>
            <th>Transport Mode</th>
            <th>Vessel Name</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="leg in schedule.transportLegs" :key="leg.departureDateTime">
            <td>{{ formatDateTime(leg.departureDateTime) }}</td>
            <td>{{ formatDateTime(leg.arrivalDateTime) }}</td>
            <td>{{ leg.facilities.startLocation.locationName }}, {{ leg.facilities.startLocation.cityName }}</td>
            <td>{{ leg.facilities.endLocation.locationName }}, {{ leg.facilities.endLocation.cityName }}</td>
            <td>{{ leg.transport.transportMode }}</td>
            <td>{{ leg.transport.vessel.vesselName }}</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OceanProducts',
  data() {
    return {
      "oceanProducts": [
        {
          "carrierProductId": "97242087",
          "carrierProductSequenceId": "2",
          "productValidFromDate": "2024-01-27",
          "productValidToDate": "4712-12-31",
          "numberOfProductLinks": "2",
          "transportSchedules": [
            {
              "departureDateTime": "2024-09-12T22:00:00",
              "arrivalDateTime": "2024-10-10T02:00:00",
              "facilities": {
                "collectionOrigin": {
                  "carrierCityGeoID": "0O3QDS78YUJ6H",
                  "cityName": "Kotka",
                  "carrierSiteGeoID": "1FGOL3E2W7YCU",
                  "locationName": "KOTKA STEVECO TERMINAL",
                  "countryCode": "FI",
                  "locationType": "TERMINAL",
                  "UNLocationCode": "FIKTK",
                  "siteUNLocationCode": "FIKTK",
                  "cityUNLocationCode": "FIKTK"
                },
                "deliveryDestination": {
                  "carrierCityGeoID": "1BX66GARX9UAH",
                  "cityName": "Santos",
                  "carrierSiteGeoID": "13PE88BJNFZ4D",
                  "locationName": "Santos, Santos Brasil Terminal",
                  "countryCode": "BR",
                  "locationType": "TERMINAL",
                  "UNLocationCode": "BRSSZ",
                  "siteUNLocationCode": "BRSSZ",
                  "cityUNLocationCode": "BRSSZ",
                  "UNRegionCode": "SP"
                }
              },
              "firstDepartureVessel": {
                "vesselIMONumber": "9778533",
                "carrierVesselCode": "O90",
                "vesselName": "VILNIA MAERSK"
              },
              "transitTime": "39120",
              "transportLegs": [
                {
                  "departureDateTime": "2024-09-12T22:00:00",
                  "arrivalDateTime": "2024-09-16T22:00:00",
                  "facilities": {
                    "startLocation": {
                      "carrierCityGeoID": "0O3QDS78YUJ6H",
                      "cityName": "Kotka",
                      "carrierSiteGeoID": "1FGOL3E2W7YCU",
                      "locationName": "KOTKA STEVECO TERMINAL",
                      "countryCode": "FI",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "FIKTK",
                      "siteUNLocationCode": "FIKTK",
                      "cityUNLocationCode": "FIKTK"
                    },
                    "endLocation": {
                      "carrierCityGeoID": "3EX4F5S3MB4OU",
                      "cityName": "Bremerhaven",
                      "carrierSiteGeoID": "2U4AD56P1LNIY",
                      "locationName": "NTB North Sea Terminal Bremerhaven",
                      "countryCode": "DE",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "DEBRV",
                      "siteUNLocationCode": "DEBRV",
                      "cityUNLocationCode": "DEBRV",
                      "UNRegionCode": "HB"
                    }
                  },
                  "transport": {
                    "transportMode": "FEO",
                    "vessel": {
                      "vesselIMONumber": "9778533",
                      "carrierVesselCode": "O90",
                      "vesselName": "VILNIA MAERSK"
                    },
                    "carrierTradeLaneName": "EUR/ESA",
                    "carrierDepartureVoyageNumber": "436S",
                    "inducementLinkFlag": "false",
                    "carrierServiceCode": "L02",
                    "carrierServiceName": "L02",
                    "linkDirection": "S",
                    "carrierCode": "L8L02M",
                    "routingType": "E"
                  }
                },
                {
                  "departureDateTime": "2024-09-22T22:00:00",
                  "arrivalDateTime": "2024-10-10T02:00:00",
                  "facilities": {
                    "startLocation": {
                      "carrierCityGeoID": "3EX4F5S3MB4OU",
                      "cityName": "Bremerhaven",
                      "carrierSiteGeoID": "2U4AD56P1LNIY",
                      "locationName": "NTB North Sea Terminal Bremerhaven",
                      "countryCode": "DE",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "DEBRV",
                      "siteUNLocationCode": "DEBRV",
                      "cityUNLocationCode": "DEBRV",
                      "UNRegionCode": "HB"
                    },
                    "endLocation": {
                      "carrierCityGeoID": "1BX66GARX9UAH",
                      "cityName": "Santos",
                      "carrierSiteGeoID": "13PE88BJNFZ4D",
                      "locationName": "Santos, Santos Brasil Terminal",
                      "countryCode": "BR",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "BRSSZ",
                      "siteUNLocationCode": "BRSSZ",
                      "cityUNLocationCode": "BRSSZ",
                      "UNRegionCode": "SP"
                    }
                  },
                  "transport": {
                    "transportMode": "MVS",
                    "vessel": {
                      "vesselIMONumber": "9633939",
                      "carrierVesselCode": "H7A",
                      "vesselName": "CAP SAN ARTEMISSIO"
                    },
                    "carrierTradeLaneName": "EUR/ESA",
                    "carrierDepartureVoyageNumber": "436S",
                    "inducementLinkFlag": "false",
                    "carrierServiceCode": "102",
                    "carrierServiceName": "NEOSAMBA",
                    "linkDirection": "S",
                    "carrierCode": "X3102M",
                    "routingType": "M"
                  }
                }
              ]
            },
            {
              "departureDateTime": "2024-09-20T18:00:00",
              "arrivalDateTime": "2024-10-19T02:00:00",
              "facilities": {
                "collectionOrigin": {
                  "carrierCityGeoID": "0O3QDS78YUJ6H",
                  "cityName": "Kotka",
                  "carrierSiteGeoID": "1FGOL3E2W7YCU",
                  "locationName": "KOTKA STEVECO TERMINAL",
                  "countryCode": "FI",
                  "locationType": "TERMINAL",
                  "UNLocationCode": "FIKTK",
                  "siteUNLocationCode": "FIKTK",
                  "cityUNLocationCode": "FIKTK"
                },
                "deliveryDestination": {
                  "carrierCityGeoID": "1BX66GARX9UAH",
                  "cityName": "Santos",
                  "carrierSiteGeoID": "13PE88BJNFZ4D",
                  "locationName": "Santos, Santos Brasil Terminal",
                  "countryCode": "BR",
                  "locationType": "TERMINAL",
                  "UNLocationCode": "BRSSZ",
                  "siteUNLocationCode": "BRSSZ",
                  "cityUNLocationCode": "BRSSZ",
                  "UNRegionCode": "SP"
                }
              },
              "firstDepartureVessel": {
                "vesselIMONumber": "9775763",
                "carrierVesselCode": "O88",
                "vesselName": "VENTA MAERSK"
              },
              "transitTime": "40800",
              "transportLegs": [
                {
                  "departureDateTime": "2024-09-20T18:00:00",
                  "arrivalDateTime": "2024-09-23T22:00:00",
                  "facilities": {
                    "startLocation": {
                      "carrierCityGeoID": "0O3QDS78YUJ6H",
                      "cityName": "Kotka",
                      "carrierSiteGeoID": "1FGOL3E2W7YCU",
                      "locationName": "KOTKA STEVECO TERMINAL",
                      "countryCode": "FI",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "FIKTK",
                      "siteUNLocationCode": "FIKTK",
                      "cityUNLocationCode": "FIKTK"
                    },
                    "endLocation": {
                      "carrierCityGeoID": "3EX4F5S3MB4OU",
                      "cityName": "Bremerhaven",
                      "carrierSiteGeoID": "2U4AD56P1LNIY",
                      "locationName": "NTB North Sea Terminal Bremerhaven",
                      "countryCode": "DE",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "DEBRV",
                      "siteUNLocationCode": "DEBRV",
                      "cityUNLocationCode": "DEBRV",
                      "UNRegionCode": "HB"
                    }
                  },
                  "transport": {
                    "transportMode": "FEO",
                    "vessel": {
                      "vesselIMONumber": "9775763",
                      "carrierVesselCode": "O88",
                      "vesselName": "VENTA MAERSK"
                    },
                    "carrierTradeLaneName": "EUR/ESA",
                    "carrierDepartureVoyageNumber": "438S",
                    "inducementLinkFlag": "false",
                    "carrierServiceCode": "L02",
                    "carrierServiceName": "L02",
                    "linkDirection": "S",
                    "carrierCode": "L8L02M",
                    "routingType": "E"
                  }
                },
                {
                  "departureDateTime": "2024-09-27T06:00:00",
                  "arrivalDateTime": "2024-10-19T02:00:00",
                  "facilities": {
                    "startLocation": {
                      "carrierCityGeoID": "3EX4F5S3MB4OU",
                      "cityName": "Bremerhaven",
                      "carrierSiteGeoID": "2U4AD56P1LNIY",
                      "locationName": "NTB North Sea Terminal Bremerhaven",
                      "countryCode": "DE",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "DEBRV",
                      "siteUNLocationCode": "DEBRV",
                      "cityUNLocationCode": "DEBRV",
                      "UNRegionCode": "HB"
                    },
                    "endLocation": {
                      "carrierCityGeoID": "1BX66GARX9UAH",
                      "cityName": "Santos",
                      "carrierSiteGeoID": "13PE88BJNFZ4D",
                      "locationName": "Santos, Santos Brasil Terminal",
                      "countryCode": "BR",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "BRSSZ",
                      "siteUNLocationCode": "BRSSZ",
                      "cityUNLocationCode": "BRSSZ",
                      "UNRegionCode": "SP"
                    }
                  },
                  "transport": {
                    "transportMode": "MVS",
                    "vessel": {
                      "vesselIMONumber": "9622239",
                      "carrierVesselCode": "I1F",
                      "vesselName": "SAN AUGUSTIN MAERS"
                    },
                    "carrierTradeLaneName": "EUR/ESA",
                    "carrierDepartureVoyageNumber": "438S",
                    "inducementLinkFlag": "false",
                    "carrierServiceCode": "102",
                    "carrierServiceName": "NEOSAMBA",
                    "linkDirection": "S",
                    "carrierCode": "X3102M",
                    "routingType": "M"
                  }
                }
              ]
            },
            {
              "departureDateTime": "2024-09-27T22:00:00",
              "arrivalDateTime": "2024-10-26T02:00:00",
              "facilities": {
                "collectionOrigin": {
                  "carrierCityGeoID": "0O3QDS78YUJ6H",
                  "cityName": "Kotka",
                  "carrierSiteGeoID": "1FGOL3E2W7YCU",
                  "locationName": "KOTKA STEVECO TERMINAL",
                  "countryCode": "FI",
                  "locationType": "TERMINAL",
                  "UNLocationCode": "FIKTK",
                  "siteUNLocationCode": "FIKTK",
                  "cityUNLocationCode": "FIKTK"
                },
                "deliveryDestination": {
                  "carrierCityGeoID": "1BX66GARX9UAH",
                  "cityName": "Santos",
                  "carrierSiteGeoID": "13PE88BJNFZ4D",
                  "locationName": "Santos, Santos Brasil Terminal",
                  "countryCode": "BR",
                  "locationType": "TERMINAL",
                  "UNLocationCode": "BRSSZ",
                  "siteUNLocationCode": "BRSSZ",
                  "cityUNLocationCode": "BRSSZ",
                  "UNRegionCode": "SP"
                }
              },
              "firstDepartureVessel": {
                "vesselIMONumber": "9778533",
                "carrierVesselCode": "O90",
                "vesselName": "VILNIA MAERSK"
              },
              "transitTime": "40560",
              "transportLegs": [
                {
                  "departureDateTime": "2024-09-27T22:00:00",
                  "arrivalDateTime": "2024-09-30T22:00:00",
                  "facilities": {
                    "startLocation": {
                      "carrierCityGeoID": "0O3QDS78YUJ6H",
                      "cityName": "Kotka",
                      "carrierSiteGeoID": "1FGOL3E2W7YCU",
                      "locationName": "KOTKA STEVECO TERMINAL",
                      "countryCode": "FI",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "FIKTK",
                      "siteUNLocationCode": "FIKTK",
                      "cityUNLocationCode": "FIKTK"
                    },
                    "endLocation": {
                      "carrierCityGeoID": "3EX4F5S3MB4OU",
                      "cityName": "Bremerhaven",
                      "carrierSiteGeoID": "2U4AD56P1LNIY",
                      "locationName": "NTB North Sea Terminal Bremerhaven",
                      "countryCode": "DE",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "DEBRV",
                      "siteUNLocationCode": "DEBRV",
                      "cityUNLocationCode": "DEBRV",
                      "UNRegionCode": "HB"
                    }
                  },
                  "transport": {
                    "transportMode": "FEO",
                    "vessel": {
                      "vesselIMONumber": "9778533",
                      "carrierVesselCode": "O90",
                      "vesselName": "VILNIA MAERSK"
                    },
                    "carrierTradeLaneName": "EUR/ESA",
                    "carrierDepartureVoyageNumber": "439S",
                    "inducementLinkFlag": "false",
                    "carrierServiceCode": "L02",
                    "carrierServiceName": "L02",
                    "linkDirection": "S",
                    "carrierCode": "L8L02M",
                    "routingType": "E"
                  }
                },
                {
                  "departureDateTime": "2024-10-04T22:00:00",
                  "arrivalDateTime": "2024-10-26T02:00:00",
                  "facilities": {
                    "startLocation": {
                      "carrierCityGeoID": "3EX4F5S3MB4OU",
                      "cityName": "Bremerhaven",
                      "carrierSiteGeoID": "2U4AD56P1LNIY",
                      "locationName": "NTB North Sea Terminal Bremerhaven",
                      "countryCode": "DE",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "DEBRV",
                      "siteUNLocationCode": "DEBRV",
                      "cityUNLocationCode": "DEBRV",
                      "UNRegionCode": "HB"
                    },
                    "endLocation": {
                      "carrierCityGeoID": "1BX66GARX9UAH",
                      "cityName": "Santos",
                      "carrierSiteGeoID": "13PE88BJNFZ4D",
                      "locationName": "Santos, Santos Brasil Terminal",
                      "countryCode": "BR",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "BRSSZ",
                      "siteUNLocationCode": "BRSSZ",
                      "cityUNLocationCode": "BRSSZ",
                      "UNRegionCode": "SP"
                    }
                  },
                  "transport": {
                    "transportMode": "MVS",
                    "vessel": {
                      "vesselIMONumber": "9633941",
                      "carrierVesselCode": "H7B",
                      "vesselName": "CAP SAN MALEAS"
                    },
                    "carrierTradeLaneName": "EUR/ESA",
                    "carrierDepartureVoyageNumber": "439S",
                    "inducementLinkFlag": "false",
                    "carrierServiceCode": "102",
                    "carrierServiceName": "NEOSAMBA",
                    "linkDirection": "S",
                    "carrierCode": "X3102M",
                    "routingType": "M"
                  }
                }
              ]
            },
            {
              "departureDateTime": "2024-10-04T22:00:00",
              "arrivalDateTime": "2024-11-02T02:00:00",
              "facilities": {
                "collectionOrigin": {
                  "carrierCityGeoID": "0O3QDS78YUJ6H",
                  "cityName": "Kotka",
                  "carrierSiteGeoID": "1FGOL3E2W7YCU",
                  "locationName": "KOTKA STEVECO TERMINAL",
                  "countryCode": "FI",
                  "locationType": "TERMINAL",
                  "UNLocationCode": "FIKTK",
                  "siteUNLocationCode": "FIKTK",
                  "cityUNLocationCode": "FIKTK"
                },
                "deliveryDestination": {
                  "carrierCityGeoID": "1BX66GARX9UAH",
                  "cityName": "Santos",
                  "carrierSiteGeoID": "13PE88BJNFZ4D",
                  "locationName": "Santos, Santos Brasil Terminal",
                  "countryCode": "BR",
                  "locationType": "TERMINAL",
                  "UNLocationCode": "BRSSZ",
                  "siteUNLocationCode": "BRSSZ",
                  "cityUNLocationCode": "BRSSZ",
                  "UNRegionCode": "SP"
                }
              },
              "firstDepartureVessel": {
                "vesselIMONumber": "9775763",
                "carrierVesselCode": "O88",
                "vesselName": "VENTA MAERSK"
              },
              "transitTime": "40560",
              "transportLegs": [
                {
                  "departureDateTime": "2024-10-04T22:00:00",
                  "arrivalDateTime": "2024-10-07T22:00:00",
                  "facilities": {
                    "startLocation": {
                      "carrierCityGeoID": "0O3QDS78YUJ6H",
                      "cityName": "Kotka",
                      "carrierSiteGeoID": "1FGOL3E2W7YCU",
                      "locationName": "KOTKA STEVECO TERMINAL",
                      "countryCode": "FI",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "FIKTK",
                      "siteUNLocationCode": "FIKTK",
                      "cityUNLocationCode": "FIKTK"
                    },
                    "endLocation": {
                      "carrierCityGeoID": "3EX4F5S3MB4OU",
                      "cityName": "Bremerhaven",
                      "carrierSiteGeoID": "2U4AD56P1LNIY",
                      "locationName": "NTB North Sea Terminal Bremerhaven",
                      "countryCode": "DE",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "DEBRV",
                      "siteUNLocationCode": "DEBRV",
                      "cityUNLocationCode": "DEBRV",
                      "UNRegionCode": "HB"
                    }
                  },
                  "transport": {
                    "transportMode": "FEO",
                    "vessel": {
                      "vesselIMONumber": "9775763",
                      "carrierVesselCode": "O88",
                      "vesselName": "VENTA MAERSK"
                    },
                    "carrierTradeLaneName": "EUR/ESA",
                    "carrierDepartureVoyageNumber": "440S",
                    "inducementLinkFlag": "false",
                    "carrierServiceCode": "L02",
                    "carrierServiceName": "L02",
                    "linkDirection": "S",
                    "carrierCode": "L8L02M",
                    "routingType": "E"
                  }
                },
                {
                  "departureDateTime": "2024-10-13T16:01:00",
                  "arrivalDateTime": "2024-11-02T02:00:00",
                  "facilities": {
                    "startLocation": {
                      "carrierCityGeoID": "3EX4F5S3MB4OU",
                      "cityName": "Bremerhaven",
                      "carrierSiteGeoID": "2U4AD56P1LNIY",
                      "locationName": "NTB North Sea Terminal Bremerhaven",
                      "countryCode": "DE",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "DEBRV",
                      "siteUNLocationCode": "DEBRV",
                      "cityUNLocationCode": "DEBRV",
                      "UNRegionCode": "HB"
                    },
                    "endLocation": {
                      "carrierCityGeoID": "1BX66GARX9UAH",
                      "cityName": "Santos",
                      "carrierSiteGeoID": "13PE88BJNFZ4D",
                      "locationName": "Santos, Santos Brasil Terminal",
                      "countryCode": "BR",
                      "locationType": "TERMINAL",
                      "UNLocationCode": "BRSSZ",
                      "siteUNLocationCode": "BRSSZ",
                      "cityUNLocationCode": "BRSSZ",
                      "UNRegionCode": "SP"
                    }
                  },
                  "transport": {
                    "transportMode": "MVS",
                    "vessel": {
                      "vesselIMONumber": "9964481",
                      "carrierVesselCode": "V5I",
                      "vesselName": "MAERSK EL PALOMAR"
                    },
                    "carrierTradeLaneName": "EUR/ESA",
                    "carrierDepartureVoyageNumber": "440S",
                    "inducementLinkFlag": "false",
                    "carrierServiceCode": "102",
                    "carrierServiceName": "NEOSAMBA",
                    "linkDirection": "S",
                    "carrierCode": "X3102M",
                    "routingType": "M"
                  }
                }
              ]
            }
          ],
          "vesselOperatorCarrierCode": "MAEU"
        }
      ]
    };
  },
  methods: {
    formatDateTime(dateTime) {
      const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return new Date(dateTime).toLocaleDateString('en-US', options);
    }
  }
};
</script>

<style scoped>
.ocean-products {
  margin: 20px;
  font-family: Arial, sans-serif;
}

.product-card {
  border: 1px solid #ccc;
  padding: 15px;

}

.schedule-card {
  padding: 20px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  background: #fff;
}
</style>