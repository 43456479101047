<template>
  <BreadCrumb />
  <DepotList />
</template>

<script lang="ts">
import { defineComponent } from "vue";

import BreadCrumb from "@/components/common/BreadCrumb.vue";
import DepotList from "@/views/Depots/DepotList.vue";

export default defineComponent({
  name: "ProcessoListPage",
  components: {
    BreadCrumb,
    DepotList,
  },
});
</script>