<template>
  <div class="card mb-25 border-0 rounded-0 bg-white expected-earnings-box">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <span class="d-block mb-6 fs-13 text-uppercase fw-medium text-dark-emphasis">
        PREV. FATURAMENTO CIF {{  monthNumberToShortName(props.month) }}/{{ props.year }}
      </span>
      <h4 class="fw-black mb-12 lh-1">R$ {{ formatDecimal(props.prevTotalMes) }}        </h4>
      <span class="fw-bold text-success text-badge d-inline-block" :class="{'text-success': percetualVariacao() > 0, 'text-danger': percetualVariacao() <= 0, 'invisible': situacaoDi != null && !situacaoDi }" >
        {{ formatDecimal(percetualVariacao()) }}%
        <i v-if="percetualVariacao() > 0"  class="flaticon-up-arrow fs-11 lh-1 position-relative top-1"> </i>
        <i v-else  class="flaticon-down-arrow fs-11 lh-1 position-relative top-1"> </i>
      </span>
      <ul class="list ps-0 mb-0 list-unstyled mt-15">

        <li class="text-muted position-relative fw-medium" v-for="(empresa, i) in totalPorEmpresa" v-bind:key="i">
          {{ empresa.filial }} - <span class="text-black fw-bold">R$ {{ formatDecimal(empresa.totalCifBrl) }}</span>
        </li>

      </ul>
      <div id="earningChart" class="chart">
        <apexchart
            type="donut"
            height="150"
            :options="earningChart"
            :series="valorTotalCifPorEmpresa"
        ></apexchart>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { monthNumberToShortName } from "../../../utils/stringHelper.js";
import {formatDecimal} from "@/utils/numberHelper.js";

const valorTotalCifPorEmpresa = computed(() => {
  return props.totalPorEmpresa.map((el) => { return el.totalCifBrl})
});

const earningChart = ref({
  dataLabels: {
    enabled: false,
  },
  colors: ["#6FD3F7","#06B48A", "#6560F0"],
  legend: {
    show: false,
    fontWeight: 500,
    fontSize: "14px",
    fontFamily: "Red Hat Display, sans-serif",
    labels: {
      colors: "#9C9AB6",
    },
    markers: {
      offsetX: -2,
      offsetY: 1,
    },
  },
  stroke: {
    width: 0,
  },
  tooltip: {
    enabled: false,
    style: {
      fontSize: "14px",
      fontFamily: "Red Hat Display, sans-serif",
    },
  },
});

function percetualVariacao () {
  let value = ((props.prevTotalMes * 100.0 / props.prevTotalMesAnterior) - 100.0)
  return  value
}

const props = defineProps({
    year: {
      type: Number,
      required: true
    },
    month: {
      type: Number,
      required: true
    },
    prevTotalMes: {
      type: Number,
      required: true
    },
    prevTotalMesAnterior: {
      type: Number,
      required: true
    },
    totalPorEmpresa: {
      type: Array,
      required: true
    },
    situacaoDi: {
      type: Boolean,
      required: false,
      default: null
    }
})
</script>
