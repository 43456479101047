<!-- AdiarTarefaModal.vue -->
<template>
  <div
      class="modal fade"
      id="adiarTarefaModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabindex="-1"
      aria-hidden="true"
      ref="modalElement"
  >
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h1 class="modal-title fs-5">Alterar configurações envio de e-mail</h1>
          <button
              type="button"
              ref="closeButton"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
              @click="handleCancel"
          ></button>
        </div>
        <div class="modal-body">
          <DynamicForm ref="mapaForm" :schema="formSchema" :show-actions="false" :initial-values="formData" @onSubmit="onSubmit" @onCancel="handleCancel" />
        </div>
        <div class="modal-footer">
          <button
              type="button"
              class="btn btn-outline-light me-3"
              @click="handleCancel"
          >
            Cancelar
          </button>
          <button
              type="button"
              class="ml-5 default-btn transition border-0 fw-medium text-white pt-10 pb-10 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-35 pe-md-35 rounded-1 fs-md-15 fs-lg-16 bg-success"
              @click="validateAndSave"
          >
            Salvar
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, onMounted, defineEmits, reactive} from 'vue';
import { useToast} from "@/plugins/useToast.js";
import { Modal } from 'bootstrap';
import DynamicForm from "@/components/form/DynamicForm.vue";
import * as Yup from "yup";
import TextInput from "@/components/form/TextInput.vue";
import PasswordInput from "@/components/form/PasswordInput.vue";
import UsuarioService from "@/services/UsuarioService.js";

const { showToast } = useToast();
const modalElement = ref(null);
const usuarioId = ref(null);
const mapaForm = ref(null);
let bsModalInstance = null;
const formData = reactive({

});

const emit = defineEmits(['cancel', 'save']);

const formSchema = {
  sections: [
    {
      //section: 'Informações Gerais',
      description: "Informações sobre a situação do mapa no processo",
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Senha',
          name: 'senha',
          type: PasswordInput,
          col: 'col-12 col-md-4',
          rules: Yup.string().required("Obrigatório"),
        },
        {
          label: 'Repita a Senha',
          name: 'senhaRepeat',
          type: PasswordInput,
          col: 'col-12 col-md-4',
          rules: Yup.string().required("Obrigatório").oneOf([Yup.ref('senha')], 'Senhas são divergentes'),
        },
      ]
    }
  ],
};

// Inicializar o modal quando o componente for montado
onMounted(() => {
  bsModalInstance = new Modal(modalElement.value);
});

const onSubmit = async (values) => {

  console.log('usuarioId.value', usuarioId.value)
  let result = await UsuarioService.alterarSenha(usuarioId.value, values);

  closeModal();
  emit('save', values)
}


// Função para abrir o modal
const openModal = (id) => {
  if (bsModalInstance) {
    usuarioId.value = id
    bsModalInstance.show();
  }
};

// Função para fechar o modal
const closeModal = () => {
  console.log('closeModal')
  if (bsModalInstance) {
    bsModalInstance.hide();
  }
};

// Função para lidar com o cancelamento
const handleCancel = () => {
  closeModal();
  emit('cancel');
};

const validateAndSave = async () => {
  if (mapaForm.value) {
    await mapaForm.value.submitForm();
  }
};




defineExpose({
  openModal,
});
</script>
