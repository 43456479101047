// src/router/notificacoes.js
import NotificacaoListPage from "@/views/Notificacoes/NotificacaoListPage.vue";
import NovoNotificacaoPage from "@/views/Notificacoes/NovoNotificacaoPage.vue";

const notificacoesBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Notificações',
        to: 'Notificacoes'
    }
]

export default [
    {
        path: '/notificacoes',
        name: 'Notificacoes',
        component: NotificacaoListPage,
        meta: {
            title: 'Notificações',
            breadcrumb: notificacoesBreadCrumb
        },
    },
    {
        path: '/notificacoes/novo',
        name: 'NotificacoesNovo',
        component: NovoNotificacaoPage,
        meta: {
            title: 'Nova Notificação',
            breadcrumb: [
                ...notificacoesBreadCrumb,
                {
                    label: 'Novo Cliente',
                    to: 'ClienteNovo'
                }
            ]
        }
    },
    {
        path: '/notificacoes/:id/edit',
        name: 'NotificacaoEdit',
        component: NovoNotificacaoPage,
        meta: {
            title: 'Detalhes da Notificação',
            breadcrumb: [
                ...notificacoesBreadCrumb,
                {
                    label: 'Nova Notificacao',
                    to: 'NotificacaoNovo'
                }
            ]
        }
    }

];
