OrigemDestino<template>
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <div
      class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
            type="text"
            class="form-control shadow-none text-black rounded-0 border-0"
            :placeholder="$t('Buscar Origem/Destino')"
            v-model="searchTerm"
            @input="filtroGeral()"
            @keydown.enter.prevent
          />
          <button
            type="submit"
            class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>
      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <select
          class="project-select form-select shadow-none fw-semibold rounded-1 mt-10 mt-sm-0 ms-sm-10"
          v-model="situacao"
          @change="situacaoAlterada()"
        >
          <option :selected="situacao == null" :value="null">Todos <span v-if="situacao == null">({{ totalItems }})</span> </option>
          <option :selected="situacao == 1" value="1">Ativo <span v-if="situacao == 1">({{ totalItems }})</span> </option>
          <option :selected="situacao == 2" value="2">Inativo <span v-if="situacao == 2">({{ totalItems }})</span> </option>
        </select>
      </div>
    </div>
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="table-responsive">
        <loading v-model:active="isLoading"
                 :can-cancel="false"
                 :is-full-page="fullPage"/>
        <table class="table table-compact text-nowrap align-middle mb-0">
          <thead>
            <tr>
              <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                NOME
              </th>

              <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                NOME EXIBIÇÃO
              </th>
              <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                SIGLA
              </th>
              <th
                  scope="col"
                  class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
              >
                ATIVO
              </th>
              <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 text-end pe-0"
              ></th>
            </tr>
          </thead>
          <tbody>

            <tr v-for="item in items" v-bind:key="item.id" @dblclick="showDetails(item)">
              <td class="shadow-none lh-1">
                <router-link
                    class="d-block text-decoration-none text-black"
                    :to="{name: 'OrigemDestinoEdit', params: {id: item.id}}"
                >
                  {{ item.nome }}
                </router-link>
              </td>
              <td class="shadow-none lh-1">
                  {{ item.nomeExibicao }}
              </td>

              <td class="shadow-none lh-1">
                {{ item.sigla }}
              </td>

              <th
                  class="shadow-none title lh-1 fw-medium fs-md-15 fs-lg-16 ps-0"
              >
                <div class="d-flex align-items-center">
                  {{ booleanToHuman(item.ativo) }}
                </div>
              </th>

              <!-- Actions -->
              <td
                class="shadow-none lh-1 fw-medium text-body-tertiary text-end pe-0"
              >
                <div class="dropdown">
                  <button
                    class="dropdown-toggle lh-1 bg-transparent border-0 shadow-none p-0 transition"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <i class="flaticon-dots"></i>
                  </button>
                  <ul class="dropdown-menu">
                    <li>
                      <router-link
                          class="dropdown-item d-flex align-items-center"
                          :to="{name: 'OrigemDestinoEdit', params: {id: item.id}}"
                      >
                        <i
                            class="flaticon-view lh-1 me-8 position-relative top-1"
                        ></i>
                        Editar
                      </router-link>
                    </li>
<!--                    <li>-->
<!--                      <a-->
<!--                        class="dropdown-item d-flex align-items-center"-->
<!--                        href="javascript:void(0);"-->
<!--                        ><i-->
<!--                          class="flaticon-delete lh-1 me-8 position-relative top-1"-->
<!--                        ></i>-->
<!--                        Delete</a-->
<!--                      >-->
<!--                    </li>-->
                  </ul>
                </div>
              </td>
              <!-- Fim Actions -->
            </tr>
          </tbody>
        </table>
      </div>

      <Pagination :total-paginas="totalPaginas" :pagina-atual="paginaAtual" :por-pagina="porPagina"
                  :total-items="totalItems"
                  :item-inicial="itemInicial"
                  :item-final="itemFinal"
                  @onPagination="OnPagination"
      />

    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue';
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/css/index.css';
import Pagination from "@/components/common/Pagination.vue";
import KeycloakService from "@/security/KeycloakService.js";
import router from "@/router/index.js";
import OrigemDestinoService from "@/services/OrigemDestinoService.js";
import {useToast} from "@/plugins/useToast.js";
import {booleanToHuman} from "../../utils/stringHelper.js";

const { showToast } = useToast();

const isLoading = ref(false);
const fullPage = ref(false);
const situacao = ref(1);
const items = ref([]);
const searchTerm = ref(null);
const totalItems = ref(0);
const porPagina = ref(20);
const paginaAtual = ref(1);
const totalPaginas = ref(0);
const itemInicial = ref(0);
const itemFinal = ref(0);
let timeout = null;
const wait = 400;


function OnPagination(data) {
  console.log('OnPagination', data)
  paginaAtual.value = data.paginaAtual
  porPagina.value = data.porPagina

  loadData()
}


function showDetails(item) {
  router.push({name: 'OrigemDestinoDetalhes', params: {id: item.id}});
}

function filtroGeral() {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    paginaAtual.value = 1
    loadData();
  }, wait);
}

function IsGranted(role) {
  console.log('IsGranted', role, KeycloakService.IsGranted(role))
  return KeycloakService.IsGranted(role);
}

async function loadData() {
  try {
    isLoading.value = true;
    let filterBy = null;
    if (searchTerm.value) {
      filterBy = searchTerm.value;
    }

    items.value = [];
    totalItems.value = 0;
    const response = await OrigemDestinoService.getOrigensDestinos(
        paginaAtual.value,
        porPagina.value,
        filterBy,
        situacao.value
    );

    items.value = response.items;
    totalItems.value = response.totalItems;
    porPagina.value = response.pageSize;
    itemInicial.value = response.from;
    itemFinal.value = response.to;
    totalPaginas.value = response.totalPages;

    isLoading.value = false;
  } catch (error) {
    isLoading.value = false;
    console.error('Erro ao carregar items:', error);
  }
}

onMounted(() => {
  loadData();
});

function situacaoAlterada() {
  paginaAtual.value = 1;
  loadData();
}


</script>
