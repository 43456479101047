<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>
  <DynamicForm ref="dynamicForm" :schema="formSchema" cancel-route="Usuarios" :initial-values="formData" @onSubmit="onSubmit" />
  <ConfiguracoesEmailModal ref="configuracoesEmailModal"  @save="onConfiguracoesEmailChanged" @onLoading="onLoadingChange" />
  <AlterarSenhaModal ref="alterarSenhaModal" @onLoading="onLoadingChange" />
  <!--  <Toast />-->

  <div class="action-point-2  bg-white" :class="{'opened': !stateStoreInstance.open}">
  </div>

</template>



<script setup>
import {reactive, ref, onBeforeMount, inject, computed} from 'vue';
import DynamicForm from "@/components/form/DynamicForm.vue"
import stateStore from "@/utils/store";
import Loading from 'vue-loading-overlay';
import ConfiguracoesEmailModal from "@/views/Usuarios/Modal/ConfiguracoesEmailModal.vue";
import AlterarSenhaModal from "@/views/Usuarios/Modal/AlterarSenhaModal.vue";

import * as Yup from 'yup';
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import router from "@/router/index.js";
import UsuarioService from "@/services/UsuarioService.js";

import {useRoute} from "vue-router";

import { useToast} from "@/plugins/useToast.js";
import PasswordInput from "@/components/form/PasswordInput.vue";
import FileInput from "@/components/form/FileInput.vue";
import ButtonInput from "@/components/form/ButtonInput.vue";

const configuracoesEmailModal = ref(null);
const alterarSenhaModal = ref(null);

const { showToast } = useToast();

const stateStoreInstance = stateStore;

const  currentRoute  = useRoute();
const dynamicForm = ref(null);

// Initial Data - Valor inicial do formulário
const formData = reactive({
});

const isLoading = ref(false);

const usuarioId = ref(null);


const allowInsertPasswords = computed(() => {
  if (currentRoute.params.hasOwnProperty('id')) {
    return true;
  }

  return  false;
});



onBeforeMount(async ()  => {
  if (currentRoute.params.hasOwnProperty('id')) {
    usuarioId.value =  currentRoute.params.id
    isLoading.value = true;

    try {
      let usuario = await UsuarioService.getUsuario(currentRoute.params.id);
      Object.assign(formData, usuario);
      updateFormValues(formData)
    } catch (error) {
      if (error.status == 403) {
        router.push('/acesso-negado')
      }

      console.log('error',error)
    } finally {
      isLoading.value = false;
    }
  }
})

const onSubmit = async (values) =>  {
  isLoading.value = true

  if (currentRoute.params.hasOwnProperty('id')) {
    try {
      let result = await UsuarioService.updateUsuario(currentRoute.params.id, values)

      showToast('Usuario salvo.', {
        title: 'Sucesso',
        type: 'success'
      });

      router.push({name: 'Usuarios'});
    } catch (err) {
      console.log('err', err)
      showToast(err.message, {
        title: 'Erro',
        type: 'error'
      });
    } finally {
      isLoading.value = false
    }

  } else {
    UsuarioService.createUsuario(values).then(r => {
      showToast('Usuario salvo.', {
        title: 'Sucesso',
        type: 'success'
      })

      router.push({name: 'Usuarios'});

    }).catch(ex => {
      if(ex.code == 'ERR_BAD_REQUEST') {
        showToast('Erro ao salvar os dados. E-mail já cadastrado.', {
          title: 'Erro',
          type: 'warning'
        })
      } else {

        showToast('Não foi possível salvar o usuário. Tente novamente mais tarde.', {
          title: 'Erro',
          type: 'error'
        })
      }
    }).finally(() => {

      isLoading.value = false;
    })
  }

}


const updateFormValues = (values) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFormValues(values)
  }
};


const formSchema = {
  sections: [
    {
      section: 'Informações Gerais',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Nome',
          name: 'nome',
          type: TextInput,
          col: 'col-12 col-md-4',
          rules: Yup.string().required("Nome é obrigatório")
        },
        {
          label: 'Sobrenome',
          name: 'sobrenome',
          type: TextInput,
          col: 'col-12 col-md-4',
          rules: Yup.string().required('Sobrenome é obrigatório')
        },
        {
          label: 'E-mail',
          name: 'email',
          type: TextInput,
          col: 'col-12 col-md-4',
          rules: Yup.string().required('E-mail é obrigatório').email('Precisa ser um e-mail válido'),
        },
        {
          label: 'Cargo',
          name: 'cargo',
          type: RelationInput,
          col: 'col-12 col-md-4',
          endpoint: 'cargo',
          display: (value) => {
            return value.nome
          },
          formatValue: (value) => {
            return value.nome
          },
          rules: Yup.object().required('Cargo é obrigatório'),
        },
        // {
        //   label: 'Foto',
        //   name: 'imagem',
        //   type: FileInput,
        //   col: 'col-12 col-md-4',
        // }
      ]
    },
    {
      section: 'Acesso',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Senha',
          name: 'senha',
          type: PasswordInput,
          visible: allowInsertPasswords.value,
          disabled: allowInsertPasswords.value,
          col: 'col-12 col-md-4',
          rules: (allowInsertPasswords.value)? Yup.string().nullable() : Yup.string().required("Obrigatório").min(6,'Deve conter pelo menos 6 caracteres')
        },
        {
          type: ButtonInput,
          label: 'Alterar senha',
          col: 'col-12 col-md-2',
          onChanged: (value) => {
            if (alterarSenhaModal.value) {
              alterarSenhaModal.value.openModal(usuarioId.value)
            }
          }
        },
      ]
    },
    {
      section: 'Vínculo Conexos',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Pessoa Conexos',
          name: 'pessoa',
          type: RelationInput,
          col: 'col-12 col-md-4',
          endpoint: 'pessoa/colaborador',
          display: (value) => {
            return value.nome ?? value.razaoSocial
          },
          rules: Yup.object().required('Você precisa vincular a uma pessoa no Conexos'),
        },
      ]
    },
    {
      section: 'Configuração envio de E-mail',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Assinatura',
          name: 'assinatura',
          type: FileInput,
          multiple: false,
          maxWidth: 700,
          allowDownloadTemporary: false,
          col: 'col-12 col-md-12',
        },
      ]
    },
  ],
};

const onLoadingChange = (loading) => {
  isLoading.value = loading
}

const onConfiguracoesEmailChanged = (values) => {
  updateFormValue('emailUsuario', values.emailUsuario)
}

const updateFormValue = (name, value) => {
  if (dynamicForm.value) {
    dynamicForm.value.updateFieldValue(name, value);
  }
};

</script>

<style scoped>
 .action-point-2 {
   position: fixed;
   bottom: 0;
   left: 0;
   right: 0;
   z-index: 9999;
   padding: 10px;
   transition: width 0.8s ease, transform 0.8s ease; /* Animação suave */
   transform: translateX(0px); /* Move o elemento para a esquerda */
 }

 .action-point-2.opened {
   left: 280px;
   transform: translateX(0px); /* Move o elemento para a esquerda */

 }
</style>