import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getOptions = async (endpoint, pageNumber, pageSize, searchTerm) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/${endpoint}`,{
            params: {
                pageNumber,
                pageSize,
                searchTerm
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};


export default {
    getOptions
};