import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getUltimasNotificacoes = async () => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/notificacao/ultimas`,{
            params: {
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar Notificações:', error);
        throw error;
    }
};


const postMarkAllRead = async () => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/notificacao/marcar-todas-como-lidas`,{
            params: {
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar Notificações:', error);
        throw error;
    }
};
const postMarkRead = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/notificacao/${id}/marcar-como-lida`, {});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};


const postMarkUnread = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/notificacao/${id}/marcar-como-nao-lida`, {});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processo', error);
        throw error;
    }
};

const getNotificacao = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/notificacao/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar notificação', error);
        throw error;
    }
};


export default {
    getUltimasNotificacoes,
    postMarkAllRead,
    postMarkRead,
    postMarkUnread,
    getNotificacao,

};