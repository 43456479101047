<template>
  <div
      class="relationInput"
      :class="{ 'has-error': !!errorMessage, success: meta.valid }"
  >
    <label :for="name" class="d-block text-black fw-semibold mb-10">{{ label }}</label>

    <div class="selected" v-show="false">
      <input
          class="form-control shadow-none rounded-0 text-black"
          :name="name"
          :id="name"
          :type="type"
          :value="inputValue"
          :placeholder="placeholder"
          :disabled="disabled"
          @input="handleChange"
          @blur="handleBlur"
      />
    </div>

    <input
        v-if="!inputValue"
        class="form-control shadow-none rounded-1 text-black"
        v-model="searchTerm"
        :placeholder="placeholder"
        @input="onFilter()"
        @keydown.enter.prevent
        :disabled="disabled"
    />

    <div ref="componentContainer" class="component-container" v-if="searchTerm != '' &&  !inputValue">
      <ul
          class="w-full rounded bg-white border border-gray-300  py-2 space-y-1 absolute z-10 "
      >
        <li
            tabindex="0"
            v-for="option in options.items"
            :key="option.name"
            @click="selectOption(option)"
            @keyup.enter="selectOption(option)"
            class="cursor-pointer hover:bg-gray-100 p-1 focusable"
        >
          {{ getDisplayPropValue(option) }}
        </li>
        <li class="px-1 pt-1 font-bold border-b border-gray-200 small">
          Mostrando {{ options.to }} de {{ options.totalItems }} resultados
        </li>
      </ul>
    </div>
    <div class="input-group rounded-1" v-if="inputValue" @dblclick="onShowDetails()">
      <input
          type="text"
          class="form-control rounded-1 text-black border-0"
          :value="getDisplayPropValue(inputValue)"
          readonly
          :disabled="disabled"
          >
      <span v-if="!disabled" class="input-group-text" @click="clean()" id="clean">
          <i class="flaticon flaticon-cancel fs-10 lh-1 position-relative top-2"></i>
      </span>
    </div>

    <p class="help-message" :class="{ 'text-danger': !!errorMessage, success: meta.valid }"  v-show="errorMessage || meta.valid">
      {{ errorMessage || successMessage }}
    </p>
  </div>
</template>


<script setup>
import RelationalInputServiceService from "@/services/RelationalInputServiceService.js";

import {computed, ref, toRef, onMounted, onUnmounted, watch, onBeforeMount} from 'vue';
import { useField } from 'vee-validate';

const emit = defineEmits(['onChanged']);

onMounted(async ()  => {
  if (props.preload) {
    loadData()
  }
})

const props = defineProps({
  type: {
    type: String,
    default: 'text',
  },
  value: {
    type: [String, Object],
    default: undefined,
  },
  display: {
    type: [String, Function],
    required: false,
    default: null
  },
  endpoint: {
    type: String,
    required: true
  },
  name: {
    type: String,
    required: true,
  },
  label: {
    type: String,
    required: true,
  },
  successMessage: {
    type: String,
    default: '',
  },
  placeholder: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  onChanged: {
    type: Function,
    required: false,
    default: () => {}
  },
  preload: {
    type: Boolean,
    required: false,
    default: false
  }
});

// use `toRef` to create reactive references to `name` prop which is passed to `useField`
// this is important because vee-validte needs to know if the field name changes
// https://vee-validate.logaretm.com/v4/guide/composition-api/caveats
const name = toRef(props, 'name');

let searchTerm = ref('')
let timeout = null
let wait = 400

const options = ref([]);

const loadData = async () => {
  try {
    let filterBy = null;
    if (searchTerm.value) {
      filterBy = searchTerm.value
    }
    options.value = await RelationalInputServiceService.getOptions(props.endpoint,1, 50, filterBy);
  } catch (error) {
    console.error('Erro ao carregar processos:', error);
  }
}

const onFilter = () => {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    loadData()
  }, wait);
}


const onShowDetails = () => {

}


watch(() => props.value, (newSchema) => {


}, { deep: true }); // Define deep para observar alterações profundas


const selectOption = (option) => {
  setValue(option)
  searchTerm.value = ''




  props.onChanged(option)
  emit('onChanged',option)

}

const clean = () => {
  searchTerm.value = ''
  setValue(null)
  emit('onChanged',null)

}


// Computed property to check if customProp is a function
const isFunction = computed(() => typeof props.display === 'function');




// Function to get the value from customProp
const getDisplayPropValue = (option) => {
  if (!props.display) {
    return  option
  }

  return isFunction.value ? props.display(option) : option[props.display];
};






// we don't provide any rules here because we are using form-level validation
// https://vee-validate.logaretm.com/v4/guide/validation#form-level-validation
const {
  value: inputValue,
  errorMessage,
  handleBlur,
  handleChange,
  meta,
  setValue,
} = useField(name, undefined, {
  initialValue: props.value,
});
</script>

<style scoped>
.focusable {
}

.focusable:focus {
}

.focusable:focus, .focusable:hover {
  outline: 1px solid var(--bs-body-color) !important;
}

.dark .focusable:focus, .dark .focusable:hover {
  outline: 1px solid var(--bs-body-bg) !important;
}

ul {
  list-style: none;
  padding: 0;

}

.input-group {
  border: 1px solid #dedee4;
}

.input-group-text {
  padding: 0px 11px;
  border: 0;
}

.component-container {
  position: absolute;
  z-index: 9999;
}

.dark .relationInput .input-group  {
  border-color: #45445e;

}

.dark .relationInput .input-group-text {
  background-color: var(--splash-black-color);
}

.relationInput .input-group-text {
  background-color: var(--bs-body-bg);
}

</style>

