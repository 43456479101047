<template>
  <div class="card mb-25 border-0 rounded-0 bg-white">
    <div class="card-body p-15 p-sm-20 p-md-25 p-lg-30 letter-spacing">
      <div class="mb-15 mb-md-30 d-sm-flex align-items-center justify-content-between">
        <!-- Título alinhado à esquerda -->
        <h6 class="card-title fw-bold mb-0 me-auto">Clientes ({{ totalItems }}) </h6>
        <!-- Container para filtro e pesquisa alinhados à direita -->
        <div class="d-flex align-items-center">
          <!-- Pesquisa -->
<!--          <form>-->
<!--            <div class="input-group">-->
<!--              <input-->
<!--                  type="text"-->
<!--                  class="form-control shadow-none fw-medium ps-12 pt-8 pb-8 pe-12 letter-spacing"-->
<!--                  placeholder="Search"-->
<!--              />-->
<!--              <button-->
<!--                  class="default-btn position-relative transition border-0 text-white ps-12 pe-12 rounded-1"-->
<!--                  type="button"-->
<!--              >-->
<!--                <i class="flaticon-search-interface-symbol position-relative"></i>-->
<!--              </button>-->
<!--            </div>-->
<!--          </form>-->
        </div>
      </div>
      <div class="table-responsive">
        <table class="table text-nowrap align-middle mb-0">
          <thead>
          <tr>
            <th scope="col" class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0 ps-0">
              CLIENTE
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              ANALISTA
            </th>

            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              CLASSIFICAÇÃO
            </th>
            <th
                scope="col"
                class="text-uppercase fw-medium shadow-none text-body-tertiary fs-13 pt-0"
            >
              N. PROCESSOS
            </th>
          </tr>
          </thead>
          <tbody>
          <!-- Loop através do `items` para renderizar os dados -->
          <tr v-for="(item, i) in items" :key="i">
            <th class="shadow-none lh-1 fw-bold ps-0">

              <router-link
                  class="d-block text-decoration-none text-black"
                  target="_blank"
                  rel="noopener noreferrer"
                  :to="{name: 'ProcessoDetalhes', params: {id: item.cliente_id}}"
              >
                {{ item.cliente }}
              </router-link>
            </th>
            <td class="shadow-none lh-1 fw-medium">
              {{ item.analista }}
            </td>
            <td class="shadow-none lh-1 fw-medium text-black-emphasis">
              <div class="d-flex align-items-center">
                {{ item.classificacao }}
              </div>
            </td>
            <td class="shadow-none lh-1 fw-medium text-body-tertiary">
              <div class="d-flex align-items-center">
                {{ item.nProcessos }}
              </div>
            </td>
          </tr>
          <tr>
            <th class="shadow-none lh-1 fw-bold ps-0" colspan="3">
              Total
            </th>

            <td class="shadow-none lh-1 fw-medium text-body-tertiary">
              <div class="d-flex align-items-center">
                {{ nProcessos }}
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>

<script setup>
import {formatDate} from "../../../utils/dateHelper.js";
import {formatDecimal} from "../../../utils/numberHelper.js";
import {computed} from "vue";

// const emit = defineEmits(['changed']);




const props = defineProps({
  items: {
    type: Array,
    required: true,
    default: () => []
  },
  totalItems: {
    type: Number,
    required: false,
    default: 0
  }
});

const nProcessos = computed(() => {

  return props.items.reduce((acumulador, el) => acumulador + el.nProcessos, 0)

})

</script>
