<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>
  <BreadCrumb/>
  <Actions @onAction="onAction" @onRefresh="refreshPage" />
  <TarefaForm v-if="!pageStatus.showHistorico" />

</template>

<script setup>
import BreadCrumb from "@/components/common/BreadCrumb.vue";
import TarefaForm from "@/views/Agenda/TarefaForm.vue";
import Actions from "@/views/Agenda/Actions.vue";
import AgendaService from "@/services/AgendaService.js";
import Loading from "vue-loading-overlay";
import {ref} from "vue";


const isLoading = ref(false);



const pageStatus = ref({
  showHistorico : false,
  agendaId: null
})


const onAction = (props) => {

  switch (props.action) {
    case 'showHistorico':
        showHistorico(props.agendaId)
          break;
    case 'showAgenda':
      showAgenda()
      break;
  }

}

const refreshPage = () => {
  console.log('refresh')
  window.location.reload();
}

const showHistorico = async (agendaId) =>  {
  isLoading.value = true

  //let historicos = await AgendaService.getAcompanhamentos(agendaId, 1, 10, null);
  pageStatus.value.agendaId = null
  pageStatus.value.showHistorico = true

  // Force change
  setTimeout(() => {
    pageStatus.value.agendaId = agendaId
  }, 100)



  isLoading.value = false
}

const showAgenda = async () =>  {


  //let historicos = await AgendaService.getAcompanhamentos(agendaId, 1, 10, null);

  pageStatus.value.showHistorico = false


}

</script>
