export const ProcessoSituacaoService = {
    getListSituacaoRecebimentoBlOriginal() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Recebido', id: 1 },
            { nome: 'Enviado via courier', id: 2 },
            { nome: 'Em posse cliente', id: 3 },
            { nome: 'Em posse agente', id: 4 },
            { nome: 'Em posse despachante', id: 5 },
            { nome: 'Aguardando informação', id: 99 },

        ];
    },
    getSituacaoRecebimentoBlOriginal() {
        return (this.getListSituacaoRecebimentoBlOriginal());
    },
    getListSituacaoNumerario() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Recebido', id: 1 },
            { nome: 'Emitido', id: 2 },
            { nome: 'Aguardando recebimento', id: 3 },
            { nome: 'Pendente emissão', id: 4 },
            { nome: 'Sem ação necessária', id: 9 },
        ];
    },
    getSituacaoNumerario() {
        return (this.getListSituacaoNumerario());
    },
    getListSituacaoMapaVegetal() {
        return [
            { nome: 'Não informado', id: 99 },
            { nome: 'Liberado', id: 1 },
            { nome: 'Aguardando análise', id: 2 },
            { nome: 'Conferencia física', id: 3},
        ];
    },
    getSituacaoMapaVegetal() {
        return (this.getListSituacaoMapaVegetal());
    },
    getListSituacaoCarregamento() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Agendado', id: 1 },
            { nome: 'Aguardando agendamento', id: 2 },
            { nome: 'Carregamento Atrasado', id: 3},
            { nome: 'Reagendamento (Outros motivos)', id: 9},

        ];
    },

    getSituacaoCarregamento() {
        return (this.getListSituacaoCarregamento());
    },
    getListSituacaoSeguro() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Averbado', id: 1 },
            { nome: 'Solicitar', id: 2 },
            { nome: 'Ag. Aprovação', id: 6 },
            { nome: 'Atrasado', id: 3 },
            { nome: 'CIF', id: 4 },
            { nome: 'Sem seguro', id: 5 },
        ];
    },
    getSituacaoSeguro() {
        return (this.getListSituacaoSeguro());
    },
    getListSituacaoPagamentoFrete() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Pago', id: 1 },
            { nome: 'Solicitar', id: 2 },
            { nome: 'Aguardando Valores', id: 3 },
            { nome: 'Atrasado', id: 4 },
        ];
    },
    getSituacaoPagamentoFrete() {
        return (this.getListSituacaoPagamentoFrete());
    },
    getListSituacaoLiberacaoDocumental() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Liberado', id: 1 },
            { nome: 'Apresentado', id: 2 },
            { nome: 'Em análise', id: 3 },
            { nome: 'Atrasado', id: 4 },
        ];
    },
    getSituacaoLiberacaoDocumental() {
        return (this.getListSituacaoLiberacaoDocumental());
    },

    getListSituacaoLi() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Deferida', id: 1 },
            { nome: 'Apresentada', id: 2 },
            { nome: 'Em análise', id: 3 },

        ];
    },
    getSituacaoLi() {
        return (this.getListSituacaoLi());
    },

    getListSituacaoExoneracaoICMS() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Exonerado', id: 1 },
            { nome: 'Pago', id: 2 },
            { nome: 'Em análise', id: 3 },
            { nome: 'Solicitar', id: 4 },
            { nome: 'Atrasado', id: 5 },
        ];
    },
    getSituacaoExoneracaoICMS() {
        return (this.getListSituacaoExoneracaoICMS());
    },
    getListSituacaoLiberacaoComex() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Liberado', id: 1 },
            { nome: 'Pendente Pagamento', id: 2 },
            { nome: 'Atrasado', id: 3 },
        ];
    },
    getSituacaoLiberacaoComex() {
        return (this.getListSituacaoLiberacaoComex());
    },
    getListSituacaoPagamentoArmazenagem() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Pago', id: 1 },
            { nome: 'Pendente Pagamento', id: 2 },
            { nome: 'Atrasado', id: 3 },
            { nome: 'Pagamento Futuro', id: 4 },
        ];
    },
    getSituacaoPagamentoArmazenagem() {
        return (this.getListSituacaoPagamentoArmazenagem());
    },
    getListSituacaoEmissaoNF() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Emitida', id: 1 },
            { nome: 'Solicitar', id: 2 },
            { nome: 'Aguardando Emissão', id: 3},
            { nome: 'Atrasado', id: 4},

        ];
    },
    getSituacaoEmissaoNF() {
        return (this.getListSituacaoEmissaoNF());
    },
    getListCanalParametrizacao() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Verde', id: 1 },
            { nome: 'Amarelo', id: 2 },
            { nome: 'Vermelho', id: 3},
            { nome: 'Cinza', id: 4},

        ];
    },
    getCanalParametrizacao() {
        return (this.getListCanalParametrizacao());
    },


    getListSituacaoInvoice() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Original', id: 1 },
            { nome: 'Cópia', id: 2 },
            { nome: 'Pendência', id: 3 }
        ];
    },
    getListSituacaoDiDigitada() {
        return [
            { nome: 'Selecione...', id: null },
            { nome: 'Aguardando Análise', id: 1 },
            { nome: 'Pendência', id: 2 },
            { nome: 'Aprovado', id: 3 }
        ];
    },
    getSituacaoInvoice() {
        return (this.getListSituacaoInvoice());
    },
    getSituacaoDiDigitada () {
        return (this.getListSituacaoDiDigitada());
    }

};
