import Httpservice from "@/services/httpservice.js";

const API_URL = import.meta.env.VITE_API_URL;

const getClientesResponsaveis = async (pessoaId) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/pessoa/cliente/${pessoaId}/responsaveis`,{});
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar informações do cliente:', error);
        throw error;
    }
};

const getClientes = async (pageNumber, pageSize, situacao, responsavelId, searchTerm) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/cliente/list`,{
            params: {
                pageNumber,
                pageSize,
                situacao,
                searchTerm,
                responsavelId,
            }
        });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar processos:', error);
        throw error;
    }
};

const getCliente = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().get(`${API_URL}/api/cliente/${id}`,{ });
        return response.data;
    } catch (error) {
        console.error('Erro ao buscar cliente', error);
        throw error;
    }
};

const updateCliente = async (id, values) => {
    try {
        const response = await Httpservice.getAxiosClient().put(`${API_URL}/api/cliente/${id}`,values);
        return response.data;
    } catch (error) {
        console.error('Erro ao salvar o cliente', error);
        throw error;
    }
};

const updateAnalistaResponsavel = async (id) => {
    try {
        const response = await Httpservice.getAxiosClient().post(`${API_URL}/api/cliente/${id}/alterar-analista`, {});
        return response.data;
    } catch (error) {
        console.error('Erro ao alaterar o analista responsavel do cliente', error);
        throw error;
    }
};



const getListRegimesTributarios = () => {
    return [
        { nome: 'Lucro Real', id: 1 },
        { nome: 'Lucro Presumido', id: 2 },
        { nome: 'Simples Nacional', id: 3 },
    ];
}

const getRegimesTributario =  (optional) => {
    if(optional === true) {
       return  [{nome: 'Selecione', id: null}, ...getListRegimesTributarios()]
    }
    return getListRegimesTributarios()
};


const getListDebitoTributosFederais = () => {
    return [
        { nome: 'Cliente', id: 1 },
        { nome: 'Prana', id: 2 },

    ];
}

const getDebitoTributosFederais =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListDebitoTributosFederais()]
    }
    return getListDebitoTributosFederais()
};


const getListFinalidadesMercadorias = () => {
    return [
        { nome: 'Revenda', id: 1 },
        { nome: 'Industrialização', id: 2 },
        { nome: 'Consumo', id: 3},
        { nome: 'Ativo', id: 4},
        { nome: 'Revenda / Industrialização', id: 5},
        { nome: 'Revenda / Ativo', id: 6},
        { nome: 'Revenda / Consumo', id: 7},
        { nome: 'Industrialização / Ativo', id: 8},
        { nome: 'Industrialização / Consumo', id: 9},
        { nome: 'Revenda / Industrialização / Ativo', id: 10},
        { nome: 'Revenda / Industrialização / Consumo', id: 11},

    ];
}

const getFinalidadesMercadorias =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListFinalidadesMercadorias()]
    }
    return getListFinalidadesMercadorias()
};




const getListPagamentoFreteInternacionalTaxas = () => {
    return [
        { nome: 'Cliente', id: 1 },
        { nome: 'Prana', id: 2 },
    ];
}

const getPagamentoFreteInternacionalTaxas =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListPagamentoFreteInternacionalTaxas()]
    }
    return getListPagamentoFreteInternacionalTaxas()
};



const getListFinalidades = () => {
    return [
        { nome: 'Revenda', id: 1 },
        { nome: 'Industrialização', id: 2 },
        { nome: 'Consumo', id: 3},
        { nome: 'Ativo', id: 4}
    ];
}

const getFinalidades =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListFinalidades()]
    }
    return getListFinalidades()
};


const getListServicoTipo = () => {
    return [
        { nome: 'Conta e Ordem', id: 1 },
        { nome: 'Encomenda', id: 2 },
        { nome: 'Conta própria', id: 3},
        { nome: 'Conta e Ordem/Encomenda ', id: 4},
        { nome: 'Conta e Ordem/Conta própria ', id: 5},
        { nome: 'Encomenda/Conta própria ', id: 6}
    ];
}

const getServicoTipo =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListServicoTipo()]
    }
    return getListServicoTipo()
};



const getListFaixasSalariosMinimos = () => {
    return [
        { nome: '1/4 salário mínimo', id: 1 },
        { nome: '1/2 salário mínimo', id: 2 },
        { nome: '1 salário mínimo', id: 3},
        { nome: '2 salários mínimos', id: 4 },

    ];
}

const getFaixasSalariosMinimos =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListFaixasSalariosMinimos()]
    }
    return getListFaixasSalariosMinimos()
};



const getListTiposEndereco = () => {
    return [
        { nome: 'Fiscal(Faturamento)', id: 1 },
        { nome: 'Responsavel (CObrança)', id: 2 },
        { nome: 'Geográficos', id: 3 },
        { nome: 'Entrega', id: 4 },
        { nome: 'Trabalho', id: 5 },
        { nome: 'Residencial', id: 6 },
        { nome: 'Armazem (Principal)', id: 7 },
        { nome: 'Armazem (Secundário)', id: 8 },
        { nome: 'Outros', id: 9 },
        { nome: 'Comercial', id: 10 },
    ];

}

const getTiposEndereco =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListTiposEndereco()]
    }
    return getListTiposEndereco()
};


const getListModalidadesRadar = () => {
    return [
        { nome: 'Expresso', id: 1 },
        { nome: 'Limitado', id: 2 },
        { nome: 'Ilimitado', id: 4 },

    ];

}

const getModalidadesRadar =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListModalidadesRadar()]
    }
    return getListModalidadesRadar()
};



const getListAssessoriaCompraCommodity = () => {
    return [
        { nome: 'Químicos e petroquímicos', id: 1 },
        { nome: 'Outros', id: 999 },

    ];

}

const getAssessoriaCompraCommodity =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListAssessoriaCompraCommodity()]
    }
    return getListAssessoriaCompraCommodity()
};


const getListDespesaAdministrativaTipo = () => {
    return [
        { nome: 'Isenção', id: 1 },
        { nome: 'Suspensão', id: 2 },
        { nome: 'Intermediário', id: 3},
        { nome: 'Isenção/Suspensão', id: 4},
    ];
}

const getDespesaAdministrativaTipo =  (optional) => {
    if(optional === true) {
        return  [{nome: 'Selecione', id: null}, ...getListDespesaAdministrativaTipo()]
    }
    return getListDespesaAdministrativaTipo()
};

export default {
    getClientesResponsaveis,
    getClientes,
    getCliente,
    updateCliente,
    updateAnalistaResponsavel,
    getRegimesTributario,
    getDebitoTributosFederais,
    getFinalidadesMercadorias,
    getPagamentoFreteInternacionalTaxas,
    getFinalidades,
    getServicoTipo,
    getFaixasSalariosMinimos,
    getTiposEndereco,
    getModalidadesRadar,
    getAssessoriaCompraCommodity,
    getDespesaAdministrativaTipo,
};