// src/router/containers.js
import TipoContainerListPage from "@/views/TiposContainers/TipoContainerListPage.vue";
import NovoTipoContainerPage from "@/views/TiposContainers/NovoTipoContainerPage.vue";

const containersBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'Containers',
        to: 'Containers'
    }
]

export default [
    {
        path: '/tipos-containers',
        name: 'TiposContainers',
        component: TipoContainerListPage,
        meta: {
            title: 'Containers',
            breadcrumb: containersBreadCrumb
        },
    },
    {
        path: '/tipos-containers/novo',
        name: 'TipoContainerNovo',
        component: NovoTipoContainerPage,
        meta: {
            title: 'Novo Tipo Container',
            breadcrumb: [
                ...containersBreadCrumb,
                {
                    label: 'Novo Tipo Container',
                    to: 'TipoContainerNovo'
                }
            ]
        }
    },
    {
        path: '/tipos-containers/:id/edit',
        name: 'TipoContainerEdit',
        component: NovoTipoContainerPage,
        meta: {
            title: 'Detalhes do tipo container',
            breadcrumb: [
                ...containersBreadCrumb,
                {
                    label: 'Novo Tipo Container',
                    to: 'TipoContainerNovo'
                }
            ]
        }
    }

];
