<template>
  <div class="vessel-info">
    <h2>Informações da Embarcação</h2>
    <p><strong>IMO Number:</strong> {{ vessel.vesselIMONumber }}</p>
    <p><strong>Carrier Vessel Code:</strong> {{ vessel.carrierVesselCode }}</p>
    <p><strong>Nome da Embarcação:</strong> {{ vessel.vesselName }}</p>
    <p><strong>Flag Code:</strong> {{ vessel.vesselFlagCode }}</p>
    <p><strong>Call Sign:</strong> {{ vessel.vesselCallSign }}</p>
  </div>
</template>

<script>
export default {
  name: 'VesselInfo',
  props: {
    vessel: Object,
  },
};
</script>

<style scoped>
.vessel-info {
  margin-bottom: 20px;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
}
</style>
