<template>
  <loading v-model:active="isLoading" :can-cancel="false" :is-full-page="true"/>

  <div className="row">
    <div className="col-12">
      <div className="row">
        <div className="col-lg-5 col-xxxl-5 col-md-5">
          <prev-faturamento-card :total-por-empresa="result.totalPorEmpresa" :year="result.ano" :month="result.mes"  :prev-total-mes="result.faturamentoTotal" :prev-total-mes-anterior="result.faturamentoTotalAnterior"  :situacao-di="situacaoDI" />
        </div>
        <div className="col-lg-7 col-xxxl-7 col-md-7">
          <prev-faturamento-media-mensal-card :year="result.ano" :month="result.mes" :media-mensal="result.mediaMensal" :situacao-di="situacaoDI" />
        </div>
      </div>
    </div>

    <div className="col-xxl-12">
      <!-- Passe o objeto `processos` completo como prop -->
      <Processos :processos="processos.items" :year="result.ano" :month="result.mes" :situacao-di="situacaoDI"  @changed="filterChanged"/>
    </div>
  </div>
</template>

<script setup>
import {ref, onBeforeMount} from 'vue';

import Loading from 'vue-loading-overlay';
import Processos from "@/views/Relatorios/PrevisaoFaturamento/Processos.vue";
import RelatorioService from "@/services/RelatorioService.js";

import PrevFaturamentoCard from "@/views/Relatorios/PrevisaoFaturamento/PrevFaturamentoCard.vue";
import PrevFaturamentoMediaMensalCard from "@/views/Relatorios/PrevisaoFaturamento/PrevFaturamentoMediaMensalCard.vue";
const isLoading = ref(false);
let today = new Date();

let year = today.getFullYear();
let month = today.getMonth() + 1;
let situacaoDI = null;
let filial = null;

let result = ref({});

let processos = ref([]); // Reactive ref for processos


async function getData () {
  isLoading.value = true;
  result.value = await RelatorioService.getPrevFaturamentoMensal(1, 200, year, month, filial, situacaoDI);

  console.log('result.value.processos', result.value.processos)

  // Atualize `processos` diretamente
  processos.value = result.value.processos;

  isLoading.value = false;
}

async function filterChanged(filtros) {
  year = filtros.year
  month = filtros.month
  situacaoDI = filtros.situacaoDI


  await getData()
}

onBeforeMount(async () => {
  result.value = {
    "totalPorEmpresa": [],
    "numeroProcessos": 0,
    "numeroProcessosAnterior": 0,
    "faturamentoTotal": 0,
    "faturamentoTotalAnterior": 0,
    "faturamentoDiRegistrada": 0,
    "faturamentoDiPendente": 0,
    "mes": month,
    "ano": year,
    "processos": {
      "items": [],
      "totalItems": 0,
      "pageNumber": 1,
      "pageSize": 20,
      "searchTerm": null,
      "totalPages": 0,
      "from": 0,
      "to": 0
    }
  };



  await getData();



});
</script>
