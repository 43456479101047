<template>
  <loading v-model:active="isLoading"
           :can-cancel="false"
           :is-full-page="true"/>
  <DynamicForm ref="dynamicForm" :schema="formSchema" cancel-route="Depots" :initial-values="formData"
               @onSubmit="onSubmit"/>

  <!--  <Toast />-->
  <div class="action-point-2  bg-white" :class="{'opened': !stateStoreInstance.open}">
  </div>

</template>


<script setup>
// RN 001 - Retornar a obrigatoriedade após data definida.
import {reactive, ref, onBeforeMount, inject, computed} from 'vue';
import DynamicForm from "@/components/form/DynamicForm.vue"
import stateStore from "@/utils/store";
import Loading from 'vue-loading-overlay';

import * as Yup from 'yup';
import RelationInput from "@/components/form/RelationInput.vue";
import TextInput from "@/components/form/TextInput.vue";
import DateInput from "@/components/form/DateInput.vue";
import router from "@/router/index.js";
import DepotService from "@/services/DepotService.js";
import ClienteService from "@/services/ClienteService.js";

import {TipoOperacaoService} from "@/services/TipoOperacaoService.js";
import SelectInput from "@/components/form/SelectInput.vue";
import {CambioService} from "@/services/CambioService.js";
import {useRoute} from "vue-router";
import {TipoCargaService} from "@/services/TipoCargaService.js";
import {ProcessoSituacaoService} from "@/services/ProcessoSituacaoService.js";
import TextAreaInput from "@/components/form/TextAreaInput.vue";
import RepeaterInput from "@/components/form/RepeaterInput.vue";
import {useToast} from "@/plugins/useToast.js";
import CheckInput from "@/components/form/CheckInput.vue";
import DateYearMonthInput from "@/components/form/DateYearMonthInput.vue";
import EnderecoService from "@/services/EnderecoService.js";
import NumberInput from "@/components/form/NumberInput.vue";
import Separator from "@/components/form/Separator.vue";
import SeparatorCheckInput from "@/components/form/SeparatorCheckInput.vue";
import HiddenInput from "@/components/form/HiddenInput.vue";
import {getFormatedFormData} from "@/utils/formHelper.js";
import ButtonInput from "@/components/form/ButtonInput.vue";

const {showToast} = useToast();

const stateStoreInstance = stateStore;

let pessoaEmptyObject = {
  nome: null,
  apelido: null,
  email: null,
  anoNaoInformado: false,
  nascimento: null,
  cargo: null,
  telefoneComercial: null,
  telefoneComercialRamal: null,
  celular: null,
  configuracoes: null
}

let enderecoEmptyObject = {
  tipo: null,
  cep: null,
  logradouro: null,
  numero: 0,
  complemento: null,
  bairro: null,
  estado: null
}


let regraCobrancaEmptyObject = {
  tipo: null,
  icmsDestacado: null,
  icmsNegociado: null,
  finalidade: null,
  camexEx: null,
  conv5291: null,
}

let contaEmptyObject = {
  instituicao: null,
  contaCorrente: null,
  agencia: null
}

const currentRoute = useRoute();
const dynamicForm = ref(null);

// Initial Data - Valor inicial do formulário
const formData = reactive({
  contasBancarias: [
    // contaEmptyObject
  ],
  contatos: [
    // pessoaEmptyObject
  ],
  regrasCobrancas: [
    regraCobrancaEmptyObject
  ],
  servicoTrading: true
});

const isLoading = ref(false);

onBeforeMount(async () => {
  if (currentRoute.params.hasOwnProperty('id')) {
    isLoading.value = true;
    let depot = await DepotService.getDepot(currentRoute.params.id);

    Object.assign(formData, depot);

    updateFormValues(getFormatedFormData(formSchema, formData))
    isLoading.value = false;
  } else {

  }
})


const onSubmit = async (values) => {
  isLoading.value = true

  if (currentRoute.params.hasOwnProperty('id')) {
    try {
      let result = await DepotService.updateDepot(currentRoute.params.id, values)

      showToast('Depot salvo.', {
        title: 'Sucesso',
        type: 'success'
      });

      router.push({name: 'Depots'});
    } catch (ex) {
      isLoading.value = false;
    }
  } else {
    try {
      let result = await  DepotService.createDepot(values)
      showToast('Depot salvo.', {
        title: 'Sucesso',
        type: 'success'
      });

      router.push({name: 'Depots'});
    } catch (ex) {
      isLoading.value = false;
    }
  }

  isLoading.value = false;
}


const updateFormValues = (values) => {

  if (dynamicForm.value) {
    dynamicForm.value.updateFormValues(values)
  }



};

const updateFieldValue = (fieldName, value) => {

  if (dynamicForm.value) {
    dynamicForm.value.updateFieldValue(fieldName, value)
  }
};


const showSeguroInfo = computed(() => {
  if (dynamicForm.value) {
    let possuiSeguro = dynamicForm.value.getFieldValue('possuiSeguroCredito')

    return (possuiSeguro === true)
  }
});








const showVinculoSalarioGeral = (checkField1Name, checkField2Name) => {
  return computed(() => {
    if (dynamicForm.value) {
      let servico = dynamicForm.value.getFieldValue(checkField1Name)

      if (servico === true || servico === 'true') {

        let servicoVinculoSalarioMinimo = dynamicForm.value.getFieldValue(checkField2Name)

        return (servicoVinculoSalarioMinimo === true || servicoVinculoSalarioMinimo === 'true')
      }
      return false
    }
  })
};


const showOutrosGeral = (checkField1Name, checkField2Name) => {
  return computed(() => {
    if (dynamicForm.value) {
      let servico = dynamicForm.value.getFieldValue(checkField1Name)

      if (servico === true || servico === 'true') {

        let outros = dynamicForm.value.getFieldValue(checkField2Name)

        return (outros === 999 || outros === '999')
      }
      return false
    }
  })
};



const showInfoGeral = (checkFieldName) => {
  return computed(() => {
    if (dynamicForm.value) {
      let servico = dynamicForm.value.getFieldValue(checkFieldName)

      return (servico === true || servico === 'true')
    }
  })
};

const showSemVinculoSalarioGeral = (checkField1Name, checkField2Name) => {
  return computed(() => {
    if (dynamicForm.value) {
      let servico = dynamicForm.value.getFieldValue(checkField1Name)

      if (servico === true || servico === 'true') {

        let servicoVinculoSalarioMinimo = dynamicForm.value.getFieldValue(checkField2Name)

        return (servicoVinculoSalarioMinimo === false || servicoVinculoSalarioMinimo === 'false')
      }
      return false
    }
  })
};




const showFinanceiamentoInfo = computed(() => {
  if (dynamicForm.value) {
    let possuiFinanciamento = dynamicForm.value.getFieldValue('possuiFinanciamento')

    return (possuiFinanciamento === true)
  }
});




const formSchema = {
  sections: [
    {
      section: 'Empresa',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Razão social',
          name: 'razaoSocial',
          type: TextInput,
          col: 'col-12 col-md-5'
        },
        {
          label: 'Nome Fantasia',
          name: 'nome',
          type: TextInput,
          col: 'col-12 col-md-4',
        },
        {
          label: 'CNPJ',
          name: 'documentoFederal',
          mask: '##.###.###/####-##',
          maxLength: 18,
          type: TextInput,
          col: 'col-12 col-md-2'
        },
        {
          label: 'Ativo',
          name: 'ativo',
          type: SelectInput,
          col: 'col-12 col-md-1',
          rules: Yup.string().required(),
          options: [{ nome: 'Selecione..', id: null},{ nome: 'Sim', id: true},{ nome: 'Não', id: false}],
          onChanged: (value) => {
            console.log('value', value)
          }
        },
      ]
    },
    // Endereços
    {
      //section: 'Endereços',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Endereços',
          name: 'enderecos',
          type: RepeaterInput,
          required: true,
          rules: Yup.array()
              .of(
                  Yup.object().shape({
                    tipo: Yup.number().required("Obrigatório"),
                    cep: Yup.string()
                        .required("Obrigatório"),
                    logradouro: Yup.string()
                        .required("Obrigatório"),
                    numero: Yup.string()
                        .required("Obrigatório"),
                    bairro: Yup.string()
                        .required("Obrigatório"),
                    estado: Yup.object()
                        .required("Obrigatório"),


                  })
              )
              .strict(),
          fields: [
            {
              label: 'Tipo',
              name: 'tipo',
              type: SelectInput,
              col: 'col-12 col-md-2',
              options: ClienteService.getTiposEndereco(true),
            },
            {
              label: 'CEP',
              name: 'cep',
              type: TextInput,
              col: 'col-12 col-md-1',
              mask: '#####-###',
              onChanged: (value) => {
                if (value && value.length == 9) {
                  let cep = value.replace('-', '')

                  isLoading.value = true
                  EnderecoService.getCepInfo(cep).then((rs) => {
                    console.log('rs', rs)
                    updateFieldValue('logradouro', rs.logradouro)
                    updateFieldValue('complemento', rs.complemento)
                    updateFieldValue('bairro', rs.bairro)
                    updateFieldValue('estado', rs.uf)

                  }).finally(() => {
                    isLoading.value = false
                  })
                }

              }
            },
            {
              label: 'Logradouro',
              name: 'logradouro',
              type: TextInput,
              col: 'col-12 col-md-4',
            },
            {
              label: 'Número',
              name: 'numero',
              type: TextInput,
              col: 'col-12 col-md-1',
            },
            {
              label: 'Complemento',
              name: 'complemento',
              type: TextInput,
              col: 'col-12 col-md-4',
            },
            {
              label: 'Bairro',
              name: 'bairro',
              type: TextInput,
              col: 'col-12 col-md-4',
            },
            {
              label: 'Estado',
              name: 'estado',
              type: RelationInput,
              endpoint: 'unidade-federativa',
              col: 'col-12 col-md-4',
              display: (value) => {
                return value.nome
              }
            },
          ],
          emptyObject: enderecoEmptyObject,
          col: 'col-12 col-md-12',
        },
      ]
    },
    // Contatos
    {
      //section: 'Contatos',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: 'Contatos',
          name: 'contatos',
          type: RepeaterInput,
          required: true,
          rules: Yup.array()
              .of(
                  Yup.object().shape({
                    nome: Yup.string()
                        .required("Nome é obrigatório"),
                    email: Yup.string()
                        .when('configuracoes', {
                          is: (value) => {
                            return (value && value.includes('1'))
                          },
                          then: () => {
                            return Yup.string()
                                .required('E-mail é obrigatório')
                                .nullable()
                                .email("Não é um e-mail válido")
                          },
                          otherwise: () => {
                            return Yup.string().nullable()
                                .email("Não é um e-mail válido")
                          }
                        }),
                    celular: Yup.string()
                        .when('configuracoes', {
                          is: (value) => {
                            return (value && value.includes('2'))
                          },
                          then: () => {
                            return Yup.string()
                                .nullable()
                                .required('Celular é obrigatório')
                          },
                          otherwise: () => {
                            return Yup.string() .nullable()
                          }
                        })
                  })
              )
              .strict(),
          fields: [
            {
              label: 'Nome',
              name: 'nome',
              type: TextInput,
              col: 'col-12 col-md-2',
            },
            {
              label: 'Apelido',
              name: 'apelido',
              type: TextInput,
              col: 'col-12 col-md-1',
            },
            {
              label: 'E-mail',
              name: 'email',
              type: TextInput,
              col: 'col-12 col-md-2',
            },
            // {
            //   label: 'Não informar ano',
            //   name: 'anoNaoInformado',
            //   type: SelectInput,
            //   col: 'col-12 col-md-1',
            //   rules: Yup.string().required(),
            //   options: [{nome: 'Sim', id: true}, {nome: 'Não', id: false}],
            // },
            {
              label: 'Data Nascimento',
              name: 'dataNascimento',
              type: DateInput,
              col: 'col-12 col-md-2',
            },
            {
              label: 'Cargo',
              name: 'cargo',
              type: RelationInput,
              col: 'col-12 col-md-3',
              endpoint: 'cargo',
              display: (value) => {
                return value.nome
              },
            },
            {
              label: 'Telefone Comercial',
              name: 'telefoneComercial',
              mask: '+## (##) #####-####',
              type: TextInput,
              col: 'col-12 col-md-2 col-md-15-percent',
            },
            {
              label: 'Ramal',
              name: 'telefoneComercialRamal',
              type: TextInput,
              col: 'col-12 col-md-2 col-md-15-percent',
            },
            {
              label: 'Celular',
              name: 'celular',
              mask: '+## (##) #####-####',
              type: TextInput,
              col: 'col-12 col-md-2 col-md-15-percent',
            },
              /*
            {
              label: 'Envio pelo whatsapp',
              name: 'habilitadoEnvioWhatsapp',
              type: SelectInput,
              col: 'col-12 col-md-2',
              options: [{id: null, nome: 'Selecione'}, {id: true, nome: 'Sim'}, {id: false, nome: 'Não'}],
            },
            {
              label: 'Envio pelo e-mail',
              name: 'habilitadoEnvioEmail',
              type: SelectInput,
              col: 'col-12 col-md-2',
              options: [{id: null, nome: 'Selecione'}, {id: true, nome: 'Sim'}, {id: false, nome: 'Não'}],
            },

               */
          ],
          emptyObject: pessoaEmptyObject,
          col: 'col-12 col-md-12',
          //rules: Yup.string().required(),
        },
      ]
    },
    {
      section: 'Observações',
      col: 'col-12 col-md-12',
      fields: [
        {
          label: '',
          name: 'observacao',
          type: TextAreaInput,
          col: 'col-12 col-md-12',
          // rules: Yup.string().required(),
        },
      ]
    }
  ],
};

</script>

<style scoped>
.action-point-2 {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 9999;
  padding: 10px;
  transition: width 0.8s ease, transform 0.8s ease; /* Animação suave */
  transform: translateX(0px); /* Move o elemento para a esquerda */
}

.action-point-2.opened {
  left: 280px;
  transform: translateX(0px); /* Move o elemento para a esquerda */
}
</style>

<style>
.separatorItem {

  background-color: #ccc;
}
</style>