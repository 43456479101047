<template>
  <ColumnsSettingsModal ref="columnsSettingsModal"  :section="section"  :columns="columns" @on-apply="onColumnsSettingsChanged" />
  <filters-pannel :filters="localFilters" :filterSchema="filterForm" :filterOpened="filterOpened" @onSubmit="applyFilters" @onCancel="closeFilter"  />
  <div class="card mb-25 border-0 rounded-0 bg-white letter-spacing">
    <!-- Filters -->
    <div  v-if="allowFilter"
        class="card-head box-shadow bg-white d-lg-flex align-items-center justify-content-between p-15 p-sm-20 p-md-25"
    >
      <div class="d-sm-flex align-items-center">
        <form class="search-box position-relative">
          <input
              type="text"
              class="form-control shadow-none text-black rounded-0 border-0"
              :placeholder="$t('Buscar')"
              v-model="searchTerm"
              @keydown.enter.prevent
          />
          <button
              type="submit"
              class="bg-transparent text-primary transition p-0 border-0"
          >
            <i class="flaticon-search-interface-symbol"></i>
          </button>
        </form>
      </div>

      <div class="d-sm-flex align-items-center mt-10 mt-lg-0">
        <router-link
            v-if="newAction"
            :to="newAction"
            class="default-btn position-relative transition border-0 fw-medium text-white pt-11 pb-11 ps-25 pe-25 pt-md-11 pb-md-11 ps-md-30 pe-md-30 rounded-1 bg-success fs-md-15 fs-lg-16 d-inline-block d-inline-block text-decoration-none"
        >
          {{ $t(newLabel ? newLabel : 'Novo') }}
          <i class="flaticon-plus position-relative ms-5 fs-12"></i>
        </router-link>
        <div  v-for="(filtro, i) in localStatusFilters" v-bind:key="i" class="mt-10 mt-sm-0 ms-sm-10">
                  <select
                      class="project-select form-select shadow-none fw-semibold rounded-1 "
                      v-model="localStatusFilters[i].value"
                      @change="onStatusFilterChanged"
                  >
                    <option v-for="option in filtro.options"
                            :selected="option.value == localStatusFilters[i].value"
                            v-bind:key="option.value"
                            :value="option.value">
                      {{ option.name }}
                    </option>
                  </select>
        </div>

        <div class=" mt-10 mt-sm-0 ms-sm-10">
          <button @click="openFilter()"
                  class="btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
                  type="button"
                  aria-expanded="false"
          >
            <span style="font-size: 30px;">
              <i class="ph ph-funnel-simple"></i>
            </span>
            <span v-if="hasFilters"
                  class="position-absolute top-10 start-100 translate-middle p-2 bg-danger border border-light rounded-circle">
              </span>
          </button>
        </div>

        <div class="dropdown mt-10 mt-sm-0 ms-sm-10">
          <button
              class="dropdown-toggle card-dot-btn lh-1 position-relative top-4 bg-transparent border-0 shadow-none p-0 transition"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
          >
            <i class="flaticon-dots"></i>
          </button>
          <ul class="dropdown-menu">
            <li v-if="allowToggleOnlyMy && somenteMeus" @click="toggleResponsavel()">
              <a
                  class="dropdown-item d-flex align-items-center"
              >
                <i class="flaticon-view lh-1 me-8 position-relative top-1"></i>
                Visualizar todos
              </a>
            </li>
            <li v-else-if="allowToggleOnlyMy" @click="toggleResponsavel()">
              <a
                  class="dropdown-item d-flex align-items-center"
              >
                <i class="flaticon-calendar lh-1 me-8 position-relative top-1"></i>
                {{ filterMyLabel }}
              </a>
            </li>
            <li>
              <a
                  class="dropdown-item d-flex align-items-center"
                  @click="onColumnsSettingsOpen"
              >
                <i class="flaticon-settings lh-1 me-8 position-relative top-1"></i>
                Colunas
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <applied-filters v-if="hasFilters" :filters="localFilters" :form-schema="filterForm" @on-clear-filters="clearFilters" @onRemoveItem="removeFilter"  @onRemoveMultipleItem="removeMultipleFilter" />
    <div class="card-body p-15 p-sm-20 p-md-25">
      <div class="dataGrid">
        <EasyDataTable
            v-model:server-options="serverOptions"
            :server-items-length="serverItemsLength"
            :loading="loading"

            :headers="localColumns.filter(p => {
              return p.visible != false
            })"
            :items="items"
            :multi-sort="multiSort"
            @click-row="handleClick"
            :body-row-class-name="getRowClass"

        >
          <template v-for="(header, hIndex) in columns" v-slot:[`item-${header.value}`]="item" v-bind:key="hIndex" >
            <slot  v-if="slots[`item-${header.value}`]"  :name="`item-${header.value}`" :item="item" />
            <div :style="{width: header.width+'px'}"  v-else>
              <div v-if="header.format" class="limited-text" :title="header.format(item)" >
                {{ header.format(item) }}
              </div>
              <div v-else class="limited-text" :title="item[header.value]" >
              {{ item[header.value] }}
              </div>
            </div>
          </template>


          <template #empty-message>
            Nenhum item encontrado.
          </template>
        </EasyDataTable>
        <Pagination :total-paginas="totalPaginas" :pagina-atual="serverOptions.page" :por-pagina="serverOptions.rowsPerPage"
                    :total-items="totalItems"
                    :item-inicial="itemInicial"
                    :item-final="itemFinal"
                    @onPagination="OnPagination"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import {ref, toRef, watch, onBeforeMount, defineEmits, computed, inject, onMounted, onBeforeUnmount} from "vue";
import DataGridService from "@/services/DataGridService.js";
import Pagination from "@/components/common/Pagination.vue";
import ColumnsSettingsModal from "@/components/dataGrid/Modal/ColumnsSettingsModal.vue";
import { useSlots } from 'vue'
import {useToast} from "@/plugins/useToast.js";
import {useRouter} from "vue-router";
import FiltersPannel from "@/components/common/FiltersPannel.vue";
import AppliedFilters from "@/components/common/AppliedFilters.vue";
import {getFilterData, getStatusFilterData} from "@/utils/formHelper.js";
import KeycloakService from "@/security/KeycloakService.js";
const {showToast} = useToast();
const { currentRoute } = useRouter();

const props = defineProps({
  filterForm: {
    type: Object,
    required: false,
    default: () => {}
  },
  section: {
    type: String,
    required: true,
  },
  endpoint: {
    type: String,
    required: true
  },
  columns: {
    type: Array,
    required: true,
    default: () => []
  },
  defaultFilters: {
    type: Object,
    required: false,
    default: () => {
      return {
        statusFilters: [],
        filters: null
      }
    }
  },
  allowFilter: {
    type: Boolean,
    required: false,
    default: true
  },
  initialSortBy: {
    type: Array,
    required: false,
    default: () => []
  },
  allowToggleOnlyMy: {
    type: Boolean,
    required: false,
    default: true
  },
  newAction: {
    type: String,
    required: false,
    default: null
  },
  newLabel: {
    type: String,
    required: false,
    default: null
  },
  filterMyLabel: {
    type: String,
    required: false,
    default: 'Somente Meus'
  },
  getRowClass: {
    type: Function,
    required: false,
    default: () => null
  },
  forceReload: {
    type: Date,
    required: false,
    default: null
  }
});

const registerShortcut = inject('registerShortcut');
const unregisterShortcut = inject('unregisterShortcut');


const filterOpened = ref(false)
const slots = useSlots()
const multiSort = ref(true)
const columnsSettingsModal = ref(null)

let timeout = null;
let clickTimeout = null;
const wait = 400;
const somenteMeus = ref(null);

const emit = defineEmits(['onClickRow', 'onDbClickRow']);

const serverOptions = ref({
  page: 1,
  rowsPerPage: 20,
  sortBy: [],
  sortType: [],
});


const localColumns = ref([...props.columns])
const localFilters = ref(props.defaultFilters.filters)
const localStatusFilters = ref([...props.defaultFilters.statusFilters])
const localSortBy = ref([...props.initialSortBy])
const localRowsPerPage = ref(serverOptions.value.rowsPerPage)
const searchTerm = ref(null)




const onColumnsSettingsOpen = () => {
  if (columnsSettingsModal.value) {
    columnsSettingsModal.value.openModal(localColumns.value)
  }
}

const sortByToDataGrid = () => {
  let sortBy = []
  let sortType = []

  for (let i in localSortBy.value) {
    let sort = localSortBy.value[i]

    sortBy.push(sort.propertyName)
    sortType.push(sort.sortOrder)
  }

  serverOptions.value.sortBy = sortBy
  serverOptions.value.sortType = sortType

}


onBeforeMount(async () => {

  originalColumns.value = props.columns

  let savedFilters = await  DataGridService.getLocalSettings(currentRoute.value.name,props.section)
  let savedSomenteMeus = await  DataGridService.getLocalSettings(currentRoute.value.name,'somenteMeus')

  let savedColumns = await  DataGridService.getSettings(currentRoute.value.name, props.section);

  if(savedSomenteMeus !== null && savedSomenteMeus != undefined) {
    somenteMeus.value = savedSomenteMeus;
  }

  if(savedColumns !== null && savedColumns != undefined && savedColumns) {
    let processedColumns = addMissingColumns(savedColumns)
    localColumns.value = processedColumns
  }

  if (savedFilters && Object.keys(savedFilters).length > 0) {

     localFilters.value = savedFilters.filters
     localStatusFilters.value = savedFilters.statusFilters
     localSortBy.value = savedFilters.sortBy

     if(savedFilters.hasOwnProperty('rowsPerPage')) {
        localRowsPerPage.value = savedFilters.rowsPerPage
        serverOptions.value.rowsPerPage = localRowsPerPage.value
     }

     sortByToDataGrid()
   }

  apply()
})



const onColumnsSettingsChanged = async (newColumnsSettings) => {
  localColumns.value = newColumnsSettings.map(item => ({ ...item }));

  try {
    let routeName = currentRoute.value.name
    if(routeName == 'Home') {
      routeName = 'Processos'
    }
    await DataGridService.saveSettings(routeName, props.section, newColumnsSettings)
  } catch (err) {
    showToast('Não foi possível salvar as preferências', {
      title: 'Erro',
      type: 'erro'
    });
  }
}


const onStatusFilterChanged = async () => {
  try {
    let filters = {
      statusFilters: localStatusFilters.value,
      filters: localFilters.value,
      sortBy: localSortBy.value,
      rowsPerPage: localRowsPerPage.value
    }

    await DataGridService.saveLocalSettings(currentRoute.value.name, props.section, filters);
  } catch (err) {
    showToast('Não foi possível salvar os filtros', {
      title: 'Alerta',
      type: 'warning'
    });
  }

  apply()
}

onMounted(() => {
  if (registerShortcut) {
    registerShortcut('Ctrl + Alt + F', () => { onFilterShortCut() },'Filtrar', 'Geral');
    registerShortcut('Ctrl', () => { onNewPageShortCut() },'Abrir em nova aba', 'Geral', () => { onKeyUpNewPageShortCut() });
  }
});


onBeforeUnmount(() => {
  if (unregisterShortcut) {
    unregisterShortcut('Ctrl + Alt + F')
    unregisterShortcut('Ctrl')
  }
})

const onFilterShortCut = () => {
  openFilter()
}

const onNewPageShortCut = () => {
  openNewPage.value = true
}

const onKeyUpNewPageShortCut = () => {
  openNewPage.value = false
}


const openNewPage = ref (false)

const items = ref([]);
const loading = ref(false);
const serverItemsLength = ref(0);
const originalColumns = ref([])


const totalPaginas = ref(0);
const totalItems = ref(0);
const itemInicial = ref(0);
const itemFinal = ref(0);

const getSortOrder = () => {
    let sortOrder = []

    for (var i in serverOptions.value.sortBy) {

      sortOrder.push({
        propertyName: serverOptions.value.sortBy[i],
        sortOrder: serverOptions.value.sortType[i],
      })
    }

    return sortOrder;
}

function apply() {
  clearTimeout(timeout);
  timeout = setTimeout(() => {
    loadFromServer();
  }, wait);
}

const loadFromServer = async () => {
  loading.value = true;
  try {
    const response = await DataGridService.getPage(props.endpoint, serverOptions, searchTerm.value, generateFilters(localFilters.value), getSortOrder())

    items.value = response.items
    totalPaginas.value = response.totalPages
    totalItems.value = response.totalItems

    serverOptions.value.rowsPerPage = response.pageSize;
    itemInicial.value = response.from;
    itemFinal.value = response.to;

    serverItemsLength.value = response.totalItems
  } catch (error) {
    if(error.status == 403) {

    }

    console.log('error.status',error.status)

    console.error("Failed to load data from server:", error);
  } finally {
    loading.value = false;
  }
};

apply();

watch(serverOptions, async () => {
  let sortBy = []

  for (let index in serverOptions.value.sortBy) {
    let name = serverOptions.value.sortBy[index]
    let sortOrder = serverOptions.value.sortType[index]
    sortBy.push({
      propertyName: name,
      sortOrder: sortOrder,
    })
  }

  localSortBy.value = [...sortBy]


  try {
    let filters = {
      statusFilters: localStatusFilters.value,
      filters: localFilters.value,
      sortBy: localSortBy.value,
      rowsPerPage: localRowsPerPage.value
    }

    await DataGridService.saveLocalSettings(currentRoute.value.name, props.section, filters);
  } catch (err) {
    showToast('Não foi possível salvar os filtros', {
      title: 'Alerta',
      type: 'warning'
    });
  }


  apply()
}, { deep: true });

watch(searchTerm, () => apply());




async function OnPagination(data) {
  serverOptions.value.page = data.paginaAtual
  // paginaAtual.value = data.paginaAtual
  serverOptions.value.rowsPerPage = data.porPagina
  localRowsPerPage.value = data.porPagina

  let filters = {
    statusFilters: localStatusFilters.value,
    filters: localFilters.value,
    sortBy: localSortBy.value,
    rowsPerPage: localRowsPerPage.value
  }

  await DataGridService.saveLocalSettings(currentRoute.value.name, props.section, filters);

}

const handleClick = (row) => {
  if (clickTimeout) {
    clearTimeout(clickTimeout)
    clickTimeout = null
    rowDbClick(row)
  } else {
    clickTimeout = setTimeout(() => {
      rowClick(row);
      clickTimeout = null;
    }, 250);
  }
}


const rowClick = (item) => {
  emit('onClickRow', item)
};

const rowDbClick = (item) => {
  emit('onDbClickRow', item, openNewPage.value)
};
////// OnlyMyTasks

async function toggleResponsavel() {
  somenteMeus.value = !somenteMeus.value;
  await DataGridService.saveLocalSettings(currentRoute.value.name, 'somenteMeus', somenteMeus.value);

  apply()
}


/////
///////// Filter

const generateFilters = () => {
  let filterData = getFilterData(props.filterForm, localFilters.value)

  console.log('filterData',filterData)
  console.log('localFilters.value',localFilters.value)


  let statusFilterData = getStatusFilterData(localStatusFilters.value)

  filterData = [...filterData, ...statusFilterData]

  if (somenteMeus.value) {
    filterData.push(
        {
          propertyName: 'responsavelId',
          value: CodigoPessoa(),
          operation: "Equals"
        }
    )
  }

  return filterData
}

const openFilter = () => {
  filterOpened.value = true;
}

const removeNullUndefinedEmptyString = (obj) => {
  console.log('removeNullUndefinedEmptyString', obj)

  return Object.fromEntries(
      Object.entries(obj).filter(([key, value]) => value !== null && value !== undefined && value !== "" && (!Array.isArray(value) || value.length > 0 ))
  );
}

const applyFilters = async (values) => {
  try {
    serverOptions.value.page = 1

    //values = values.filter(p => p.)
    values = removeNullUndefinedEmptyString(values)

    localFilters.value = values
    let filters = {
      statusFilters: localStatusFilters.value,
      filters: localFilters.value,
      sortBy: localSortBy.value,
      rowsPerPage: localRowsPerPage.value
    }

    await DataGridService.saveLocalSettings(currentRoute.value.name, props.section, filters);
  } catch (err) {
    showToast('Não foi possível salvar os filtros', {
      title: 'Alerta',
      type: 'warning'
    });
  }

  apply()
  closeFilter()
}

const closeFilter = () => {
  filterOpened.value = false;
}
const removeMultipleFilter = async (filter, value) => {
  let multipleFilter = localFilters.value[filter.field]

  multipleFilter = multipleFilter.filter((p) =>  p.id != value.id)

  if(multipleFilter.length <= 0) {
    delete localFilters.value[filter.field]
  } else {
    localFilters.value[filter.field] = [...multipleFilter]
  }

  if (Object.keys(localFilters.value).length === 0) {
    localFilters.value = null
  }


  try {
    let filters = {
      statusFilters: localStatusFilters.value,
      filters: localFilters.value,
      sortBy: localSortBy.value,
      rowsPerPage: localRowsPerPage.value,
    }

    await DataGridService.saveLocalSettings(currentRoute.value.name, props.section, filters);
  } catch (err) {
    showToast('Não foi possível salvar os filtros', {
      title: 'Alerta',
      type: 'warning'
    });
  }


  apply()
}

const removeFilter = async (filter) => {
  delete localFilters.value[filter.field]

  if (Object.keys(localFilters.value).length === 0) {
    localFilters.value = null
  }


  try {
    let filters = {
      statusFilters: localStatusFilters.value,
      filters: localFilters.value,
      sortBy: localSortBy.value,
      rowsPerPage: localRowsPerPage.value,
    }

    await DataGridService.saveLocalSettings(currentRoute.value.name, props.section, filters);
  } catch (err) {
    showToast('Não foi possível salvar os filtros', {
      title: 'Alerta',
      type: 'warning'
    });
  }


  apply()
}

const clearFilters = async () => {
  localFilters.value = null

  try {
    let filters = {
      statusFilters: localStatusFilters.value,
      filters: localFilters.value,
      sortBy: localSortBy.value,
      rowsPerPage: localRowsPerPage.value

    }

    await DataGridService.saveLocalSettings(currentRoute.value.name, props.section, filters);
  } catch (err) {
    showToast('Não foi possível salvar os filtros', {
      title: 'Alerta',
      type: 'warning'
    });
  }

  apply()
}

//////// End Filter

function CodigoPessoa() {
  return KeycloakService.GetCodigoPessoa();
}

const hasFilters = computed(() => {
  if(localFilters.value) {
    let checkFilter = JSON.parse(JSON.stringify(localFilters.value))

    if (Object.keys(checkFilter).length > 0) {
      return true
    }
  }

  return false;
});


const addMissingColumns = (colunas) => {
  const missingColumns = props.columns.filter(defaultCol => {
        return !colunas.some(novaCol => novaCol.value === defaultCol.value)
  });

  colunas.push(...missingColumns);

  return colunas
}

const forceReloadRef = toRef(props, 'forceReload');


watch(forceReloadRef, (newVal, oldVal) => {
  console.log('forceReload changed:', oldVal, '->', newVal);
  apply();
});

</script>

<style>
 .dataGrid span.multi-sort__number {
   background-color: #f0f;
   text-align: center;
   font-size: 0.8em;
 }

 .dataGrid .vue3-easy-data-table__footer {
   display: none !important;
 }


 .limited-text {
    width: 100% !important;
    white-space: nowrap;    /* Impede que o texto quebre em várias linhas */
    overflow: hidden;       /* Oculta o excesso de texto */
    text-overflow: ellipsis; /* Adiciona "..." ao final do texto */
 }

.dataGrid .danger-line td {
  background-color: #F28C8ECC !important ;
 }

 .dataGrid .warning-line td {
   background-color: #F7E7A0CC !important;
 }

 .dataGrid * {
   background-color: inherit !important;

 }

 .dark .dataGrid * {
   color: #fff;
 }


 .multi-sort__number {
   background-color: var(--splash-black-color) !important;
   color: var(--splash-white-color) !important;
 }


 .sortable desc {
   color: var(--splash-white-color) !important;
 }

 .dark .multi-sort__number {
   background-color: var(--splash-white-color) !important;
   color: var(--splash-black-color) !important;
 }




 .sortType-icon {
   border-bottom-color:  var(--splash-black-color) !important;
 }

 .dark .sortType-icon {
   border-bottom-color: var(--splash-white-color) !important;
 }

 .dataGrid .pagination .page-link.active {
   color: var(--bs-pagination-color);
 }
</style>
