// src/router/origensDestinos.js
import OrigemDestinoListPage from "@/views/OrigensDestinos/OrigemDestinoListPage.vue";
import NovoOrigemDestinoPage from "@/views/OrigensDestinos/NovoOrigemDestinoPage.vue";

const origensDestinosBreadCrumb = [
    {
        label: 'Home',
        to: 'Home'
    },
    {
        label: 'OrigensDestinos',
        to: 'OrigensDestinos'
    }
]

export default [
    {
        path: '/origens-destinos',
        name: 'OrigensDestinos',
        component: OrigemDestinoListPage,
        meta: {
            title: 'Origens/Destinos',
            breadcrumb: origensDestinosBreadCrumb
        },
    },
    {
        path: '/origens-destinos/novo',
        name: 'OrigemDestinoNovo',
        component: NovoOrigemDestinoPage,
        meta: {
            title: 'Novo Origem/Destino',
            breadcrumb: [
                ...origensDestinosBreadCrumb,
                {
                    label: 'Nova Origem/Destino',
                    to: 'ClienteNovo'
                }
            ]
        }
    },
    {
        path: '/origens-destinos/:id/edit',
        name: 'OrigemDestinoEdit',
        component: NovoOrigemDestinoPage,
        meta: {
            title: 'Detalhes da Origem/Destino',
            breadcrumb: [
                ...origensDestinosBreadCrumb,
                {
                    label: 'Nova Origem/Destino',
                    to: 'OrigemDestinoNovo'
                }
            ]
        }
    }

];
